<template>
  <button
    v-bind="$attrs"
    v-on="$listeners"
    class="btn d-inline-flex align-items-center"
    :class="[variantClass, sizeClass]"
    :disabled="disabled || isLoading"
  >
    <slot />
    <div
      v-if="isLoading"
      class="spinner-border spinner-border-sm ml-2"
      role="status"
    />
  </button>
</template>
<script setup lang="ts">
import { computed, toRefs } from "vue";

/**
 * @todo find out why we get `null` when importing directly, probably a circular reference?
 */
type DataVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

const props = withDefaults(
  defineProps<{
    variant?: DataVariant | "link" | "tertiary";
    size?: "small" | "large";
    disabled?: boolean;
    isLoading?: boolean;
  }>(),
  {
    variant: "primary",
  }
);

const { variant, size } = toRefs(props);
const variantClass = computed(
  () => `btn-${variant.value === "tertiary" ? "grey" : variant.value}`
);
const sizeClass = computed(() =>
  size.value ? `btn-${{ small: "sm", large: "lg" }[size.value]}` : ""
);
</script>
