<template>
  <div>
    <div class="d-flex justify-content-between align-items-top">
      <!-- avatar -->
      <div class="channel-row-icon my-2">
        <img v-if="message.avatar" :src="message.avatar" alt="user-img" />
        <span class="text-center" v-else>
          {{ message.initials }}
        </span>
      </div>

      <!-- message info -->
      <div class="channel-row-text mr-auto" style="width: 100%; ">
        <div class="ml-3 my-2">
          <span class="font-weight-normal">
            {{ message.userName }}
          </span>
          <span class="chat-subtext">
            {{ message.created }}
          </span>
        </div>
        <div class="ml-3" v-if="!editingMessage">
          <div class="message-text">{{ message.comment }}</div>
          <small class="deleted-time" v-if="message.deleted">{{
            message.deleted
          }}</small>
        </div>

        <div class="ml-3" v-if="editingMessage">
          <div class="growing-message-container">
            <textarea-autosize
              ref="myTextarea"
              v-model="editedMessage"
              :max-height="250"
            />
          </div>

          <div class="mt-2 edit-channel">
            <button class="btn btn-grey action-btn" @click="saveEditedMessage">
              <i class="far fa-check"></i>
            </button>
            <button
              class="btn btn-grey cancel-btn"
              @click="cancelEditingMessage"
            >
              <i class="fal fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <!-- message settings -->
      <div class="hidden-dropdown" v-if="!message.deleted">
        <div class="dropdown">
          <button
            type="button"
            class="btn icon-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-v"></i>
          </button>

          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              v-if="message.isOwner"
              @click="toggleEditingMessage(true, message.comment)"
              >{{ trans("chat.editMessageButton") }}</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click="copyMessage(message.comment)"
              >{{ trans("chat.copyMessageButton") }}</a
            >
            <a
              class="dropdown-item"
              href="#"
              v-if="message.isOwner"
              @click="deleteMessage(message.id)"
              >{{ trans("chat.deleteMessageButton") }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end" v-if="message.readByUsers">
      <span class="chat-subtext">
        <span
          class="chat-message-initials"
          v-for="(initials, i) in message.readByUsers"
          :key="i"
          >{{ initials }}</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: Object,
  },
  data() {
    return {
      editingMessage: false,
      editedMessage: null,
    };
  },
  methods: {
    toggleEditingMessage: function(bool, messageText) {
      this.$emit("toggleEditMessage", true);
      this.editedMessage = messageText;
      this.editingMessage = bool;
    },
    cancelEditingMessage: function() {
      this.editedMessage = null;
      this.editingMessage = false;
    },
    saveEditedMessage: function() {
      this.$emit("saveEditedMessage", this.message, this.editedMessage);
      this.toggleEditingMessage(false);
    },
    copyMessage: function(messageText) {
      this.$emit("copyMessage", messageText);
    },
    deleteMessage: function(messageId) {
      this.$emit("deleteMessage", messageId);
    },
  },
};
</script>
