<template>
  <field-panel
    :title="title"
    :infoPopupText="getInfoPopupText()"
    :newDocumentationButton="
      !addNewDocumentation
        ? {
            onAddNewDocumentation: this.onAddNewDocumentation,
          }
        : null
    "
    :totalComments="totalComments"
  >
    <examination-documentation
      v-if="mappedFields.length"
      :examinationId="examination.id"
      :enabledTypes="mappedFields[0].extraOptions.enabledTypes"
      :addNewDocumentation="addNewDocumentation"
      :productField="documentationProductField"
      @dismissAddNewDocumentation="dismissAddNewDocumentation"
      @setTotalComments="setTotalComments"
    ></examination-documentation>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import ExaminationDocumentation from "./ExaminationDocumentation.vue";

import { getFieldType } from "../../../../helpers/product";
import { canRead, canWrite } from "../componentMapper";
import { infoPopupText } from "../../../../helpers/misc";
import _find from "lodash/fp/find";

export default {
  mounted: function() {
    this.mapData();
  },
  props: {
    /** @type ProductField[] */
    fields: Array,
    examination: Object,
    fieldGroupName: String,
  },
  data() {
    return {
      title: "examination.consultBoxLabel",
      mappedFields: [],
      addNewDocumentation: false,
      totalComments: null,
    };
  },
  computed: {
    documentationProductField() {
      return _find({ name: "documentation" }, this.fields);
    },
  },
  methods: {
    mapData: function() {
      const { fields } = this;
      const userPermissions = JSON.parse(this.$store.state.user.permissions);
      const mappedFields = [];
      fields.forEach(field => {
        const mappedField = {
          title: field.title,
          type: getFieldType(field),
        };
        if ("value_options" in field) {
          const enabledTypes = [];
          JSON.parse(field.value_options).forEach(option => {
            enabledTypes.push({
              name: option.name,
              edit:
                "write_permission" in option
                  ? canWrite({ name: option.write_permission }, userPermissions)
                  : true,
              read:
                "read_permission" in option
                  ? canRead({ name: option.read_permission }, userPermissions)
                  : true,
            });
          });
          mappedField.extraOptions = {
            enabledTypes: enabledTypes,
          };
        }
        mappedFields.push(mappedField);
      });
      this.mappedFields = mappedFields;
    },
    onAddNewDocumentation: function() {
      this.addNewDocumentation = true;
    },
    dismissAddNewDocumentation: function(bool) {
      this.addNewDocumentation = bool;
    },
    setTotalComments: function(num) {
      this.totalComments = num;
    },
    getInfoPopupText: function() {
      return this.mappedFields.length > 0
        ? infoPopupText(
            this.mappedFields[0].extraOptions.enabledTypes,
            this.fieldGroupName
          )
        : "";
    },
  },
  components: {
    FieldPanel,
    ExaminationDocumentation,
  },
};
</script>
