import { createPopper } from "@popperjs/core";

export function calculatePositionWithPopper(
  dropdownList,
  component,
  { width }
) {
  dropdownList.style.width = "auto";
  dropdownList.style.minWidth = width;

  const popper = createPopper(component.$refs.toggle, dropdownList, {
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, -1],
        },
      },
      {
        name: "toggleClass",
        enabled: true,
        phase: "write",
        fn({ state }) {
          component.$el.classList.toggle("drop-up", state.placement === "top");
        },
      },
    ],
  });

  return () => popper.destroy();
}
