<template>
  <Panel :title="lang.get('quicksilva.title')">
    <div v-if="hasToken">
      <p class="!tw-mb-4">{{ lang.get("quicksilva.active") }}</p>
      <Button
        type="button"
        variant="danger"
        @click="handleRevoke"
        :is-loading="revokeUserQuicksilva.isLoading.value"
        >{{ lang.get("quicksilva.revoke") }}</Button
      >
    </div>
    <div v-else>
      <form @submit.prevent="handleSubmit">
        <div class="form-group" :class="{ 'has-error': errorMessages.length }">
          <label for="token">{{ lang.get("quicksilva.token") }}</label>
          <div class="tw-flex tw-gap-2">
            <input
              type="text"
              id="token"
              class="form-control"
              v-model="accessCode"
            />
            <Button :is-loading="registerUserQuicksilva.isLoading.value">{{
              lang.get("quicksilva.connect")
            }}</Button>
          </div>
          <form-error
            v-if="errorMessages.length"
            :errors="errorMessages"
          ></form-error>
        </div>
        <small>{{ lang.get("quicksilva.toolbar") }}</small>
      </form>
    </div>
  </Panel>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { z } from "zod";
import { lang } from "../../i18n";
import {
  useUserQuicksilva,
  useUserQuicksilvaRegister,
  useUserQuicksilvaRevoke,
} from "../../store/api/use-user-quicksilva-resource";
import Button from "../Layout/Button.vue";
import Panel from "../Layout/Panel.vue";

const userQuicksilvaResource = useUserQuicksilva();
const registerUserQuicksilva = useUserQuicksilvaRegister();
const revokeUserQuicksilva = useUserQuicksilvaRevoke();

const hasToken = computed(() => userQuicksilvaResource.data.value?.token);

const accessCode = ref<string>();

const accessCodeSchema = z
  .string()
  .length(8, { message: lang.get("quicksilva.tokenLengthError") });

const formErrors = ref<z.ZodFormattedError<typeof accessCodeSchema> | null>(
  null
);

const errorMessages = computed(() => {
  if (formErrors.value) {
    return formErrors.value._errors;
  }

  if (registerUserQuicksilva.error.value instanceof Error) {
    const userError =
      lang.get(registerUserQuicksilva.error.value.message) ??
      registerUserQuicksilva.error.value.message;
    return [userError];
  }

  return [];
});

function handleSubmit() {
  const parsedValue = accessCodeSchema.safeParse(accessCode.value);
  if (parsedValue.success) {
    formErrors.value = null;
    registerUserQuicksilva.mutate(parsedValue.data);
  } else {
    formErrors.value = parsedValue.error.format();
  }
}

function handleRevoke() {
  revokeUserQuicksilva.mutate();
}
</script>
