<template>
  <div class="panel panel-default panel-actions">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.generalHeadline") }}

        <span class="actions" v-if="examination">
          <button
            class="btn btn-custom float-right"
            type="button"
            @click.stop="editWound(true)"
            v-if="!isEditing && !disableEdit"
          >
            {{ trans("examination.diagnosReplyEditButton") }}
          </button>
          <button
            class="btn btn-custom float-right submit"
            type="button"
            @click.stop="submitChanges"
            v-if="isEditing"
          >
            {{ trans("general.formSaveLabel") }}
          </button>
          <button
            class="btn btn-custom float-right cancel"
            type="button"
            @click.stop="editWound(false)"
            v-if="isEditing"
          >
            {{ trans("general.modalCancelButton") }}
          </button>
        </span>
      </h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.ssn") }}</strong>
          <br />
          <p class="text-muted">{{ patient.ssn }}</p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.fullName") }}</strong>
          <br />
          <p
            class="text-muted"
            v-dompurify-html:fullName="patient.fullName"
          ></p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.age") }}</strong>
          <br />
          <p class="text-muted">{{ patient.age }}</p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.gender") }}</strong>
          <br />
          <p class="text-muted">{{ patient.gender }}</p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.patientViewLastUpdated") }}</strong>
          <br />
          <p v-if="examination" class="text-muted">
            {{ examination.createdAt }}
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("examination.centerLabel") }}</strong>
          <br />
          <p v-if="examination" class="text-muted">
            {{ examination.center }}
          </p>
        </div>

        <div class="m-b-20 col-md-6">
          <strong>{{ trans("patient.patientViewUpdatedBy") }}</strong>
          <br />
          <p v-if="examination" class="text-muted">
            {{ examination.createdUser }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {
      age: String,
      gender: String,
      createdAt: String,
      center: String,
      createdUser: String,
    },
    patient: {
      gender: String,
      age: String,
      fullName: String,
      ssn: String,
    },
    isEditing: Boolean,
    disableEdit: Boolean,
  },
  methods: {
    editWound: function(bool) {
      this.$emit("editWound", bool);
    },
    submitChanges: function() {
      this.$emit("submitWoundChanges");
    },
  },
};
</script>
