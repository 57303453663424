import Vuex from "vuex";
import URLS from "./config/urls";
import { userStudyResultGet } from "./store/core/users";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    sidebarIsOpen: false,
    chatIsOpen: false,
    isAuthenticated: false,
    user: {},
    primaryCenterName: null,
    isSplashScreen: true,
    dermicusPlatform: window.dermicusPlatform,
    cancelTokens: [],
    products: {},
    currentPatient: {
      patientCases: [],
      productType: undefined,
      patientId: undefined,
    },
    currentExamination: {
      examinationId: undefined,
      examinationData: {},
    },
    currentStudyWorkflow: {
      studyWorkflow: undefined,
    },
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarIsOpen = !state.sidebarIsOpen;
      state.chatIsOpen = false;
    },
    closeSidebar(state) {
      state.sidebarIsOpen = false;
    },
    toggleChat(state) {
      state.chatIsOpen = !state.chatIsOpen;
      state.sidebarIsOpen = false;
    },
    closeChat(state) {
      state.chatIsOpen = false;
    },
    addCancelToken(state, payload) {
      state.cancelTokens.push(payload.token);
    },
    resetCancelTokens(state) {
      state.cancelTokens = [];
    },
    setAuthenticated(state, payload) {
      state.isAuthenticated = payload;
    },
    setUser(state, payload) {
      state.user = payload;
    },
    setUserAvatar(state, payload) {
      state.user = {
        ...state.user,
        avatar: payload,
      };
    },
    setPrimaryCenterName(state, payload) {
      state.primaryCenterName = payload;
    },
    saveProduct(state, payload) {
      state.products = {
        ...state.products,
        [payload.id]: payload.product,
      };
    },
    setCurrentPatientCases(state, payload) {
      state.currentPatient = {
        ...state.currentPatient,
        patientCases: payload,
      };
    },
    setCurrentPatientId(state, payload) {
      state.currentPatient = {
        ...state.currentPatient,
        patientId: payload,
      };
    },
    setCurrentPatientProductType(state, payload) {
      state.currentPatient = {
        ...state.currentPatient,
        productType: payload,
      };
    },
    resetCurrentPatient(state) {
      state.currentPatient = {
        patientCases: [],
        productType: undefined,
        patientId: undefined,
      };
    },
    setCurrentExamination(state, payload) {
      state.currentExamination = {
        examinationId: payload,
      };
    },
    updateExamination(state, payload) {
      state.currentExamination = {
        examinationData: payload,
      };
    },
    setCurrentStudyWorkflow(state, payload) {
      state.currentStudyWorkflow = {
        studyWorkflow: payload,
      };
    },
  },
  actions: {
    async loadPatientCases({ commit, state }) {
      const { patientId, productType } = state.currentPatient;
      try {
        const route = "/api/internal/patients/tumour-wound";
        const response = await axios.get(
          `${route}/${patientId}/${productType}`
        );
        commit("setCurrentPatientCases", response.data.examinations);
      } catch (error) {
        console.log("Get examinations error: ", error);
      }
    },
    async reloadExamination({ commit, state }) {
      const { examinationId } = state.currentExamination;

      if (examinationId) {
        try {
          const route = `${URLS.TUMOUR_WOUND}/${examinationId}`;
          const response = await axios.get(`${route}`);
          commit("updateExamination", response.data.examination);
        } catch (error) {
          console.log("Reload examination error: ", error);
        }
      }
      return;
    },
    async loadStudyWorkflow({ commit }, payload) {
      try {
        commit(
          "setCurrentStudyWorkflow",
          await userStudyResultGet(payload.userId, payload.studyExaminationId, {
            refresh: payload.refresh,
          })
        );
      } catch (error) {
        console.log("get workflow failed error: ", error);
      }
    },
  },
});
