<template>
  <div class="educations mt-4">
    <clip-loader v-show="loading"></clip-loader>

    <div v-if="!loading && hasEducationActive">
      <clip-loader v-show="loading"></clip-loader>

      <div class="table-responsive">
        <table class="table table-hover table-actions-bar interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th
                class="sort-by"
                @click="sortBy('id', $event)"
                :class="sortBySelection === 'id' ? 'table-active' : ''"
              >
                {{ trans("evaluation.tableColumnCaseNo") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th>
                {{ trans("evaluation.tableColumnStatus") }}
              </th>
              <th
                class="sort-by"
                v-if="hasMultipleFeatures"
                @click="sortBy('type', $event)"
                :class="sortBySelection === 'type' ? 'table-active' : ''"
              >
                {{ trans("evaluation.tableColumnProduct") }}
                <i class="fas fa-caret-down"></i>
              </th>
              <th
                class="sort-by"
                @click="sortBy('createdAt', $event)"
                :class="sortBySelection === 'createdAt' ? 'table-active' : ''"
              >
                {{ trans("evaluation.tableColumnCreatedAt") }}
                <i class="fas fa-caret-down"></i>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="openExamination(examination)"
              v-for="(examination, index) in examinations"
              :key="index"
            >
              <td>{{ examination.caseNo }}</td>
              <td>
                <case-status
                  :examination="examination"
                  :hideLabel="true"
                ></case-status>
              </td>
              <td>{{ examination.createdAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <paginate
          :pageCount="paginate.last_page"
          :click-handler="getResults"
          :prev-text="'‹'"
          :next-text="'›'"
          :containerClass="'pagination'"
          ref="paginate"
          v-model="searchFields.currentPage"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :active-class="'page-item active'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
        >
        </paginate>
      </div>
    </div>

    <div class="educations-bg-image" v-if="!hasEducationActive && !loading">
      <div
        class="educations-text"
        v-if="salesInfo !== undefined"
        v-dompurify-html="salesInfo.data"
      ></div>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { educationModuleSalesInfoGet } from "../../app/practitioner-portal/education/store/education-module-sales-info";

const { state: salesInfo } = useAsyncState(
  educationModuleSalesInfoGet(),
  undefined
);
</script>
<script>
import Datepicker from "vuejs-datepicker";
import CaseStatus from "../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";

export default {
  created: function() {
    this.getResults();
  },
  data() {
    return {
      loading: true,
      dermicusPlatform: window.dermicusPlatform,
      hasEducationActive: false,
      examinations: [],
      hasMultipleFeatures: false,
      isAdvancedSearchOpen: false,
      searchOptions: {
        centers: [],
        users: [],
        products: [],
      },
      isSearching: false,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      searchFields: {
        query: "",
        sort: {
          field: "id",
          orderBy: "DESC",
        },
        currentPage: 1,
      },
      sortBySelection: "createdAt",
    };
  },
  watch: {
    paginate: function(page) {
      this.$nextTick(() => {
        this.$refs.paginate.selected = this.paginate.current_page - 1;
      });
    },
  },
  methods: {
    getResults(page) {
      var instance = this;
      instance.loading = true;

      this.searchFields.currentPage = page
        ? page
        : this.searchFields.currentPage;
      localStorage.setItem(
        "last_education_query_uri",
        JSON.stringify(this.searchFields)
      );

      let queryUri =
        "?q=" +
        this.searchFields.query +
        "&page=" +
        this.searchFields.currentPage +
        "&orderByField=" +
        this.searchFields.sort.field +
        "&orderBy=" +
        this.searchFields.sort.orderBy;

      axios
        .get("/api/internal/educations" + queryUri)
        .then(data => {
          let collection = data.data;
          instance.examinations = collection.data;
          instance.paginate = collection.paginate;
          this.hasEducationActive = true;
          instance.loading = false;

          if (this.$refs.paginate) {
            this.$refs.paginate.selected = collection.paginate.current_page - 1;
          }
        })
        .catch(error => {
          instance.loading = false;
          instance.hasEducationActive ? instance.userErrorMessage(error) : null;
        });
    },
    openExamination: function(examination) {
      this.$router.push({ path: examination.editUrl });
    },
    sortBy: function(field, event) {
      var instance = this;
      this.sortBySelection = field;

      instance.loading = true;
      // Reset all to bottom
      if (field !== instance.searchFields.sort.field) {
        $("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
        instance.searchFields.sort.orderBy = "DESC";
      }
      instance.searchFields.sort.field = field;
      if (this.searchFields.sort.orderBy == "ASC") {
        instance.searchFields.sort.orderBy = "DESC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-up")
          .removeClass("fa-caret-up")
          .addClass("fa-caret-down");
      } else {
        instance.searchFields.sort.orderBy = "ASC";
        $(event.currentTarget)
          .find("i.fas.fa-caret-down")
          .removeClass("fa-caret-down")
          .addClass("fa-caret-up");
      }
      return this.getResults(this.currentPage);
    },
  },
  components: {
    Datepicker,
    CaseStatus,
  },
};
</script>
