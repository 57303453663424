<template>
  <div>
    <div class="back-button py-2 px-1 mb-2" @click="backToAllChannels">
      <i class="fal fa-arrow-left"></i>
      <span class="px-2">{{ trans("general.back") }}</span>
    </div>

    <clip-loader v-if="!channel && loading"></clip-loader>

    <div v-if="channel" class="chat-conversation-container d-flex flex-column">
      <channel-header
        :channel="channel"
        @toggleAddingPeopleToChannel="toggleAddingPeopleToChannel"
        @toggleRemovingPeopleFromChannel="toggleRemovingPeopleFromChannel"
        @toggleChangeChannelName="toggleChangeChannelName"
      >
        <edit-channel-name
          :showEditChannelName="showEditChannelName"
          :channelName="channel.name"
          @changeChannelName="changeChannelName"
          @toggleChangeChannelName="toggleChangeChannelName"
        ></edit-channel-name>

        <add-users-to-channel
          :addingPeopleToChannel="addingPeopleToChannel"
          :participants="availableParticipants"
          @toggleAddingPeopleToChannel="toggleAddingPeopleToChannel"
          @addNewParticipants="addNewParticipants"
        ></add-users-to-channel>

        <remove-channel-users
          :removingPeopleFromChannel="removingPeopleFromChannel"
          :channelParticipants="channel.participants"
          @toggleRemovingPeopleFromChannel="toggleRemovingPeopleFromChannel"
          @removeParticipants="removeParticipants"
        ></remove-channel-users>
      </channel-header>

      <div
        class="chat-conversation-message-container mb-auto"
        id="chatMessagesContainer"
      >
        <div v-if="loading" class="overlay"><clip-loader></clip-loader></div>

        <div
          v-if="!loading && channelMessages && channelMessages.length === 0"
          class="text-center"
          style="position: relative; top: 50%;"
        >
          {{ trans("chat.channelHasNoMessagesInfo") }}
        </div>

        <div
          v-for="(message, index) in filteredChannelMessages"
          :key="index"
          class="message-row"
          :class="{ 'deleted-message': message.deleted }"
        >
          <channel-message-row
            :message="message"
            @toggleEditingMessage="toggleEditingMessage"
            @saveEditedMessage="saveEditedMessage"
            @copyMessage="copyMessage"
            @deleteMessage="deleteMessage"
          ></channel-message-row>
        </div>
      </div>

      <div class="chat-conversation-write-message-container ">
        <div class="mt-auto">
          <div class="growing-message-container" style="width: 100%;">
            <textarea-autosize
              rows="1"
              name="text"
              id="text"
              :placeholder="trans('chat.addCommentPlaceholder')"
              v-model="commentText"
              :max-height="250"
            />
          </div>

          <div class="tw-flex tw-justify-end">
            <div class="btn-group dropdown mb-2 ml-2">
              <button
                type="button"
                class="btn btn-primary"
                @click="sendMessage"
                :disabled="!commentText || loading"
              >
                {{ trans("chat.sendMesssage") }}
              </button>
              <button
                type="button"
                class="btn btn-primary dropdown-toggle-split"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="!commentText || loading"
              >
                <i class="fal fa-angle-down"></i>
              </button>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" @click="sendMessage">{{
                  trans("chat.sendMesssage")
                }}</a>
                <a
                  class="dropdown-item"
                  href="#"
                  @click="sendMessageAndNotify"
                  >{{ trans("chat.sendMessageWithNotification") }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div
          class="form-group col-md-12"
          :class="{ 'has-error': errors.text }"
          v-if="errors.text"
        >
          <form-error :errors="errors.text"></form-error>
        </div>
      </div>
    </div>

    <div v-if="!loading && channel" class="text-center py-2">
      <small>
        {{ trans("examination.consultCommentInfoText") }}
      </small>
    </div>
  </div>
</template>

<script>
import ChannelHeader from "./Utils/ChannelHeader.vue";
import EditChannelName from "./Utils/EditChannelName.vue";
import AddUsersToChannel from "./Utils/AddUsersToChannel.vue";
import RemoveChannelUsers from "./Utils/RemoveChannelUsers.vue";
import ChannelMessageRow from "./Utils/ChannelMessageRow.vue";
import ExternalResource from "./Utils/ExternalResource";

export default {
  components: {
    ChannelHeader,
    EditChannelName,
    AddUsersToChannel,
    RemoveChannelUsers,
    ChannelMessageRow,
  },
  props: {
    editSaveUri: String,
    channelId: Number,
  },
  mounted: function() {
    this.getChannelInfo();
  },
  data() {
    return {
      loading: false,
      commentText: null,
      channel: null,
      channelMessages: null,
      availableParticipants: [],
      showEditChannelName: false,
      addingPeopleToChannel: false,
      removingPeopleFromChannel: false,
      errors: [],
    };
  },
  computed: {
    filteredChannelMessages() {
      /**
       * Filter out messages without comment, i.e. messages with only the 'usersReadToHere' property.
       */
      return this.channelMessages.filter(message => message.comment);
    },
  },
  methods: {
    backToAllChannels: function() {
      this.$emit("backToAllChannels");
    },
    sendMessage: function(extraNotification) {
      this.loading = true;

      let message = this.commentText;
      this.errors = [];
      let override = extraNotification === 1 || null;

      if (message) {
        axios
          .post(`${this.editSaveUri}/${this.channelId}/messages`, {
            _token: Laravel.csrfToken,
            text: message,
            override,
          })
          .then(() => {
            this.loading = false;
            message = null;
            this.commentText = null;
            this.getConversation();
          })
          .catch(error => {
            this.loading = false;
            this.errors = error.response.data;
            this.userErrorMessage(error);
          });
      }
    },
    sendMessageAndNotify: function() {
      this.sendMessage(1);
    },
    getChannelInfo: function() {
      this.loading = true;

      axios
        .get(`${this.editSaveUri}/${this.channelId}`)
        .then(response => {
          this.channel = response.data.channel;
          this.channelMessages = response.data.messages;

          if (this.channel.has_notification) {
            this.$emit("updateTopNavigation");
          }

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    // same as get Channel info but updates only messages
    getConversation: function() {
      this.loading = true;

      axios
        .get(`${this.editSaveUri}/${this.channelId}`)
        .then(response => {
          this.channelMessages = response.data.messages;
          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    // same as get Channel info but updates only users
    updateChannelUsers: function() {
      this.loading = true;

      axios
        .get(`${this.editSaveUri}/${this.channelId}`)
        .then(response => {
          this.channel.participants = response.data.channel.participants;
          this.channel.name = response.data.channel.name;

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    toggleAddingPeopleToChannel: function(bool) {
      this.addingPeopleToChannel = bool;
      if (bool) {
        this.getUsers();
      }
    },
    toggleRemovingPeopleFromChannel: function(bool) {
      this.removingPeopleFromChannel = bool;
    },
    getUsers: function() {
      this.loading = true;

      if (
        this.availableParticipants &&
        this.availableParticipants.length === 0
      ) {
        axios
          .get(this.editSaveUri + "/user/candidates")
          .then(response => {
            let availableParticipantsWithoutCurrentUser = response.data.users.filter(
              user => user.id !== this.$store.state.user.id
            );

            this.availableParticipants = availableParticipantsWithoutCurrentUser.filter(
              x => !this.channel.participants.some(y => x.id === y.id)
            );

            this.loading = false;
          })
          .catch(error => {
            this.userErrorMessage(error);
            this.loading = false;
          });
      } else {
        this.availableParticipants = this.availableParticipants.filter(
          x => !this.channel.participants.some(y => x.id === y.id)
        );
      }
      this.loading = false;
    },
    addNewParticipants: function(newParticipants) {
      if (
        ExternalResource.isExternalResouceId(
          this.availableParticipants,
          newParticipants
        ).isExternalResources
      ) {
        this.$dialog
          .confirm(
            {
              title: this.trans(
                "chat.startConversationWithExternalResourceHeader"
              ),
              body: `${
                ExternalResource.isExternalResouceId(
                  this.availableParticipants,
                  newParticipants
                ).externalResourcesList
              } ${this.trans("chat.addExternalResourceToChannelBody")}`,
            },
            {
              okText: this.trans("general.modalConfirmButton"),
              cancelText: this.trans("general.modalCancelButton"),
            }
          )
          .then(() => {
            this.addParticipants(newParticipants);
          })
          .catch(() => {});
      } else {
        this.addParticipants(newParticipants);
      }
    },
    addParticipants: function(newParticipants) {
      newParticipants.forEach(user => {
        axios
          .post(this.editSaveUri + "/" + this.channelId + "/members", {
            _token: Laravel.csrfToken,
            user: user,
          })
          .then(() => {
            this.updateChannelUsers();
          })
          .catch(error => {
            this.userErrorMessage(error);
          });
      });

      this.addingPeopleToChannel = false;
    },
    removeParticipants: function(usersToKeep) {
      let usersToRemove = [];

      if (usersToKeep && usersToKeep.length !== 0) {
        usersToRemove = this.channel.participants.filter(
          ({ id }) => !usersToKeep.includes(id)
        );
      }

      usersToRemove.forEach(({ id }) => {
        axios
          .delete(`${this.editSaveUri}/${this.channelId}/members/${id}`, {
            _token: Laravel.csrfToken,
          })
          .then(() => {
            this.loading = false;
            this.updateChannelUsers();
            this.toggleRemovingPeopleFromChannel(false);
          })
          .catch(error => {
            console.log("delete error: ", error);
          });
      });
    },
    toggleChangeChannelName: function(bool) {
      this.showEditChannelName = bool;
    },
    changeChannelName: function(newChannelName) {
      axios
        .post(this.editSaveUri + "/" + this.channelId, {
          _token: Laravel.csrfToken,
          channelName: newChannelName,
        })
        .then(response => {
          this.channel.name = response.data.name;
          this.toggleChangeChannelName(false);
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    deleteMessage: function(messageId) {
      axios
        .delete(
          this.editSaveUri + "/" + this.channelId + "/messages/" + messageId,
          { _token: Laravel.csrfToken }
        )
        .then(() => {
          this.getConversation();
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    copyMessage: function(messageText) {
      this.$copyText(messageText)
        .then(() => {
          this.$notify({
            text: this.trans("general.notificationTextCopy"),
            type: "success",
            duration: 1000,
          });
        })
        .catch(error => {
          console.log("Couldn't copy: ", error);
        });
    },
    toggleEditingMessage: function(bool) {
      this.editingMessage = bool;
    },
    saveEditedMessage: function(comment, message) {
      this.loading = true;
      let url =
        this.editSaveUri + "/" + this.channelId + "/messages/" + comment.id;
      axios
        .post(url, {
          _token: Laravel.csrfToken,
          text: message,
        })
        .then(() => {
          this.loading = false;
          this.toggleEditingMessage(false);
          this.getConversation();
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    scrollToBottom: function() {
      let container = this.$el.querySelector("#chatMessagesContainer");
      if (container) container.scrollTop = container.scrollHeight;
    },
  },
  watch: {
    channelMessages: function() {
      this.$nextTick(() => {
        this.scrollToBottom();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-conversation-container {
  border: 2px solid #dee2e6;
  min-height: 84vh;
  width: auto;
  border-radius: 5px;
}

.chat-conversation-message-container {
  overflow-y: auto;
  height: 62vh;
  position: relative;
}

.chat-conversation-write-message-container {
  padding: 1rem;
  width: 100%;
  border-top: 2px solid #dee2e6;
  background-color: #f5f5f5;
}

.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff87;
}
</style>
