<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.consultationHeadline") }}
      </h3>
    </div>
    <div class="panel-body">
      <div class="m-b-20 col-md-12 row">
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.lesionLocation") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionLocalizationBodyPart }}
            <span v-if="examination.case.lesionLocalizationLaterality"
              >({{ examination.case.lesionLocalizationLaterality }})</span
            >
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.otherComment") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.otherComment }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.reasonForVisit") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.reasonForVisit }}
          </p>
        </div>
      </div>

      <!-- <div class="m-b-20 col-md-12 row">
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.lesionLocationComment") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionLocationComment }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.lesionSize") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionSize }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.earlierConsultation") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.earlierConsultation }}
          </p>
        </div>
      </div> -->

      <div class="m-b-20 col-md-12 row">
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.lesionExistence") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionExistence }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.lesionChange") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionChange }}
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{
            trans("examination.lesionChangeNewlyDiscovered")
          }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.lesionChangeNewlyDiscovered }}
          </p>
        </div>
      </div>

      <div class="m-b-20 col-md-12 row">
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.theCharacterOfTheChange") }}</strong>
          <br />
          <p class="text-muted">
            <span v-if="examination.case.lesionChangeCharacteristicsNoChange"
              >{{ trans("examination.lesionChangeCharacteristicsNoChange")
              }}<br
            /></span>
            <span v-if="examination.case.lesionChangeCharacteristicsSize"
              >{{ trans("examination.lesionChangeCharacteristicsSize") }}<br
            /></span>
            <span v-if="examination.case.lesionChangeCharacteristicsElevated"
              >{{ trans("examination.lesionChangeCharacteristicsElevated")
              }}<br
            /></span>
            <span v-if="examination.case.lesionChangeCharacteristicsForm"
              >{{ trans("examination.lesionChangeCharacteristicsForm") }}<br
            /></span>
            <span v-if="examination.case.lesionChangeCharacteristicsColor"
              >{{ trans("examination.lesionChangeCharacteristicsColor") }}<br
            /></span>
          </p>
        </div>
        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.symptomsFromTheLesion") }}</strong>
          <br />
          <p class="text-muted">
            <span v-if="examination.case.lesionSymptomsNoSymptom"
              >{{ trans("examination.lesionSymptomsNoSymptom") }}<br
            /></span>
            <span v-if="examination.case.lesionSymptomsItch"
              >{{ trans("examination.lesionSymptomsItch") }}<br
            /></span>
            <span v-if="examination.case.lesionSymptomsBleed"
              >{{ trans("examination.lesionSymptomsBleed") }}<br
            /></span>
            <span v-if="examination.case.lesionSymptomsSecretion"
              >{{ trans("examination.lesionSymptomsSecretion") }}<br
            /></span>
          </p>
        </div>

        <div class="m-b-20 col-md-4">
          <strong>{{ trans("examination.totalBodyScan") }}</strong>
          <br />
          <p class="text-muted">
            {{ examination.case.totalBodyScan }}
          </p>
        </div>
      </div>

      <div class="m-b-20 col-md-12 row">
        <div class="m-b-20 col-md-12">
          <strong>{{ trans("examination.approval") }}</strong>
          <br />
          <p class="text-muted">
            <i
              v-if="examination.case.consentPhoto"
              class="fal fa-check-circle"
            ></i>
            <i
              v-else-if="!examination.case.consentPhoto"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentPhoto") }}<br />
            <i
              v-if="examination.case.consentJournal"
              class="fal fa-check-circle"
            ></i>
            <i
              v-else-if="!examination.case.consentJournal"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentJournal") }}<br />
            <i
              v-if="examination.case.consentEducation"
              class="fal fa-check-circle"
            ></i>
            <i
              v-else-if="!examination.case.consentEducation"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentEducation") }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {
      case: {
        lesionLocalizationBodyPart: String,
        lesionLocalizationLaterality: String,
        otherComment: String,
        //   lesionLocationComment
        //   lesionSize
        //  earlierConsultation
        reasonForVisit: String,
        lesionExistence: String,
        lesionChange: String,
        lesionChangeNewlyDiscovered: String,
        lesionChangeCharacteristicsNoChange: Number,
        lesionChangeCharacteristicsSize: Number,
        lesionChangeCharacteristicsElevated: Number,
        lesionChangeCharacteristicsForm: Number,
        lesionChangeCharacteristicsColor: Number,
        lesionSymptomsNoSymptom: Number,
        lesionSymptomsItch: Number,
        lesionSymptomsBleed: Number,
        lesionSymptomsSecretion: Number,
        totalBodyScan: String,
        consentPhoto: Number,
        consentJournal: Number,
        consentEducation: Number,
      },
    },
  },
};
</script>
