import { queryClient } from "./index";
import { useMutation, useQuery } from "@tanstack/vue-query";
import Vue, { computed } from "vue";
import {
  centerFeatureDisable,
  centerFeatureEnable,
  centerFeatureGet,
} from "../core/center-settings";
import { CenterFeatureName } from "./../../../../../app/Dermicus/Centers/CenterFeatureName";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { lang } from "../../i18n";

export function useCenterFeature(
  centerId: number,
  featureName: CenterFeatureName
) {
  const centerFeatureResource = useQuery(
    computed(() => centerFeatureGet(centerId, featureName))
  );

  return centerFeatureResource;
}

export function useCenterFeatureEnable() {
  const mutation = useMutation({
    mutationFn: ({
      centerId,
      featureName,
    }: {
      centerId: number;
      featureName: CenterFeatureName;
    }) => centerFeatureEnable(centerId, featureName),
    onMutate({ centerId, featureName }) {
      queryClient.setQueryData(["center-feature", centerId, featureName], {
        active: true,
        optimistic: true,
      });
    },
    onSuccess() {
      Vue.notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
    onSettled(data, error, { centerId, featureName }) {
      void queryClient.invalidateQueries([
        "center-feature",
        centerId,
        featureName,
      ]);
    },
  });

  return mutation;
}

export function useCenterFeatureDisable() {
  const mutation = useMutation({
    mutationFn: ({
      centerId,
      featureName,
    }: {
      centerId: number;
      featureName: CenterFeatureName;
    }) => centerFeatureDisable(centerId, featureName),
    onMutate({ centerId, featureName }) {
      queryClient.setQueryData(["center-feature", centerId, featureName], {
        active: false,
      });
    },
    onSuccess() {
      Vue.notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onSettled(data, error, { centerId, featureName }) {
      void queryClient.invalidateQueries([
        "center-feature",
        centerId,
        featureName,
      ]);
    },
    onError: userErrorMessage,
  });

  return mutation;
}
