<template>
  <div
    class="tw-grid tw-grid-cols-2 sm:tw-grid-cols-4 tw-gap-2 tw-px-4 tw-py-2 tw-bg-gray-100"
  >
    <div v-for="item in patientInfo" :key="item.key">
      <small class="tw-block tw-font-light">{{ item.label }}</small>
      <strong>{{ item.value }}</strong>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Patient } from "../../../../../app/Dermicus/Patients/Patient";
import { lang } from "../../i18n";

const props = defineProps<{
  dataPatient: Patient;
}>();

const patientInfo = computed(() => [
  {
    key: "ssn",
    label: lang.get("patient.ssn"),
    value: props.dataPatient.ssn,
  },
  {
    key: "fullName",
    label: lang.get("patient.name"),
    value: props.dataPatient.fullName,
  },
  {
    key: "age",
    label: lang.get("patient.age"),
    value: props.dataPatient.age ?? "-",
  },
  {
    key: "gender",
    label: lang.get("patient.gender"),
    value: props.dataPatient.gender,
  },
]);
</script>
