<template>
  <examination-documentation
    :examinationId="examinationId"
    :enabledTypes="extraOptions.enabledTypes"
  />
</template>

<script>
import ExaminationDocumentation from "../ExaminationDocumentation.vue";

export default {
  components: { ExaminationDocumentation },
  props: {
    examinationId: Number,
    extraOptions: {
      enabledTypes: Array,
    },
  },
};
</script>
