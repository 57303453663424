<template>
  <div class="row" v-if="products.length > 0">
    <div class="col-md-6" v-for="(product, index) in products" :key="index">
      <div class="panel panel-default hover-effect">
        <div class="panel-heading p-0">
          <div
            :class="[
              { grayscale: !product.active },
              productImageClass(product.name),
            ]"
            class="widget-img"
          >
            {{ productName(productNameByType(product.type)) }}
          </div>
        </div>
        <div class="panel-body">
          <div class="row col-md-12">
            <div class="col-md-12">
              <div class="text-left mb-1">
                <h6>{{ trans("users.notificationActive") }}</h6>
              </div>
              <div class="text-center">
                <DerToggle v-model="product.active" />
              </div>
            </div>
          </div>

          <horizontal-line
            class="mb-3"
            v-if="product.active"
            :text="trans('users.notificationHeadline')"
          ></horizontal-line>

          <div class="row col-md-12 align-items-end" v-if="product.active">
            <div class="col-4">
              <div class="text-left">
                <label>{{ trans("users.notificationNewCase") }}</label>
              </div>
              <div class="text-center">
                <DerToggle v-model="product.notifications_new_examinations" />
              </div>
            </div>
            <div class="col-4">
              <div class="text-left">
                <label>{{ trans("users.notificationNewConsultAnswer") }}</label>
              </div>
              <div class="text-center">
                <DerToggle
                  v-model="product.notification_new_document_consult_answer"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="text-left">
                <label>{{
                  trans("users.notificationNewChatConsultAnswer")
                }}</label>
              </div>
              <div class="text-center">
                <DerToggle v-model="product.consensus_notifications" />
              </div>
            </div>

            <div class="col-4">
              <div class="text-left">
                <label
                  >{{ trans("users.notificationNewConsensusGroupChannel") }}
                </label>
              </div>
              <div class="text-center">
                <DerToggle
                  v-model="product.new_consensus_channel_notifications"
                />
              </div>
            </div>

            <div
              class="col-4"
              v-if="product.name.toLowerCase().includes('mole')"
            >
              <div class="text-left">
                <label>{{ trans("users.notificationUnansweredCases") }}</label>
              </div>
              <div class="text-center">
                <DerToggle v-model="product.unanswered_cases" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggle from "../../../../design-system/DerToggle.vue";
import HorizontalLine from "../../../Utils/HorizontalLine.vue";
import {
  getProductNameTranslation,
  getProductNameByType,
} from "../../../../helpers/product";

export default {
  props: {
    products: {},
    user: {},
  },
  methods: {
    productImageClass: function(productName) {
      const name = productName.toLowerCase().replace(/\s+/g, "");
      if (name.includes("wound")) {
        return "wound";
      } else if (name.includes("mole")) {
        return "mole";
      } else {
        return "photo";
      }
    },
    productNameByType: function(type) {
      return getProductNameByType(type);
    },
    productName: function(name) {
      return getProductNameTranslation(name);
    },
  },
  components: {
    DerToggle,
    HorizontalLine,
  },
};
</script>
