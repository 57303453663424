<template>
  <div :style="{ minHeight: sliderHeight }">
    <div class="tw-grid tw-grid-cols-[1fr_1em] tw-gap-2 tw-items-center">
      <vue-slider
        :id="id"
        :value="value"
        :disabled="disabled"
        :data="dataOptions"
        :data-value="dataOptionValue"
        :data-label="dataOptionLabel"
        :min="dataOptionMin"
        :max="dataOptionMax"
        :interval="dataOptionInterval"
        tooltip="none"
        :dot-style="!hasValidValue ? { display: 'none' } : undefined"
        :marks="dataOptionMarks ?? minMaxMarks"
        @change="onInput"
        ref="sliderRef"
      />
      <button
        :class="{
          'tw-invisible': !hasValidValue,
          'tw-visible': hasValidValue,
        }"
        type="button"
        class="close"
        aria-label="Close"
        @click="onClearValue"
        :disabled="disabled"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>
<script>
import VueSlider from "vue-slider-component";

export default {
  components: { VueSlider },
  props: {
    /**
     * `string | undefined`
     */
    id: { type: String },
    /**
     * `string | number | undefined`
     */
    value: { required: true },
    /**
     * `boolean | undefined`
     */
    disabled: { type: Boolean },
    /**
     * `T[] | undefined`
     */
    dataOptions: { type: Array },
    /**
     * `string | undefined`
     */
    dataOptionValue: { type: String },
    /**
     * `string | undefined`
     */
    dataOptionLabel: { type: String },
    /**
     * `number | undefined`
     */
    dataOptionMin: { type: Number },
    /**
     * `number | undefined`
     */
    dataOptionMax: { type: Number },
    /**
     * `number | undefined`
     */
    dataOptionInterval: { type: Number },
    /**
     * `{} | undefined`
     */
    dataOptionMarks: { type: Object },
  },
  methods: {
    onInput($event) {
      /**
       * `string | number | undefined`
       */
      this.$emit("input", $event);
    },
    onClearValue() {
      this.onInput(undefined);
    },
    getTallestLabelHeight() {
      return Math.max(
        ...Array.from(
          this.$refs.sliderRef.$el.querySelectorAll(".vue-slider-mark-label")
        ).map(el => el.clientHeight)
      );
    },
  },
  data() {
    return {
      tallestLabelHeight: 0,
    };
  },
  mounted() {
    this.tallestLabelHeight = this.getTallestLabelHeight();
  },
  computed: {
    hasValidValue() {
      return this.value !== null && this.value !== undefined;
    },
    /**
     * Since the labels are positioned absolutely, we need to
     * calculate the height of the tallest label and add its margin and the slider height,
     * so that we can explicitly set the height of the slider.
     */
    sliderHeight() {
      return this.tallestLabelHeight + 25 + "px";
    },
    /**
     * Show marks when the min/max config is used.
     * This will override any labels from the data options.
     * These two configurations should not be combined.
     *
     * Show percentage marks when the values are 0 - 100.
     */
    minMaxMarks() {
      if (
        this.dataOptionMin !== undefined &&
        this.dataOptionMax !== undefined
      ) {
        const isPercentage =
          this.dataOptionMin === 0 && this.dataOptionMax === 100;
        return {
          [this.dataOptionMin]: isPercentage ? "0%" : this.dataOptionMin,
          [this.dataOptionMax]: isPercentage ? "100%" : this.dataOptionMax,
        };
      }
    },
  },
};
</script>
