<template>
  <AsyncState :data-state="center">
    <Center
      :data-center="center.state"
      :data-save-settings="centerSettingsUpdate"
      :data-avatar-resource="centerAvatarResource"
      @avatarUpload="onAvatarUpload.mutate"
      @avatarDelete="onAvatarDelete.mutate"
    />
  </AsyncState>
</template>
<script setup lang="ts">
import { useAsyncState } from "@vueuse/core";
import { toRefs, watchEffect } from "vue";
import AsyncState from "../design-system/AsyncState.vue";
import {
  useCenterAvatar,
  useCenterAvatarDelete,
  useCenterAvatarUpload,
} from "../store/api/use-avatar-resource";
import {
  centerSettingsGet,
  centerSettingsUpdate,
} from "../store/core/center-settings";
import Center from "./Center/Center.vue";

const props = defineProps<{ dataCenterId: number }>();
const { dataCenterId } = toRefs(props);
const center = useAsyncState(centerSettingsGet, undefined, {
  immediate: false,
});
watchEffect(() => center.execute(0, dataCenterId.value));

const centerAvatarResource = useCenterAvatar(dataCenterId.value);
const onAvatarUpload = useCenterAvatarUpload();
const onAvatarDelete = useCenterAvatarDelete();
</script>
