<template>
  <div>
    <clip-loader v-show="loading"></clip-loader>
    <div>
      <div class="row row-eq-height" v-if="patient">
        <div class="col-md-12 col-lg-6 mb-3">
          <patient-general-info
            :examination="examination"
            :patient="patient"
            :disableEdit="true"
          ></patient-general-info>
        </div>

        <div class="col-md-12 col-lg-6 mb-3 consult-reply">
          <diagnose-reply
            :product-type="PRODUCTS.WOUND"
            :disableNewAnswer="true"
            :edit-save-uri="
              '/api/internal/patients/' + this.$route.params.id + '/comments'
            "
          ></diagnose-reply>
        </div>
      </div>

      <div class="row row-eq-height">
        <div class="col-md-12 col-lg-6 mb-3">
          <wound-medical-history
            :isEditing="isEditing"
            :examination="examination"
          ></wound-medical-history>
        </div>

        <div class="col-md-12 col-lg-6 mb-3">
          <wound-vascular-history
            :isEditing="isEditing"
            :examination="examination"
          ></wound-vascular-history>
        </div>
      </div>

      <div class="row row-eq-height">
        <div class="col-md-12 col-lg-6 mb-3">
          <wound-history
            :isEditing="isEditing"
            :examination="examination"
          ></wound-history>
        </div>

        <div class="col-md-12 col-lg-6 mb-3">
          <patient-case-diagnosis
            :examination="examination"
          ></patient-case-diagnosis>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DiagnoseReply from "../../../Examinations/AddOns/DiagnoseReply.vue";

import WoundMedicalHistory from "./WoundMedicalHistory.vue";
import WoundVascularHistory from "./WoundVascularHistory.vue";

import PatientGeneralInfo from "../../PatientGeneralInfo.vue";
import PatientCaseDiagnosis from "./PatientCaseDiagnosis.vue";
import WoundHistory from "./WoundHistory.vue";
import { PRODUCTS } from "../../../../helpers/definitions";

export default {
  props: ["patient"],

  mounted: function() {
    if (this.$route.params.id) {
      this.loadData();
    }
  },
  data() {
    return {
      loading: true,
      isEditing: false,
      displayHistory: true,
      examination: null,
      PRODUCTS: PRODUCTS,
    };
  },
  methods: {
    loadData: function() {
      axios
        .get("/api/internal/patients/" + this.$route.params.id + "/wound")
        .then(response => {
          if (!$.isEmptyObject(response.data)) {
            this.examination = response.data;
          }

          this.loading = false;
        });
    },
    submitChanges: function() {
      let instance = this;
      this.$dialog
        .confirm(this.trans("patient.patientmodalCreateCaseConfirmation"), {
          okText: this.trans("general.modalConfirmButton"),
          cancelText: this.trans("general.modalCancelButton"),
        })
        .then(function() {
          instance.loading = true;

          axios
            .post(
              "/api/internal/patients/" + instance.$route.params.id + "/wound",
              instance.examination
            )
            .then(response => {
              instance.loading = false;
              instance.isEditing = false;
              instance.examination = response.data;
              instance.$snotify.success(
                this.trans("general.notificationSaved")
              );
            });
        });
    },
    editWound: function(bool) {
      this.isEditing = bool;
    },
  },
  components: {
    DiagnoseReply,
    WoundMedicalHistory,
    PatientGeneralInfo,
    PatientCaseDiagnosis,
    WoundVascularHistory,
    WoundHistory,
  },
};
</script>
