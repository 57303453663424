<template>
  <span class="help-block">
    <strong>{{ errorMessage }}</strong>
  </span>
</template>

<script>
export default {
  props: ["errors"],
  computed: {
    errorMessage: function() {
      if (Array.isArray(this.errors)) {
        return this.errors[0];
      } else {
        return this.errors;
      }
    },
  },
};
</script>
