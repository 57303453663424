<template>
  <div
    class="modal fade"
    id="imageCompareModal"
    tabindex="-1"
    aria-labelledby="imageCompareModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="closeImageCompare"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body col-md-12 row">
          <img :src="selectedImg[0].uri" class="col-md-6 mb-1 compare-image" />
          <img :src="selectedImg[1].uri" class="col-md-6 mb-1 compare-image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["selectedImg", "showModal"],
  mounted: function() {
    $("#imageCompareModal").modal("show");

    this.triggerHidden();
  },

  methods: {
    closeImageCompare: function() {
      this.$emit("closeImageCompare");
    },
    triggerHidden: function() {
      var self = this;
      if ($("#imageCompareModal").length) {
        $("#imageCompareModal").on("hidden.bs.modal", function() {
          // catch the native bootstrap close event and trigger action
          self.$emit("closeImageCompare");
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fade:not(.show) {
  opacity: 1;
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0px;
  margin: 0px;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 0px;
}

@media only screen and (device-width: 768px) {
  .compare-image {
    max-height: 50vh;
    max-width: 50vh;
  }
}
</style>
