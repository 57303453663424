<template>
  <div>
    <div>
      <div>{{ diagnosis.label }}</div>
      <div>{{ Math.round(diagnosis.percentage) }}% ({{ diagnosis.value }})</div>
    </div>

    <div class="progress my-2">
      <div
        class="progress-bar"
        :class="[
          type === 'planned'
            ? 'planned-actions-progress-bar'
            : 'diagnoses-progress-bar',
        ]"
        role="progressbar"
        :style="
          `width: ${Math.round(
            diagnosis.percentage
          )}%; background-color: ${color};`
        "
        :aria-valuenow="Math.round(diagnosis.percentage)"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    diagnosis: Object,
    color: String,
    type: String,
  },
};
</script>

<style lang="scss" scoped>
.progress {
  height: 5px;
}
</style>
