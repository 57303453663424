<template>
  <consultation-images
    class="col-md-12 px-0"
    v-if="Object.keys(images).length > 0"
    :caseId="examination.id"
    :images="images"
    @update:images="images = $event"
    :product="examination.type.name"
    :imagesWithAiFilters="imagesWithAiFilters"
    @update:imagesWithAiFilters="imagesWithAiFilters = $event"
    @getAiFilters="getAiFilters"
    :imagesWithAiFiltersLoading="imagesWithAiFiltersLoading"
    :data-image-metadata="imageMetadata"
  ></consultation-images>
</template>

<script>
import ConsultationImages from "../../AddOns/ConsultationImages.vue";
import { FIELD_CONSTANTS } from "../fieldMap";
import _find from "lodash/fp/find";
import _flow from "lodash/fp/flow";
import _get from "lodash/fp/get";

export default {
  mounted: function() {
    this.mapImages();
  },
  props: {
    fields: Array,
    examination: Object,
  },
  computed: {
    imageMetadata() {
      return _flow(
        _find({ type: "external_result" }),
        _get("metadata.images")
      )(this.examination?.study_results);
    },
  },
  data() {
    return {
      images: {},
      imagesWithAiFiltersLoading: false,
      imagesWithAiFilters: null,
    };
  },
  methods: {
    mapImages: function() {
      const images = {};
      this.fields.forEach(field => {
        if (field.type === FIELD_CONSTANTS.FIELD_TYPE_IMAGES) {
          if (field.name in this.examination.images) {
            images[field.name] = this.examination.images[field.name];
          }
        }
      });
      this.images = images;
    },
    getAiFilters: function() {
      return;
    },
  },
  components: {
    ConsultationImages,
  },
};
</script>
