import { useMutation, useQuery } from "@tanstack/vue-query";
import Vue, { computed } from "vue";
import { queryClient } from ".";
import { lang } from "../../i18n";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import {
  userQuicksilvaFeatureGet,
  userQuicksilvaGet,
  userQuicksilvaRegister,
  userQuicksilvaRevoke,
} from "../core/user-quicksilva";

export function useUserQuicksilvaFeature() {
  const userQuicksilvaFeature = useQuery(
    computed(() => userQuicksilvaFeatureGet())
  );

  return userQuicksilvaFeature;
}

export function useUserQuicksilva() {
  const userQuicksilvaResource = useQuery(computed(() => userQuicksilvaGet()));

  return userQuicksilvaResource;
}

export function useUserQuicksilvaRegister() {
  const mutation = useMutation({
    mutationFn: (accessCode: string) => userQuicksilvaRegister(accessCode),
    onSuccess() {
      Vue.notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onSettled() {
      void queryClient.invalidateQueries(["quicksilva-verify"]);
    },
  });

  return mutation;
}

export function useUserQuicksilvaRevoke() {
  const mutation = useMutation({
    mutationFn: () => userQuicksilvaRevoke(),
    onSuccess() {
      Vue.notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    onError: userErrorMessage,
    onSettled() {
      void queryClient.invalidateQueries(["quicksilva-verify"]);
    },
  });

  return mutation;
}
