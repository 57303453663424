<script>
export default {
  template:
    '<nav class="breadcrumb" v-if="$breadcrumbs.length" aria-label="breadcrumb"> ' +
    '<router-link class="breadcrumb-item" v-for="(crumb, index) in $breadcrumbs" :to="linkProp(crumb)" :key="index" >{{ trans(crumb.meta.breadcrumb) }}</router-link> ' +
    "</nav>",
};
</script>

<style>
nav.breadcrumb {
  background-color: transparent;
  font-size: 12px;
}

nav a.breadcrumb-item {
  color: #706d75 !important;
}

nav a.breadcrumb-item.router-link-exact-active.router-link-active {
  color: #7a7d84 !important;
}
</style>
