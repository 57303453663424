<template>
  <div class="consultation-container">
    <div id="mole" class="col-lg-12" v-if="examination">
      <examinations-header
        @mountImageCompare="mountImageCompare"
        :totalExaminations="totalExaminations"
        @initializePrint="printCase"
      ></examinations-header>

      <chat-container
        :edit-save-uri="'/api/internal/' + examination.id + '/channels'"
        :chatNotifications="consensusNotifications"
        @updateTopNavigation="updateTopNavigation"
      ></chat-container>

      <div class="tab-content">
        <div class="tab-pane active" id="home">
          <div class="row row-eq-height">
            <div class="col-md-12 col-lg-6 mb-4">
              <general-info
                :examination="examination"
                @update:examination="examination = $event"
                :user="user"
                :actions="actions"
                @closeCase="closeCase"
                @printCase="printCase"
                @updateCaseActions="updateCaseActions"
                product="moleUk"
              ></general-info>
            </div>

            <div class="col-md-12 col-lg-6 mb-4 consult-reply">
              <diagnose-reply
                :edit-save-uri="'/api/internal/' + examination.id + '/comments'"
              ></diagnose-reply>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-lg-6">
              <consultation-images
                :images="images"
                :caseId="examination.id"
                :product="PRODUCTS.MOLE_UK"
              ></consultation-images>
              <!-- diagnosis that's vivible on bigger screens -->
              <div class="hidden-mobile">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-underline">
                    <h3 class="panel-title">
                      {{ trans("examination.diagnosiskHeadline") }}

                      <span class="float-right"
                        >{{ examination.diagnosisUpdatedAt }}
                        <info-popup
                          :text-content="trans('tooltip.moleUkDiagnosisBox')"
                        ></info-popup>
                      </span>
                    </h3>
                  </div>
                  <div class="panel-body">
                    <div class="m-b-20 col-md-12">
                      <strong>{{
                        trans("examination.diagnosisCodeDermatoskopiskLabel")
                      }}</strong>
                      <br />

                      <select2
                        name="diagnosis_code_dermatoscopic"
                        :disabled="!$can('diagnose')"
                        :options="examination.diagnosisCodeDermatoscopicList"
                        :model-value="examination.case.dermatoscopicDiagnosisId"
                        :data-save-url="
                          '/api/internal/moles/' +
                            examination.id +
                            '/save-diagnostic'
                        "
                      ></select2>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("examination.diagnosiskPlanedHeadline")
                      }}</label>
                      <br />
                      <select2
                        v-if="!plannedToAction.active"
                        :disabled="!$can('diagnose')"
                        name="diagnosis_code_planed"
                        :options="examination.plannedToActionList"
                        :model-value="examination.case.plannedDiagnosisId"
                        :data-save-url="
                          '/api/internal/moles-uk/' +
                            examination.id +
                            '/save-diagnostic'
                        "
                      ></select2>
                      <input
                        v-if="plannedToAction.active"
                        disabled
                        type="text"
                        name="channelName"
                        :value="
                          trans(
                            'examination.diagnosiskPlanedToAnotherDepartmentLabel'
                          )
                        "
                        class="form-control"
                        :disable="plannedToAction.active"
                      />
                    </div>

                    <div
                      :class="[
                        plannedToAction.active ? 'card-footer pb-0' : 'mb-1',
                        'col-md-12',
                      ]"
                    >
                      <label :class="plannedToAction.active ? 'mx-4' : ''"
                        >{{
                          trans(
                            "examination.diagnosiskPlanedToAnotherDepartmentLabel"
                          )
                        }}
                        <DerToggle
                          :disabled="!$can('diagnose')"
                          v-model="plannedToAction.active"
                          name="active"
                        />
                      </label>
                    </div>

                    <div
                      class="card-header mb-4"
                      v-if="plannedToAction.active"
                      :class="plannedToAction.active ? 'pt-0' : ''"
                    >
                      <div class="m-b-20 col-md-12">
                        <label>{{
                          trans("examination.diagnosiskPlanedReferTo")
                        }}</label>
                        <input
                          name="planedToActionReferto"
                          :disabled="!$can('diagnose')"
                          type="text"
                          v-model="plannedToAction.plannedToActionReferto"
                          class="form-control"
                          @change="updatePlannedAction"
                          :placeholder="
                            trans('examination.diagnosiskPlanedReferTo')
                          "
                        />
                      </div>
                      <div class="m-b-20 col-md-12">
                        <label>
                          {{
                            trans(
                              "examination.diagnosiskPlanedReasonForReferral"
                            )
                          }}</label
                        >
                        <input
                          name="planedToActionReasonForReferral"
                          :disabled="!$can('diagnose')"
                          type="text"
                          v-model="
                            plannedToAction.plannedToActionReasonForReferral
                          "
                          class="form-control"
                          @change="updatePlannedAction"
                          :placeholder="
                            trans(
                              'examination.diagnosiskPlanedReasonForReferral'
                            )
                          "
                        />
                      </div>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <strong>{{
                        trans("examination.diagnosiscodeHistopatologiskLabel")
                      }}</strong>
                      <br />
                      <select2
                        name="diagnosis_code_histopathological"
                        :options="
                          examination.diagnosisCodeHistopathologicalList
                        "
                        :model-value="
                          examination.case.histopathologicalDiagnosisId
                        "
                        :data-save-url="
                          '/api/internal/moles-uk/' +
                            examination.id +
                            '/save-diagnostic'
                        "
                      ></select2>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <strong>{{ trans("examination.tagsLabel") }}</strong>
                      <br />
                      <select2
                        name="tags"
                        class="select2 form-control responsive"
                        :options="examination.tagsList"
                        :model-value="examination.case.tags"
                        multiple="multiple"
                        data-doNotStackNotifications="true"
                        :data-save-url="
                          '/api/internal/moles-uk/' +
                            examination.id +
                            '/save-diagnostic'
                        "
                        :label="trans('examination.tagsSelectOptionGroupLabel')"
                      ></select2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 col-lg-6 consultation-box">
              <examination-consultation-info
                :examination="examination"
                product="moleUk"
              ></examination-consultation-info>
            </div>
            <!-- diagnosis that's visible on mobile screens -->
            <div class="col-md-12 col-lg-6 visible-mobile">
              <div class="panel panel-default">
                <div class="panel-heading panel-heading-underline">
                  <h3 class="panel-title">
                    {{ trans("examination.diagnosiskHeadline") }}
                    <span class="float-right"
                      >{{ examination.diagnosisUpdatedAt }}
                      <info-popup
                        :text-content="trans('tooltip.moleUkDiagnosisBox')"
                      ></info-popup
                    ></span>
                  </h3>
                </div>
                <div class="panel-body">
                  <div class="m-b-20 col-md-12">
                    <strong>{{
                      trans("examination.diagnosisCodeDermatoskopiskLabel")
                    }}</strong>
                    <br />

                    <select2
                      name="diagnosis_code_dermatoscopic"
                      :disabled="!$can('diagnose')"
                      :options="examination.diagnosisCodeDermatoscopicList"
                      :model-value="examination.case.dermatoscopicDiagnosisId"
                      :data-save-url="
                        '/api/internal/moles/' +
                          examination.id +
                          '/save-diagnostic'
                      "
                    ></select2>
                  </div>

                  <div class="m-b-20 col-md-12">
                    <label>{{
                      trans("examination.diagnosiskPlanedHeadline")
                    }}</label>
                    <br />
                    <select2
                      v-if="!plannedToAction.active"
                      :disabled="!$can('diagnose')"
                      name="diagnosis_code_planed"
                      :options="examination.plannedToActionList"
                      :model-value="examination.case.plannedDiagnosisId"
                      :data-save-url="
                        '/api/internal/moles-uk/' +
                          examination.id +
                          '/save-diagnostic'
                      "
                    ></select2>
                    <input
                      v-if="plannedToAction.active"
                      disabled
                      type="text"
                      name="channelName"
                      :value="
                        trans(
                          'examination.diagnosiskPlanedToAnotherDepartmentLabel'
                        )
                      "
                      class="form-control"
                      :disable="plannedToAction.active"
                    />
                  </div>

                  <div
                    :class="[
                      plannedToAction.active ? 'card-footer pb-0' : 'mb-1',
                      'col-md-12',
                    ]"
                  >
                    <label :class="plannedToAction.active ? 'mx-4' : ''"
                      >{{
                        trans(
                          "examination.diagnosiskPlanedToAnotherDepartmentLabel"
                        )
                      }}
                      <DerToggle
                        :disabled="!$can('diagnose')"
                        v-model="plannedToAction.active"
                        name="active"
                      />
                    </label>
                  </div>

                  <div
                    class="card-header mb-4"
                    v-if="plannedToAction.active"
                    :class="plannedToAction.active ? 'pt-0' : ''"
                  >
                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("examination.diagnosiskPlanedReferTo")
                      }}</label>
                      <input
                        name="planedToActionReferto"
                        :disabled="!$can('diagnose')"
                        type="text"
                        v-model="plannedToAction.plannedToActionReferto"
                        class="form-control"
                        @change="updatePlannedAction"
                        :placeholder="
                          trans('examination.diagnosiskPlanedReferTo')
                        "
                      />
                    </div>
                    <div class="m-b-20 col-md-12">
                      <label>
                        {{
                          trans("examination.diagnosiskPlanedReasonForReferral")
                        }}</label
                      >
                      <input
                        name="planedToActionReasonForReferral"
                        :disabled="!$can('diagnose')"
                        type="text"
                        v-model="
                          plannedToAction.plannedToActionReasonForReferral
                        "
                        class="form-control"
                        @change="updatePlannedAction"
                        :placeholder="
                          trans('examination.diagnosiskPlanedReasonForReferral')
                        "
                      />
                    </div>
                  </div>

                  <div class="m-b-20 col-md-12">
                    <strong>{{
                      trans("examination.diagnosiscodeHistopatologiskLabel")
                    }}</strong>
                    <br />
                    <select2
                      name="diagnosis_code_histopathological"
                      :options="examination.diagnosisCodeHistopathologicalList"
                      :model-value="
                        examination.case.histopathologicalDiagnosisId
                      "
                      :data-save-url="
                        '/api/internal/moles-uk/' +
                          examination.id +
                          '/save-diagnostic'
                      "
                    ></select2>
                  </div>

                  <div class="m-b-20 col-md-12">
                    <strong>{{ trans("examination.tagsLabel") }}</strong>
                    <br />
                    <select2
                      name="tags"
                      class="select2 form-control responsive"
                      :options="examination.tagsList"
                      :model-value="examination.case.tags"
                      multiple="multiple"
                      data-doNotStackNotifications="true"
                      :data-save-url="
                        '/api/internal/moles-uk/' +
                          examination.id +
                          '/save-diagnostic'
                      "
                      :label="trans('examination.tagsSelectOptionGroupLabel')"
                    ></select2>
                  </div>
                </div>
              </div>
            </div>

            <ai-diagnosis
              v-if="$store.state.user.has_ai_diagnosis"
              :ai-diagnosis-available="aiDiagnosisAvailable"
              :examination-id="examination.id"
            ></ai-diagnosis>
          </div>
        </div>

        <div class="tab-pane" id="image-compare" v-if="examination">
          <image-compare
            ref="imageCompare"
            :examination-id="examination.id"
            :type="11"
          ></image-compare>
        </div>
      </div>
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </div>
</template>

<script>
import CustomPrintViewDialog from "./AddOns/PrintCaseView.vue";
import DerToggle from "../../design-system/DerToggle.vue";
import Notifications from "../Utils/notifications";

import ImageCompare from "./AddOns/ImageCompare.vue";

import ExaminationsHeader from "./AddOns/ExaminationsHeader.vue";
import GeneralInfo from "./AddOns/GeneralInfo.vue";
import DiagnoseReply from "./AddOns/DiagnoseReply.vue";
import ConsultationImages from "./AddOns/ConsultationImages.vue";
import ExaminationConsultationInfo from "./AddOns/ExaminationConsultationInfo.vue";

import { PRODUCTS } from "../../helpers/definitions";
import InfoPopup from "../Utils/InfoPopup.vue";
import ChatContainer from "./AddOns/Chat/ChatContainer.vue";

import AiDiagnosis from "./AddOns/AiFeatures/AiDiagnosis.vue";
import VueJsDialogMixinWrapper from "../../mixins/VueJsDialogMixinWrapper.vue";

export default {
  mounted: function() {
    this.loadData();
  },
  data() {
    return {
      loading: true,
      examination: null,
      comments: {},
      user: {},
      backButtonUri: "/",
      plannedToAction: {
        active: false,
        plannedToActionReferto: "",
        plannedToActionReasonForReferral: "",
      },
      consensusNotifications: null,
      actions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
      },
      images: {},
      totalExaminations: null,
      diagnoseIsSet: false,
      plannedActionIsSet: false,
      PRODUCTS: PRODUCTS,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  watch: {
    "plannedToAction.active": function(val, oldVal) {
      let instance = this;
      if (val.active) {
        this.examination.case.diagnosisCodePlaned = null;
        this.plannedToAction.active = true;
      } else if (!this.plannedToAction.active) {
        this.$dialog
          .confirm(this.trans("general.modalCancel"), {
            okText: this.trans("general.modalConfirmButton"),
            cancelText: this.trans("general.modalCancelButton"),
          })
          .then(() => {
            instance.plannedToAction.active = false;
            instance.plannedToAction.plannedToActionReferto = "";
            instance.plannedToAction.plannedToActionReasonForReferral = "";

            return axios.post(
              `/api/internal/moles-uk/${instance.$route.params.id}/planned-action`,
              instance.plannedToAction
            );
          })
          .then(() => {
            this.$notify({
              text: this.trans("general.notificationSaved"),
              type: "success",
              duration: 1000,
            });
          })
          .catch(() => {
            instance.plannedToAction.active = true;
          });
      }
    },
  },
  methods: {
    loadData: function() {
      this.loading = true;
      this.user = this.$store.state.user;

      axios
        .get("/api/internal/moles-uk/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.examination = collection.examination;
          this.images = { ...collection.examination.images };
          if ("signedConsentForm" in this.images)
            delete this.images["signedConsentForm"];
          this.plannedToAction = collection.examination.plannedToAction;
          this.comments = collection.comments;
          this.consensusNotifications =
            collection.notifications !== 0 ? collection.notifications : null;
          this.actions.isPrioritized = collection.examination.isPrioritized;
          this.actions.isFlagged = collection.examination.isFlagged;
          this.actions.isBookmarked = collection.examination.isBookmarked;

          collection.examination.case.dermatoscopicDiagnosisId
            ? (this.diagnoseIsSet = true)
            : (this.diagnoseIsSet = false);
          collection.examination.case.plannedDiagnosisId
            ? (this.plannedActionIsSet = true)
            : (this.plannedActionIsSet = false);

          this.getTotalExaminations();

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });

      localStorage.setItem(
        "last_visited_examination_id",
        this.$route.params.id
      );
    },
    getTotalExaminations: function() {
      axios
        .get(
          "/api/internal/image-comparer/count-examinations/" +
            this.$route.params.id +
            "?type=11"
        )
        .then(data => {
          let collection = data.data;
          this.totalExaminations = collection.total_examinations;
        })
        .catch(error => {
          console.log("Get total examinations error: ", error);
        });
    },
    closeCase: function() {
      axios
        .get("/api/internal/moles/" + this.$route.params.id + "/close")
        .then(data => {
          this.examination.statusName = data.data.examination.statusName;
          this.examination.caseCloseAt = data.data.examination.caseCloseAt;
          Notifications.saveSuccess();
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    printCase: function() {
      this.showPrintDialogWithOptions = {
        data: {
          uri: "/api/internal/moles-uk/" + this.$route.params.id + "/print",
          caseNo: this.examination.caseNo,
          type: "mole-uk",
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
    updatePlannedAction({ target }) {
      if (target.name == "plannedToActionReferto") {
        this.plannedToAction.plannedToActionReferto = target.value;
      } else if (target.name == "plannedToActionReasonForReferral") {
        this.plannedToAction.plannedToActionReasonForReferral = target.value;
      }

      this.plannedToAction.active = true;

      axios
        .post(
          "/api/internal/moles-uk/" + this.$route.params.id + "/planned-action",
          this.plannedToAction
        )
        .then(data => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        });
    },
    mountImageCompare: function() {
      this.$refs.imageCompare.getImages();
    },
    updateCaseActions: function(actionName) {
      this.actions[actionName] = this.actions[actionName] ? false : true;

      axios
        .put(
          "/api/internal/moles-uk/" + this.$route.params.id + "/actions",
          this.actions
        )
        .then(() => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    updateTopNavigation: function() {
      axios
        .get("/api/internal/moles-uk/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.consensusNotifications =
            collection.notifications !== 0 ? collection.notifications : null;
        });
    },
    /**
     * Event trigger then select2 has been triggerd.
     */
    updateSelect2Event: function() {
      axios
        .get("/api/internal/moles-uk/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.examination.diagnosisUpdatedAt =
            collection.examination.diagnosisUpdatedAt;

          collection.examination.case.dermatoscopicDiagnosisId
            ? (this.diagnoseIsSet = true)
            : (this.diagnoseIsSet = false);

          collection.examination.case.plannedDiagnosisId
            ? (this.plannedActionIsSet = true)
            : (this.plannedActionIsSet = false);
        });
    },
  },
  computed: {
    aiDiagnosisAvailable: function() {
      if (this.diagnoseIsSet && this.plannedActionIsSet) {
        return true;
      }
      return false;
    },
  },
  components: {
    VueJsDialogMixinWrapper,
    ImageCompare,
    DiagnoseReply,
    DerToggle,
    ExaminationsHeader,
    ConsultationImages,
    GeneralInfo,
    ExaminationConsultationInfo,
    AiDiagnosis,
    InfoPopup,
    ChatContainer,
  },
};
</script>
