<template>
  <div class="col-lg-12">
    <clip-loader v-show="loading"></clip-loader>
    <button class="btn btn-grey my-3" @click="back()">
      {{ trans("general.back") }}
    </button>

    <div class="panel panel-default panel-fill" v-if="!loading">
      <div class="panel-heading">
        <h3 class="panel-title">
          Center: {{ center.name }} | Tilldelade ärendenummer
        </h3>
      </div>
      <div class="panel-body">
        <table class="table table-hover m-0 table-actions-bar">
          <thead>
            <tr>
              <th>Ärendenummer</th>
              <th>Skapad</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(examination, index) in examinations" :key="index">
              <td>{{ examination.case_no }}</td>
              <td>{{ examination.education_created_at }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function() {
    this.loadData();
  },
  data() {
    return {
      loading: true,
      center: {
        name: null,
      },
      examinations: {},
    };
  },
  methods: {
    loadData: function() {
      this.loading = true;

      axios
        .get("/api/internal/admin/educations/" + this.$route.params.id)
        .then(response => {
          let data = response.data;
          this.center = data.center;
          this.examinations = data.examinations;
          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    back: function() {
      this.$router.push({ name: "AdminEducations" });
    },
  },
};
</script>
