import { AxiosInstance } from "axios";
import { practitionerHttpService } from "./practitioner-http-service";
import { StudyConsent } from "../../../../../../app/Dermicus/PatientPortal/StudyConsent";

export function examinationStudyConsentsProvider(config: {
  httpService: AxiosInstance;
}) {
  return function examinationStudyConsents(id: number) {
    return config.httpService
      .get<StudyConsent[]>(`/api/internal/examinations/${id}/study-consents`)
      .then(({ data }) => data);
  };
}

export const examinationStudyConsents = examinationStudyConsentsProvider({
  httpService: practitionerHttpService,
});
