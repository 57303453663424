import { AxiosInstance } from "axios";
import { AppConfigResource } from "../../../../../../app/Http/Resources/AppConfigResource";
import { practitionerHttpService } from "./practitioner-http-service";

export function appConfigGetProvider(config: { httpService: AxiosInstance }) {
  return function appConfigGet(): Promise<AppConfigResource> {
    return config.httpService
      .request<AppConfigResource>({
        method: "GET",
        url: "/api/internal/app/config",
      })
      .then(response => response.data);
  };
}

export const appConfigGet = appConfigGetProvider({
  httpService: practitionerHttpService,
});
