<template>
  <div style="position: relative;">
    <div class="overlay" v-if="loading">
      <clip-loader />
    </div>

    <div class="table-responsive research-participant-cases-list">
      <div
        class="overlay"
        v-if="!loading && studyResults && studyResults.length === 0"
      >
        <div class="overlay-content">
          {{ lang.get("aiFeatures.noStudyCasesInfoText") }}
        </div>
      </div>

      <table class="table table-hover table-actions-bar interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ lang.get("evaluation.tableColumnCaseNo") }}</th>
            <th>{{ lang.get("evaluation.tableColumnStatus") }}</th>
            <th>{{ lang.get("evaluation.tableColumnCreatedAt") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(studyResult, index) in studyResults"
            :key="index"
            @click="openStudyCase(studyResult)"
            :class="{
              'disabled-research-case':
                studyResult.status === 'research-answered',
            }"
          >
            <td>{{ studyResult.study_examination_id }}</td>
            <td>
              <StudyResultStatusBadge :data-status="studyResult.status" />
            </td>
            <td>
              {{ moment(studyResult.created_at).format(shortDateFormat()) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computedAsync } from "@vueuse/core";
import { ref, toRefs } from "vue";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { locationOf, ROUTES } from "../routing/routes";
import { useRouter } from "vue-router/composables";
import { lang } from "../../i18n";
import { store } from "../../store.js";
import { userStudyResultsGet } from "../../store/core/users";
import { StudyResultParticipantSummary } from "../../../../../app/Dermicus/Studies/StudyResultParticipantSummary";
import StudyResultStatusBadge from "../../design-system/StudyResultStatusBadge.vue";
import moment from "moment";
import { shortDateFormat } from "../../helpers/platform";

const router = useRouter();
const props = defineProps<{ dataResearchId: number }>();
const { dataResearchId } = toRefs(props);
const loading = ref();
const studyResults = computedAsync(
  () => userStudyResultsGet(store.state.user.id, dataResearchId.value),
  [],
  {
    evaluating: loading,
    onError: userErrorMessage,
  }
);

function openStudyCase(studyResult: StudyResultParticipantSummary) {
  if (studyResult.status !== "research-answered") {
    router.push(
      locationOf(ROUTES.researchResearchCase, {
        params: { id: `${studyResult.study_examination_id}` },
      })
    );
  }
}
</script>
<style scoped lang="scss">
.disabled-research-case {
  cursor: not-allowed;
  background-color: #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.research-participant-cases-list {
  min-height: 300px;
}
</style>
