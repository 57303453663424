<template>
  <span>
    <span class="dropdown" v-if="!!unselectedItems.length">
      <button
        type="button"
        class="btn btn-link"
        data-toggle="dropdown"
        aria-expanded="false"
      >
        <i class="fa fa-angle-down fa-lg"></i>
      </button>
      <span class="dropdown-menu">
        <button
          v-for="item in unselectedItems"
          type="button"
          class="dropdown-item"
          @click="$emit('update:dataSelectedItem', item.item)"
        >
          {{ item.display }}
        </button>
      </span>
    </span>
    {{ selectedItem?.display ?? dataPlaceholder }}
  </span>
</template>
<script setup lang="ts" generic="T">
import { computed, toRefs } from "vue";
import _find from "lodash/fp/find";
import _without from "lodash/fp/without";

const props = defineProps<{
  dataItems: T[];
  dataItemDisplay: (item: T) => string;
  dataPlaceholder: string;
  dataSelectedItem: T;
}>();

defineEmits<{
  (event: "update:dataSelectedItem", payload: T): void;
}>();

const { dataItems, dataItemDisplay, dataSelectedItem } = toRefs(props);

const items = computed(() =>
  dataItems.value.map(item => ({ item, display: dataItemDisplay.value(item) }))
);

const selectedItem = computed(() =>
  _find({ item: dataSelectedItem.value }, items.value)
);

const unselectedItems = computed(() =>
  _without([selectedItem.value], items.value)
);
</script>
