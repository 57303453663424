<template>
  <div class="write mb-2 mx-2">
    <div
      class="col-md-12 my-3"
      v-if="selectedDocumentationType && editType === 'editDocumentation'"
    >
      <input
        type="text"
        :value="selectedDocumentationType.typeLabel"
        disabled
        class="form-control"
      />
    </div>

    <div
      class="form-group col-md-12 textbox"
      :class="{ 'has-error': error || (errors && errors.text) }"
    >
      <small class="form-text description">{{ description }}</small>
      <span class="help-block" v-if="error">
        <strong>{{ error }}</strong>
      </span>

      <textarea
        :placeholder="trans('examination.modelTextAreaPlaceholder')"
        v-model="text.value"
        class="text-area"
        ref="documentation"
      ></textarea>
      <span class="help-block" v-if="error">
        <strong>{{ error }}</strong>
      </span>
      <form-error
        v-if="errors && errors.text"
        :errors="errors.text"
      ></form-error>
    </div>

    <div class="col-md-12 text-right space-x-2">
      <button
        type="button"
        class="btn btn-secondary"
        @click="onCancel"
        :title="trans('general.modalCancelButton')"
      >
        {{ trans("examination.modelCancelButtonText") }}
      </button>
      <button
        type="button"
        class="btn btn-primary"
        @click="onSubmit"
        :disabled="!text.value || isSaving"
        :title="[
          !text.value
            ? trans('general.emptyComment')
            : trans('general.formSaveLabel'),
        ]"
      >
        <i class="fas fa-circle-notch fa-spin" v-if="isSaving"></i>
        <span>{{ trans("examination.modelSaveButtonText") }}</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    comment: String || undefined,
    error: String,
    isSaving: Boolean,
    description: String,
    selectedDocumentationType: [Object, String],
    errors: [Object, Array],
    editType: String,
  },
  mounted: function() {
    if (this.comment) this.text.value = this.comment;
  },
  data() {
    return {
      text: {
        value: "",
      },
    };
  },
  methods: {
    onSubmit: function() {
      this.$emit("on-submit", this.text.value);
    },
    onCancel: function() {
      this.$emit("on-cancel");
    },
  },
};
</script>

<style lang="scss" scoped>
.description {
  margin-bottom: 10px;
}

.text-area {
  min-height: 200px;
  border: 1px solid #dee2e6;
}

.form-group.has-error textarea {
  border-color: #d57171;
}
</style>
