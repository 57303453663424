<template>
  <div class="tab-pane" id="min_doktor_retransfers">
    <clip-loader v-if="loading"></clip-loader>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>#</th>
            <th>{{ trans("examination.case_no") }}</th>
            <th>{{ trans("examination.min_doktor_case_number") }}</th>
            <th>{{ trans("examination.caseStatusLabel") }}</th>
            <th>{{ trans("examination.createdAtLabel") }}</th>
            <th>{{ trans("examination.transfer") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(examination, index) in examinations" :key="index">
            <td>{{ examination.id }}</td>
            <td>{{ examination.case_no }}</td>
            <td>{{ examination.external_case_number }}</td>
            <td><span v-dompurify-html="examination.status"></span></td>
            <td>{{ examination.created }}</td>
            <td>
              <button
                class="btn btn-primary"
                @click="retryMinDoktor(examination.id)"
              >
                {{ trans("examination.transfer") }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ClipLoader from "../../Utils/ClipLoader.vue";
import UserErrorMessage from "../../../mixins/UserErrorMessage.vue";

export default {
  mixins: [UserErrorMessage],
  created: function() {
    this.getMDExaminations();
  },
  data() {
    return {
      loading: true,
      examinations: {},
    };
  },
  methods: {
    getMDExaminations(page) {
      this.loading = true;

      axios
        .get("/api/internal/admin/logs/min-doktor-examinations")
        .then(data => {
          this.examinations = data.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    retryMinDoktor: function(examinationId) {
      this.loading = true;
      this.$notify({
        text:
          "Sending retry to Min Doktor can take up to 30s. Please wait for response before clicking on the Transfer button again.",
        duration: 4000,
        type: "success",
      });

      axios
        .get("/api/internal/admin/retransfer-to-min-doktor/" + examinationId)
        .then(data => {
          this.loading = false;
          this.$notify({
            text: data.data.message,
            duration: 3000,
            type: "success",
          });
        })
        .catch(error => {
          this.$notify({
            text: error.response.data.message,
            duration: 3000,
            type: "error",
          });
          this.loading = false;
        });
    },
  },
  components: {
    ClipLoader,
  },
};
</script>
