<template>
  <div>
    <header-admin :active-section="5"></header-admin>

    <div class="text-right my-2">
      <router-link to="/admin/educations/new" class="btn btn-primary"
        >Lägg till nya ärende</router-link
      >
    </div>

    <div id="custom-search-input">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          type="text"
          class="form-control input-lg"
          id="search-field"
          placeholder="Sök efter centernamn..."
        />
        <span class="input-group-btn">
          <button
            class="btn"
            type="button"
            @click.stop="search"
            v-if="!isSearching"
          >
            <i class="far fa-search"></i>
          </button>
          <button
            class="btn"
            type="button"
            @click.stop="clearSearch"
            v-if="isSearching"
          >
            <i class="fal fa-times"></i>
          </button>
        </span>
      </form>
    </div>

    <div id="evaluations">
      <div class="table-responsive">
        <clip-loader v-show="loading"></clip-loader>

        <table class="table table-hover m-0 table-actions-bar cases-list">
          <thead class="sortable-table-header">
            <tr>
              <th>Centernamn</th>
              <th>Antal provcase</th>
            </tr>
          </thead>
          <tbody>
            <tr
              @click="openCenter(center.id)"
              v-for="(center, index) in centers"
              :key="index"
            >
              <td>{{ center.name }}</td>
              <td>{{ center.total_cases }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row-fluid float-right">
        <paginate
          :pageCount="paginate.last_page"
          :click-handler="getResults"
          :prev-text="'‹'"
          :next-text="'›'"
          :containerClass="'pagination'"
          ref="paginate"
          v-model="paginate"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :active-class="'page-item active'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "./../Header.vue";

export default {
  props: ["examinationsCollection"],
  created: function() {
    this.getResults();
  },
  data() {
    return {
      loading: true,
      centers: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      isSearching: false,
    };
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  methods: {
    getResults(page) {
      var instance = this;
      instance.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/educations?page=" + page)
        .then(data => {
          var collection = data.data;
          instance.paginate = collection.paginate;
          instance.centers = collection.data;
          instance.loading = false;
        })
        .catch(error => {
          instance.userErrorMessage(error);
          instance.loading = false;
        });
    },
    clearSearch: function() {
      $("#search-field").val("");
      this.isSearching = false;
      this.getResults();
    },
    search: function() {
      var instance = this;

      instance.loading = true;
      instance.isSearching = true;
      var query = $("#search-field").val();
      axios
        .get("/api/internal/admin/centers?q=" + query)
        .then(data => {
          var collection = data.data;
          instance.data = collection.paginate;
          instance.centers = collection.data;
          instance.paginate = collection.paginate;
          instance.loading = false;
        })
        .catch(error => {
          instance.userErrorMessage(error);
          instance.loading = false;
        });
    },
    openCenter: function(id) {
      this.$router.push({ name: "AdminEducationsEdit", params: { id: id } });
    },
  },
  components: {
    HeaderAdmin,
  },
};
</script>
