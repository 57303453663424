import { QuicksilvaFeature } from "./../../../../../app/Http/Controllers/Api/Internal/Quicksilva/QuicksilvaResource";
import { practitionerHttpService } from "./../../app/practitioner-portal/store/practitioner-http-service";

export function userQuicksilvaFeatureGet() {
  return {
    queryKey: ["quicksilva-feature"],
    queryFn: () =>
      practitionerHttpService
        .get<QuicksilvaFeature>(`/api/internal/quicksilva/is-active`)
        .then(response => response.data),
  };
}

export function userQuicksilvaGet() {
  return {
    queryKey: ["quicksilva-verify"],
    queryFn: () =>
      practitionerHttpService
        .get(`/api/internal/quicksilva/token/verify`)
        .then(response => {
          return {
            token: true,
          };
        })
        .catch(error => {
          if (error.response && error.response.status === 410) {
            /**
             * API will return 410 when no token has been set.
             * We can intercept that here and modify the response to indicate there is no token,
             * but return other errors.
             */
            return {
              token: false,
            };
          } else {
            throw error;
          }
        }),
  };
}

export function userQuicksilvaRegister(accessCode: string) {
  return practitionerHttpService
    .post(`/api/internal/quicksilva/token/register`, { accessCode })
    .then(response => response.data)
    .catch(error => {
      throw new Error(error.response.data.error);
    });
}

export function userQuicksilvaRevoke() {
  return practitionerHttpService
    .post(`/api/internal/quicksilva/token/revoke`)
    .then(response => response.data);
}
