<template>
  <nav class="patient-header">
    <ul class="nav nav-tabs tabs-bordered">
      <li class="nav-item" @click="onBackClick">
        <router-link to="/patients" class="nav-link">
          <span class="visible-xs">
            <i class="fal fa-arrow-left"></i>
          </span>
          <span class="hidden-xs">{{ trans("general.back") }}</span>
        </router-link>
      </li>

      <li class="nav-item">
        <router-link
          :to="{ name: 'PatientOverview', params: { id: $route.params.id } }"
          class="nav-link"
          exact-active-class="active"
          >{{ trans("patient.menuOverview") }}</router-link
        >
      </li>

      <li class="nav-item">
        <router-link
          :to="{ name: 'PatientImages', params: { id: $route.params.id } }"
          class="nav-link"
          exact-active-class="active"
          >{{ trans("patient.menuCompare") }}</router-link
        >
      </li>

      <li class="nav-item">
        <router-link
          :to="{
            name: 'PatientRelatedExaminations',
            params: { id: $route.params.id },
          }"
          class="nav-link"
          exact-active-class="active"
          >{{ trans("patient.menuRelatedConsultations") }}</router-link
        >
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  methods: {
    onBackClick: function() {
      this.cancelRequests();
    },
    cancelRequests: function() {
      this.$store.state.cancelTokens.forEach(token => {
        token.cancel();
      });
      this.$store.commit("resetCancelTokens");
    },
  },
};
</script>
