<template>
  <div>
    <div v-if="hasStatisticsData">
      <div class="statistics-controls-container my-3">
        <div class="d-flex justify-content-between">
          <div class="col-sm-9 col-md-10 mr-3 search-options">
            <statistics-selections-menu
              :loading="loading"
              :availableCenters="availableCenters"
              @getData="getData"
            ></statistics-selections-menu>
          </div>

          <div class="m-auto">
            <button
              v-if="enableExport"
              :disabled="loading"
              class="btn btn-primary statistics-export-button"
              @click="exportStatistics"
            >
              {{ trans("statistics.export") }}
            </button>
          </div>
        </div>
      </div>
      <div class="statistics-charts-container">
        <average-response-time
          class="statistics-item response-time"
          :responseTimes="responseTimes"
          :loading="loading"
        ></average-response-time>

        <consultations-chart
          class="statistics-item consultations"
          :consultations="consultationsData"
          :loading="loading"
        ></consultations-chart>

        <response-tracker-chart
          class="statistics-item response-tracker"
          :responseData="responseTrackerData"
          :loading="loading"
        ></response-tracker-chart>

        <patients-chart
          class="statistics-item patients"
          :patients="patients"
          :loading="loading"
        ></patients-chart>

        <dermatoscopic-diagnosis-chart
          class="statistics-item dermatoscopic-diagnosis"
          :dermatoscopicDiagnoses="dermatoscopicDiagnoses"
          :loading="loading"
        ></dermatoscopic-diagnosis-chart>

        <planned-actions-chart
          class="statistics-item planned-actions"
          :plannedActions="plannedActions"
          :loading="loading"
        ></planned-actions-chart>
      </div>
    </div>
    <feature-not-available v-else />
  </div>
</template>

<script>
import ConsultationsChart from "./StatisticsComponents/ConsultationsChart.vue";
import ResponseTrackerChart from "./StatisticsComponents/ResponseTracker.vue";
import AverageResponseTime from "./StatisticsComponents/AverageResponseTime.vue";
import PatientsChart from "./StatisticsComponents/PatientsChart.vue";
import PlannedActionsChart from "./StatisticsComponents/PlannedActionsChart.vue";
import DermatoscopicDiagnosisChart from "./StatisticsComponents/DermatoscopicDiagnosisChart.vue";
import { isSafari } from "../../helpers/platform";
import StatisticsSelectionsMenu from "./StatisticsComponents/StatisticsUtils/StatisticsSelectionsMenu.vue";
import FeatureNotAvailable from "./StatisticsComponents/StatisticsUtils/FeatureNotAvailable.vue";

export default {
  components: {
    ConsultationsChart,
    ResponseTrackerChart,
    AverageResponseTime,
    PatientsChart,
    PlannedActionsChart,
    DermatoscopicDiagnosisChart,
    StatisticsSelectionsMenu,
    FeatureNotAvailable,
  },
  data: function() {
    return {
      loading: false,
      availableCenters: [],
      consultationsData: null,
      responseTrackerData: null,
      dermatoscopicDiagnoses: null,
      plannedActions: null,
      responseTimes: null,
      patients: null,
      enableExport: !isSafari,
      selectedCenters: [this.$store.state.user.primaryCenter.id],
      dateFrom: "",
      dateTo: "",
    };
  },
  created: function() {
    this.getAvailableCenters();
    this.getData(this.selectedCenters, this.dateFrom, this.dateTo);
  },
  methods: {
    getData: function(selectedCenters, dateFrom, dateTo) {
      this.loading = true;

      let formattedCentersList = this.formatCentersListToJson(selectedCenters);

      axios
        .get(
          `/api/internal/statistics/examinations?center=${formattedCentersList}&fromDate=${dateFrom}&untilDate=${dateTo}`
        )
        .then(data => {
          this.consultationsData = data.data.statuses;
          this.responseTrackerData = data.data;
          this.dermatoscopicDiagnoses = data.data.diagnoses;
          this.plannedActions = data.data.plannedActions;
          this.responseTimes = data.data.responseTimes;
          this.patients = data.data.patients;

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    getAvailableCenters: function() {
      this.loading = true;

      axios
        .get("/api/internal/statistics/available-centers")
        .then(data => {
          this.availableCenters = data.data;
          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    exportStatistics: function() {
      const resize = function() {
        for (const id in Chart.instances) {
          Chart.instances[id].resize();
        }
      };

      if (window.matchMedia) {
        let mediaQueryList = window.matchMedia("print");
        mediaQueryList.addEventListener("change", mql => {
          if (mql.matches) {
            resize();
          }
        });
      }

      window.onbeforeprint = resize;
      window.print();
      resize();
    },
    formatCentersListToJson: function(selectedCenters) {
      let selectedCentersJson;

      if (
        selectedCenters.length === 1 &&
        selectedCenters[0].id === this.$store.state.user.primaryCenter.id
      ) {
        selectedCentersJson = JSON.stringify([selectedCenters[0].id]);
      } else {
        selectedCentersJson = JSON.stringify(selectedCenters);
      }

      return selectedCentersJson;
    },
  },
  computed: {
    hasStatisticsData: function() {
      // mole and mole uk
      const productsWithStatistics = [1, 11, 110];
      const userProducts = this.$store.state.user.products;

      return userProducts.some(product => {
        return productsWithStatistics.includes(product.type) && product.active;
      });
    },
  },
};
</script>
