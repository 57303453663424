import { translator as trans } from "./misc";

// includes both form types and manually defined form error types
export const FORM_TYPES = {
  EMPTY: "emptyString",
  FIRST_NAME: "firstname",
  LAST_NAME: "lastname",
  USER_NAME: "username",
  PHONE: "phone_no",
  PHONE_TOO_SHORT: "phoneTooShort",
  JOB_TITLE: "title",
  HSA_ID: "hsa_id",
  PASSWORD: "password",
  PASSWORD_CONFIRM: "password_confirmation",
  EMAIL: "email",
  CENTER_NAME: "center_name",
  HAS_RIKSSAR: "has_rikssar",
  TEXT_TOO_LONG: "textTooLongError",
  PASSWORD_DOES_NOT_MATCH: "passwordConfirmationError",
  PASSWORD_TOO_SHORT: "passwordTooShort",
  PASSWORD_TOO_LONG: "passwordTooLong",
  REPORT_DATE: "report_date",
  DATE_IN_FUTURE: "dateInFuture",
};

const errorMessages = {
  firstname: trans("forms.freetextErrorMsg"),
  lastname: trans("forms.freetextErrorMsg"),
  username: trans("forms.usernameError"),
  title: trans("forms.freetextErrorMsg"),
  hsa_id: trans("forms.freetextErrorMsg"),
  phone_no: trans("forms.phoneErrorMsg"),
  phoneTooShort: trans("forms.phoneTooShort"),
  emptyString: trans("forms.emptyStringError"),
  passwordTooShort: trans("forms.passwordTooShortError"),
  passwordTooLong: trans("forms.passwordTooLongError"),
  password_confirmation: trans("forms.passwordConfirmationError"),
  passwordConfirmationError: trans("forms.passwordConfirmationError"),
  textTooLongError: trans("forms.textTooLongError"),
  email: trans("forms.emailNotValid"),
  dateInFuture: trans("forms.dateInFuture"),
};

const isEmptyString = input => {
  let entry = typeof input === "string" ? input.trim() : input;

  if (!input || entry === "") {
    return true;
  }
  return false;
};

const isTextTooLong = input => {
  if (input.length > 20) {
    return false;
  }
  return true;
};

const isValidText = input => {
  // whitelist only characters
  if (!input.match(/^[a-zA-Z åäöéèæêøßÅÄÖÉÈÆÊØ']+$/i)) {
    return false;
  }
  return true;
};

const isValidUserName = input => {
  if (input.length < 4) {
    return false;
  }
  return true;
};

//if Phone input contains letters, return false
const isValidPhoneNumber = input => {
  if (input.match(/.*[a-zA-Z]+/i)) {
    return false;
  }
  return true;
};

const isTooShortPhoneNumber = input => {
  return input.length < 8 ? false : true;
};

const isPwdShort = input => {
  if (input.length < 8) {
    return false;
  }
  return true;
};
const isPwdLong = input => {
  if (input.length > 20) {
    return false;
  }
  return true;
};
const isEmailValid = input => {
  if (input.match(/.*(?=[^@]*@[^@]*$)/i)) {
    return true;
  }
  return false;
};

const isRePwdSame = (password, passwordConfirmation) => {
  if (passwordConfirmation === password) {
    return true;
  }
  return false;
};

export const validatePasswordIsSame = (password, passwordConfirmation) => {
  let error = undefined;
  if (!isRePwdSame(password, passwordConfirmation)) {
    let type = FORM_TYPES.PASSWORD_DOES_NOT_MATCH;
    error = errorMessages[type];
    return error;
  }
  return;
};

export const isDateInFuture = input => {
  const todayDate = new Date();
  return moment(input).isAfter(todayDate, "day");
};

export const validateInput = (type, input, required) => {
  let error = undefined;
  if (!required) {
    return;
  }
  if (required && isEmptyString(input)) {
    type = FORM_TYPES.EMPTY;
    error = errorMessages[type];
    return error;
  }
  switch (type) {
    case FORM_TYPES.FIRST_NAME:
      if (!isValidText(input)) {
        error = errorMessages[type];
      }
      if (!isTextTooLong(input)) {
        type = FORM_TYPES.TEXT_TOO_LONG;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.LAST_NAME:
      if (!isValidText(input)) {
        error = errorMessages[type];
      }
      if (!isTextTooLong(input)) {
        type = FORM_TYPES.TEXT_TOO_LONG;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.USER_NAME:
      if (!isValidUserName(input)) {
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.JOB_TITLE:
      if (!isValidText(input)) {
        error = errorMessages[type];
      }
      if (!isTextTooLong(input)) {
        type = FORM_TYPES.TEXT_TOO_LONG;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.HSA_ID:
      if (!isValidText(input)) {
        error = errorMessages[type];
      }
      if (!isTextTooLong(input)) {
        type = FORM_TYPES.TEXT_TOO_LONG;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.PHONE:
      if (!isValidPhoneNumber(input)) {
        type = FORM_TYPES.PHONE;
        error = errorMessages[type];
      }
      if (!isTooShortPhoneNumber(input)) {
        type = FORM_TYPES.PHONE_TOO_SHORT;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.PASSWORD:
      if (!isPwdShort(input)) {
        type = FORM_TYPES.PASSWORD_TOO_SHORT;
        error = errorMessages[type];
      }
      if (!isPwdLong(input)) {
        type = FORM_TYPES.PASSWORD_TOO_LONG;
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.EMAIL:
      if (!isEmailValid(input)) {
        error = errorMessages[type];
      }
      break;
    case FORM_TYPES.REPORT_DATE:
      if (isDateInFuture(input)) {
        type = FORM_TYPES.DATE_IN_FUTURE;
        error = errorMessages[type];
      }
      break;

    default:
      break;
  }
  return error;
};

/**
 * Regular expression to parse a string for a numeric value followed by a unit.
 *
 * The expression consists of two main parts:
 * 1. `(-?[\d.]+)`: Captures the numeric value.
 *    - `-?` allows for an optional negative sign.
 *    - `[\d.]+` matches one or more digits or decimal points, capturing the numeric part of the string.
 *
 * 2. `\s*([\w\s%]*)`: Captures the unit, allowing spaces within the unit.
 *    - `\s*` matches zero or more whitespace characters, accommodating any space between the number and the unit.
 *    - `([\w\s%]*)` captures the unit, which can include:
 *      - `\w` any word characters (letters, digits, and underscores).
 *      - `\s` spaces, to allow for units with spaces (e.g., 'mm hg').
 *      - `%` the percentage symbol, if applicable.
 *
 * This regular expression is useful for parsing strings that contain a numeric value
 * followed by a unit, where the unit can be a simple word like 'cm', 'kg', or a
 * compound unit like 'mm hg'. The regex handles optional spaces between the
 * number and the unit and within the unit itself.
 *
 * Example usage:
 * const res = str.match(/(-?[\d.]+)\s*([\w\s%]*)/);
 * // For '22 mm hg', res[1] will be '22' (numeric value) and res[2] will be 'mm hg' (unit).
 */

export function parseValueAndUnitFromString(str) {
  const res = str.match(/(-?[\d.]+)\s*([\w\s%]*)/);
  return {
    val: parseFloat(res[1]),
    unit: res[2],
  };
}
