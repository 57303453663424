<template>
  <div>
    <clip-loader v-show="!patient"></clip-loader>
    <div v-if="patient">
      <div class="row row-eq-height">
        <div class="col-md-12 col-lg-6">
          <div class="panel panel-default">
            <div class="panel-heading panel-heading-underline">
              <h3 class="panel-title">
                {{ trans("examination.generalHeadline") }}
              </h3>
            </div>
            <div class="panel-body">
              <div class="row">
                <div class="m-b-20 col-md-6">
                  <strong>{{ trans("patient.ssn") }}</strong>
                  <br />
                  <p class="text-muted">{{ patient.ssn }}</p>
                </div>

                <div class="m-b-20 col-md-6">
                  <strong>{{ trans("patient.fullName") }}</strong>
                  <br />
                  <p
                    class="text-muted"
                    v-dompurify-html:fullName="patient.fullName"
                  ></p>
                </div>

                <div class="m-b-20 col-md-6">
                  <strong>{{ trans("patient.age") }}</strong>
                  <br />
                  <p class="text-muted">{{ patient.age }}</p>
                </div>

                <div class="m-b-20 col-md-6">
                  <strong>{{ trans("patient.gender") }}</strong>
                  <br />
                  <p class="text-muted">{{ patient.gender }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- / .col-md-6 -->
      </div>
      <!-- / .row-eq-height -->
    </div>
  </div>
</template>

<script>
export default {
  props: ["patient"],
};
</script>
