<template>
  <div>
    <header-admin :active-section="9"></header-admin>

    <div class="text-right my-2">
      <router-link to="/admin/regions/new" class="btn btn-primary">{{
        trans("region.newRegion")
      }}</router-link>
    </div>

    <div class="table-responsive">
      <clip-loader v-show="loading"></clip-loader>

      <table class="table table-hover interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ trans("region.regionName") }}</th>
            <th>{{ trans("region.connectedCenters") }}</th>
            <th>{{ trans("region.regionAdmin") }}</th>
            <th>{{ trans("examination.createdAtLabel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            @click="editRegion(region.id)"
            v-for="(region, index) in regions"
            :key="index"
          >
            <td>{{ region.name }}</td>
            <td>{{ region.centers.length }}</td>
            <td>{{ region.region_admins.length }}</td>
            <td>{{ region.created_at_formatted }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <paginate
        :pageCount="paginate.last_page"
        :click-handler="loadData"
        :prev-text="'‹'"
        :next-text="'›'"
        :containerClass="'pagination'"
        ref="paginate"
        v-model="paginate"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'page-item active'"
        :prev-class="'page-item'"
        :prev-link-class="'page-link'"
        :next-class="'page-item'"
        :next-link-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
import HeaderAdmin from "../Header.vue";

export default {
  mounted: function() {
    this.loadData();
  },
  data() {
    return {
      loading: false,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      page: 1,
      regions: [],
    };
  },
  methods: {
    loadData: function(page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/regions?page=" + page)
        .then(data => {
          let collection = data.data;
          this.paginate = collection.paginate;
          this.regions = collection.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    editRegion: function(regionId) {
      this.$router.push({ name: "AdminRegionsEdit", params: { id: regionId } });
    },
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  components: {
    HeaderAdmin,
  },
};
</script>
