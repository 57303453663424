<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p class="text-muted" v-dompurify-html="data ? data : '-'"></p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: String | Number,
  },
};
</script>
