<template>
  <div>
    <header-admin :active-section="6"></header-admin>

    <div class="mt-3">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" v-for="(tab, index) in tabsList" :key="index">
          <router-link active-class="active" class="nav-link" :to="`${tab}`">{{
            trans("admin.logsMenuItem" + tab)
          }}</router-link>
        </li>
        <li
          class="nav-item"
          v-if="$store.state.dermicusPlatform === 'stockholm'"
        >
          <router-link active-class="active" class="nav-link" to="takeCare">{{
            trans("admin.logsMenuItemtakeCare")
          }}</router-link>
        </li>
        <li class="nav-item" v-if="$store.state.dermicusPlatform === 'sweden'">
          <router-link
            active-class="active"
            class="nav-link"
            to="minDoktorRetransfers"
            >{{ trans("admin.logsMenuItemminDoktorRetransfers") }}</router-link
          >
        </li>
      </ul>
    </div>

    <router-view class="tab-content"></router-view>
  </div>
</template>

<script>
import HeaderAdmin from "./../Header.vue";

export default {
  components: {
    HeaderAdmin,
  },
  data() {
    return {
      // all tabs that should be visible despite special conditions
      tabsList: ["system", "apiIn", "apiOut", "email"],
    };
  },
};
</script>
