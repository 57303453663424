import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import { ProductsConfigResult } from "../../../../../app/Http/Controllers/Api/Internal/ProductsController";
import _once from "lodash/fp/once";

// For the moment, this can be safely cached to avoid rate limit issues.
// The config content now only depends on the user role, which doesn't really
// change during a session, and it's being used in a non-standard way as it's
// a workaround for UI that doesn't use the product definitions currently.
export const productsConfigGet = _once(() =>
  practitionerHttpService
    .get<ProductsConfigResult>("/api/internal/products/config")
    .then(response => response.data)
);
