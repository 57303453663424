<template>
  <login-form-container>
    <div class="text-center container">
      <h5 class="pt-5 pb-3">{{ trans("forms.thankYou") }}</h5>

      <p class="pb-5">
        {{ trans("forms.formSubmitSuccessful") }}
      </p>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "../Auth/LoginFormContainer.vue";

export default {
  components: {
    LoginFormContainer,
  },
};
</script>
