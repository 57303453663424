<template>
  <div class="multi-selection-container">
    <label
      >{{ title }}
      <i v-if="required" class="far fa-asterisk" role="img"></i>
    </label>
    <v-select
      :name="title"
      :options="data"
      multiple
      v-model="value"
      @input="onInput"
      :reduce="reduce"
      :disabled="disabled"
      append-to-body
      :calculate-position="calculatePositionWithPopper"
    />
    <div v-if="withOther && otherSelected">
      <label v-if="otherLabel" class="mt-3"
        >{{ otherLabel }}
        <i v-if="otherRequired" class="far fa-asterisk" role="img"></i>
      </label>
      <FormTextfield
        :disabled="disabled"
        :required="otherRequired"
        @isValid="otherIsValid = $event"
        :extra-options="otherExtraOptions"
        @field-unmount="clearOtherValue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, ref, toRefs, watch, shallowRef } from "vue";
import { calculatePositionWithPopper } from "../../../../../helpers/popper";
import { lang } from "../../../../../i18n";
import FormTextfield from "./FormTextfield.vue";

const emit = defineEmits<{
  (e: "field-unmount"): void;
  (e: "isValid", payload: boolean): void;
}>();

onBeforeUnmount(() => {
  emit("field-unmount");
});

const props = defineProps<{
  title?: string;
  required?: boolean;
  disabled?: boolean;
  reduce?: (value: unknown) => unknown;
  data: Array<{ value: string }>;
  formValue?: Array<{ value: string }>;
  extraOptions?: {
    key?: string;
    onValueChange: (payload: { key?: string; value?: string }) => void;
    fieldOptions?: {
      other_value?: string;
      other_label?: string;
      other_key?: string;
      other_required?: boolean;
      other_onValueChange: (payload: { key?: string; value?: string }) => void;
    };
    postUrl?: string;
  };
}>();

const value = ref();

const { formValue, extraOptions, reduce, required } = toRefs(props);

watch(
  formValue,
  () => {
    value.value = Array.isArray(props.formValue) ? props.formValue : [];
  },
  { immediate: true }
);

const otherIsValid = shallowRef(false);

const withOther = computed(
  () => !!extraOptions.value?.fieldOptions?.other_value
);

const otherLabel = computed(
  () =>
    extraOptions.value?.fieldOptions?.other_label &&
    lang.get(extraOptions.value?.fieldOptions?.other_label)
);

const otherSelected = computed(() =>
  value.value?.some(v => v === extraOptions.value?.fieldOptions?.other_value)
);

const otherExtraOptions = computed(() => ({
  key: extraOptions.value?.fieldOptions?.other_key,
  onValueChange: extraOptions.value?.fieldOptions?.other_onValueChange,
}));

const otherRequired = computed(
  () => !!extraOptions.value?.fieldOptions?.other_required
);

const isValid = computed(() => {
  if (!required?.value) {
    return true;
  }
  if (
    value?.value === undefined ||
    value?.value === null ||
    value?.value?.length === 0
  ) {
    return false;
  }
  if (
    withOther.value &&
    otherSelected.value &&
    otherRequired.value &&
    !otherIsValid.value
  ) {
    return false;
  }
  return true;
});
watch(isValid, () => emit("isValid", isValid.value), { immediate: true });

function onInput(event: any) {
  props.extraOptions?.onValueChange({
    key: props.extraOptions.key,
    value: event,
  });
}

function clearOtherValue() {
  otherExtraOptions.value?.onValueChange({
    key: otherExtraOptions.value.key,
    value: null,
  });
}
</script>
