<template>
  <div class="tab-pane" id="api_out">
    <div id="custom-search-input" class="mb-3">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          type="text"
          class="form-control input-lg"
          id="apiOutSearchInputCaseNo"
          :placeholder="trans('forms.searchForCaseNumber')"
          :title="trans('general.search')"
        />
        <input
          type="text"
          id="apiOutSearchInputRequest"
          class="form-control input-lg"
          :placeholder="trans('forms.searchForRequestUrl')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            class="btn"
            type="button"
            @click.stop="clearSearch"
            v-if="isSearching"
          >
            <i class="fal fa-times"></i>
          </button>
          <button class="btn" type="button" @click.stop="search">
            <i class="far fa-search"></i>
          </button>
        </span>
      </form>
    </div>

    <clip-loader v-if="loading"></clip-loader>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Request url</th>
            <th>User</th>
            <th>Body</th>
            <th>Examination</th>
            <th>Response code</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in api_out.logs" :key="index">
            <td>{{ log.request_uri }}</td>
            <td>{{ log.user }}</td>
            <td>{{ log.text }}</td>
            <td>{{ log.examination }}</td>
            <td>{{ log.response_code }}</td>
            <td>{{ log.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <paginate
        :pageCount="api_out.paginate.last_page"
        :click-handler="getApiOutResults"
        :prev-text="'‹'"
        :next-text="'›'"
        :containerClass="'pagination'"
        ref="paginate"
        v-model="api_out.paginate"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'page-item active'"
        :prev-class="'page-item'"
        :prev-link-class="'page-link'"
        :next-class="'page-item'"
        :next-link-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  created: function() {
    this.getApiOutResults();
  },
  data() {
    return {
      loading: true,
      api_out: {
        paginate: {
          current_page: 0,
          total: 0,
          last_page: 0,
        },
        logs: [],
      },
      isSearching: false,
    };
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  methods: {
    getApiOutResults(page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/logs/api-out?page=" + page)
        .then(data => {
          var collection = data.data;
          this.api_out.paginate = collection.paginate;
          this.api_out.logs = collection.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    search: function() {
      this.loading = true;
      this.isSearching = true;
      let caseNumber = $("#apiOutSearchInputCaseNo").val();
      let request = $("#apiOutSearchInputRequest").val();
      axios
        .get(
          "/api/internal/admin/logs/api-out?case_number=" +
            caseNumber +
            "&request=" +
            request
        )
        .then(data => {
          var collection = data.data;
          this.api_out.logs = collection.data;
          this.api_out.paginate = collection.paginate;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    clearSearch: function() {
      $("#apiOutSearchInput").val("");
      $("#apiOutSearchRequest").val("");
      this.isSearching = false;
      this.getApiOutResults();
    },
    retryMinDoktor: function(examinationId) {
      this.loading = true;
      this.$dialog.alert({
        title: "Retry can take up to 30s",
        body:
          "Sending retry to Min Doktor can take up to 30s. Please wait for response before clicking on the retry button again.",
      });
      axios
        .get("/api/internal/admin/retransfer-to-min-doktor/" + examinationId)
        .then(data => {
          this.loading = false;
          this.$notify({
            text: "Min Doktor retry sent successfully!",
            duration: 3000,
            type: "success",
          });
          this.clearSearch();
        })
        .catch(error => {
          this.loading = false;
        });
    },
  },
};
</script>
