<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("aiFeatures.addAiResultsPanelHeader") }}
      </h3>
    </div>

    <div class="panel-body add-ai-results-panel-body row col-md-12 m-0">
      <div v-if="loadingAiResults" class="overlay">
        <clip-loader></clip-loader>
      </div>

      <div @dragover.prevent @drop.prevent class="col-md-12 p-1">
        <div @drop="uploadFile($event, 'drop')" class="p-5 upload-file-box">
          <div v-if="file" class="selected-file-box text-center">
            <i class="fal fa-file-check fa-3x"></i>
            <p class="p-3 font-weight-bold">
              {{ fileName }}
            </p>
            <horizontal-line
              :text="trans('auth.middleTextOr')"
            ></horizontal-line>
            <label class="p-3 custom-file-input-inline-btn">
              <input type="file" @change="uploadFile($event, 'upload')" />{{
                trans("aiFeatures.selectDifferentFileBtnText")
              }}</label
            >
          </div>
          <div v-else class="text-center">
            <div class="text-center">
              <i class="fas fa-cloud-upload-alt fa-3x my-2"></i>
              <p>{{ trans("aiFeatures.dragAndDropFileInfoText") }}</p>
            </div>
            <horizontal-line
              :text="trans('auth.middleTextOr')"
            ></horizontal-line>

            <label class="custom-file-input-btn">
              <input
                type="file"
                accept=".csv"
                @change="uploadFile($event, 'upload')"
              />{{ trans("aiFeatures.selectFileBtnText") }}</label
            >
          </div>
        </div>
      </div>
    </div>

    <div class="text-right mb-3 mr-4 tw-space-x-2">
      <button class="btn btn-secondary" @click="onCancel">
        {{ trans("general.modalCancelButton") }}
      </button>
      <button
        class="btn btn-primary"
        @click="uploadAiResults"
        :disabled="!file"
      >
        {{ trans("aiFeatures.addAiResultsBtn") }}
      </button>
    </div>
  </div>
</template>

<script>
import HorizontalLine from "../../Utils/HorizontalLine.vue";

export default {
  components: {
    HorizontalLine,
  },
  props: {
    loadingAiResults: Boolean,
  },
  data() {
    return {
      file: null,
      fileName: "",
    };
  },
  methods: {
    uploadFile(e, type) {
      let csvFile = null;
      const formData = new FormData();

      if (type === "drop") {
        if (e.dataTransfer.files) {
          csvFile = e.dataTransfer.files[0];
          this.fileName = csvFile.name;
        }
      } else if (type === "upload") {
        if (e.currentTarget.files) {
          csvFile = e.currentTarget.files[0];
          this.fileName = csvFile.name;
        }
      }

      if (csvFile) {
        formData.append("csv", csvFile);
      }

      this.file = formData;
    },
    onCancel: function() {
      this.$emit("onCancel");
    },
    uploadAiResults: function() {
      this.$emit("uploadAiResults", this.file);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.add-ai-results-panel-body {
  position: relative;
}

.upload-file-box {
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border: 2px dashed #b1b1b1;
  border-radius: 3px;

  .fas.fa-cloud-upload-alt {
    color: #b1b1b1;
  }
}

input[type="file"] {
  display: none;
}

.custom-file-input-btn {
  content: "Choose CSV file...";
  border-radius: 2px;
  outline: none !important;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: variables.$dermicusBlue !important;
  border: 1px solid variables.$dermicusBlue !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
}

.custom-file-input-inline-btn {
  content: "Choose CSV file...";
  outline: none !important;
  font-size: 14px !important;
  color: variables.$dermicusBlue !important;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  user-select: none;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
