<template>
  <div>
    <header-admin :active-section="2"></header-admin>

    <clip-loader v-show="loading"></clip-loader>

    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 mt-5">
          <div class="p-0 text-center">
            <div>
              <h4 class="m-b-5">{{ user.fullName }}</h4>
              <p class="text-muted">{{ user.title }}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="col-md-12 text-right">
          <button class="btn btn-grey mr-1" @click="goBack">
            {{ trans("general.back") }}
          </button>
          <button
            class="btn btn-primary saveButton"
            @click="saveFormData"
            :disabled="!isValid || loading"
            :title="isValid ? '' : trans('examination.requiredFieldMissing')"
          >
            {{ trans("users.saveButton") }}
          </button>
        </div>

        <user-header componentPurpose="admin"></user-header>

        <div class="tab-content">
          <div class="tab-pane active" id="profile">
            <user-profile :errors="errors" :user="user"></user-profile>

            <div class="text-right mb-5">
              <button
                v-if="user.initials"
                type="button"
                class="btn btn-secondary mr-1"
                @click="sendPasswordReset"
              >
                <span v-if="user.is_verified">{{
                  trans("center.sendPasswordReset")
                }}</span>
                <span v-if="!user.is_verified">{{
                  trans("center.resendVerificationEmail")
                }}</span>
              </button>
              <button
                v-if="user.initials"
                class="btn btn-primary"
                @click="saveFormData"
                :disabled="!isValid || loading"
                :title="
                  isValid ? '' : trans('examination.requiredFieldMissing')
                "
              >
                {{ trans("users.saveButton") }}
              </button>
            </div>
          </div>

          <div class="tab-pane" id="settings">
            <user-settings
              :user="user"
              @update:user="user = $event"
              :externalResources="externalResources"
              :ucr="ucr"
              @syncUcr="syncUcr"
              :errors="errors"
              :userRoles="userRoles"
              :dropdownRole="dropdownRole(user)"
            ></user-settings>

            <!-- User centers -->
            <div class="row mb-5">
              <div class="col-md-12">
                <div class="panel panel-default panel-fill">
                  <div class="panel-heading">
                    <h3 class="panel-title">
                      {{ trans("users.resursHeadline") }}
                    </h3>
                  </div>
                  <div class="panel-body">
                    <div
                      class="form-group"
                      v-if="this.$route.params.id === 'new'"
                    >
                      <div
                        class="form-group primaryCenterSelection"
                        :class="{ 'has-error': errors.primary_center_id }"
                      >
                        <label for="primaryCenter">
                          {{ trans("users.primaryCenterLabel") }}
                        </label>
                        <i class="far fa-asterisk"></i>
                        <select2
                          id="primaryCenter"
                          name="primaryCenter"
                          v-model="primaryCenterId"
                          disable-clear="true"
                        >
                          <option :value="primaryCenter.id" selected>
                            {{ primaryCenter.name }}
                          </option>
                          <option
                            v-for="(center, index) in centers"
                            :value="center.id"
                            :key="index"
                            >{{ center.text }}</option
                          >
                        </select2>
                        <form-error
                          v-if="errors.primary_center_id"
                          :errors="errors.primary_center_id"
                        ></form-error>
                      </div>
                    </div>
                    <div
                      class="form-group"
                      v-if="this.$route.params.id !== 'new'"
                    >
                      <label class="tw-block">
                        {{ trans("users.primaryCenterLabel") }}:
                        <span class="tw-font-light">
                          {{ primaryCenter.name }}
                        </span>
                      </label>
                      <button
                        type="button"
                        @click="showModal"
                        class="btn btn-primary"
                      >
                        <i class="fa fa-external-link-alt"></i>
                        <span>{{
                          trans("users.changePrimaryCenterLabel")
                        }}</span>
                      </button>
                    </div>
                    <div
                      class="table-responsive"
                      v-if="this.$route.params.id !== 'new'"
                    >
                      <label>
                        {{ trans("users.connectedCentersLabel") }}
                      </label>

                      <table class="table">
                        <thead>
                          <tr>
                            <th>{{ trans("evaluation.tableColumnCenter") }}</th>
                            <th>{{ trans("admin.canRegister") }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(selectedCenter, index) in selectedCenters"
                            :key="index"
                          >
                            <td class="centerCell">
                              {{ selectedCenter.name }}
                            </td>
                            <td>
                              <DerToggle v-model="selectedCenter.is_editable" />
                            </td>
                            <td>
                              <div class="text-right">
                                <button
                                  :data-forCenter="selectedCenter.name"
                                  class="btn btn-danger"
                                  @click="removeSelectedCenter(index)"
                                >
                                  {{ trans("general.formDeleteLabel") }}
                                </button>
                              </div>
                            </td>
                          </tr>

                          <!-- form to add new connected center -->
                          <tr
                            v-if="newConnectedCenter.isEditing"
                            class="editing-centers"
                          >
                            <td>
                              <select2
                                name="centerSelector"
                                id="centerSelector"
                                v-model="newConnectedCenter.center_id"
                              >
                                <option
                                  v-for="(center, index) in centers"
                                  :key="index"
                                  :value="center.id"
                                  >{{ center.text }}</option
                                >
                              </select2>
                            </td>
                            <td>
                              <DerToggle
                                v-model="newConnectedCenter.is_editable"
                              />
                            </td>
                            <td>
                              <div
                                class="text-right"
                                v-if="newConnectedCenter.isEditing"
                              >
                                <button
                                  class="btn btn-grey m-1"
                                  @click="cancelAddingNewSelectedCenter()"
                                >
                                  {{ trans("general.modalCancelButton") }}
                                </button>
                                <button
                                  class="btn btn-primary saveSelectedCentre"
                                  @click="saveNewSelectedCenter()"
                                >
                                  {{ trans("general.formSaveLabel") }}
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>

                      <button
                        class="btn btn-primary m-1 float-right addCenterButton"
                        @click="addNewSelectedCenter()"
                        v-if="!newConnectedCenter.isEditing"
                      >
                        {{ trans("admin.addNewCenter") }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- User centers -->
          </div>

          <div class="tab-pane" id="products">
            <user-products :products="products" :user="user"></user-products>
            <div v-if="!products.length">
              <h6 class="text-center">
                {{ trans("admin.userNotConnectedToCenterMessage") }}
              </h6>
            </div>
          </div>
        </div>
      </div>
      <VueJsDialogMixinWrapper
        v-if="showMoveUserDialog"
        :data-component="moveUserDialogComponent"
        :data-options="moveUserDialogOptions"
        @userSettingsChanges="loadData"
        @close="closeModal"
      />
    </div>
  </div>
</template>

<script>
import DerToggle from "../../../../design-system/DerToggle.vue";
import HeaderAdmin from "../../Header.vue";
import UserHeader from "../../../User/UserHeader.vue";
import UserProfile from "./UserProfile.vue";
import UserProducts from "./UserProducts.vue";
import UserSettings from "./UserSettings.vue";

import DropdownRole from "../../../../mixins/DropdownRole.vue";
import Notifications from "../../../Utils/notifications";
import VueJsDialogMixinWrapper from "../../../../mixins/VueJsDialogMixinWrapper.vue";
import AdminMoveUser from "./MoveUser.vue";

export default {
  mixins: [DropdownRole],
  created: function() {
    this.loadRoles();
    this.loadData();
  },
  beforeRouteEnter(to, from, next) {
    next(instance => {
      instance.backRoute = from.path;
    });
  },
  data() {
    return {
      loading: false,
      user: {},
      primaryCenter: {
        id: null,
      },
      primaryCenterId: "",
      centers: "",
      selectedCenters: "",
      externalResources: "",
      csrfToken: Laravel.csrfToken,
      newConnectedCenter: {
        isEditing: false,
        center_id: null,
        is_editable: false,
      },
      ucr: {
        active: true,
        id: "",
      },
      products: {
        notifications_new_examinations: false,
        consensus_notifications: false,
        new_consensus_channel_notifications: false,
        notification_new_document_consult_answer: false,
        unanswered_cases: false,
        cases_without_photos: false,
      },
      errors: [],
      roles: [],
      userRoles: {
        consultant: false,
        senior_consultant: false,
        wound_user: false,
        user: false,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
        ai_tester: false,
        consultation_creator: false,
        consultation_photographer: false,
        study_admin: false,
        study_participator: false,
      },
      backRoute: "/",
      showMoveUserDialog: false,
      moveUserDialogComponent: AdminMoveUser,
    };
  },
  methods: {
    showModal() {
      this.showMoveUserDialog = true;
    },
    closeModal() {
      this.showMoveUserDialog = false;
    },
    goBack: function() {
      if (this.backRoute.includes("preliminary")) {
        this.$router.push({ name: "AdminUsersPreliminary" });
      } else {
        this.$router.go(-1);
      }
    },
    loadRoles: function() {
      axios
        .get("/api/internal/admin/roles")
        .then(data => {
          let collection = data.data;
          this.roles = collection;
        })
        .catch(error => {
          console.log("Loading roles error: ", error);
        });
    },
    loadData: function() {
      this.loading = true;
      axios
        .get("/api/internal/admin/users/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.user = collection.user;
          this.primaryCenter = collection.primaryCenter;
          this.primaryCenterId = collection.primaryCenter.id;
          this.centers = collection.centers;
          this.selectedCenters = collection.selectedCenters;
          this.externalResources = collection.externalResources;
          this.ucr = collection.ucr;

          // checkbox
          this.products = collection.user.products;

          // assign role to true if the role is in the assigned user roles list
          let assignedUserRoles = this.user.roles;
          assignedUserRoles.map(value => {
            this.userRoles[value.name] = true;
          });

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    saveFormData: function() {
      /**
       * We want to show a dialog to confirm that you are deactivating a user.
       * We can check this by seeing if the current user is not blocked, and the new
       * userRoles.user value is false.
       *
       * The direct mutation of props is very confusing and error-prone here, as well as
       * the duplication of the various roles/userRoles, so this seems like the easiest way to
       * handle this for now.
       */
      if (this.userRoles.user === false && this.user.blocked === false) {
        this.$dialog
          .confirm(this.trans("general.modalBlockUserMessage"), {
            okText: this.trans("general.modalConfirmButton"),
            cancelText: this.trans("general.modalCancelButton"),
          })
          .then(() => {
            // If confirmed, proceed with saving.
            this.submitForm();
          })
          .catch(() => {
            // If canceled, abort the save.
            return false;
          });
      } else {
        this.submitForm();
      }
    },
    submitForm() {
      this.user.primary_center_id = this.primaryCenterId;
      this.user.centers = this.selectedCenters;
      this.user.products = this.products;
      this.user.userRoles = this.userRoles;

      this.errors = [];
      this.loading = true;

      // don't create blocked user
      if (this.$route.params.id === "new") {
        this.user.userRoles.user = true;

        // don't create users that can't create cases, unless they are only supposed to be photographers
        if (this.user.userRoles.consultation_photographer === false) {
          this.user.userRoles.consultation_creator = true;
        }
      }

      axios
        .post("/api/internal/admin/users/" + this.user.id, this.user)
        .then(response => {
          Notifications.saveSuccess();

          if (response.data.isNew) {
            this.$router.push({ name: "AdminUsers" });
          }

          this.loadData();
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    addNewSelectedCenter: function() {
      this.newConnectedCenter.isEditing = true;
    },
    saveNewSelectedCenter: function() {
      let newConnectedCenter = {
        center_id: parseInt(this.newConnectedCenter.center_id),
        examination_id: null,
        id: null,
        is_editable: this.newConnectedCenter.is_editable,
        is_primary: null,
        user_id: this.user.id,
      };

      this.selectedCenters.push(newConnectedCenter);

      this.cancelAddingNewSelectedCenter();
      this.saveFormData();
    },
    cancelAddingNewSelectedCenter: function() {
      this.newConnectedCenter.isEditing = false;
      this.newConnectedCenter.center_id = null;
      this.newConnectedCenter.is_editable = false;
    },
    removeSelectedCenter: function(index) {
      this.selectedCenters.splice(index, 1);
      this.saveFormData();
    },
    syncUcr: function() {
      this.errors = {};

      axios
        .post(
          "/api/internal/admin/users/" + this.user.id + "/ucr/" + this.ucr.id
        )
        .then(response => {
          if (response.data.success) {
            Notifications.saveSuccessCustomText(response.data.message);
            this.loadData();
          } else {
            Notifications.errorCustomText(response.data.message);
          }
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    sendPasswordReset: function() {
      axios
        .post("/api/internal/admin/users/password/reset/" + this.user.id)
        .then(() => {
          Notifications.saveSuccessCustomText(
            "general.notificationPasswordResetSent"
          );
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
  },
  computed: {
    moveUserDialogOptions() {
      return {
        userId: this.user.id,
        primaryCenter: this.primaryCenter,
      };
    },
    isValid: function() {
      if (
        !this.user.culture ||
        !this.user.username ||
        !this.user.email ||
        !this.user.firstname ||
        !this.user.lastname ||
        !this.primaryCenterId
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    DerToggle,
    UserProfile,
    UserProducts,
    UserSettings,
    UserHeader,
    HeaderAdmin,
    VueJsDialogMixinWrapper,
  },
};
</script>
