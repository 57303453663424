<template>
  <div class="advanced-search">
    <div class="row">
      <div class="col-md-4" v-if="searchOptions.products.length > 1">
        <label>{{ trans("evaluation.tableColumnProduct") }}</label>
        <v-select
          name="products"
          :options="searchOptions.products"
          v-model="searchFields.products"
          label="name"
          :reduce="product => product.type"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2" v-if="searchOptions.centers.length > 1">
        <label>{{ trans("evaluation.tableColumnCenter") }}</label>
        <v-select
          name="center"
          :options="searchOptions.centers"
          v-model="searchFields.center"
          label="text"
          :reduce="center => center.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUserTypes") }}</label>
        <v-select
          name="userActivity"
          :options="searchOptions.userTypes"
          v-model="searchFields.userType"
          label="text"
          :reduce="userType => userType.value"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUsers") }}</label>
        <v-select
          name="users"
          :options="searchOptions.users"
          v-model="searchFields.user"
          label="text"
          :reduce="user => user.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnStatus") }}</label>
        <v-select
          name="status"
          :options="searchOptions.statuses"
          v-model="searchFields.status"
          label="text"
          :reduce="status => status.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchOptions: {
      products: Array,
      centers: Array,
      userTypes: Array,
      users: Array,
      statuses: Array,
    },
    searchFields: Object,
  },
};
</script>

<style></style>
