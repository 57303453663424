<template>
  <ul class="nav nav-tabs tabs-bordered">
    <li v-for="item in dataItems" :key="item.to.name" :class="itemClass(item)">
      <router-link :to="item.to" data-toggle="tab" role="tab">
        {{ item.display }}
      </router-link>
    </li>
  </ul>
</template>
<script setup lang="ts">
import { useRoute } from "vue-router/composables";
import { ref, watch } from "vue";
import { Location, Route } from "vue-router";

defineProps<{ dataItems: { to: Location; display: string }[] }>();

const route = useRoute();
const from = ref<Route>(route);
watch(
  () => route,
  route => (from.value = route)
);

function itemClass(item) {
  return item.to.name === from.value.name ? "active" : undefined;
}
</script>
