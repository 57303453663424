/**
 * Based on a copy from
 * https://github.com/Godofbrowser/vuejs-dialog/blob/master/src/plugin/js/mixins/dialog-mixin.js
 * As with the Vue upgrade, we'll not be able to use the custom view option
 * anymore with the newer version we'll use for dialogs/modals. Still to be
 * seen what we'll do later though. For now, we can use this and adapt if
 * needed for our custom print dialogs.
 */

const DIALOG_TYPES = {
  ALERT: "alert", // ex: Congrats! record created
  CONFIRM: "confirm", // ex: Please confirm delete
  PROMPT: "prompt", // ex: Type your password to save changes
};

const CONFIRM_TYPES = {
  BASIC: "basic", // ex: click to confirm
  SOFT: "soft", // ex: click 3 times to confirm
  HARD: "hard", // ex: enter verification, then click to confirm
};

const MessageMixin = {
  computed: {
    messageHasTitle() {
      let m = this.options.message;
      return typeof m === "object" && m !== null && m.title;
    },
    messageTitle() {
      return this.messageHasTitle ? this.options.message.title : null;
    },
    messageBody() {
      let m = this.options.message;
      return typeof m === "string" ? m : m.body || "";
    },
  },
};

const ButtonMixin = {
  computed: {
    cancelBtnDisabled() {
      return this.options.window === DIALOG_TYPES.ALERT;
    },
    okBtnDisabled() {
      return (
        this.options.window === DIALOG_TYPES.CONFIRM &&
        this.options.type === CONFIRM_TYPES.HARD &&
        this.input !== this.options.verification
      );
    },
    leftBtnEnabled() {
      return this.cancelBtnDisabled === false || this.options.reverse === true;
    },
    rightBtnEnabled() {
      return this.cancelBtnDisabled === false || this.options.reverse === false;
    },
    leftBtnFocus() {
      return !this.isHardConfirm && this.options.reverse === true;
    },
    rightBtnFocus() {
      return !this.isHardConfirm && this.options.reverse === false;
    },
    leftBtnText() {
      return this.options.reverse
        ? this.options.okText
        : this.options.cancelText;
    },
    rightBtnText() {
      return this.options.reverse
        ? this.options.cancelText
        : this.options.okText;
    },
  },
};

export default {
  data: function() {
    return {
      input: "",
      loading: false,
    };
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
  },
  computed: {
    loaderEnabled() {
      return !!this.options.loader;
    },
    isHardConfirm() {
      return (
        this.options.window === DIALOG_TYPES.CONFIRM &&
        this.options.type === CONFIRM_TYPES.HARD
      );
    },
    isPrompt() {
      return this.options.window === DIALOG_TYPES.PROMPT;
    },
    leftBtnComponent() {
      return this.options.reverse === false ? "cancel-btn" : "ok-btn";
    },
    rightBtnComponent() {
      return this.options.reverse === true ? "cancel-btn" : "ok-btn";
    },
    hardConfirmHelpText() {
      return this.options.verificationHelp.replace(
        /\[\+:(\w+)]/g,
        (match, $1) => {
          return this.options[$1] || match;
        }
      );
    },
    promptHelpText() {
      return this.options.promptHelp.replace(/\[\+:(\w+)]/g, (match, $1) => {
        return this.options[$1] || match;
      });
    },
  },
  mounted() {
    this.isHardConfirm && this.$refs.inputElem && this.$refs.inputElem.focus();
  },
  methods: {
    clickRightBtn() {
      this.options.reverse
        ? this.cancel()
        : this.proceed(this.getDefaultData());
    },
    clickLeftBtn() {
      this.options.reverse
        ? this.proceed(this.getDefaultData())
        : this.cancel();
    },
    submitDialogForm() {
      this.okBtnDisabled || this.proceed(this.getDefaultData());
    },
    getDefaultData() {
      return this.isPrompt ? this.input : null;
    },
    proceed(withData = null) {
      if (this.loaderEnabled) {
        this.switchLoadingState(true);
        this.options.promiseResolver({
          close: this.close,
          loading: this.switchLoadingState,
          data: withData,
        });
      } else {
        this.options.promiseResolver({
          data: withData,
        });
        this.close();
      }
    },
    cancel() {
      if (this.loading === true) {
        return;
      }
      this.close();
    },
    switchLoadingState(loading = null) {
      if (loading === null) {
        loading = !this.loading;
      }

      this.loading = !!loading;
    },
    close() {
      this.$emit("close");
    },
  },
  mixins: [MessageMixin, ButtonMixin],
};
