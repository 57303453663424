<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading panel-heading-underline">
        <h3 class="panel-title">
          {{ trans("aiFeatures.aiResultsPresentationPanelHeader") }}

          <info-popup
            v-if="aiData.labels"
            :text-content="getInfoPopupText()"
          ></info-popup>
        </h3>
      </div>
      <div class="panel-body add-ai-results-panel-body">
        <bar-chart
          v-if="aiData"
          :chartdata="aiData"
          :options="aiDataOptions"
        ></bar-chart>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "../../Statistics/StatisticsComponents/ChartTypes/BarChart.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";

import { infoPopupText } from "../../../helpers/misc";

export default {
  components: { BarChart, InfoPopup },
  props: {
    examination: {
      study_results: Array,
    },
  },
  mounted: function() {
    this.getAiData();
  },
  data() {
    return {
      aiData: {
        labels: null,
        datasets: null,
      },
      aiDataOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: "top",
          labels: {
            boxWidth: 10,
            padding: 20,
            usePointStyle: true,
          },
        },
        tooltips: {
          titleFontColor: "rgba(0, 0, 0, 1)",
          bodyFontColor: "rgba(0, 0, 0, 1)",
          backgroundColor: "rgba(245, 245, 245, 1)",
          xPadding: 10,
          yPadding: 10,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                max: 100,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    getAiData: function() {
      const { study_results } = this.examination;
      if (study_results.length === 0) return;
      const studyResult = study_results.find(res => {
        return res && "type" in res && res.type === "external_result";
      });
      if (studyResult) {
        const result = studyResult.results;
        let labels = Object.keys(result).map(key => result[key].label);

        let aiResults = Object.keys(result).map(
          key => +(result[key].value * 100).toFixed(2)
        );

        this.aiData = {
          labels,
          datasets: [
            {
              data: aiResults,
              label: this.trans("aiFeatures.aiResultsPresentationChartName"),
              backgroundColor: "#706d75",
            },
          ],
        };
      }
    },
    getInfoPopupText: function() {
      return infoPopupText(this.aiData.labels);
    },
  },
};
</script>
