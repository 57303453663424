<template>
  <div>
    <div class="panel panel-default">
      <div class="panel-heading panel-heading-underline">
        <h3 class="panel-title">
          {{ addResourcePanelTitle }}
        </h3>
      </div>
      <div class="panel-body add-resource-panel-body">
        <div v-if="loading" class="overlay"><clip-loader></clip-loader></div>
        <div class="row d-flex flex-wrap">
          <div class="col-md-6 mb-1">
            <v-select
              name="resources"
              :options="availableResources"
              :reduce="studyResource => studyResource.id"
              v-model="selectedResourceId"
              :label="resourceType === 'participants' ? 'full_name' : 'case_no'"
              :placeholder="trans('forms.generalSelectPlaceholder')"
            ></v-select>
          </div>

          <div class="col-md-6 text-right">
            <button
              class="btn btn-secondary mr-2 mb-1 col-md-4"
              @click="onCancel"
            >
              {{ trans("general.modalCancelButton") }}
            </button>
            <button
              class="btn btn-primary col-md-6 mb-1"
              @click="addResource"
              :disabled="!selectedResourceId"
            >
              {{ addResourceButtonText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addResourcePanelTitle: String,
    availableResources: Array,
    addResourceButtonText: String,
    loading: Boolean,
    resourceType: String,
  },
  data() {
    return {
      addingResource: false,
      selectedResourceId: null,
    };
  },
  methods: {
    addResource: function() {
      this.$emit("addResource", this.selectedResourceId);
    },
    onCancel: function() {
      this.selectedResourceId = null;
      this.addingResource = false;
      this.$emit("cancelAddingResource");
    },
    toggleAddingResource: function() {
      this.addingResource = !this.addingResource;
    },
  },
};
</script>

<style scoped>
.add-resource-panel-body {
  position: relative;
}
</style>
