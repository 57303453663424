<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("patient.patientViewBoxLabelAnamnes") }}
      </h3>
    </div>
    <div class="panel-body" v-if="examination">
      <div class="row">
        <div class="m-b-20 col-md-12">
          <strong>{{ trans("patient.patientViewBoxLabelAnamnes") }}</strong>

          <p class="text-muted">
            {{ trans("examination.medicalHistoryDiabetes") }}:

            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.medicalHistoryDiabetes"
              >
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="diabetes">{{
              trans("examination." + examination.case.medicalHistoryDiabetes)
            }}</span>

            <br />
            {{ trans("examination.medicalHistoryHeartLungVascDis") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.medicalHistoryHearLungVascDis"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="heartLungVascDis">{{
              trans(
                "examination." + examination.case.medicalHistoryHearLungVascDis
              )
            }}</span>
            <br />
            {{ trans("patient.patientViewDiagnosRheumaticDisease") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.rheumaticDisease"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="rheumaticDisease">{{
              trans("examination." + examination.case.rheumaticDisease)
            }}</span>
            <br />
            {{ trans("examination.medicalHistoryMultiResistantBacteria") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.medicalHistoryMultiResistantBacteria"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="multiResistantBacteria">{{
              trans(
                "examination." +
                  examination.case.medicalHistoryMultiResistantBacteria
              )
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-12">
          <strong>{{ trans("patient.patientVievMedical") }}</strong>
          <p class="text-muted">
            {{ trans("patient.patientVievMedicalBloodThinning") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.anticoagulants"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="anticoagulants">{{
              trans("examination." + examination.case.anticoagulants)
            }}</span>
            <br />
            {{ trans("patient.patientVievMedicalSteroidsPerOs") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.steroidsOrally"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="steroidsOrally">{{
              trans("examination." + examination.case.steroidsOrally)
            }}</span>
            <br />
            {{ trans("patient.patientVievMedicalChemotherapy") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.chemotherapy"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="chemotherapy">{{
              trans("examination." + examination.case.chemotherapy)
            }}</span>
          </p>
        </div>

        <div class="m-b-20 col-md-12">
          <strong>{{ trans("patient.patientViewBoxLabeOthers") }}</strong>
          <p class="text-muted">
            {{ trans("examination.medicalHistorySmoker") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.medicalHistorySmoker"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
                <option value="EX_SMOKER">{{
                  trans("examination.EX_SMOKER")
                }}</option>
              </select2>
            </span>
            <span v-else class="smoker">{{
              trans("examination." + examination.case.medicalHistorySmoker)
            }}</span>
            <br />
            {{ trans("patient.patientViewDiagnosMovement") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.mobility"
              >
                <option value="walksWithoutAid">{{
                  trans("examination.walksWithoutAid")
                }}</option>
                <option value="walksAid">{{
                  trans("examination.walksAid")
                }}</option>
                <option value="walksWithSupportingPersonnel">{{
                  trans("examination.walksWithSupportingPersonnel")
                }}</option>
                <option value="wheelChair">{{
                  trans("examination.wheelChair")
                }}</option>
                <option value="bedBound">{{
                  trans("examination.bedBound")
                }}</option>
              </select2>
            </span>
            <span v-else class="mobility">{{
              trans("examination." + examination.case.mobility)
            }}</span>
            <br />
            {{ trans("patient.patientViewFoodIntake") }}:

            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.foodIntake"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="normalPortion">{{
                  trans("examination.normalPortion")
                }}</option>
                <option value="treeThirdsNormalPortions">{{
                  trans("examination.treeThirdsNormalPortions")
                }}</option>
                <option value="halfPortion">{{
                  trans("examination.halfPortion")
                }}</option>
                <option value="lessThanHalfPortion">{{
                  trans("examination.lessThanHalfPortion")
                }}</option>
              </select2>
            </span>
            <span v-else class="foodIntake">{{
              trans("examination." + examination.case.foodIntake)
            }}</span>

            <br />
            {{ trans("patient.patientViewWeightLossTreeMonths") }}:
            <span v-if="isEditing">
              <select2
                class="select2 form-control responsive groups"
                v-model="examination.case.weightLossLastThreeMonths"
              >
                <option value="UNKNOWN">{{
                  trans("examination.unknown")
                }}</option>
                <option value="YES">{{ trans("examination.yes") }}</option>
                <option value="NO">{{ trans("examination.no") }}</option>
              </select2>
            </span>
            <span v-else class="weightLossLastThreeMonths">{{
              trans("examination." + examination.case.weightLossLastThreeMonths)
            }}</span>
          </p>
        </div>
      </div>
    </div>

    <div class="panel-body" v-else>
      {{ trans("general.emptyDataReuslt") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {
      case: {
        medicalHistoryDiabetes: String,
        medicalHistoryHearLungVascDis: String,
        rheumaticDisease: String,
        medicalHistoryMultiResistantBacteria: String,
        anticoagulants: String,
        steroidsOrally: String,
        chemotherapy: String,
        medicalHistorySmoker: String,
        mobility: String,
        foodIntake: String,
        weightLossLastThreeMonths: String,
      },
    },
    isEditing: Boolean,
  },
};
</script>
