<template>
  <div class="chat-row">
    <div v-if="comment">
      <div class="toolbar float-right" v-if="userCanEdit">
        <a href="#" @click.prevent="onEdit()">{{
          trans("examination.diagnosReplyEditButton")
        }}</a>
        <br />
      </div>

      <div class="textholder">
        <label>{{ label }}</label>
        <div>
          <v-popover :disabled="!displayUserContact" class="d-inline">
            <template v-slot:popover v-if="userContactInfo">
              <div>
                <b>{{ trans("users.personalInfoFullName") }}: </b
                >{{ userContactInfo.firstname }}
                {{ userContactInfo.lastname }}
              </div>
              <div>
                <b>{{ trans("users.emailLabel") }}: </b
                >{{ userContactInfo.email }}
              </div>
              <div>
                <b>{{ trans("users.contactNoLabel") }}: </b
                >{{ userContactInfo.phone_no }}
              </div>
            </template>

            <span
              :class="[
                displayUserContact ? 'popover-link' : '',
                'text-muted font-weight-bold',
              ]"
            >
              {{ comment.userName }}
            </span>
          </v-popover>
          <span class="font-weight-bold">({{ comment.created }})</span>
          <a
            class="toolbar font-weight-bold"
            data-toggle="modal"
            @click="getCommentHistory"
            :data-target="`#commentHistory-${comment.id}`"
            v-if="comment.editedCommentId > 0"
          >
            {{ trans("examination.modelCommentHasBeenEdited") }}
            {{ comment.updated }}</a
          >
        </div>
        <div class="comment-text pt-1">{{ comment.comment }}</div>
      </div>
    </div>

    <div
      class="modal fade comment-history-modal"
      :id="`commentHistory-${comment.id}`"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              {{ trans("examination.modelCommentHasBeenEditedlabel") }}
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="m-b-20 col-md-12">
                <div
                  class="comment-line"
                  v-for="(previousComment, index) in previousComments"
                  :key="index"
                >
                  <b
                    >{{ previousComment.userName }} ({{
                      previousComment.created
                    }})</b
                  >
                  <div class="text">{{ previousComment.comment }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    userContactInfo: {
      firstname: String,
      lastname: String,
      email: String,
      phone_no: String,
    },
    comment: {
      userName: String,
      created: String,
      comment: String,
    },
    previousComments: Array,
    userCanEdit: Boolean,
    displayUserContact: Boolean,
  },
  methods: {
    onEdit: function() {
      this.$emit("on-edit", this.comment);
    },
    getCommentHistory: function() {
      this.$emit("on-get-history", this.comment);
    },
  },
};
</script>

<style lang="scss" scoped>
.comment-text {
  word-break: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  user-select: text;
  cursor: text;
}
</style>
