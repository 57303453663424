<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p class="text-muted">{{ displayValue }}</p>
  </div>
</template>

<script>
import _isBoolean from "lodash/fp/isBoolean";

export default {
  props: {
    title: String,
    // Don't type as Boolean, because that will do an implicit conversion (e.g. of empty strings) !!!
    data: {
      default: null,
    },
  },
  computed: {
    displayValue() {
      if (_isBoolean(this.data)) {
        return this.data
          ? this.trans("examination.yes")
          : this.trans("examination.no");
      }
      return "-";
    },
  },
};
</script>

<style lang="scss" scoped>
#hash-para {
  display: inline-block;
}
</style>
