<template>
  <div class="col-md-12 col-lg-6">
    <div class="panel panel-default panel-fill panel-actions">
      <div class="panel-heading panel-heading-underline">
        <h3 class="panel-title">
          {{ trans("aiFeatures.aiDiagnosisHeader") }}
        </h3>
      </div>
      <div class="panel-body">
        <div class="my-3">
          <p>
            {{ trans("aiFeatures.aiDiagnosisFeatureRequirements") }}
          </p>
          <p>
            {{ trans("aiFeatures.aiDiagnosisFeatureDescription") }}
          </p>
        </div>

        <clip-loader v-show="loading"></clip-loader>

        <button
          v-if="!showDiagnosis || !aiDiagnosisAvailable"
          :class="[
            aiDiagnosisAvailable ? 'btn-primary' : 'btn-grey',
            'btn btn-block btn-lg mt-3',
          ]"
          :disabled="!aiDiagnosisAvailable"
          @click="getAiDiagnosis"
        >
          {{ trans("aiFeatures.showAiDiagnosisProbability") }}
        </button>

        <div v-if="showDiagnosis && aiDiagnosisAvailable">
          <strong
            >{{ trans("aiFeatures.aiDiagnosisProbabilityText") }}
            {{ trans(`aiFeatures.${aiDiagnosis.ai_diagnosis}`) }}
            {{ trans("aiFeatures.diagnosisProbabilityIs") }}
            <span>{{ aiDiagnosis.ai_probability }}%</span></strong
          >
          <div class="progress my-2">
            <div
              class="progress-bar"
              role="progressbar"
              :style="`width: ${aiDiagnosis.ai_probability}%;`"
              :aria-valuenow="aiDiagnosis.ai_probability"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ aiDiagnosis.ai_probability }}%
            </div>
          </div>

          <!-- DER-1341: decide if this info is relevant -->
          <!-- <div class="mt-3">
            <small
              ><i class="far fa-asterisk"></i>{{ $store.state.user.fullName }}
              {{ trans("aiFeatures.userChoseToSeeProbability") }} ({{
                aiDiagnosis.ai_diagnosis_date
              }}).</small
            >
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserErrorMessage from "../../../../mixins/UserErrorMessage.vue";
import ClipLoader from "../../../Utils/ClipLoader.vue";

export default {
  props: ["aiDiagnosisAvailable", "examinationId"],
  mixins: [UserErrorMessage],
  data: function() {
    return {
      loading: false,
      aiDiagnosis: null,
      showDiagnosis: false,
    };
  },
  methods: {
    getAiDiagnosis: function() {
      this.loading = true;

      axios
        .get(`/api/internal/ai/diagnosis/${this.examinationId}`)
        .then(data => {
          let collection = data.data;
          this.aiDiagnosis = collection;
          this.loading = false;
          this.showDiagnosis = true;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
          this.showDiagnosis = false;
        });
    },
  },
  components: {
    ClipLoader,
  },
};
</script>
<style lang="scss" scoped>
.btn:disabled {
  opacity: 1;
}

.progress {
  height: 20px;

  .progress-bar {
    background-color: #b92844;
    font-size: 11px;
  }
}
</style>
