<template>
  <nav class="examination-header">
    <ul class="nav nav-tabs tabs-bordered">
      <li class="nav-item" @click="onBackClick">
        <router-link :to="{ name: 'Evaluation' }" class="nav-link">
          <span class="visible-xs">
            <i class="fal fa-arrow-left"></i>
          </span>
          <span class="hidden-xs">{{ trans("general.back") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a
          href="#home"
          data-toggle="tab"
          class="examinationLink nav-link active"
          aria-expanded="false"
        >
          <span>
            {{ trans("examination.tabLabelConsultation") }}
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          href="#image-compare"
          data-toggle="tab"
          aria-expanded="false"
          class="imageCompare nav-link"
          @click="mountImageCompare"
        >
          <span>
            {{ trans("examination.tabLabelImageCompare") }}
            <span v-if="totalExaminations" class="consultationCount"
              >({{ totalExaminations }})</span
            >
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" @click="initializePrint">
          <span
            >{{ trans("general.actionPrintText") }} /
            <i class="fas fa-print"></i>
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: ["totalExaminations"],
  methods: {
    mountImageCompare: function() {
      this.$emit("mountImageCompare");
    },
    initializePrint: function() {
      this.$emit("initializePrint");
    },
    onBackClick: function() {
      this.cancelRequests();
    },
    cancelRequests: function() {
      this.$store.state.cancelTokens.forEach(token => {
        token.cancel();
      });
      this.$store.commit("resetCancelTokens");
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .tabs-bordered li a,
  .tabs-bordered li a:hover,
  .tabs-bordered li a:focus {
    padding: 1rem 0.5rem !important;
  }

  .nav-tabs .nav-item {
    margin-right: 0rem !important;
  }
}
</style>
