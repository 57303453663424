<template>
  <field-panel
    :title="title"
    :updatedDate="examination.diagnosis.diagnosis_updated_at"
    :infoPopupText="trans('tooltip.moleDiagnosisBox')"
  >
    <div class="panel-body" v-if="examination">
      <div v-if="loading" class="overlay">
        <clip-loader></clip-loader>
      </div>
      <div class="form-group">
        <label :for="diagnosisTentativeInputId">
          {{ trans("examination.diagnosisCodeDermatoskopiskLabel") }}
        </label>
        <der-select-with-children
          :id="diagnosisTentativeInputId"
          v-model="examination.diagnosis.dermatoscopic_diagnosis_id"
          :disabled="!$can('diagnose')"
          :data-options="diagnosisTentativeOptions"
          :data-get-option-value="option => option.id"
          :data-get-option-label="option => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          :data-sub-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_code_dermatoscopic')"
        />
      </div>
      <div class="form-group">
        <label :for="diagnosisStrategyInputId">
          {{ trans("examination.diagnosiskPlanedHeadline") }}
        </label>
        <der-select-with-children
          :id="diagnosisStrategyInputId"
          v-model="examination.diagnosis.planned_diagnosis_id"
          :disabled="!$can('diagnose')"
          :data-options="diagnosisStrategyOptions"
          :data-get-option-value="option => option.id"
          :data-get-option-label="option => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          :data-sub-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_code_planed')"
        />
      </div>
      <div class="form-group">
        <label :for="diagnosisPathologyInputId">
          {{ trans("examination.diagnosiscodeHistopatologiskLabel") }}
        </label>
        <v-select
          :inputId="diagnosisPathologyInputId"
          name="diagnosis_code_histopathological"
          :options="examination.diagnosis.diagnosis_code_histopathological_list"
          :reduce="diagnosis => diagnosis.id"
          v-model="examination.diagnosis.histopathological_diagnosis_id"
          label="text"
          @input="setDiagnosis($event, 'diagnosis_code_histopathological')"
          append-to-body
          :calculate-position="calculatePositionWithPopper"
          :placeholder="trans('forms.generalSelectPlaceholder')"
        ></v-select>
      </div>
      <div class="form-group">
        <label :for="diagnosisTagsInputId">
          {{ trans("examination.tagsLabel") }}
        </label>
        <v-select
          :inputId="diagnosisTagsInputId"
          multiple
          name="tags"
          :options="examination.diagnosis.tags_list"
          :reduce="tag => tag.id"
          :value="examination.diagnosis.tags_list.text"
          v-model="examination.diagnosis.tags"
          label="text"
          @input="setDiagnosis($event, 'tags')"
          append-to-body
          :calculate-position="calculatePositionWithPopper"
          :placeholder="trans('forms.generalSelectPlaceholder')"
        ></v-select>
      </div>
      <div class="form-group">
        <label :for="diagnosisConfidenceInputId">
          {{ trans("examination.diagnosisConfidenceLevelLabel") }}
        </label>
        <der-select
          :id="diagnosisConfidenceInputId"
          v-model="examination.diagnosis.confidence_level_id"
          :disabled="!$can('diagnose')"
          :data-options="$data.diagnosisConfidenceOptions"
          :data-get-option-value="option => option.id"
          :data-get-option-label="option => option.text"
          :data-placeholder="trans('forms.generalSelectPlaceholder')"
          @input="setDiagnosis($event, 'diagnosis_confidence_level_id ')"
        />
      </div>
      <div class="form-group">
        <label :for="diagnosisImageQualityInputId">
          {{ trans("examination.diagnosisImageQualityLabel") }}
        </label>
        <der-slider-image-quality
          :id="diagnosisImageQualityInputId"
          v-model="examination.diagnosis.image_quality_id"
          :disabled="!$can('diagnose')"
          @input="setDiagnosis($event, 'diagnosis_image_quality_id')"
        />
      </div>
    </div>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import UserErrorMessage from "../../../../mixins/UserErrorMessage.vue";
import Notifications from "../../../Utils/notifications";
import { calculatePositionWithPopper } from "../../../../helpers/popper";

import { URLS } from "../../../../config";
import { v4 as uuidV4 } from "uuid";
import DerSelectWithChildren from "../../../Utils/DerSelectWithChildren.vue";
import DerSelect from "../../../Utils/DerSelect.vue";
import DerSliderImageQuality from "../../../Utils/DerSliderImageQuality.vue";

export default {
  components: {
    DerSliderImageQuality,
    DerSelect,
    DerSelectWithChildren,
    FieldPanel,
  },
  mixins: [UserErrorMessage],
  props: {
    fields: Array,
    examination: Object,
  },
  data: function() {
    return {
      diagnosisTentativeInputId: uuidV4(),
      diagnosisStrategyInputId: uuidV4(),
      diagnosisPathologyInputId: uuidV4(),
      diagnosisTagsInputId: uuidV4(),
      diagnosisConfidenceInputId: uuidV4(),
      diagnosisConfidenceOptions: [
        { id: 1, text: this.trans("examination.confidenceVeryCertain") },
        { id: 2, text: this.trans("examination.confidenceCertain") },
        { id: 3, text: this.trans("examination.confidenceUncertain") },
      ],
      diagnosisImageQualityInputId: uuidV4(),
      title: "examination.diagnosiskHeadline",
      loading: false,
    };
  },
  computed: {
    diagnosisTentativeOptions() {
      return this.examination.diagnosis.diagnosis_code_dermatoscopic_list;
    },
    diagnosisStrategyOptions() {
      return this.examination.diagnosis.planned_to_action_list;
    },
  },
  methods: {
    setDiagnosis: function(diagnosisCode, diagnosisType) {
      let formData = {
        key: diagnosisType,
        resources: diagnosisCode,
      };
      this.loading = true;

      axios
        .post(
          "/api/internal/moles/" + this.examination.id + "/save-diagnostic",
          formData
        )
        .then(() => {
          this.loading = false;
          Notifications.saveSuccess();
          this.updateExamination();
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    updateExamination: function() {
      axios.get(`${URLS.TUMOUR_WOUND}/${this.$route.params.id}`).then(data => {
        let collection = data.data.examination;

        this.examination.diagnosis.diagnosis_updated_at =
          collection.diagnosis.diagnosis_updated_at;
      });
    },
    calculatePositionWithPopper,
  },
};
</script>
