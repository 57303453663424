<template>
  <div>
    <label>{{ title }}</label>
    <br />
    <datepicker
      :class="{ disabled: disabled }"
      :format="customDateFormatter"
      name="date"
      :wrapper-class="'form-control'"
      :value="formValue && formValue.value && formValue.value"
      :disabled="disabled"
      @input="onInput"
      :clear-button="true"
      :clear-button-icon="'fa fa-times'"
      :calendar-button="true"
      :calendar-button-icon="'fas fa-calendar-alt'"
      :disabled-dates="{
        from: new Date(),
      }"
    ></datepicker>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { shortDateFormat } from "../../../../../helpers/platform";

export default {
  props: {
    title: String,
    data: String | Number,
    disabled: Boolean,
    formValue: [Array, Object, String],
    extraOptions: {
      postUrl: String,
      key: String,
      onValueChange: Function,
    },
  },
  methods: {
    customDateFormatter: function(date) {
      return moment(date).format(shortDateFormat());
    },
    onInput: function(event) {
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event ? new Date(event) : event,
      });
    },
  },
  components: {
    Datepicker,
  },
};
</script>

<style lang="scss">
.vdp-datepicker.disabled {
  background-color: #eee;
  border-color: #e3e3e3;
}

.vdp-datepicker__calendar {
  max-width: 100% !important;
}
</style>
