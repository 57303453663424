import { AppModule } from "../routing/app-module";
import { ROUTES } from "../routing/routes";
import ResearchRoute from "./ResearchRoute.vue";
import ResearchCaseRoute from "./ResearchCaseRoute.vue";
import { redirectByAvailableStudies } from "./redirect-by-available-studies";

export const research: AppModule = {
  routes: [
    {
      ...ROUTES.research,
      beforeEnter: redirectByAvailableStudies,
      component: ResearchRoute,
      meta: {
        requiresAuth: true,
        researchParticipantAuth: true,
      },
    },
    {
      ...ROUTES.researchResearchCase,
      component: ResearchCaseRoute,
      meta: {
        requiresAuth: true,
        researchParticipantAuth: true,
      },
    },
  ],
};
