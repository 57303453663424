<template>
  <div>
    <header-admin :active-section="3"></header-admin>

    <clip-loader v-show="loading"></clip-loader>

    <h3 class="my-3">{{ collection.currentYear.year }}</h3>
    <table class="table table-bordered my-2 table-actions-bar table-hover">
      <thead>
        <tr>
          <th>{{ trans("center.name") }}</th>
          <th>{{ trans("center.deletedAt.label") }}</th>
          <th v-for="month in months">{{ month }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(center, index) in collection.currentYear.data" :key="index">
          <td>{{ center.centerName }}</td>
          <td>{{ center.deletedAt }}</td>
          <td>
            <b v-if="center.jan">{{ formatCounters(center.jan) }}</b>
          </td>
          <td>
            <b v-if="center.feb">{{ formatCounters(center.feb) }}</b>
          </td>
          <td>
            <b v-if="center.mar">{{ formatCounters(center.mar) }}</b>
          </td>
          <td>
            <b v-if="center.apr">{{ formatCounters(center.apr) }}</b>
          </td>
          <td>
            <b v-if="center.may">{{ formatCounters(center.may) }}</b>
          </td>
          <td>
            <b v-if="center.jun">{{ formatCounters(center.jun) }}</b>
          </td>
          <td>
            <b v-if="center.jul">{{ formatCounters(center.jul) }}</b>
          </td>
          <td>
            <b v-if="center.aug">{{ formatCounters(center.aug) }}</b>
          </td>
          <td>
            <b v-if="center.sep">{{ formatCounters(center.sep) }}</b>
          </td>
          <td>
            <b v-if="center.oct">{{ formatCounters(center.oct) }}</b>
          </td>
          <td>
            <b v-if="center.nov">{{ formatCounters(center.nov) }}</b>
          </td>
          <td>
            <b v-if="center.dec">{{ formatCounters(center.dec) }}</b>
          </td>
        </tr>
      </tbody>
    </table>

    <h3 class="my-3">{{ collection.lastYear.year }}</h3>
    <table class="table table-bordered mt-2 mb-5 table-actions-bar table-hover">
      <thead>
        <tr>
          <th>{{ trans("center.name") }}</th>
          <th>{{ trans("center.deletedAt.label") }}</th>
          <th v-for="month in months">{{ month }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(center, index) in collection.lastYear.data" :key="index">
          <td>{{ center.centerName }}</td>
          <td>{{ center.deletedAt }}</td>
          <td>
            <b v-if="center.jan">{{ formatCounters(center.jan) }}</b>
          </td>
          <td>
            <b v-if="center.feb">{{ formatCounters(center.feb) }}</b>
          </td>
          <td>
            <b v-if="center.mar">{{ formatCounters(center.mar) }}</b>
          </td>
          <td>
            <b v-if="center.apr">{{ formatCounters(center.apr) }}</b>
          </td>
          <td>
            <b v-if="center.may">{{ formatCounters(center.may) }}</b>
          </td>
          <td>
            <b v-if="center.jun">{{ formatCounters(center.jun) }}</b>
          </td>
          <td>
            <b v-if="center.jul">{{ formatCounters(center.jul) }}</b>
          </td>
          <td>
            <b v-if="center.aug">{{ formatCounters(center.aug) }}</b>
          </td>
          <td>
            <b v-if="center.sep">{{ formatCounters(center.sep) }}</b>
          </td>
          <td>
            <b v-if="center.oct">{{ formatCounters(center.oct) }}</b>
          </td>
          <td>
            <b v-if="center.nov">{{ formatCounters(center.nov) }}</b>
          </td>
          <td>
            <b v-if="center.dec">{{ formatCounters(center.dec) }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import HeaderAdmin from "./Header.vue";

export default {
  created: function() {
    this.loading = true;
    axios.get("/api/internal/admin/analytics").then(data => {
      this.collection = data.data;
      this.loading = false;
    });
  },
  data() {
    return {
      loading: true,
      collection: {
        lastYear: {
          year: "",
          data: {},
        },
        currentYear: {
          year: "",
          data: {},
        },
      },
      months: moment
        .localeData(localStorage.getItem("lang_code", "en"))
        .monthsShort(),
    };
  },
  methods: {
    formatCounters(month) {
      return month
        ? `${month.EXAMINATIONS ?? ""} (${month.PATIENTS ?? ""})`
        : "";
    },
  },
  components: {
    HeaderAdmin,
  },
};
</script>
