const BASE = "/api/internal";
const EXAMINATIONS = `${BASE}/examinations`;
const TUMOUR_WOUND = `${BASE}/tumour-wound`;
const PRODUCTS = `${BASE}/products`;
const TUMOUR_WOUND_DIAGNOS_ACTION = `${TUMOUR_WOUND}/save-diagnostic`;
const TUMOUR_WOUND_SAVE_ACTION = `${TUMOUR_WOUND}/actions`;
const TUMOUR_WOUND_SAVE_EXAMINATION = TUMOUR_WOUND;
const TUMOUR_WOUND_CLOSE_CASE = `${TUMOUR_WOUND}/close`;
const TUMOUR_WOUND_SUBMIT_UCR = `${TUMOUR_WOUND}/submit-ucr`;
const CENTER_ADMIN_URL = `${BASE}/center-admin`;
const REGION_ADMIN_URL = `${BASE}/region-admin`;
const REGISTER_NEW_USER = `${BASE}/register`;
const FETCH_NEW_USER = `${BASE}/validateRegistration`;
const ADMIN_ALL_CENTERS = `${BASE}/admin/all-centers`;
const ADMIN_TENTATIVE_USERS = `${BASE}/admin/tentative/users`;
const ADMIN_TENTATIVE_USERS_ACTIVATE = `${ADMIN_TENTATIVE_USERS}/activate`;
const PATIENT_DELETE_DOCUMENTATION_NOTIFICATION = `${TUMOUR_WOUND}/notification`;
const STUDY_RESULT = `${BASE}/study-participant-result`;
const STUDY_ADMIN = `${BASE}/study-admin`;
const STUDY_ADMIN_USERS = `${STUDY_ADMIN}/users`;

const URLS = {
  BASE,
  TUMOUR_WOUND,
  EXAMINATIONS,
  PRODUCTS,
  TUMOUR_WOUND_DIAGNOS_ACTION,
  TUMOUR_WOUND_SAVE_ACTION,
  TUMOUR_WOUND_SAVE_EXAMINATION,
  TUMOUR_WOUND_CLOSE_CASE,
  CENTER_ADMIN_URL,
  REGION_ADMIN_URL,
  REGISTER_NEW_USER,
  FETCH_NEW_USER,
  ADMIN_ALL_CENTERS,
  ADMIN_TENTATIVE_USERS,
  ADMIN_TENTATIVE_USERS_ACTIVATE,
  PATIENT_DELETE_DOCUMENTATION_NOTIFICATION,
  STUDY_RESULT,
  STUDY_ADMIN,
  STUDY_ADMIN_USERS,
  TUMOUR_WOUND_SUBMIT_UCR,
};

export default URLS;
