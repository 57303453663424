<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("examination.imagesHeadline") }}
        <info-popup
          v-if="!product.toLowerCase().includes('photo')"
          :text-content="getInfoPopupText()"
        ></info-popup>
      </h3>
    </div>
    <div class="panel-body">
      <image-gallery
        :images="images"
        :data-image-metadata="dataImageMetadata"
        :productType="productType"
        :caseId="caseId"
        :useColumn="false"
        :isLoading="false"
      >
        <div
          class="row"
          v-if="
            $can('test_ai') &&
              product === 'mole' &&
              $store.state.dermicusPlatform === 'sweden'
          "
        >
          <div class="collapse" id="imagesWithAiFiltersCollapse">
            <ai-filter-images
              v-if="imagesWithAiFilters && imagesWithAiFilters.length !== 0"
              :imagesWithAiFilters="imagesWithAiFilters"
              @update:imagesWithAiFilters="imagesWithAiFilters = $event"
            ></ai-filter-images>
          </div>
          <clip-loader
            v-if="imagesWithAiFiltersLoading"
            class="mt-5"
          ></clip-loader>
          <button
            class="btn btn-grey btn-lg btn-block mt-3"
            type="button"
            data-toggle="collapse"
            data-target="#imagesWithAiFiltersCollapse"
            aria-expanded="false"
            aria-controls="collapseExample"
            @click="toggleShowImgWithAiFilters"
          >
            {{
              showImgWithAiFilters
                ? trans("examination.hideAiFilterImages")
                : trans("examination.showAiFilterImages")
            }}
            <i
              :class="[
                showImgWithAiFilters ? 'fa-angle-up' : 'fa-angle-down',
                'far fa-lg',
              ]"
            ></i>
          </button>
        </div>
      </image-gallery>
    </div>
  </div>
</template>

<script>
import AiFilterImages from "./AiFilterImages.vue";
import ImageGallery from "./ImageGallery.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";

import { PRODUCTS } from "../../../helpers/definitions";
import { getProductType } from "../../../helpers/product";
import { infoPopupText } from "../../../helpers/misc";

export default {
  props: {
    images: {},
    dataImageMetadata: Object,
    product: String,
    imagesWithAiFilters: Array,
    imagesWithAiFiltersLoading: Boolean,
    caseId: Number,
  },
  mounted: function() {
    this.productType = `${getProductType(this.product)}`;
  },
  data: function() {
    return {
      showImgWithAiFilters: false,
      PRODUCTS: PRODUCTS,
      isLoadingAi: false,
      productType: undefined,
    };
  },
  methods: {
    toggleShowImgWithAiFilters: function() {
      this.showImgWithAiFilters = !this.showImgWithAiFilters;
      if (this.imagesWithAiFilters.length === 0) this.$emit("getAiFilters");
    },
    getInfoPopupText: function() {
      return infoPopupText(this.images);
    },
  },
  components: {
    AiFilterImages,
    ImageGallery,
    InfoPopup,
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  min-height: 200px;
}
</style>
