<template>
  <div id="chat-sidebar">
    <div class="chat-sidebar-container">
      <div v-if="showAllChannels" class="channels-wrapper">
        <div class="consensus-header">{{ trans("chat.chat") }}</div>
        <div class="channels-container" v-if="!loading">
          <chat-channels
            :list="sortedChannelsList"
            @activatePredefinedGroup="activatePredefinedGroup"
            @openConversation="openConversation"
          ></chat-channels>
        </div>

        <clip-loader v-if="loading"></clip-loader>

        <div class="mt-3">
          <button
            class="btn btn-primary btn-block"
            @click="openAddPrivateMessageWindow"
          >
            <i class="far fa-plus"></i>&nbsp;
            <span>{{ trans("chat.newChatChannel") }}</span>
          </button>
        </div>
      </div>

      <new-private-message-window
        v-if="showAddPrivateMessageWindow"
        @backToAllChannels="backToAllChannels"
        :editSaveUri="editSaveUri"
        @newChannelCreated="newChannelCreated"
      ></new-private-message-window>

      <chat-conversation-window
        v-if="showChatConversationWindow"
        @backToAllChannels="backToAllChannels"
        :editSaveUri="editSaveUri"
        :channelId="channelId"
        @updateTopNavigation="updateTopNavigation"
      ></chat-conversation-window>
    </div>
  </div>
</template>

<script>
import NewPrivateMessageWindow from "./NewPrivateMessageWindow.vue";
import ChatChannels from "./ChatChannels.vue";
import ChatConversationWindow from "./ChatConversationWindow.vue";
import ExternalEesource from "./Utils/ExternalResource";

export default {
  components: {
    NewPrivateMessageWindow,
    ChatChannels,
    ChatConversationWindow,
  },
  props: { chatIsOpen: Boolean, editSaveUri: String },
  mounted: function() {
    // decide if this is the right way to not load chat, when id in base url is undefined. Error in patient chat,caused by keep-alive(?)
    if (this.$route.params.id) {
      this.getChannels();
    }
  },
  data() {
    return {
      loading: false,
      showAllChannels: true,
      showAddPrivateMessageWindow: false,
      showChatConversationWindow: false,
      sortedChannelsList: null,
      channelId: null,
    };
  },
  methods: {
    openAddPrivateMessageWindow: function() {
      this.$emit("openAddPrivateMessageWindow");
      this.showAllChannels = false;
      this.showChatConversationWindow = false;

      this.showAddPrivateMessageWindow = true;
    },
    backToAllChannels: function() {
      this.showAllChannels = true;
      this.showAddPrivateMessageWindow = false;
      this.showChatConversationWindow = false;

      this.getChannels();
    },
    getChannels: function() {
      this.loading = true;

      axios
        .get(this.editSaveUri)
        .then(response => {
          const collection = response.data.channels;
          let inactivePredefinedGroups = response.data.groups;

          // if there are no other active groups then general is on the bottom
          if (collection && collection.length === 1) {
            inactivePredefinedGroups.push(collection[0]);
            this.sortedChannelsList = inactivePredefinedGroups;
          } else {
            // if there are other active groups, sort by last_change_at

            const allChannels = collection.concat(inactivePredefinedGroups);
            const general = allChannels.find(
              channel => channel.type === "public"
            );
            const allChannelsWithoutPublic = allChannels.filter(channel => {
              return channel.type !== "public";
            });

            // if general has last_change_at then it gets sorted with all channels
            if (general.last_change_at) {
              this.sortedChannelsList = this.sortChannels(allChannels);
            } else {
              // general on the bottom, if there is no last_change_at on general
              let sortedChannelsWithoutPublic = this.sortChannels(
                allChannelsWithoutPublic
              );

              //  add general to the bottom
              sortedChannelsWithoutPublic.push(general);

              this.sortedChannelsList = sortedChannelsWithoutPublic;
            }
          }

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    sortChannels: function(channelsList) {
      return channelsList.sort(function(a, b) {
        // fix sorting later in backend?
        // last_change_at = messages
        // updated_at = created date OR change channel name

        let aTimestamp = a.last_change_at || a.updated_at;
        let bTimestamp = b.last_change_at || b.updated_at;

        return new Date(bTimestamp) - new Date(aTimestamp);
      });
    },
    openConversation: function(channelId) {
      this.channelId = channelId;

      this.showChatConversationWindow = true;
      this.showAllChannels = false;
      this.showAddPrivateMessageWindow = false;
    },
    newChannelCreated: function(newChannelId) {
      this.openConversation(newChannelId);
    },
    activatePredefinedGroup: function(group) {
      if (
        ExternalEesource.isExternalResource(group.participants)
          .isExternalResources
      ) {
        this.$dialog
          .confirm(
            {
              title: this.trans(
                "chat.startConversationWithExternalResourceHeader"
              ),
              body: `${
                ExternalEesource.isExternalResource(group.participants)
                  .externalResourcesList
              } ${this.trans(
                "chat.startConversationWithExternalResourceBody"
              )}`,
            },
            {
              okText: this.trans("general.modalConfirmButton"),
              cancelText: this.trans("general.modalCancelButton"),
            }
          )
          .then(() => {
            this.activateGroup(group.id);
          })
          .catch(() => {});
      } else {
        this.activateGroup(group.id);
      }
    },
    activateGroup: function(groupId) {
      this.loading = true;
      axios
        .post(`${this.editSaveUri}/from-group/${groupId}`)
        .then(response => {
          this.loading = false;
          this.openConversation(response.data.channel.id);
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    updateTopNavigation: function() {
      this.$emit("updateTopNavigation");
    },
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.5em;
  font-weight: bold;
  font-size: 12px;
  color: #676a6c;
}
</style>
