<template>
  <UseStoreResource :dataResource="centerCanRequestStudyConsentResource">
    <AdminCenterConsentStudiesPanel
      :hasConsent="hasConsent"
      :hasOptimisticConsent="hasOptimisticConsent"
      @toggleConsent="handleConsentToggle"
    >
      <AdminCenterConnectedStudies :centerId="centerId" />
    </AdminCenterConsentStudiesPanel>
  </UseStoreResource>
</template>

<script setup lang="ts">
import {
  useCenterFeature,
  useCenterFeatureDisable,
  useCenterFeatureEnable,
} from "../../../../store/api/use-center-feature-resource";
import { CenterFeatureName } from "../../../../../../../app/Dermicus/Centers/CenterFeatureName";
import Vue, { computed } from "vue";
import UseStoreResource from "../../../../design-system/UseStoreResource.vue";
import AdminCenterConnectedStudies from "./AdminCenterConnectedStudies.vue";
import AdminCenterConsentStudiesPanel from "./AdminCenterConsentStudiesPanel.vue";
import { lang } from "../../../../i18n";

const props = defineProps<{
  centerId: number;
}>();

const centerCanRequestStudyConsentResource = useCenterFeature(
  props.centerId,
  CenterFeatureName.REQUEST_STUDY_CONSENT
);

const hasOptimisticConsent = computed(() => {
  return centerCanRequestStudyConsentResource.data.value?.active;
});

const hasConsent = computed(() => {
  return (
    centerCanRequestStudyConsentResource.data.value?.active &&
    !centerCanRequestStudyConsentResource.data.value?.optimistic
  );
});

const enableCenterFeature = useCenterFeatureEnable();
const disableCenterFeature = useCenterFeatureDisable();

function handleConsentToggle(value: boolean) {
  if (value === true) {
    enableCenterFeature.mutate({
      centerId: props.centerId,
      featureName: CenterFeatureName.REQUEST_STUDY_CONSENT,
    });
  } else {
    // @ts-ignore
    Vue.dialog
      .confirm(lang.get("admin.disableRequestStudyConsentConfirmation"), {
        okText: lang.get("general.modalConfirmButton"),
        cancelText: lang.get("general.modalCancelButton"),
      })
      .then(() => {
        disableCenterFeature.mutate({
          centerId: props.centerId,
          featureName: CenterFeatureName.REQUEST_STUDY_CONSENT,
        });
      });
  }
}
</script>
