<template>
  <div class="col-lg-12">
    <clip-loader v-show="loading"></clip-loader>
    <button class="btn btn-grey my-3" @click="back()">
      {{ trans("general.back") }}
    </button>

    <div class="panel panel-default panel-fill" v-if="!loading">
      <select id="cases" class="select2"></select>
    </div>

    <button class="btn btn-primary float-right" @click="submitCases()">
      {{ trans("general.formSaveLabel") }}
    </button>
  </div>
</template>

<script>
import { practitionerAuthToken } from "../../../app/practitioner-portal/auth/practitioner-auth-token";

export default {
  mounted: function() {
    this.loadSelect2();
  },
  data() {
    return {
      loading: false,
      examinations: {},
    };
  },
  methods: {
    submitCases: function() {
      this.loading = true;
      axios
        .post("/api/internal/admin/educations/new", {
          cases: $(".select2").val(),
        })
        .then(response => {
          this.loading = false;
          this.loadSelect2();

          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    back: function() {
      this.$router.push({ name: "AdminEducations" });
    },
    loadSelect2() {
      this.$nextTick(() => {
        window.$("#cases").select2({
          ajax: {
            url: "/api/internal/admin/educations/search",
            data: function(params) {
              return {
                search: params.term,
                page: params.page || 1,
              };
            },
            headers: {
              Authorization: "Bearer " + practitionerAuthToken.get(),
            },
            dataType: "json",
            processResults: function(data) {
              data.page = data.page || 1;
              return {
                results: data.items.map(function(item) {
                  return {
                    id: item.id,
                    text: item.name,
                  };
                }),
                pagination: {
                  more: data.pagination,
                },
              };
            },
            cache: true,
            delay: 250,
          },
          placeholder: "Case no",
          minimumInputLength: 1,
          multiple: true,
        });
      });
    },
  },
};
</script>
