import ExaminationImages from "./ExaminationComponents/ExaminationImages.vue";
import ExaminationData from "./ExaminationComponents/ExaminationData.vue";
import ExaminationDiagnosis from "./ExaminationComponents/ExaminationDiagnosis.vue";
import ExaminationGeneralInfo from "./ExaminationComponents/ExaminationGeneralInfo.vue";
import ExaminationMedicalHistory from "./ExaminationComponents/ExaminationMedicalHistory.vue";
import ExaminationStudyDiagnosisForm from "./ExaminationComponents/ExaminationStudyDiagnosisForm.vue";
import TextWithLabel from "./ExaminationComponents/components/TextWithLabel.vue";
import WoundMeasurementTextWithLabel from "./ExaminationComponents/components/WoundMeasurementTextWithLabel.vue";
import MultiCheckbox from "./ExaminationComponents/components/MultiCheckbox.vue";
import MultipleText from "./ExaminationComponents/components/MultipleText.vue";
import YesNo from "./ExaminationComponents/components/YesNo.vue";
import FieldWithHtmlContent from "./ExaminationComponents/components/FieldWithHtmlContent.vue";
import CustomImage from "./ExaminationComponents/components/Image.vue";
import CaseStatus from "./ExaminationComponents/components/CaseStatus.vue";
import PatientExaminationImages from "./ExaminationComponents/PatientExaminationImages.vue";
import SingleSelectionInput from "./ExaminationComponents/components/SingleSelectionInput.vue";
import MultipleSelectionInput from "./ExaminationComponents/components/MultipleSelectionInput.vue";
import PatientIdLink from "./ExaminationComponents/components/PatientIdLink.vue";
import ExaminationDocumentationGroup from "./ExaminationComponents/ExaminationDocumentationGroup.vue";
import DocumentationWrapper from "./ExaminationComponents/components/DocumentationWrapper.vue";
import FieldHeader from "./ExaminationComponents/components/FieldHeader.vue";
import DateField from "./ExaminationComponents/components/DateField.vue";
import ExaminationAiDiagnosis from "./ExaminationComponents/ExaminationAiDiagnosis.vue";
import MoleExaminationDiagnosis from "./ExaminationComponents/MoleExaminationDiagnosis.vue";
import MoleUkExaminationDiagnosis from "./ExaminationComponents/MoleUkExaminationDiagnosis.vue";
import DividerField from "./ExaminationComponents/components/DividerField.vue";
import BmiCalculator from "./ExaminationComponents/components/BmiCalculator.vue";
import AreaCalculator from "./ExaminationComponents/components/AreaCalculator.vue";
import DiagnosisSingleSelectionPicker from "./ExaminationComponents/components/DiagnosisSingleSelectionPicker.vue";
import FieldButton from "./ExaminationComponents/components/FieldButton.vue";
import AiResultsPanel from "../../Research/Utils/AiResultsPresentationPanel.vue";
import AiMcdResultsPanel from "../../Research/Utils/AiMcdResultsPresentationPanel.vue";
import RangeSlider from "./ExaminationComponents/components/RangeSlider.vue";
import FormTextfield from "./ExaminationComponents/components/FormTextfield.vue";
import Checkbox from "./ExaminationComponents/components/Checkbox.vue";
import DerRangeSlider from "./ExaminationComponents/components/DerRangeSlider.vue";
import DerChangeDiagnosis from "./ExaminationComponents/components/DerChangeDiagnosis.vue";
import FormNumberInput from "./ExaminationComponents/components/FormNumberInput.vue";

export const FIELD_CONSTANTS = {
  FIELD_TYPE_IMAGES: "FIELD_TYPE_IMAGES",
  FIELD_TYPE_SINGLE_SELECTION_PICKER: "FIELD_TYPE_SINGLE_SELECTION_PICKER",
  FIELD_TYPE_SINGLE_SELECTION_PICKER_POP_UP:
    "FIELD_TYPE_SINGLE_SELECTION_PICKER_POP_UP",
  FIELD_TYPE_MULTIPLE_SELECTION: "FIELD_TYPE_MULTIPLE_SELECTION",
  FIELD_TYPE_BOOLEAN: "FIELD_TYPE_BOOLEAN",
  FIELD_TYPE_SLIDER_NRS: "FIELD_TYPE_SLIDER_NRS",
  FIELD_TYPE_FREE_TEXT: "FIELD_TYPE_FREE_TEXT",
  FIELD_GROUP_TYPE_IMAGES: "FIELD_GROUP_TYPE_IMAGES",
  FIELD_GROUP_TYPE_PATIENT: "FIELD_GROUP_TYPE_PATIENT",
  FIELD_GROUP_TYPE_CASE_DATA: "FIELD_GROUP_TYPE_CASE_DATA",
  FIELD_GROUP_TYPE_GENERAL_INFO: "FIELD_GROUP_TYPE_GENERAL_INFO",
  FIELD_GROUP_TYPE_FORM: "FIELD_GROUP_TYPE_FORM",
  FIELD_TYPE_TEXT_WITH_LABEL: "FIELD_TYPE_TEXT_WITH_LABEL",
  FIELD_TYPE_WOUND_MEASUREMENT_TEXT_WITH_LABEL:
    "FIELD_TYPE_WOUND_MEASUREMENT_TEXT_WITH_LABEL",
  FIELD_TYPE_MULTIPLE_VALUES: "FIELD_TYPE_MULTIPLE_VALUES",
  FIELD_TYPE_CHECKBOX: "FIELD_TYPE_CHECKBOX",
  FIELD_TYPE_PATIENT_ID: "FIELD_TYPE_PATIENT_ID",
  FIELD_TYPE_CASE_STATUS: "FIELD_TYPE_CASE_STATUS",
  FIELD_TYPE_CONDITIONAL_TEXT_WITH_LABEL:
    "FIELD_TYPE_CONDITIONAL_TEXT_WITH_LABEL",
  FIELD_TYPE_TEXT_WITH_HTML_CONTENT: "FIELD_TYPE_TEXT_WITH_HTML_CONTENT",
  FIELD_GROUP_TYPE_PATIENT_IMAGES: "FIELD_GROUP_TYPE_PATIENT_IMAGES",
  FIELD_TYPE_FORM_SINGLE_SELECTION: "FIELD_TYPE_FORM_SINGLE_SELECTION",
  FIELD_TYPE_FORM_MULTIPLE_SELECTION: "FIELD_TYPE_FORM_MULTIPLE_SELECTION",
  FIELD_TYPE_FORM_RANGE_SLIDER: "FIELD_TYPE_FORM_RANGE_SLIDER",
  FIELD_TYPE_FORM_DER_RANGE_SLIDER: "FIELD_TYPE_FORM_DER_RANGE_SLIDER",
  FIELD_TYPE_FORM_DER_CHANGE_DIAGNOSIS: "FIELD_TYPE_FORM_DER_CHANGE_DIAGNOSIS",
  FIELD_TYPE_FORM_FREE_TEXT: "FIELD_TYPE_FORM_FREE_TEXT",
  FIELD_GROUP_TYPE_DOCUMENTATION: "FIELD_GROUP_TYPE_DOCUMENTATION",
  FIELD_TYPE_DOCUMENTATION: "FIELD_TYPE_DOCUMENTATION",
  FIELD_GROUP_TYPE_MEDICAL_HISTORY: "FIELD_GROUP_TYPE_MEDICAL_HISTORY",
  FIELD_TYPE_HEADER: "FIELD_TYPE_HEADER",
  FIELD_TYPE_DATE_SELECTION: "FIELD_TYPE_DATE_SELECTION",
  FIELD_GROUP_TYPE_AI_DIAGNOSIS: "FIELD_GROUP_TYPE_AI_DIAGNOSIS",
  FIELD_GROUP_TYPE_DIAGNOSIS: "FIELD_GROUP_TYPE_DIAGNOSIS",
  FIELD_GROUP_TYPE_UK_DIAGNOSIS: "FIELD_GROUP_TYPE_UK_DIAGNOSIS",
  FIELD_TYPE_DIVIDER: "FIELD_TYPE_DIVIDER",
  FIELD_TYPE_BMI_CALCULATOR: "FIELD_TYPE_BMI_CALCULATOR",
  FIELD_TYPE_AREA_CALCULATOR: "FIELD_TYPE_AREA_CALCULATOR",
  FIELD_GROUP_STUDY_TYPE_FORM: "FIELD_GROUP_STUDY_TYPE_FORM",
  FIELD_TYPE_DIAGNOSIS_SINGLE_SELECTION_PICKER:
    "FIELD_TYPE_DIAGNOSIS_SINGLE_SELECTION_PICKER",
  FIELD_TYPE_SAVE_DIAGNOSIS_BUTTON: "FIELD_TYPE_SAVE_DIAGNOSIS_BUTTON",
  FIELD_GROUP_STUDY_AI_RESULTS: "FIELD_GROUP_STUDY_AI_RESULTS",
  FIELD_GROUP_MCD_STUDY_AI_RESULTS: "FIELD_GROUP_MCD_STUDY_AI_RESULTS",
  FIELD_TYPE_FORM_CHECKBOX: "FIELD_TYPE_FORM_CHECKBOX",
  FIELD_TYPE_NUMBER_INPUT: "FIELD_TYPE_NUMBER_INPUT",
  FIELD_TYPE_FORM_NUMBER_INPUT: "FIELD_TYPE_FORM_NUMBER_INPUT",
  FIELD_TYPE_FORM_TEXT_INPUT: "FIELD_TYPE_FORM_TEXT_INPUT",
};

export const editableFieldMap = {
  [FIELD_CONSTANTS.FIELD_TYPE_SINGLE_SELECTION_PICKER]:
    FIELD_CONSTANTS.FIELD_TYPE_FORM_SINGLE_SELECTION,
  [FIELD_CONSTANTS.FIELD_TYPE_SINGLE_SELECTION_PICKER_POP_UP]:
    FIELD_CONSTANTS.FIELD_TYPE_FORM_SINGLE_SELECTION,
  [FIELD_CONSTANTS.FIELD_TYPE_MULTIPLE_SELECTION]:
    FIELD_CONSTANTS.FIELD_TYPE_FORM_MULTIPLE_SELECTION,
  [FIELD_CONSTANTS.FIELD_TYPE_SLIDER_NRS]:
    FIELD_CONSTANTS.FIELD_TYPE_FORM_SINGLE_SELECTION,
  [FIELD_CONSTANTS.FIELD_TYPE_NUMBER_INPUT]:
    FIELD_CONSTANTS.FIELD_TYPE_FORM_NUMBER_INPUT,
  [FIELD_CONSTANTS.FIELD_TYPE_FREE_TEXT]: "",
};

export const fieldMap = {
  [FIELD_CONSTANTS.FIELD_TYPE_IMAGES]: CustomImage,
  [FIELD_CONSTANTS.FIELD_TYPE_MULTIPLE_SELECTION]: MultipleText,
  [FIELD_CONSTANTS.FIELD_TYPE_BOOLEAN]: YesNo,
  [FIELD_CONSTANTS.FIELD_TYPE_SINGLE_SELECTION_PICKER]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_SLIDER_NRS]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_FREE_TEXT]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_IMAGES]: ExaminationImages,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_CASE_DATA]: ExaminationData,
  [FIELD_CONSTANTS.FIELD_TYPE_MULTIPLE_VALUES]: MultipleText,
  [FIELD_CONSTANTS.FIELD_TYPE_CHECKBOX]: MultiCheckbox,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_GENERAL_INFO]: ExaminationGeneralInfo,
  [FIELD_CONSTANTS.FIELD_TYPE_PATIENT_ID]: PatientIdLink,
  [FIELD_CONSTANTS.FIELD_TYPE_CASE_STATUS]: CaseStatus,
  [FIELD_CONSTANTS.FIELD_TYPE_CONDITIONAL_TEXT_WITH_LABEL]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_SINGLE_SELECTION_PICKER_POP_UP]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_TEXT_WITH_LABEL]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_WOUND_MEASUREMENT_TEXT_WITH_LABEL]: WoundMeasurementTextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_TEXT_WITH_HTML_CONTENT]: FieldWithHtmlContent,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_PATIENT_IMAGES]: PatientExaminationImages,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_FORM]: ExaminationDiagnosis,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_SINGLE_SELECTION]: SingleSelectionInput,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_MULTIPLE_SELECTION]: MultipleSelectionInput,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_DOCUMENTATION]: ExaminationDocumentationGroup,
  [FIELD_CONSTANTS.FIELD_TYPE_DOCUMENTATION]: DocumentationWrapper,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_MEDICAL_HISTORY]: ExaminationMedicalHistory,
  [FIELD_CONSTANTS.FIELD_TYPE_HEADER]: FieldHeader,
  [FIELD_CONSTANTS.FIELD_TYPE_DATE_SELECTION]: DateField,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_AI_DIAGNOSIS]: ExaminationAiDiagnosis,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_DIAGNOSIS]: MoleExaminationDiagnosis,
  [FIELD_CONSTANTS.FIELD_GROUP_TYPE_UK_DIAGNOSIS]: MoleUkExaminationDiagnosis,
  [FIELD_CONSTANTS.FIELD_TYPE_DIVIDER]: DividerField,
  [FIELD_CONSTANTS.FIELD_TYPE_BMI_CALCULATOR]: BmiCalculator,
  [FIELD_CONSTANTS.FIELD_TYPE_AREA_CALCULATOR]: AreaCalculator,
  [FIELD_CONSTANTS.FIELD_GROUP_STUDY_TYPE_FORM]: ExaminationStudyDiagnosisForm,
  [FIELD_CONSTANTS.FIELD_TYPE_DIAGNOSIS_SINGLE_SELECTION_PICKER]: DiagnosisSingleSelectionPicker,
  [FIELD_CONSTANTS.FIELD_TYPE_SAVE_DIAGNOSIS_BUTTON]: FieldButton,
  [FIELD_CONSTANTS.FIELD_GROUP_STUDY_AI_RESULTS]: AiResultsPanel,
  [FIELD_CONSTANTS.FIELD_GROUP_MCD_STUDY_AI_RESULTS]: AiMcdResultsPanel,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_RANGE_SLIDER]: RangeSlider,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_FREE_TEXT]: FormTextfield,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_CHECKBOX]: Checkbox,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_DER_RANGE_SLIDER]: DerRangeSlider,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_DER_CHANGE_DIAGNOSIS]: DerChangeDiagnosis,
  [FIELD_CONSTANTS.FIELD_TYPE_NUMBER_INPUT]: TextWithLabel,
  [FIELD_CONSTANTS.FIELD_TYPE_FORM_NUMBER_INPUT]: FormNumberInput,
};
