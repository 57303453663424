<template>
  <div>
    <div
      v-if="!showAllCenters"
      class="overflow-auto d-flex justify-content-between"
    >
      <button
        to="/region/centers"
        class="btn btn-grey mt-4 mb-2 mx-3 align-self-start"
        @click="backToAllCenters"
      >
        <i class="far fa-arrow-left"></i>
        <span>{{ trans("region.backToAllCenters") }}</span>
      </button>

      <div class="mt-4 mb-2 mx-3">
        <h3 class="centerName" v-if="selectedCenter">
          {{ selectedCenter.name }}
        </h3>
      </div>
    </div>

    <div class="tab-content" v-if="showAllCenters">
      <clip-loader v-show="loading"></clip-loader>

      <div class="text-right mb-4 mx-4">
        <router-link to="/region/centers/newCentre" class="btn btn-primary">
          <i class="far fa-plus"></i>
          <span>{{ trans("center.addNewCenter") }}</span>
        </router-link>
      </div>

      <div
        class="panel panel-default panel-fill"
        v-if="!loading && this.$route.params.id !== 'new'"
      >
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("users.connectedCentersLabel") }}
          </h3>
        </div>
        <div class="panel-body table-responsive">
          <table class="table table-hover interactive-table">
            <thead>
              <tr>
                <th>{{ trans("center.name") }}</th>
                <th>{{ trans("center.primaryUsers") }}</th>
                <th>{{ trans("center.contactPerson") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="openCenter(center.id)"
                v-for="(center, index) in centers"
                :key="index"
              >
                <td>{{ center.name }}</td>
                <td>{{ center.primary_users_count }}</td>
                <td>{{ center.contact_person }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <router-view ref="rv"></router-view>
  </div>
</template>

<script>
import { store } from "../../store";
import { regionCentersGet } from "../../store/core/regions";
import _find from "lodash/fp/find";

export default {
  updated() {
    this.showAllCenters = typeof this.$refs.rv === "undefined";
  },
  mounted: function() {
    this.getRegionCenters();
    this.showAllCenters = typeof this.$refs.rv === "undefined";
  },
  data: function() {
    return {
      loading: false,
      centers: [],
      showAllCenters: true,
    };
  },
  methods: {
    getRegionCenters: function() {
      this.loading = true;

      regionCentersGet(store.state.user.primaryCenter.region_id)
        .then(centers => {
          this.centers = centers;
          this.loading = false;
        })
        .catch(error => {
          console.log("Get region centers error: ", error);
          this.loading = false;
        });
    },
    openCenter: function(centerId) {
      this.$router.push({ path: `/region/centers/${centerId}` });
    },
    backToAllCenters: function() {
      this.$router.push({ path: `/region/centers` });
    },
  },
  computed: {
    selectedCenter() {
      return _find(
        { id: parseInt(this.$route.params.id ?? 0, 10) },
        this.centers
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.centerName {
  color: variables.$dermicusBlue;
}
</style>
