import { StudyResultsImportStatistics } from "../../../../app/Imports/StudyResultsImportStatistics";
import { DataVariant } from "../design-system/data-variant";

export function studyResultsImportStatisticsVariant(
  value: StudyResultsImportStatistics
): DataVariant {
  if (value.successCount === value.rowCount) {
    return "success";
  } else if (value.successCount === 0) {
    return "danger";
  } else {
    return "warning";
  }
}
