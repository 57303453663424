<template>
  <div class="dropdown">
    <button
      class="btn btn-custom"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      :disabled="loading || !availableExaminationActions"
    >
      {{ trans("general.action") }}
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <a
        v-for="(item, index) in availableExaminationActions"
        :key="index"
        class="dropdown-item"
        href="#"
        @click.prevent="onAction(item)"
      >
        {{ item.actionTitle }}
      </a>
    </div>
  </div>
</template>

<script>
import { productHasPermission } from "../../../../../helpers/product";
import {
  PRODUCT_ACTIONS,
  PRODUCT_PERMISSIONS,
} from "../../../../../helpers/definitions";

export default {
  props: {
    examinationActions: Object,
    product: Object,
    loading: Boolean,
    errorUpdatingActions: Boolean,
    caseClosedAt: [String, Object],
    viewOption: String,
  },
  mounted: function() {
    this.$nextTick(() => {
      this.mapCaseActions();
    });
  },
  data: function() {
    return {
      productActions: PRODUCT_ACTIONS,
      availableExaminationActions: null,
    };
  },
  methods: {
    mapCaseActions: function() {
      let enabledActions = [];

      this.productActions.forEach(action => {
        let enabled =
          productHasPermission(action.name, this.product) &&
          this.$can(action.name);

        if (enabled) {
          // dont' show option to close case if case is already closed or actions are on examination overview
          if (
            action.name === PRODUCT_PERMISSIONS.CLOSE_CASES &&
            (this.caseClosedAt || this.viewOption === "examination_overview")
          ) {
            return;
          }
          // don't show edit case in examination
          if (
            action.name === PRODUCT_PERMISSIONS.EDIT_EXAMINATION_VALUES &&
            this.viewOption === "examination_overview"
          ) {
            return;
          }

          // to do: find a better way to define actions in product definitions or refactor all 'generic' actions handling
          // only show edit_medical_history on medical history panel (using customActionHandler.vue)
          if (action.name === PRODUCT_PERMISSIONS.EDIT_MEDICAL_HISTORY) {
            return;
          }

          enabledActions.push({
            ...action,
            enabled: enabled,
            actionTitle: this.getActionTitle(action),
          });
        }
      });

      this.availableExaminationActions = enabledActions;
    },
    getActionTitle: function(action) {
      if (action.hasBoolValue && this.examinationActions) {
        return this.examinationActions[action.key]
          ? action.removeTitle
          : action.title;
      }
      return action.title;
    },
    updateActionTitle: function(action) {
      this.availableExaminationActions.find(a => {
        if (a.key === action.key) {
          a.actionTitle = this.getActionTitle(a);
        }
      });
    },
    onAction: function(item) {
      this.$emit("on-action", item);
      this.updateActionTitle(item);
    },
  },
  watch: {
    errorUpdatingActions: function() {
      if (this.errorUpdatingActions) {
        this.mapCaseActions();
      }
    },
    caseClosedAt: function() {
      this.mapCaseActions();
    },
  },
};
</script>
