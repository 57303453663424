<template>
  <div class="switch-container">
    <button
      type="button"
      v-for="option in options"
      :key="option.value"
      @click="$emit('update:selectedOption', option.value)"
      :class="['switch-button', { isActive: option.value === selectedOption }]"
    >
      {{ option.title }}
    </button>
  </div>
</template>

<script>
export default {
  props: ["options", "selectedOption"],
  model: {
    prop: "selectedOption",
    event: "update:selectedOption",
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.switch-container {
  background-color: variables.$dermicusBlue-100;
  padding: 0.25em;
  border-radius: 5px;
  display: flex;
  gap: 0.5em;
}

.switch-button {
  border: 0;
  color: variables.$dermicusBlue;
  font-weight: variables.$font-weight-regular;
  border-radius: 5px;
  background-color: unset;

  &.isActive {
    background-color: variables.$dermicusWhite;
  }
}
</style>
