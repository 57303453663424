<template>
  <div>
    <div v-if="loading" class="overlay">
      <clip-loader></clip-loader>
    </div>
    <div class="row my-2">
      <div class="col-md-12">
        <div class="p-0 text-center">
          <div class="member-card">
            <div class="avatarWrapper">
              <div class="avatar cursor-pointer">
                <img v-if="avatar.image" :src="avatar.image" alt="user-img" />

                <div
                  v-if="!avatar.image"
                  class="member-thumb m-b-10 center-block humb-md avatar-round-big initials"
                >
                  {{ user.initials }}
                </div>
                <span id="pick-avatar" ref="fileUploadRef"></span>
                <avatar-cropper
                  @uploaded="handleUploaded"
                  trigger="#pick-avatar"
                  upload-url="/api/internal/auth/upload-avatar"
                  :uploadHeaders="avatar.headerSettings"
                  :labels="avatar.buttonLabels"
                />
              </div>
              <div class="dropdown">
                <button
                  class="form-image-upload__edit btn btn-grey"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  type="button"
                >
                  <i class="fas fa-pen editIcon"></i>
                  <span>{{ trans("users.edit") }}</span>
                </button>
                <div class="dropdown-menu">
                  <button
                    class="dropdown-item"
                    @click="handleEditClick"
                    type="button"
                  >
                    {{ trans("users.uploadLogo") }}
                  </button>
                  <button
                    v-if="avatar.image"
                    class="dropdown-item"
                    @click="handleClearClick"
                    type="button"
                  >
                    {{ trans("users.removeLogo") }}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <h4 class="m-2 mt-4">{{ user.fullName }}</h4>
              <p class="text-muted">{{ user.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="m-t-30">
      <div class="col-md-12">
        <div class="text-right">
          <Button
            @click="saveFormData"
            :disabled="!isValid || loading"
            :title="isValid ? '' : trans('examination.requiredFieldMissing')"
            type="button"
            :isLoading="isSaving"
          >
            {{ trans("users.saveButton") }}
          </Button>
        </div>
      </div>

      <user-header componentPurpose="userSettings"></user-header>

      <div class="tab-content" v-if="!loading">
        <div class="tab-pane active" id="profile">
          <user-profile :user="user" :errors="errors"></user-profile>
          <div class="text-right mb-5">
            <button
              class="btn btn-primary"
              :disabled="!isValid || loading"
              :title="isValid ? '' : trans('examination.requiredFieldMissing')"
              @click="saveFormData"
            >
              {{ trans("users.saveButton") }}
            </button>
          </div>
        </div>

        <div class="tab-pane" id="settings">
          <div class="row">
            <div class="col-md-12">
              <user-settings
                :user="user"
                :centers="centers"
                :primaryCenter="primaryCenter"
                :externalResources="externalResources"
                :errors="errors"
                :userRoles="userRoles"
                :dropdownRole="dropdownRole(user)"
              ></user-settings>
            </div>
          </div>
        </div>

        <div class="tab-pane" id="products">
          <user-products :products="products" :user="user"></user-products>
        </div>

        <div class="tab-pane" id="history">
          <user-history
            :timeline="timeline"
            @getMoreNotifications="getMoreNotifications"
          ></user-history>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarCropper from "vue-avatar-cropper";

import UserHeader from "./UserHeader.vue";
import UserHistory from "./UserHistory.vue";
import UserProfile from "../Admin/Users/User/UserProfile.vue";
import UserProducts from "../Admin/Users/User/UserProducts.vue";
import UserSettings from "./UserSettings.vue";
import DropdownRole from "../../mixins/DropdownRole.vue";
import { lang } from "../../i18n";
import { practitionerAuthToken } from "../../app/practitioner-portal/auth/practitioner-auth-token";

import Button from "../Layout/Button.vue";

export default {
  mixins: [DropdownRole],
  created: function() {
    this.loadData();
  },
  mounted: function() {
    this.avatar.headerSettings = {
      Authorization: "Bearer " + practitionerAuthToken.get(),
    };
  },
  data() {
    return {
      errors: [],
      loading: false,
      isSaving: false,
      user: "",
      primaryCenterId: null,
      primaryCenter: {
        id: null,
      },
      centers: "",
      externalResources: "",
      csrfToken: Laravel.csrfToken,
      products: {
        notifications_new_examinations: false,
        consensus_notifications: false,
        notification_new_document_consult_answer: false,
        unanswered_cases: false,
        cases_without_photos: false,
      },
      timeline: {
        data: {},
        paginate: {},
      },
      avatar: {
        image: undefined,
        headerSettings: {},
        buttonLabels: { submit: "Submit", cancel: "Cancel" },
        cropperOptions: {
          autoCropArea: 1,
          viewMode: 1,
          movable: 1,
          zoomable: 1,
        },
      },
      roles: [],
      hasAnyExternalResourceRole: false,
      userRoles: {
        consultant: false,
        senior_consultant: false,
        user: false,
        center_admin: false,
        region_admin: false,
        external_resource: false,
        region_external_resource: false,
        admin: false,
        case_admin: false,
      },
    };
  },
  methods: {
    loadData: function() {
      this.loading = true;
      axios
        .get("/api/internal/auth/settings")
        .then(data => {
          let collection = data.data;
          this.user = collection.user;
          this.primaryCenter = collection.primaryCenter;

          this.centers = collection.centers;
          this.externalResources = collection.externalResources;

          // checkbox
          this.products = collection.user.products;
          this.timeline = collection.notifications;

          this.avatar.image = this.$store.state.user.avatar;

          // assign role to true if the role is in the assigned user roles list
          let assignedUserRoles = this.user.roles;
          assignedUserRoles.map(value => {
            this.userRoles[value.name] = true;
          });

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    saveFormData: function(e) {
      e.preventDefault();

      this.errors = [];

      this.user.products = this.products;
      this.user.userRoles = this.userRoles;

      this.isSaving = true;

      axios
        .post("/api/internal/auth/settings", this.user)
        .then(response => {
          if (response.data.success) {
            this.isSaving = false;
            this.$notify({
              text: lang.get("general.notificationSaved"),
              type: "success",
              duration: 1000,
            });

            // With this form, it's possible to change the preferred locale.
            lang.setLocale(this.user.culture);
          }
        })
        .catch(error => {
          this.isSaving = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    handleEditClick() {
      this.$refs.fileUploadRef.click();
    },
    handleClearClick() {
      axios
        .delete("/api/internal/auth/delete-avatar")
        .then(response => {
          this.avatar.image = response.avatar;
          this.$store.commit("setUserAvatar", this.avatar.image);

          this.$notify({
            text: lang.get("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        })
        .catch(error => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    handleUploaded(response) {
      this.avatar.image = response.avatar;
      this.$store.commit("setUserAvatar", this.avatar.image);

      this.$notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    },
    getMoreNotifications: function() {
      if (this.timeline.paginate.next_page_url) {
        axios
          .get(this.timeline.paginate.next_page_url)
          .then(response => {
            let collection = response.data;

            if (collection) {
              for (var i = 0; i < collection.data.length; i++) {
                this.timeline.data.push(collection.data[i]);
              }

              this.timeline.paginate = collection.paginate;
            }
          })
          .catch(error => {
            this.userErrorMessage(error);
          });
      }
    },
  },
  computed: {
    isValid: function() {
      if (
        !this.user.culture ||
        !this.user.username ||
        !this.user.email ||
        !this.user.firstname ||
        !this.user.lastname
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  components: {
    AvatarCropper,
    UserHeader,
    UserHistory,
    UserProfile,
    UserProducts,
    UserSettings,
    Button,
  },
};
</script>

<style lang="scss" scoped>
.form-image-upload__edit {
  position: absolute;
  bottom: -1em;
  left: 50%;
  transform: translateX(-50%);
}
</style>
