<template>
  <CenterSettingsFrame :data-center-id="centerId" />
</template>
<script setup lang="ts">
import { store } from "../../store";
import { ref, watch } from "vue";
import CenterSettingsFrame from "../../components/CenterSettingsFrame.vue";

/**
 * @todo Use observables in the store, so we can subscribe to the principal, and
 *    not end up with 1000 requests, as we can keep this cached during subscription,
 *    or with other means, and only update when really needed! For now, we'll
 *    use the current store (vuex), but that's very hard to maintain!!!
 */
const centerId = ref<number>();
watch(
  () => store.state.user.primaryCenter.id,
  value => (centerId.value = value),
  { immediate: true }
);
</script>
