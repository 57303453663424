<template>
  <div>
    <header-support :active-section="1"></header-support>
    <div class="tab-pane">
      <div class="col-12">
        <div class="text-center m-5">
          <h6>{{ lang.get("support.regionSupportPageInfo") }}:</h6>
          <a
            class="btn btn-primary mt-3"
            :href="getPlatformSpecificSupportUrl(platform)"
            target="blank"
            :title="lang.get('support.regionSupportTitle')"
            >{{ lang.get("support.visitSupportPage") }}</a
          >
        </div>

        <div class="w-60 m-auto">
          <a
            :href="getPlatformSpecificSupportUrl(platform)"
            target="blank"
            :title="lang.get('support.regionSupportTitle')"
          >
            <img
              class="col-12"
              :src="getPlatformSpecificSupportImg(platform)"
              alt="Region support page"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  getPlatformSpecificSupportImg,
  getPlatformSpecificSupportUrl,
} from "../../../helpers/platform";
import { lang } from "../../../i18n";
import { store } from "../../../store";
import HeaderSupport from "../Header.vue";

const platform = computed(() => store.state.dermicusPlatform);
</script>

<style lang="scss" scoped>
img {
  object-fit: cover;
  opacity: 0.8;
}
</style>
