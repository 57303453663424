const isExternalResource = function(usersList) {
  let externalResourcesNamesList = [];

  usersList.filter(user => {
    if (user.is_external_resource === true && !user.has_access) {
      externalResourcesNamesList.push(user.name);
    }
  });

  return {
    externalResourcesList: externalResourcesNamesList.join(", "),
    isExternalResources: externalResourcesNamesList.length > 0 ? true : false,
  };
};

const isExternalResouceId = function(allUsers, selectedUsersIds) {
  let externalResourcesNamesList = [];

  selectedUsersIds.forEach(user => {
    allUsers.filter(u => {
      if (user === u.id && u.is_external_resource === true && !u.has_access) {
        externalResourcesNamesList.push(u.name);
      }
    });
  });

  return {
    externalResourcesList: externalResourcesNamesList.join(", "),
    isExternalResources: externalResourcesNamesList.length > 0 ? true : false,
  };
};

export default { isExternalResource, isExternalResouceId };
