<!--Duplicated groud logic from Examinations.vue in order to not break generic-->
<!--components layout, but specify research layout requirements-->
<template>
  <div class="study-case-container">
    <div v-for="component in components" class="col-md-12">
      <component :is="component.element" v-bind="component.props" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { mapComponents } from "../../components/Examinations/Examination/componentMapper";
import { computed, toRefs } from "vue";

const props = defineProps<{
  examination?: Record<string, unknown>;
  product?: Record<string, unknown>;
  dataPermissions: string[];
}>();

const { product, examination, dataPermissions } = toRefs(props);
const components = computed(() => {
  return product?.value && examination?.value
    ? mapComponents(
        product.value,
        dataPermissions,
        examination.value,
        "study_overview"
      )
    : [];
});
</script>
<style lang="scss" scoped>
.study-case-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(min-content, auto);

  & > :first-child {
    grid-column: 1;
    grid-row: 1;
  }

  & > :nth-child(2) {
    grid-column: 1;
    grid-row: 2;
  }

  & > :nth-child(3) {
    grid-column: 2;
    grid-row: 1 / 3;
  }

  & > :nth-child(4) {
    grid-column: 1;
    grid-row: 3;
  }

  & > :nth-child(5) {
    grid-column: 2;
    grid-row: 3;
  }
}

@media (max-width: 768px) {
  .study-case-container {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
