<template>
  <div
    class="panel panel-default panel-fill"
    v-if="platformAttributes && platformAttributes.length > 0"
  >
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ trans("general.specificFor") }} {{ platform }}
      </h3>
    </div>
    <div class="panel-body">
      <div class="row">
        <div
          v-for="(attribute, index) in platformAttributes"
          :key="index"
          class="m-b-20 col-md-6"
        >
          <label :for="attribute.name">{{ trans(attribute.name) }}</label>
          <span v-if="attribute.form_field == 'text'">
            <input
              type="text"
              :name="'platformAttributes[' + attribute.name + ']'"
              v-model="platformAttributeValues[attribute.name]"
              class="form-control"
              :placeholder="trans(attribute.name)"
            />
          </span>
          <span v-if="attribute.form_field == 'longtext'">
            <textarea
              :name="'platformAttributes[' + attribute.name + ']'"
              v-model="platformAttributeValues[attribute.name]"
              class="form-control"
              :placeholder="trans(attribute.name)"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["platformAttributes", "platformAttributeValues", "platform"],
};
</script>
