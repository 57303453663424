<template>
  <div class="panel panel-default">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans("patient.patientVievWoundDiagnos") }}
      </h3>
    </div>
    <div class="panel-body" v-if="examination">
      <div class="m-b-20 col-md-12">
        <strong>{{ trans("examination.diagnosiskHeadline") }}</strong>
        <br />

        <select2
          name="diagnosis_code_wound"
          :model-value="examination.diagnosisCodeWound"
          :disabled="true"
          :data-save-url="
            '/api/internal/wounds/' + examination.id + '/save-diagnostic'
          "
        >
          <option value="1">{{ trans("examination.missing") }}</option>
          <option value="2">{{ trans("examination.venousUlcer") }}</option>
          <option value="3">{{ trans("examination.arterialUlcer") }}</option>
          <option value="4">{{
            trans("examination.arteriovenousUlcers")
          }}</option>
          <option value="5">{{
            trans("examination.neuropathicFootUlcer")
          }}</option>
          <option value="6">{{ trans("examination.pressureUlcer") }}</option>
          <option value="7">{{ trans("examination.woundInjury") }}</option>
          <option value="8">{{ trans("examination.tumorUlcer") }}</option>
          <option value="9">{{
            trans("examination.smallVesselVasculitisUlcer")
          }}</option>
          <option value="10">{{
            trans("examination.pyodermaGangrenosum")
          }}</option>
          <option value="11">{{
            trans("examination.necrobiosisLipoidica")
          }}</option>
          <option value="12">{{
            trans("examination.otherWoundDiagnosis")
          }}</option>
        </select2>
      </div>

      <div class="m-b-20 col-md-12">
        <strong>{{ trans("examination.tagsLabel") }}</strong>
        <br />
        <select2
          name="diagnosis_code_histopathological"
          :model-value="examination.diagnosisCodeHistopathological"
          :disabled="true"
          :data-save-url="
            '/api/internal/wounds/' + examination.id + '/save-diagnostic'
          "
        >
          <optgroup :label="trans('examination.tagsSelectOptionGroupLabel')">
            <option value="1">{{
              trans("examination.tagsSelectOptionEducation")
            }}</option>
            <option value="7">{{
              trans("examination.tagsSelectOptionHoldPad")
            }}</option>
            <option value="12">{{
              trans("examination.tagSelectOptionResearch")
            }}</option>
            <option value="2">{{
              trans("examination.tagsSelectOptionPublicationFall")
            }}</option>
          </optgroup>
        </select2>
      </div>
    </div>
    <div class="panel-body" v-else>
      {{ trans("general.emptyDataReuslt") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    examination: {},
  },
};
</script>
