<template>
  <div>
    <horizontal-bars-chart
      :elements="plannedActions"
      elementsHeaderName="plannedActions"
      :barColor="color"
      chartType="planned"
      :loading="loading"
    ></horizontal-bars-chart>
  </div>
</template>

<script>
import HorizontalBarsChart from "./ChartTypes/HorizontalBarsChart.vue";

export default {
  components: {
    HorizontalBarsChart,
  },
  props: {
    plannedActions: {
      label: String,
      value: Number,
      percentage: Number,
    },
    loading: Boolean,
  },
  data: function() {
    return {
      color: "#f2ad3c",
    };
  },
};
</script>
