<template>
  <div class="col-lg-10 col-md-10">
    <center-header :selected-menu-item="5"></center-header>

    <div class="tab-content">
      <div class="tab-pane active">
        <Panel :title="trans('general.pageTitleEvaluations')">
          <clip-loader v-if="isExporting" />
          <div v-show="!isExporting">
            <ExportView
              :data-available-exports="availableExports"
              :data-form-errors="errors"
              @exportData="exportData"
            />
          </div>
        </Panel>
      </div>
    </div>
  </div>
</template>

<script>
import Panel from "../../Layout/Panel.vue";
import CenterHeader from "../CenterHeader.vue";
import ExportView from "../../../app/practitioner-portal/generic/export/ExportView.vue";
import ExportExaminationsForm from "../../../app/practitioner-portal/admin-center/ExportExaminationsForm.vue";
import ExportPatientLogForm from "../../../app/practitioner-portal/generic/export/ExportPatientLogForm.vue";
import ExportUserLogForm from "../../../app/practitioner-portal/generic/export/ExportUserLogForm.vue";
import contentDisposition from "content-disposition";
import { shortDateFormat } from "../../../helpers/platform";

export default {
  data() {
    return {
      isExporting: false,
      errors: {},
    };
  },
  computed: {
    availableExports() {
      return [
        {
          type: "mole_consultations",
          name: this.trans(`admin.exports.types.mole_consultations.label`),
          form: ExportExaminationsForm,
        },
        {
          type: "patient_log",
          name: this.trans(`admin.exports.types.patient_log.label`),
          form: ExportPatientLogForm,
        },
        {
          type: "user_log",
          name: this.trans(`admin.exports.types.user_log.label`),
          form: ExportUserLogForm,
        },
      ];
    },
  },
  methods: {
    exportData: function(formData) {
      formData.set("responseType", "arraybuffer");
      // TODO: This form data must be aligned, so all exports are using the
      //    same keys for the values, which would make so much more sense,
      //    and make code re-usable, as this is functionally the same....
      if (formData.has("dateFrom")) {
        formData.set("fromDate", formData.get("dateFrom"));
        formData.delete("dateFrom");
      }
      if (formData.has("dateUntil")) {
        formData.set("untilDate", formData.get("dateUntil"));
        formData.delete("dateUntil");
      }

      // TODO: the data should be returned in ISO date format, so this conversion is not needed
      const format = shortDateFormat();
      if (formData.has("fromDate")) {
        formData.set(
          "fromDate",
          moment(formData.get("fromDate"), format).format("YYYY-MM-DD")
        );
      }
      if (formData.has("untilDate")) {
        formData.set(
          "untilDate",
          moment(formData.get("untilDate"), format).format("YYYY-MM-DD")
        );
      }

      this.exportToCsv(formData);
    },
    exportToCsv: function(formData) {
      this.isExporting = true;
      this.errors = {};
      axios
        .post("/api/internal/center-admin/export", formData)
        .then(response => {
          const blob = new Blob(
            [new Uint8Array([0xef, 0xbb, 0xbf]), response.data],
            {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }
          );

          const fileName = contentDisposition.parse(
            response.headers["content-disposition"]
          ).parameters.filename;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          }

          const data = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
          }, 100);

          this.isExporting = false;
        })
        .catch(error => {
          this.errors = error.response.data;
          this.isExporting = false;
          this.userErrorMessage(error);
        });
    },
  },
  components: {
    ExportView,
    CenterHeader,
    Panel,
  },
};
</script>
