<template>
  <div>
    <div class="row col-md-12 m-0 p-0">
      <div class="col-md-12 p-0">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("users.tabMenuLabelSettings") }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="row col-md-12">
              <div class="mb-3 col-md-6">
                <div
                  class="form-group"
                  :class="{ 'has-error': errors.culture }"
                >
                  <label for="culture">{{
                    trans("users.languageLabel")
                  }}</label>
                  <i class="far fa-asterisk"></i>
                  <select2 name="culture" v-model="user.culture">
                    <option value="sv">{{
                      trans("users.languageOptionSwedish")
                    }}</option>
                    <option value="en">{{
                      trans("users.languageOptionEnglish")
                    }}</option>
                    <option value="nl">
                      {{ trans("users.languageOptionDutch") }}
                    </option>
                    <option value="de">
                      {{ trans("users.languageOptionGerman") }}
                    </option>
                    <option value="fr">
                      {{ trans("users.languageOptionFrench") }}
                    </option>
                    <option value="it">
                      {{ trans("users.languageOptionItalian") }}
                    </option>
                  </select2>

                  <form-error
                    v-if="errors.culture"
                    :errors="errors.culture"
                  ></form-error>
                </div>
              </div>
              <div class="mb-3 col-md-6">
                <div class="form-group">
                  <label for="userRole">{{
                    trans("users.userRoleLabel")
                  }}</label>
                  <select2
                    disabled
                    id="userRole"
                    name="userRole"
                    :model-value="dropdownRole"
                  >
                    <option value></option>
                    <option value="user">
                      {{ trans("roles.user") }}
                    </option>
                    <option value="consultant">
                      {{ trans("roles.consultant") }}
                    </option>
                    <option value="senior_consultant">
                      {{ trans("roles.senior_consultant") }}
                    </option>
                    <option value="wound_user">
                      {{ trans("roles.wound_user") }}
                    </option>
                  </select2>
                </div>
              </div>
            </div>
            <div class="row col-md-12">
              <div class="mb-3 col-md-6">
                <div class="form-group">
                  <label for="hidePersonalData">{{
                    trans("users.hidePersonalNoLabel")
                  }}</label>
                  <DerToggle v-model="user.hide_personal_data" />
                </div>
              </div>
              <div class="mb-3 col-md-6">
                <div class="form-group">
                  <label for="hideCaseAnswers">{{
                    trans("users.hideConsultAnswerLabel")
                  }}</label>
                  <DerToggle v-model="user.hide_case_answers" />
                </div>
              </div>
            </div>

            <span class="text-left"
              ><i class="far fa-asterisk"></i>
              {{ trans("center.requiredField") }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row col-md-12 m-0 p-0">
      <div class="col-md-6 pl-0">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("users.externalResursHeadline") }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="col-md-12">
              <div class="form-group">
                <label for="allowExternalResource">{{
                  trans("roles.external_resource")
                }}</label>
                <p class="mb-3">
                  <small>{{
                    trans("users.externalResourceRoleConsent")
                  }}</small>
                </p>
                <DerToggle
                  :model-value="hasAnyExternalResourceRole"
                  @update:modelValue="updateHasAnyExternalResourceRole"
                />

                <select2
                  v-if="hasAnyExternalResourceRole"
                  name="externalResourceRole"
                  :model-value="selectedExternalResourceRole"
                  @update:modelValue="updateSelectedExternalResourceRole"
                  disable-clear="true"
                >
                  <option value="external_resource">{{
                    trans("roles.external_resource_full_title")
                  }}</option>
                  <option value="region_external_resource">{{
                    trans("roles.region_external_resource_full_title")
                  }}</option>
                </select2>
              </div>
              <table class="table table-hover">
                <tbody>
                  <tr
                    v-for="(externalResource, index) in externalResourcesList"
                    :key="index"
                  >
                    <td v-if="externalResource">
                      {{ externalResource.case_no }}
                    </td>
                  </tr>
                  <button
                    class="btn btn-grey btn-lg btn-block mt-2"
                    @click="showAllExternalCases = !showAllExternalCases"
                    v-if="externalResources && externalResources.length > 5"
                  >
                    <i
                      :class="[
                        showAllExternalCases ? 'fa-angle-up' : 'fa-angle-down',
                        'fas',
                      ]"
                    ></i>
                    {{
                      showAllExternalCases
                        ? trans("center.showLess")
                        : trans("examination.consultationShowAllButton")
                    }}
                  </button>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 p-0">
        <div class="panel panel-default panel-fill">
          <div class="panel-heading">
            <h3 class="panel-title">
              {{ trans("users.resursHeadline") }}
            </h3>
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label for="primaryCenter">{{
                trans("users.primaryCenterLabel")
              }}</label>
              <select2 name="primaryCenter" disabled v-model="primaryCenter.id">
                <option
                  v-for="(center, index) in centers"
                  :key="index"
                  :value="center.id"
                  >{{ center.name }}
                </option>
              </select2>
            </div>

            <div class="form-group" v-if="connectedCenters.length !== 0">
              <label for="connectedCenters">{{
                trans("users.connectedCentersLabel")
              }}</label>
              <table class="table table-hover">
                <tbody>
                  <tr v-for="(center, index) in connectedCenters" :key="index">
                    <td v-if="!center.pivot.is_primary">{{ center.name }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 pl-0">
        <UserQuicksilvaConnection />
      </div>
    </div>
  </div>
</template>

<script>
import DerToggle from "../../design-system/DerToggle.vue";
import UserQuicksilvaConnection from "./UserQuicksilvaConnection.vue";

export default {
  props: {
    user: {
      allow_external_resource: Boolean,
      hide_case_answers: Boolean,
      hide_personal_data: Boolean,
      culture: String,
    },
    primaryCenter: {},
    centers: {},
    externalResources: {},
    errors: {},
    userRoles: {
      external_resource: Boolean,
      region_external_resource: Boolean,
    },
    dropdownRole: String,
  },
  data: function() {
    return {
      showAllExternalCases: false,
      lastSelectedExternalResourceRole: "external_resource",
    };
  },
  computed: {
    connectedCenters: function() {
      return this.centers.filter(center => {
        return center.id !== this.primaryCenter.id;
      });
    },
    externalResourcesList: function() {
      if (
        !this.showAllExternalCases &&
        this.externalResources &&
        this.externalResources.length > 5
      ) {
        return this.externalResources.slice(0, 5);
      } else {
        return this.externalResources;
      }
    },
    hasAnyExternalResourceRole() {
      return !!(
        this.userRoles["external_resource"] ||
        this.userRoles["region_external_resource"]
      );
    },
    selectedExternalResourceRole() {
      if (this.userRoles["external_resource"]) {
        this.lastSelectedExternalResourceRole = "external_resource";
      } else if (this.userRoles["region_external_resource"]) {
        this.lastSelectedExternalResourceRole = "region_external_resource";
      }
      return this.lastSelectedExternalResourceRole;
    },
  },
  methods: {
    updateHasAnyExternalResourceRole(value) {
      this.userRoles[this.selectedExternalResourceRole] = !!value;
    },
    updateSelectedExternalResourceRole(role) {
      this.lastSelectedExternalResourceRole = role;
      this.userRoles["external_resource"] = false;
      this.userRoles["region_external_resource"] = false;
      this.userRoles[role] = true;
    },
  },
  components: {
    DerToggle,
    UserQuicksilvaConnection,
  },
};
</script>
