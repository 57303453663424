<template>
  <div id="custom-search-input" class="mb-3">
    <form class="input-group col-md-12" @submit.prevent="handleSubmit">
      <input
        type="text"
        v-model="searchInput"
        class="form-control input-lg"
        id="search-field"
        :placeholder="props.placeHolder"
      />
      <span class="input-group-btn">
        <button class="btn" type="submit">
          <i class="far fa-search"></i>
        </button>
        <button
          v-if="searchInput.length > 0"
          class="btn"
          type="button"
          @click="handleClear"
        >
          <i class="fal fa-times"></i>
        </button>
      </span>
    </form>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";

const props = defineProps<{
  placeHolder?: string;
}>();

const emit = defineEmits({
  submit: (value: string) => {},
  clearInput: () => {},
});

const searchInput = ref("");

function handleSubmit() {
  if (searchInput.value.length !== 0) {
    emit("submit", searchInput.value);
  } else {
    emit("clearInput");
  }
}

function handleClear() {
  searchInput.value = "";
  emit("clearInput");
}
</script>
