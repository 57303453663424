<template>
  <UseStoreResource :data-resource="resource">
    <div
      class="form-image-upload tw-w-[200px]"
      :class="{ 'form-image-upload--preview': avatar }"
    >
      <ImageUploader
        :key="avatar"
        :debug="1"
        :maxWidth="512"
        :quality="1.7"
        :autoRotate="true"
        outputFormat="verbose"
        :preview="false"
        :className="['fileinput', { 'fileinput--loaded': avatar }]"
        capture="environment"
        accept="image/*"
        @input="$emit('avatarUpload', { identifier, data: $event })"
      >
        <template v-slot:upload-label>
          <label
            for="fileInput"
            class="mb-0 uploadLabel"
            ref="fileUploadRef"
            :style="{
              pointerEvents: avatar ? 'none' : '',
            }"
          >
            <div v-if="avatar">
              <img :src="avatar" />
            </div>

            <span v-if="!avatar" class="upload-caption">
              <div class="upload-caption__icon">
                <i
                  id="pick-avatar"
                  class="fas fa-cloud-upload-alt uploadOverlayIcon"
                ></i>
              </div>
              {{ lang.get("center.uploadLogo") }}
            </span>
          </label>
        </template>
      </ImageUploader>
      <div class="dropdown" v-if="avatar">
        <button
          class="form-image-upload__edit btn btn-grey"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          type="button"
        >
          <i class="fas fa-pen editIcon"></i>
          <span>{{ lang.get("center.edit") }}</span>
        </button>
        <div class="dropdown-menu">
          <button class="dropdown-item" @click="onEditClick" type="button">
            {{ lang.get("center.uploadLogo") }}
          </button>
          <button
            class="dropdown-item"
            @click="$emit('avatarDelete', { identifier })"
            type="button"
          >
            {{ lang.get("center.removeLogo") }}
          </button>
        </div>
      </div>
    </div>
  </UseStoreResource>
</template>
<script setup lang="ts">
import ImageUploader from "vue-image-upload-resize";
import { computed, ref, toRefs } from "vue";
import { lang } from "../i18n";
import { AvatarResource } from "../store/core/avatar";
import UseStoreResource from "../design-system/UseStoreResource.vue";

const fileUploadRef = ref();
const props = defineProps<{
  dataResource: AvatarResource;
}>();
const { dataResource } = toRefs(props);

const resource = dataResource;
const identifier = computed(() => resource.value.data.value?.identifier);
const avatar = computed(() => resource.value.data.value?.avatar);

function onEditClick() {
  fileUploadRef.value.click();
}

defineEmits<{
  (event: "avatarUpload", payload: { identifier: string; data: unknown }): void;
  (event: "avatarDelete", payload: { identifier: string }): void;
}>();
</script>
<style lang="scss">
.form-image-upload {
  #fileInput {
    display: none !important;
  }
}
</style>
<style lang="scss" scoped>
.uploadLabel {
  cursor: pointer;
}

.form-image-upload {
  background: #fff;

  &:not(.form-image-upload--preview) {
    text-align: center;
    border: 1px solid gainsboro;

    label {
      padding: 1em;
    }
  }

  img,
  label {
    width: 100%;
  }
}

.upload-caption__icon {
  font-size: 3em;
}

.form-image-upload__edit {
  margin-top: 0.2em;
}

.editIcon {
  font-size: 0.9em;
}
</style>
