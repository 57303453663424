<template>
  <div>
    <der-slider
      :id="id"
      :value="value"
      :disabled="disabled"
      :data-options="dataOptions"
      :data-option-value="dataOptionValue"
      :data-option-label="dataOptionLabel"
      @input="onInput"
      class="mb-5 px-4"
    />
    <div v-if="!dataHideHelpText" class="form-text text-info pl-4">
      <span v-if="!value">{{ valueHelpTextEmpty }}</span>
      <ul v-else class="pl-0">
        <li v-for="(helpTextLine, index) in valueHelpText" :key="index">
          {{ helpTextLine }}
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import DerSlider from "./DerSlider.vue";

export default {
  components: { DerSlider },
  data() {
    return {
      dataOptions: [
        { value: 1, label: this.trans("examination.sliderValueVeryBad") },
        { value: 2, label: this.trans("examination.sliderValueBad") },
        { value: 3, label: this.trans("examination.sliderValueAcceptable") },
        { value: 4, label: this.trans("examination.sliderValueGood") },
        { value: 5, label: this.trans("examination.sliderValueVeryGood") },
      ],
      dataOptionValue: "value",
      dataOptionLabel: "label",
      valueHelpTextEmpty: this.trans("examination.sliderValueNoValue"),
      valueHelpTextFor: {
        1: this.trans("examination.sliderValueVeryBadInfo"),
        2: this.trans("examination.sliderValueBadInfo"),
        3: this.trans("examination.sliderValueAcceptableInfo"),
        4: this.trans("examination.sliderValueGoodInfo"),
        5: this.trans("examination.sliderValueVeryGoodInfo"),
      },
    };
  },
  props: {
    /**
     * `string | undefined`
     */
    id: { type: String },
    /**
     * `number | undefined`
     */
    value: { required: true },
    /**
     * `boolean | undefined`
     */
    disabled: { type: Boolean },
    /**
     * `boolean | undefined`
     */
    dataHideHelpText: { type: Boolean },
  },
  computed: {
    valueHelpText() {
      return this.valueHelpTextFor[this.value]?.split(". ") || [];
    },
  },
  methods: {
    onInput($event) {
      /**
       * `number | undefined`
       */
      this.$emit("input", $event);
    },
  },
};
</script>
