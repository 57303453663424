<template>
  <clip-loader v-show="loading"></clip-loader>
</template>

<script>
export default {
  mounted: function() {
    if (!this.$route.query.grandidsession) {
      axios.get("/api/internal/auth/siths").then(response => {
        window.location = response.data.redirectUrl;
      });
    } else {
      // Something when wrong
      this.$router.push({ name: "Auth" });
    }
  },
  data() {
    return {
      loading: false,
    };
  },
};
</script>
