<template>
  <div @click="handleImageClick(data)">
    <encoded-image
      :imageId="data.id"
      :caseId="examinationId"
      width="200"
      :uri="data.uri"
      @on-receive-encoded-image="onEncodedImageLoaded"
      styleClass="lightbox-thumbnail"
    />
    <portal to="lightbox-container">
      <Lightbox
        v-if="activeImage"
        :images="lightboxImages"
        v-model="activeImage"
        @closeEvent="handleClose"
      />
    </portal>
  </div>
</template>

<script>
import Lightbox from "../../../../Layout/Lightbox.vue";
import EncodedImage from "../../../AddOns/EncodedImage.vue";

export default {
  props: {
    title: String,
    data: Object,
    examinationId: Number,
  },
  name: "CustomImage",
  data() {
    return {
      encodedImages: {},
      activeImage: false,
    };
  },
  computed: {
    lightboxImages() {
      return Object.entries(this.encodedImages).map(([id, url]) => ({
        id,
        url,
      }));
    },
  },
  methods: {
    handleImageClick(image) {
      this.activeImage = this.activeImage
        ? false
        : this.lightboxImages.find(img => parseInt(img.id) === image.id);
    },
    handleClose() {
      this.activeImage = false;
    },
    onEncodedImageLoaded: function(event) {
      this.encodedImages = {
        ...this.encodedImages,
        [event.imageId]: event.uri,
      };
    },
  },
  components: { Lightbox, EncodedImage },
};
</script>
