$.fn.modal.Constructor.prototype.enforceFocus = function() {};

Date.prototype.yyyymmdd = function() {
  var month = "" + (this.getMonth() + 1);
  var day = "" + this.getDate();
  var year = this.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

window.queryStringBuilder = function(data) {
  if (typeof data === "string") {
    return data;
  }

  var query = [];
  for (var key in data) {
    if (typeof data[key] === "object") {
      for (var nestedKey in data[key]) {
        query.push(
          encodeURIComponent(key) +
            "[" +
            nestedKey +
            "]=" +
            encodeURIComponent(data[key][nestedKey])
        );
      }
    } else if (data.hasOwnProperty(key)) {
      query.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key]));
    }
  }
  return query.join("&");
};

window.inArray = function(needle, haystack) {
  var length = haystack.length;
  for (var i = 0; i < length; i++) {
    if (haystack[i] == needle) return true;
  }
  return false;
};
