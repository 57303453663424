<template>
  <VueSwitches
    class="switchery"
    theme="bootstrap"
    color="primary"
    type-bold="true"
    v-bind="$attrs"
    :value="modelValue"
    @input="$emit('update:modelValue', $event)"
    :emit-on-mount="emitOnMount"
  />
</template>
<script>
import VueSwitches from "vue-switches";

export default {
  model: { prop: "modelValue", event: "update:modelValue" },
  props: ["modelValue", "emitOnMount"],
  components: { VueSwitches },
};
</script>
