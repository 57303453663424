import { appConfigGet } from "../../store/app-config";
import { lang } from "../../../../i18n";

/**
 * EducationModuleSalesInfo Get store resource factory provider.
 */
export function educationModuleSalesInfoGetProvider(config: {
  lang: Pick<typeof lang, "get" | "has">;
  appConfigGet: typeof appConfigGet;
}) {
  return function educationModuleSalesInfoGet(): Promise<{ data: string }> {
    return appConfigGet().then(appConfig => {
      const platform = appConfig.data.platform.name;

      if (!appConfig.data.module?.education?.supportedForPlatform) {
        return {
          data: lang.get("educations.salesInfo.platformUnsupported", {
            platformName: lang.get(`general.platform.${platform}.name`),
          }),
        };
      }

      return {
        data: lang.has(`educations.salesInfo.platformSpecific.${platform}`)
          ? lang.get(`educations.salesInfo.platformSpecific.${platform}`)
          : lang.get("educations.salesInfo.platformGeneric", {
              contactEmail: appConfig.data.company.contact.email,
            }),
      };
    });
  };
}

/**
 * EducationModuleSalesInfo Get store resource factory.
 */
export const educationModuleSalesInfoGet = educationModuleSalesInfoGetProvider({
  lang,
  appConfigGet,
});
