<template>
  <div>
    <div
      v-for="(channel, index) in list"
      :key="index"
      class="channel-row d-flex justify-content-between align-items-center"
      @click="openConversation(channel)"
      :title="trans('chat.' + chatTitleOnHover(channel))"
    >
      <channel-icon :channel="channel" />
      <div
        class="channel-row-text ml-3 mr-auto"
        :class="{ 'inactive-group': isInactive(channel) }"
      >
        <div class="font-weight-normal">{{ channel.name }}</div>
        <channel-participants-list
          :channel="channel"
        ></channel-participants-list>

        <small v-if="channel.last_change_at"
          >{{ trans("chat.lastUpdated") }}: {{ channel.last_change_at }}</small
        >
      </div>
      <chat-message-bubble
        :numberOfUnreadMessages="channel.unread_count"
        :hasNotification="channel.has_notification"
        :channelType="channel.type"
      />
    </div>
  </div>
</template>

<script>
import ChannelIcon from "./Utils/ChannelIcon.vue";
import ChannelParticipantsList from "./Utils/ChannelParticipantsList.vue";
import ChatMessageBubble from "./Utils/ChatMessageBubble.vue";

export default {
  components: { ChannelIcon, ChatMessageBubble, ChannelParticipantsList },
  props: {
    list: Array,
  },
  methods: {
    openConversation: function(chat) {
      if (chat.type) {
        this.$emit("openConversation", chat.id);
      } else {
        this.$emit("activatePredefinedGroup", chat);
      }
    },
    isInactive: function(chat) {
      return !chat.type;
    },
    chatTitleOnHover: function(chat) {
      if (!chat.type) {
        return "inactiveCenterGroupTitle";
      } else if (chat.type === "predefined") {
        return "activeCenterGroupTitle";
      } else if (
        chat.type === "manual" &&
        chat.participants &&
        chat.participants.length > 1
      ) {
        return "privateGroupChannelTitle";
      } else if (
        chat.type === "manual" &&
        chat.participants &&
        chat.participants.length === 1
      ) {
        return "privateChannelTitle";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inactive-group {
  color: #aaaaaa;
}
</style>
