<template>
  <ResearchAdminCasesList
    :key="refreshKey"
    @action:sendInvites="onSendInvites"
  />
</template>
<script setup lang="ts">
import Vue, { computed, ref, watch } from "vue";
import { ROUTES, useRouteOf } from "../routing/routes";
import ResearchAdminCasesList from "../../components/Research/ResearchAdmin/ResearchAdminCasesList.vue";
import { studySendInvites } from "../../store/core/studies";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { lang } from "../../i18n";
import { v4 as uuidV4 } from "uuid";

const route = useRouteOf<typeof ROUTES["researchAdmin.studyCases"]>();
/**
 * @todo Remove force re-render once old component uses best-practices on data loading!
 */
const studyId = ref<string>();
watch(
  () => route.params.studyId,
  value => (studyId.value = value),
  { immediate: true }
);

/**
 * @todo Remove force re-render once we can update the store resources that are updated.
 */
const refreshUuid = ref<string>(uuidV4());
const refreshKey = computed(() => `${studyId.value}-${refreshUuid.value}`);

function onSendInvites(studyId: number) {
  studySendInvites(studyId)
    .then(() => {
      refreshUuid.value = uuidV4();
      Vue.notify({
        text: lang.get("action.study.sendInvites.success"),
        type: "success",
        duration: 1000,
      });
    })
    .catch(userErrorMessage);
}
</script>
