<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel panel-default panel-fill">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("users.historicalHeader") }}
          </h3>
        </div>
        <div class="panel-body">
          <div class="timeline">
            <article
              v-for="(timelineRow, index) in timeline.data"
              :key="index"
              class="timeline-item"
              :class="{ alt: index % 2 == 0 }"
            >
              <router-link
                v-if="timelineRow.uri"
                :to="timelineRow.uri"
                class="timeline-desk"
              >
                <div class="panel">
                  <div class="timeline-box">
                    <span class="arrow-alt"></span>
                    <span class="timeline-icon"
                      ><i class="mdi mdi-checkbox-blank-circle-outline"></i>
                    </span>
                    <h4>{{ timelineRow.title }}</h4>
                    <p class="timeline-date">
                      <small>{{ timelineRow.date }}</small>
                    </p>
                    <p>{{ stripHTML(timelineRow.message) }}</p>
                  </div>
                </div>
              </router-link>
            </article>

            <article
              class="timeline-item alt"
              v-if="
                timeline.data &&
                  timeline.data.length > 0 &&
                  timeline.paginate.next_page_url
              "
            >
              <div class="text-right">
                <div class="time-show">
                  <a @click="getMoreNotifications" class="btn btn-custom">{{
                    trans("users.showMoreNotificationButton")
                  }}</a>
                </div>
              </div>
            </article>
            <p
              v-else-if="
                timeline.data &&
                  timeline.data.length === 0 &&
                  !timeline.paginate.next_page_url
              "
              class="text-muted text-center"
            >
              {{ trans("users.noTimelineEvents") }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    timeline: {},
  },
  methods: {
    getMoreNotifications: function() {
      this.$emit("getMoreNotifications");
    },
    stripHTML: function(string) {
      return string.replace(/<\/?[^>]+>/gi, " ");
    },
  },
};
</script>
