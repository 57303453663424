<template>
  <field-panel
    :title="title"
    :actionsMenu="
      viewOption === 'patient_examination'
        ? {
            onAction: this.onAction,
            examinationActions: this.examinationActions,
            product: this.product,
            loading: this.loading,
            errorUpdatingActions: this.errorUpdatingActions,
            caseClosedAt: this.caseClosedAt,
            viewOption: this.viewOption,
          }
        : undefined
    "
    :infoPopupText="
      !product.meta.name.toLowerCase().includes('tumour')
        ? getInfoPopupText()
        : ''
    "
  >
    <edit-examination
      v-if="editExamination"
      :visible="editExamination"
      :loading="loading"
      @on-cancel="onCancelEditExamination"
      @on-save="onSaveEditExamination"
      :examination="examination"
      :productDefinition="product"
      editType="case_data"
    ></edit-examination>

    <div class="panel-body">
      <div v-if="examinationData.length > 0" class="row">
        <column v-for="(field, index) in examinationData" :key="index">
          <field
            :examinationId="examination.id"
            :title="field.title"
            :data="field.data"
            :type="field.type"
            :extraOptions="field.extraOptions"
          />
        </column>
      </div>
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </field-panel>
</template>

<script>
import Column from "./components/Column.vue";
import FieldPanel from "./components/FieldPanel.vue";
import Notifications from "../../.././Utils/notifications";
import EditExamination from "../../../Examinations/Examination/ExaminationComponents/components/EditExamination.vue";
import CustomPrintViewDialog from "../../Examination/ExaminationComponents/components/PrintCaseView.vue";

import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import {
  PRODUCT_ACTION_NAMES,
  STORE_CONSTANTS,
} from "../../../../helpers/definitions";
import { URLS } from "../../../../config";
import { Api } from "../../../../helpers";
import { infoPopupText } from "../../../../helpers/misc";
import { examinationCloseConfirm } from "../../examination-close-confirm";
import VueJsDialogMixinWrapper from "../../../../mixins/VueJsDialogMixinWrapper.vue";
import { inject } from "vue";
import _isString from "lodash/fp/isString";

export default {
  setup() {
    return {
      showPatientLevelReports: inject("showPatientLevelReports", false),
    };
  },
  mounted: function() {
    this.mapData();
  },
  props: {
    fields: Array,
    examination: {
      id: Number,
      case_data: Object,
    },
    product: Object,
    viewOption: String,
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      examinationActions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
      },
      loading: false,
      editExamination: false,
      errorUpdatingActions: false,
      caseClosedAt: null,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  computed: {
    title() {
      /**
       * @todo Push this "title" into the product definition + check how we should localise it!
       *    Current done this way as this is done in several places right now, and all will
       *    need to be updated once we push this into the product definition.
       */
      const productName = this.product?.meta?.name;
      return _isString(productName) && /^moleuk$/i.test(productName)
        ? "examination.consultationHeadlineUk"
        : "examination.consultationHeadline";
    },
  },
  watch: {
    examination() {
      this.mapData();
    },
  },
  methods: {
    mapData: function() {
      this.setExaminationActions();

      const { fields, examination } = this;

      // flag case being closed
      this.caseClosedAt = this.examination
        ? this.examination.caseCloseAt
        : null;

      const examinationData = [];
      fields.forEach(field => {
        const hasValue = field.name in examination.case_data;

        /**
         * TODO: This config override should be moved to the backend field definitions.
         * @see {@link https://gnosco.atlassian.net/browse/DER-2682}
         */
        const isWoundMeasurementField = [
          "ulcer_length",
          "ulcer_width",
          "ulcer_area",
        ].includes(field.name);

        if (field.visible) {
          const valueOptions = JSON.parse(
            JSON.stringify(JSON.parse(field.value_options))
          );

          examinationData.push({
            title: field.name,
            data: hasValue ? examination.case_data[field.name] : "-",
            type:
              isWoundMeasurementField &&
              examination.images.wound_measurement !== undefined &&
              examination.woundMeasurementHasBeenUpdatedManually === false
                ? FIELD_CONSTANTS.FIELD_TYPE_WOUND_MEASUREMENT_TEXT_WITH_LABEL
                : getFieldType(field),
            disabled: field.disabled,
            extraOptions: {
              valueOptions: valueOptions,
              examination: examination.case_data,
            },
          });
        }
      });
      this.examinationData = examinationData;
    },
    setExaminationActions: function() {
      this.examinationActions.isPrioritized = this.examination.isPrioritized;
      this.examinationActions.isFlagged = this.examination.isFlagged;
      this.examinationActions.isBookmarked = this.examination.isBookmarked;
    },
    onAction: function(action) {
      if (action.submitable) this.updateCaseActions(action);
      else {
        switch (action.name) {
          case PRODUCT_ACTION_NAMES.EDIT_EXAMINATION:
            this.editExamination = true;
            break;
          case PRODUCT_ACTION_NAMES.PRINT_CASE:
            this.printCase();
            break;
          case PRODUCT_ACTION_NAMES.CLOSE_CASE:
            this.closeCase();
            break;
          case PRODUCT_ACTION_NAMES.USE_UCR:
            this.submitToUcr();
            break;
        }
      }
    },
    submitToUcr: async function() {
      this.loading = true;
      try {
        const response = await axios.get(
          `${URLS.TUMOUR_WOUND_SUBMIT_UCR}/${this.examination.id}`
        );
        if (response.data.success)
          Notifications.saveSuccessCustomText(response.data.message);
        else Notifications.errorCustomText(response.data.message);
      } catch (error) {
        Notifications.error();
      }
      this.loading = false;
    },
    closeCase: async function() {
      try {
        await examinationCloseConfirm(this.$dialog, this.examination);
        await axios.get(
          `${URLS.TUMOUR_WOUND_CLOSE_CASE}/${this.examination.id}`
        );
        this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);
      } catch (e) {
        return;
      }
    },
    updateCaseActions: async function(action) {
      // update local action values
      this.examinationActions[action.key] = this.examinationActions[action.key]
        ? false
        : true;

      this.loading = true;
      this.errorUpdatingActions = false;

      axios
        .post(
          `${URLS.TUMOUR_WOUND_SAVE_ACTION}/${this.examination.id}`,
          this.examinationActions
        )
        .then(() => {
          this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);
        })
        .then(() => {
          Notifications.saveSuccess();
          this.loading = false;
        })
        .catch(error => {
          this.errorUpdatingActions = true;
          this.setExaminationActions();
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    printCase: function() {
      this.showPrintDialogWithOptions = {
        data: {
          uri: `${URLS.EXAMINATIONS}/${this.examination.id}/print`,
          caseNo: this.examination.caseNo,
          productDefinition: this.product,
          showPatientLevelReports: this.showPatientLevelReports,
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
    onCancelEditExamination: function() {
      this.editExamination = false;
    },
    onSaveEditExamination: async function(values) {
      try {
        this.loading = true;
        await Api.post(
          `${URLS.TUMOUR_WOUND_SAVE_EXAMINATION}/${this.examination.id}`,
          values,
          this
        );
        await this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);

        this.loading = false;
        this.editExamination = false;
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
      } catch (error) {
        this.loading = false;
        this.editExamination = true;
        console.log("Save edited examination values error: ", error);
      }
    },
    getInfoPopupText: function() {
      return this.examinationData.length > 0
        ? infoPopupText(this.examinationData)
        : "";
    },
  },
  components: {
    VueJsDialogMixinWrapper,
    Column,
    FieldPanel,
    EditExamination,
  },
};
</script>
