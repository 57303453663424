<template>
  <form novalidate class="panel panel-default" @submit.prevent="onSubmit">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ lang.get("model.center.reportOptions.label") }}
      </h3>
    </div>
    <AsyncState class="panel-body" :data-state="reportOptionsGet">
      <input name="center_id" type="hidden" v-model="reportOptions.center_id" />
      <div class="form-group" :class="{ 'has-error': !!formErrors.header }">
        <label :for="headerInputId">
          {{ lang.get("model.centerReportOptions.header.label") }}
        </label>
        <textarea
          name="header"
          :id="headerInputId"
          class="form-control"
          v-model="reportOptions.header"
        />
        <form-error v-if="!!formErrors.header" :errors="formErrors.header" />
      </div>
      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ lang.get("general.formSaveLabel") }}
        </button>
      </div>
    </AsyncState>
  </form>
</template>
<script setup lang="ts">
import Vue, { computed, shallowRef, toRefs, watchEffect } from "vue";
import { useAsyncState } from "@vueuse/core";
import AsyncState from "../design-system/AsyncState.vue";
import { lang } from "../i18n";
import { v4 as uuidV4 } from "uuid";
import { zfd } from "zod-form-data";
import _cloneDeep from "lodash/fp/cloneDeep";
import { userErrorMessage } from "../mixins/UserErrorMessage.vue";
import {
  centerReportOptionsGet,
  centerReportOptionsUpdate,
} from "../store/core/center-settings";
import { z } from "zod";

const FORM_SCHEMA = zfd.formData({
  center_id: zfd.numeric(),
  header: zfd.text(
    z
      .string()
      .optional()
      .nullable()
      .default(null)
  ),
});
const formErrors = shallowRef({});
const headerInputId = uuidV4();
const props = defineProps<{ dataCenterId: number }>();
const { dataCenterId } = toRefs(props);
const reportOptionsGet = useAsyncState(centerReportOptionsGet, undefined, {
  immediate: false,
});
watchEffect(() => reportOptionsGet.execute(0, dataCenterId.value));
const reportOptions = computed(() =>
  _cloneDeep(reportOptionsGet.state.value ?? {})
);

function onSubmit({ target }: SubmitEvent) {
  formErrors.value = {};
  centerReportOptionsUpdate(
    FORM_SCHEMA.parse(new FormData(target as HTMLFormElement))
  )
    .then(() => {
      Vue.notify({
        text: lang.get("general.notificationSaved"),
        type: "success",
        duration: 1000,
      });
    })
    .catch(error => {
      userErrorMessage(error);
      if (error?.response?.status === 422) {
        formErrors.value = error.response.data;
      }
    });
}
</script>
