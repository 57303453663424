<template>
  <div
    class="modal fade"
    id="infoModal"
    tabindex="-1"
    aria-labelledby="infoModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body text-center">
          <i :class="infoIcon()"></i>
          <div class="mt-4 mb-3">
            <h5>{{ infoHeaderText }}</h5>
            <p v-if="infoText">
              {{ infoText }}
            </p>
            <div
              v-if="infoHTMLContent"
              v-dompurify-html="infoHTMLContent"
              class="modal-html-content"
            />
          </div>
        </div>

        <div class="text-center">
          <button
            type="button"
            class="btn btn-primary px-5 mb-3"
            data-dismiss="modal"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted: function() {
    $("#infoModal").modal("show");
    this.triggerHidden();
  },
  props: {
    infoHeaderText: String,
    infoText: String,
    infoType: String,
    infoHTMLContent: String,
  },
  methods: {
    triggerHidden: function() {
      var self = this;
      if ($("#infoModal").length) {
        $("#infoModal").on("hidden.bs.modal", function() {
          // catch the native bootstrap close event and trigger action
          self.$emit("closeInfoModal");
        });
      }
    },
    infoIcon: function() {
      switch (this.infoType) {
        case "warning":
          return "fal fa-exclamation-triangle warning";
        case "error":
          return "fal fa-times-circle error";
        default:
          return "fal fa-check-circle info";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.fal {
  font-size: 4rem;

  &.info {
    color: variables.$dermicusBlue;
  }

  &.warning {
    color: #e2ab3b;
  }

  &.error {
    color: #b92844;
  }
}

.modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0px;
  margin: 0px;
}

.modal-header .close {
  margin: -1rem -1rem -1rem auto;
}

.modal .modal-dialog .modal-content .modal-body {
  padding: 0px;
}
</style>

<style lang="scss">
.modal-html-content {
  text-align: left;
  h1 {
    font-size: 1.2rem;
  }

  p {
    font-weight: 300;
    line-height: 1.6;
  }
}
</style>
