<template>
  <div class="skeleton-loader">
    <div class="content"></div>
  </div>
</template>

<script>
export default {
  name: "SkeletonLoader",
};
</script>

<style lang="scss">
.skeleton-loader {
  background-color: #eeeeee;
  position: relative;
  overflow: hidden;
  width: 100%;

  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(#fff, 0) 0,
    rgba(#fff, 0.2) 20%,
    rgba(#fff, 0.5) 60%,
    rgba(#fff, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}
</style>
