<template>
  <div>
    <div id="mole" class="col-lg-12" v-if="examination">
      <ul class="nav nav-tabs nav tabs-bordered">
        <li>
          <router-link to="/educations">
            <span class="visible-xs">
              <i class="fal fa-arrow-left"></i>
            </span>
            <span class="hidden-xs">{{ trans("general.back") }}</span>
          </router-link>
        </li>
        <li class="active">
          <a href="#home" data-toggle="tab" aria-expanded="false">
            <span>{{ trans("examination.tabLabelConsultation") }}</span>
          </a>
        </li>
      </ul>

      <div class="tab-content">
        <div class="tab-pane active" id="home">
          <div class="row">
            <div class="col-md-6">
              <div class="col-md-12">
                <education-case-general-info
                  :education="education"
                  :examination="examination"
                ></education-case-general-info>
              </div>

              <div class="col-md-12">
                <consultation-images
                  :caseId="examination.id"
                  :images="images"
                  @update:images="images = $event"
                  :product="PRODUCTS.MOLE_EDUCATIONS"
                ></consultation-images>
              </div>
            </div>

            <div class="col-md-6 consultation-box">
              <education-case-consultation-info
                :examination="examination"
              ></education-case-consultation-info>
            </div>
          </div>

          <div class="row diagnos-reply">
            <div class="col-md-6">
              <div class="col-md-12 px-0">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-underline">
                    <h3 class="panel-title">
                      {{ trans("educations.educationDiagnosSuggestions") }}
                    </h3>
                  </div>
                  <div class="panel-body">
                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("educations.educationTextDescription")
                      }}</label>
                      <textarea
                        rows="7"
                        v-model="educationResponse.text"
                      ></textarea>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>
                        {{
                          trans("examination.diagnosisCodeDermatoskopiskLabel")
                        }}
                      </label>
                      <select2
                        name="dermatoscopic"
                        :options="dermatoscopicDiagnoses"
                        :model-value="
                          educationResponse.dermatoscopic_diagnosis_id
                        "
                        v-model="educationResponse.dermatoscopic"
                      ></select2>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("examination.diagnosiskPlanedHeadline")
                      }}</label>
                      <select2
                        name="code_planed"
                        :options="plannedDiagnoses"
                        :model-value="educationResponse.planned_diagnosis_id"
                        v-model="educationResponse.code_planed"
                      ></select2>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6 mb-5" v-if="!showResult">
              <button
                class="btn btn-primary"
                @click="saveShowEducationResults()"
              >
                {{ trans("educations.educationButtonSaveCorrect") }}
              </button>
            </div>

            <div class="col-md-6" v-if="showResult">
              <div class="col-md-12 px-0">
                <div class="panel panel-default">
                  <div class="panel-heading panel-heading-underline">
                    <h3 class="panel-title">
                      {{ trans("educations.educationDiagnosiskHeadline") }}
                      <span class="pull-right">{{
                        examination.diagnosisUpdatedAt
                      }}</span>
                    </h3>
                  </div>
                  <div class="panel-body">
                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("educations.dermatologistReplyLabel")
                      }}</label>
                      <textarea
                        rows="7"
                        v-if="lastConsultResponse"
                        v-dompurify-html="lastConsultResponse.comment"
                        disabled
                      ></textarea>
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>
                        {{
                          trans("examination.diagnosisCodeDermatoskopiskLabel")
                        }}
                      </label>
                      {{ examination.case.dermatoscopicDiagnosis }}
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>{{
                        trans("examination.diagnosiskPlanedHeadline")
                      }}</label>
                      {{ examination.case.plannedDiagnosis }}
                    </div>

                    <div class="m-b-20 col-md-12">
                      <label>
                        {{
                          trans("examination.diagnosiscodeHistopatologiskLabel")
                        }}
                      </label>
                      {{ examination.case.histopathologicalDiagnosis }}
                    </div>

                    <button class="btn btn-danger" @click="showResult = false">
                      {{ trans("educations.educationButtonHideCorrect") }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- / .tab-content-->
    </div>
  </div>
</template>

<script>
import EducationCaseGeneralInfo from "./EducationCaseGeneralInfo.vue";
import ConsultationImages from "../../Examinations/AddOns/ConsultationImages.vue";
import EducationCaseConsultationInfo from "./EducationCaseConsultationInfo.vue";
import { PRODUCTS } from "../../../helpers/definitions";

export default {
  mounted: function() {
    this.loadData();
  },
  data() {
    return {
      loading: true,
      education: null,
      examination: null,
      lastConsultResponse: "",
      user: {},
      backButtonUri: "/",
      showResult: false,
      educationResponse: {
        id: null,
        text: "",
        dermatoscopic_diagnosis_id: "",
        planned_diagnosis_id: "",
      },
      dermatoscopicDiagnoses: {},
      plannedDiagnoses: {},
      histopathologicalDiagnoses: {},
      tags: {},
      images: {},
      PRODUCTS: PRODUCTS,
    };
  },
  methods: {
    loadData: function() {
      this.loading = true;
      this.user = this.$store.state.user;

      this.getDiagnosisCodes();

      this.getEducationMoleCase();

      this.$nextTick(() => {
        this.getEducationAnswer();
      });

      localStorage.setItem(
        "last_visited_examination_id",
        this.$route.params.id
      );
    },
    saveShowEducationResults: function() {
      axios
        .post(
          "/api/internal/educations/moles/" +
            this.$route.params.id +
            "/response" +
            (this.educationResponse.id ? "/" + this.educationResponse.id : ""),
          this.educationResponse
        )
        .then(data => {
          this.showResult = true;
          this.loadData();
        })
        .catch(error => {
          console.log("save show education results error: ", error);
        });
    },
    getDiagnosisCodes: function() {
      axios
        .get("/api/internal/search/filter-lists/1")
        .then(data => {
          let collection = data.data;

          this.dermatoscopicDiagnoses = collection.dermatoscopicDiagnoses;
          this.plannedDiagnoses = collection.plannedDiagnoses;
          this.histopathologicalDiagnoses =
            collection.histopathologicalDiagnoses;
          this.tags = collection.tags;
        })
        .catch(error => {
          console.log("Get diagnosis codes error: ", error);
        });
    },
    getEducationMoleCase: function() {
      axios
        .get("/api/internal/educations/moles/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.education = collection.education;
          this.examination = collection.examination;
          this.images = collection.examination.images;
          this.lastConsultResponse = collection.lastConsultResponse;
        })
        .catch(error => {
          console.log("getEducationMoleCase error: ", error);
        });
    },
    getEducationAnswer: function() {
      axios
        .get(
          "/api/internal/educations/moles/" +
            this.$route.params.id +
            "/response"
        )
        .then(data => {
          this.educationResponse = data.data;
        })
        .catch(error => {
          console.log("No education answer error");
        });
    },
  },
  components: {
    EducationCaseGeneralInfo,
    ConsultationImages,
    EducationCaseConsultationInfo,
  },
};
</script>
