<template>
  <vue-select
    :input-id="id"
    :options="dataOptions"
    :get-option-key="dataGetOptionValue"
    :get-option-label="dataGetOptionLabel"
    :placeholder="dataPlaceholder"
    append-to-body
    :calculate-position="calculatePositionWithPopper"
    :reduce="dataGetOptionValue"
    :value="value"
    :disabled="disabled"
    :clearable="clearable"
    @input="onInput"
  />
</template>
<script>
import VueSelect from "vue-select";
import { calculatePositionWithPopper } from "../../helpers/popper";

export default {
  components: { VueSelect },
  props: {
    /**
     * `string | undefined`
     */
    id: { type: String },
    /**
     * `string | number | undefined`
     */
    value: { required: true },
    /**
     * `boolean | undefined`
     */
    disabled: { type: Boolean },
    /**
     * `boolean | undefined`
     */
    clearable: { type: Boolean, default: true },
    /**
     * `T[]`
     */
    dataOptions: { type: Array, required: true },
    /**
     * `(option: T) => string | number | undefined`
     */
    dataGetOptionValue: { type: Function, required: true },
    /**
     * `(option: T) => string`
     */
    dataGetOptionLabel: { type: Function, required: true },
    /**
     * `string | undefined`
     */
    dataPlaceholder: { type: String },
  },
  methods: {
    calculatePositionWithPopper,
    onInput($event) {
      /**
       * `string | number | undefined`
       */
      this.$emit("input", $event);
    },
  },
};
</script>
