<template>
  <datepicker
    :name="name ?? 'datepicker'"
    :format="customDateFormatter"
    :wrapper-class="'form-control'"
    @input="onDateChange($event)"
    :value="date"
    :placeholder="placeholder"
    :disabled-dates="{
      from: new Date(),
    }"
    :clear-button="true"
    :clear-button-icon="'fa fa-times'"
  ></datepicker>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { shortDateFormat } from "../../helpers/platform";

export default {
  props: {
    name: String,
    placeholder: String,
    date: String,
  },
  methods: {
    customDateFormatter: function(date) {
      return moment(date).format(shortDateFormat());
    },
    onDateChange: function(date) {
      const dateInSaveFormat = date ? date.yyyymmdd() : "";
      this.$emit("onDateChange", dateInSaveFormat);
    },
  },
  components: {
    Datepicker,
  },
};
</script>
