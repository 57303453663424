<template>
  <div>
    <div class="position-relative">
      <i class="fas fa-comment-alt" :data-count="allMessages"></i>

      <span
        v-if="unreadMessages > 0"
        class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
        >&nbsp;</span
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["allMessages", "unreadMessages"],
};
</script>

<style lang="scss" scoped>
.badge.rounded-pill {
  top: -0.45rem;
}

.fas {
  font-size: 1.5rem;
}

.fas.fa-comment-alt[data-count]:after {
  position: absolute;
  content: attr(data-count);
  font-size: 50%;
  transform: translate(-16px, 4px);
  color: white;
  text-align: center;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: 500;
}

.fa-comment-alt {
  color: #676a6c;
}

.badge {
  height: 12px;
  width: 12px;
  left: 19px;
  background-color: #b92844;
}
</style>
