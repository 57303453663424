<template>
  <div class="login-window">
    <div class="row">
      <div class="col-sm-12">
        <div class="bg-text">
          <div class="my-3">
            <div class="text-center logo">
              <span>
                <a href="/" target="_self" title="Back to Dermicus">
                  <img src="/images/dermicus-logo.svg" alt="" height="50" />
                </a>
              </span>
            </div>

            <div class="text-center mt-4 mb-5">
              <h6 class="mb-3">
                {{ headerText }}
              </h6>
              <p>
                {{ bodyText }}
              </p>
              <router-link to="/" class="btn btn-primary">{{
                buttonText
              }}</router-link>
            </div>
          </div>

          <div class="information">
            <div v-if="platform === 'stockholm'">
              {{ trans("stockholmPlatform.stockholmSupportMessage") }}
            </div>
            <div
              v-else-if="appConfig"
              class="d-flex justify-content-center flex-wrap"
            >
              <div class="m-3">
                <i class="fas fa-phone fa-1x pr-1"></i>
                <span>{{ appConfig.company.support.phone }}</span>
              </div>

              <div class="m-3">
                <i class="fas fa-envelope fa-1x pr-1"></i>
                <span>
                  <a :href="`mailto:${appConfig.company.support.email}`">
                    {{ appConfig.company.support.email }}
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { appConfigGet } from "../../app/practitioner-portal/store/app-config";

const { state: appConfig } = useAsyncState(
  appConfigGet().then(response => response.data),
  undefined
);
</script>
<script>
export default {
  props: {
    headerText: String,
    bodyText: String,
    buttonText: String,
  },
  data: function() {
    return {
      platform: this.$store.state.dermicusPlatform,
    };
  },
};
</script>

<style lang="scss" scoped>
.login-window {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
  .login-window {
    width: 90%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .login-window {
    width: 40%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .login-window {
    width: 35%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .login-window {
    width: 30%;
  }
}

.information {
  font-size: 12px;
}
</style>
