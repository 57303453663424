<template>
  <div>
    <Table
      :headings="[
        { title: lang.get('model.studyConsent.status.label') },
        { title: lang.get('model.studyConsent.study.label') },
        { title: '' },
      ]"
    >
      <tr v-for="study in studies" :key="study.id">
        <td>
          <Badge :dataVariant="study.isConnected ? 'success' : 'danger'">{{
            study.isConnected
              ? lang.get("model.studyConsentStatus.connected.display")
              : lang.get("model.studyConsentStatus.disconnected.display")
          }}</Badge>
        </td>
        <td>{{ study.name }}</td>
        <td class="tw-text-right tw-w-[200px]">
          <Button
            @click="handleConnect(study.id, study.isConnected)"
            :isLoading="currentLoadingConnection === study.id"
          >
            {{
              study.isConnected
                ? lang.get("action.study.adminRemove.label")
                : lang.get("action.study.adminAdd.label")
            }}
          </Button>
        </td>
      </tr>
    </Table>
  </div>
</template>

<script setup lang="ts">
import { StudySummary } from "../../../../../../../app/Dermicus/Studies/StudySummary";
import { lang } from "../../../../i18n";
import Badge from "../../../Layout/Badge.vue";
import Button from "../../../Layout/Button.vue";
import Table from "../../../Layout/Table.vue";

defineProps<{
  studies: Array<StudySummary & { isConnected: boolean }>;
  currentLoadingConnection?: number;
}>();

const emit = defineEmits<{
  (event: "connectStudy", studyId: number, isConnected: boolean): void;
}>();

function handleConnect(studyId: number, isConnected: boolean) {
  emit("connectStudy", studyId, isConnected);
}
</script>
