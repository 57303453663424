import { appConfigGet } from "./app-config";
import { lang } from "../../../i18n";

/**
 * AppSupportFooter Get store resource factory provider.
 */
export function appSupportFooterGetProvider(config: {
  lang: Pick<typeof lang, "get">;
  appConfigGet: typeof appConfigGet;
}) {
  return function appSupportFooterGet(): Promise<{ data: string }> {
    return appConfigGet().then(appConfig => ({
      data: appConfig.data.supportByPlatform
        ? lang.get("support.byPlatform.footerHtml", {
            appVersion: appConfig.data.version,
          })
        : lang.get("support.byDermicus.footerHtml", {
            supportEmail: appConfig.data.company.support.email,
            supportPhone: appConfig.data.company.support.phone,
            appVersion: appConfig.data.version,
          }),
    }));
  };
}

/**
 * AppSupportFooter Get store resource factory.
 */
export const appSupportFooterGet = appSupportFooterGetProvider({
  lang,
  appConfigGet,
});
