<template>
  <div class="d-flex justify-content-between align-items-center space-x-2 py-3">
    <div class="flex-fill">
      <v-select
        label="name"
        multiple
        name="centerPicker"
        :options="availableCentersWithAll"
        :value="selectedCenters"
        :reduce="selectedCenter => selectedCenter.id"
        @input="updateSelectedCenter($event)"
      ></v-select>
    </div>

    <time-pickers-menu
      class="flex-fill"
      :loading="loading"
      @updateDate="updateDate"
      ref="statisticsTimePickers"
    ></time-pickers-menu>

    <div class="space-x-2">
      <button
        class="btn btn-danger"
        type="button"
        @click="clearSelectedCenters"
      >
        <span>{{ trans("general.clearSearch") }}</span>
        <i class="fal fa-times"></i>
      </button>
      <button
        class="btn btn-primary"
        :disabled="selectedCenters.length === 0"
        @click="getData"
      >
        {{ trans("statistics.seeDataButtonText") }}
      </button>
    </div>
  </div>
</template>

<script>
import TimePickersMenu from "./TimePicker.vue";

export default {
  components: {
    TimePickersMenu,
  },
  props: {
    availableCenters: Array,
    loading: Boolean,
  },
  watch: {
    availableCenters: function() {
      const optionAllCenters = [
        JSON.parse(
          JSON.stringify({
            name: this.trans("statistics.allCenters"),
            id: "all",
          })
        ),
      ];

      this.availableCentersWithAll = optionAllCenters.concat(
        this.availableCenters
      );
    },
  },
  data: function() {
    return {
      availableCentersWithAll: [],
      selectedCenters: [this.$store.state.user.primaryCenter],
      dateFrom: "",
      dateTo: "",
    };
  },
  methods: {
    getData: function() {
      this.$emit("getData", this.selectedCenters, this.dateFrom, this.dateTo);
      this.$emit(
        "getPatientsStatistics",
        this.selectedCenters,
        this.dateFrom,
        this.dateTo
      );
    },
    updateSelectedCenter: function(centers) {
      if (centers.includes("all")) {
        const allCentersIds = this.availableCenters.map(center => center.id);
        this.selectedCenters = allCentersIds;
      } else {
        this.selectedCenters = centers.filter(c => c !== "all");
      }
    },
    clearSelectedCenters: function() {
      this.selectedCenters = [];
      this.$refs.statisticsTimePickers.clearDate();
      this.getData();
    },
    updateDate: function(from, to) {
      this.dateFrom = from;
      this.dateTo = to;
    },
  },
};
</script>
