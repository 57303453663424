<template>
  <div>
    <chart-header headerName="averageResponseTime"></chart-header>

    <div>
      <clip-loader v-if="loading"></clip-loader>
      <h1
        class="p-3"
        v-if="!loading && responseTimes && responseTimes.avgResponseTime"
      >
        <span v-if="formatTime(responseTimes.avgResponseTime).days"
          >{{ formatTime(responseTimes.avgResponseTime).days }}
          {{ trans("statistics.days") }}</span
        >
        <span v-if="formatTime(responseTimes.avgResponseTime).hours"
          >{{ formatTime(responseTimes.avgResponseTime).hours }}
          {{ trans("statistics.hours") }}</span
        >
      </h1>
      <h1
        class="text-center"
        v-if="!loading && responseTimes && !responseTimes.avgResponseTime"
      >
        <span v-if="responseTimes.numberOfExaminations > 0">{{
          responseTimes.avgResponseTime
        }}</span>
        <span v-else>-</span>
      </h1>
    </div>
  </div>
</template>

<script>
import ChartHeader from "./StatisticsUtils/ChartHeader.vue";
export default {
  components: {
    ChartHeader,
  },
  methods: {
    formatTime: function(responseTime) {
      if (responseTime < 48) {
        // show hours
        return { hours: Math.ceil(responseTime) };
      } else {
        //show days
        const days = Math.ceil(moment.duration(responseTime, "hours").asDays());
        return { days };
      }
    },
  },
  props: {
    responseTimes: {
      avgResponseTime: Number,
      numberOfExaminations: Number,
    },
    loading: Boolean,
  },
};
</script>
