<template>
  <span class="float-right">
    <v-popover>
      <template v-slot:popover>
        <div v-dompurify-html="textContent"></div>
      </template>

      <i class="fal fa-info-circle popover-link" />
    </v-popover>
  </span>
</template>

<script>
export default {
  props: {
    textContent: String,
  },
};
</script>

<style lang="scss" scoped>
.popover-link {
  border: none;
}
</style>
