<template>
  <PatientPortalView :data-principal="principal" @logout="onLogout">
    <ErrorAlert v-if="!!error" :data-error="error" />
    <!-- ! ":key" is needed here to make sure we have new components when any of
    the route params/query changes, because we are not using any FE store to
    manage resources based on these params/query well, and thus we are unable
    to reload the data and keep the components without adding lots of management
    code, and thus introduce a higher risk of bugs. This will indeed hurt the
    performance due this constant re-creation of the component trees, and thus
    also trigger lots of API calls in the process that might not be needed. -->
    <router-view :key="$route.fullPath" />
  </PatientPortalView>
</template>
<script setup lang="ts">
import PatientPortalView from "./PatientPortalView.vue";
import { useRouter } from "vue-router/composables";
import _noop from "lodash/fp/noop";
import { useAsyncState } from "@vueuse/core";
import { patientPrincipalGet } from "./store/patient-principal";
import { locationOf, ROUTES } from "../routing/routes";
import ErrorAlert from "../../design-system/ErrorAlert.vue";

const router = useRouter();

const { state: principal, error } = useAsyncState(
  patientPrincipalGet(),
  undefined
);

function onLogout() {
  router.push(locationOf(ROUTES.patientLogout, {})).catch(_noop);
}
</script>
