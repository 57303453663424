<<template>
  <div>
    <strong v-if="location === 'general-info'">{{
      trans("examination.priorityLabel")
    }}</strong>

    <div class="text-muted">
      <i
        class="fas fa-exclamation-circle"
        :title="trans('general.casePrioritizedTitle')"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    location: String,
  },
};
</script>
