<template>
  <div>
    <header-admin :active-section="1" />

    <div
      class="col-lg-12 pt-2 pb-3 my-3"
      :class="!isActive && !loading ? 'gray-background' : ''"
    >
      <div v-if="!isActive && !loading" class="mt-2">
        <h4 class="float-left">This centre is inactive</h4>
      </div>
      <div class="text-right my-2" v-if="!loading">
        <button class="btn btn-secondary" @click="$router.go(-1)">
          {{ trans("general.back") }}
        </button>
        <button
          class="btn btn-primary ml-2"
          @click="saveData()"
          :disabled="!center.name || !center.country || loading || !isActive"
          :title="!isActive ? 'Centre is inactive' : ''"
        >
          {{
            center.id
              ? trans("general.formSaveLabel")
              : trans("center.addNewCenter")
          }}
        </button>
      </div>

      <clip-loader v-show="loading"></clip-loader>

      <admin-center-contact-info
        :center="center"
        :errors="errors"
        v-if="!loading"
      />

      <div
        class="panel panel-default panel-fill"
        v-if="!loading"
        style="position: relative;"
      >
        <div v-if="regionsLoading" class="overlay">
          <clip-loader></clip-loader>
        </div>
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("region.connectCentreToRegion") }}
          </h3>
        </div>
        <div class="panel-body col-md-12">
          <div class="mb-3" v-if="center.region_id && regionName">
            {{ trans("center.centerIsConnectedTo") }}
            <span class="font-weight-bold">{{ regionName }}</span
            >.
          </div>
          <label for="regions">
            {{
              center.region_id
                ? trans("center.connectToDifferentRegion")
                : trans("center.connectToDermicusRegion")
            }}:
          </label>
          <select2
            name="regions"
            v-model="selectedRegionId"
            :data-placeholder="trans('region.chooseDermicusRegion')"
          >
            <option value=""></option>
            <option
              v-for="(region, index) in regions"
              :key="index"
              :value="region.id"
              >{{ region.name }}</option
            >
          </select2>
        </div>
      </div>

      <div class="panel panel-default panel-fill" v-if="!loading">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("patient.patientLookupType") }}
          </h3>
        </div>
        <div class="panel-body">
          <select class="form-control" v-model="center.patient_lookup_type">
            <option :key="null" :value="null">
              {{ trans(`center.patientLookupTypeNull`) }}
            </option>
            <option
              v-for="patientLookupType in patientLookupTypes"
              :key="patientLookupType.id"
              :value="patientLookupType.id"
            >
              {{ patientLookupType.display }}
            </option>
          </select>
        </div>
      </div>

      <admin-center-products
        organisation="center"
        :products="products"
        v-if="!loading"
      />

      <admin-center-settings
        organisation="center"
        :settings="features"
        :center="center"
        v-if="!loading"
      />

      <AdminCenterConsentStudies
        v-if="!loading && center.id"
        :centerId="center.id"
      />

      <platform-attribute-editor
        v-if="!loading"
        :platform="platform"
        :platformAttributes="center.platform_attributes"
        :platformAttributeValues="center.platform_attribute_values"
      />

      <div class="text-right mb-4" v-if="!loading">
        <button class="btn btn-secondary w-md mx-2" @click="$router.go(-1)">
          {{ trans("general.back") }}
        </button>
        <button
          class="btn btn-primary w-md"
          @click="saveData()"
          :disabled="!center.name || !center.country || loading || !isActive"
          :title="!isActive ? 'Centre is inactive' : ''"
        >
          {{
            center.id
              ? trans("general.formSaveLabel")
              : trans("center.addNewCenter")
          }}
        </button>
      </div>

      <div class="panel panel-default panel-fill" v-if="!loading && center.id">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("center.deactivateReactivateCenterLabel") }}
          </h3>
        </div>
        <div class="panel-body col-md-12">
          <div class="col-md-12">
            <p class="mb-3">
              If the center is deactivated all primary center users will be
              blocked. Blocked users can no longer login. Patient cases are not
              affected by center being deactivated. If center is activated users
              are still blocked, until they are unblocked by the admin.
            </p>

            <button
              v-if="isActive"
              class="btn btn-danger"
              @click="deactivateCenter"
            >
              {{ trans("center.deactivateCenter") }}
            </button>
            <button
              v-if="!isActive"
              class="btn btn-primary"
              @click="reactivateCenter"
            >
              {{ trans("center.reactivateCenter") }}
            </button>
          </div>
        </div>
      </div>

      <div class="panel panel-default panel-fill" v-if="!loading && center.id">
        <div class="panel-heading">
          <h3 class="panel-title">{{ trans("center.primaryUsers") }}</h3>
        </div>
        <div class="panel-body table-responsive">
          <!--<a href="#addUser" data-toggle="modal" class="btn btn-primary   pull-right" v-if="center.name">Anslut ny användare</a>-->

          <table class="table table-hover interactive-table">
            <thead>
              <tr>
                <th>{{ trans("forms.hsaId") }}</th>
                <th>{{ trans("forms.email") }}</th>
                <th>{{ trans("forms.firstname") }}</th>
                <th>{{ trans("forms.lastname") }}</th>
                <th>{{ trans("roles.adminRolesHeader") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="openUser(user.id)"
                v-for="(user, index) in users.primaryCenters"
                :key="index"
              >
                <td>{{ user.hsa_id }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td>
                  <span
                    v-for="(role, index) in userAdminRoles(user)"
                    :key="index"
                    ><span v-if="role">{{ trans("roles." + role) }} / </span>
                  </span>
                </td>
                <td>
                  <span
                    v-if="user.blocked"
                    :title="trans('forms.userCannotLoginTitle')"
                    >({{ trans("users.blockedUser") }})</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="panel panel-default panel-fill" v-if="!loading && center.id">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("center.groupTableConnectedUsers") }}
          </h3>
        </div>
        <div class="panel-body table-responsive">
          <!--<a href="#addResource" data-toggle="modal" class="btn btn-primary   pull-right" v-if="center.name">Anslut resurs</a>-->

          <table class="table table-hover interactive-table">
            <thead>
              <tr>
                <th>{{ trans("forms.hsaId") }}</th>
                <th>{{ trans("forms.email") }}</th>
                <th>{{ trans("forms.firstname") }}</th>
                <th>{{ trans("forms.lastname") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                @click="openUser(user.id)"
                v-for="(user, index) in users.resource"
                :key="index"
              >
                <td>{{ user.hsa_id }}</td>
                <td>{{ user.email }}</td>
                <td>{{ user.firstname }}</td>
                <td>{{ user.lastname }}</td>
                <td>
                  <span
                    v-if="user.blocked"
                    :title="trans('forms.userCannotLoginTitle')"
                    >({{ trans("users.blockedUser") }})</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Add resource modal -->
      <div
        class="modal fade full-screen"
        id="addResource"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addResurs"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{ trans("examination.privateMessagesLabel") }}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="m-b-20 col-md-12">
                  <form>
                    <label for="FullName">Person</label>
                    <select2
                      class="select2 form-control responsive"
                      style="width: 100%"
                    >
                      <optgroup label="Välj resurs">
                        <option disabled></option>
                        <option
                          v-for="(user, index) in usersList"
                          :value="user.id"
                          :key="index"
                        >
                          {{ user.firstname }} {{ user.lastname }} ({{
                            user.email
                          }})
                        </option>
                      </optgroup>
                    </select2>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                :click="addResourceUser()"
              >
                {{ trans("general.formSaveLabel") }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Add user modal -->
      <div
        class="modal fade full-screen"
        id="addUser"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addResurs"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Anslut primära användare
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="m-b-20 col-md-12">
                  <form>
                    <label for="FullName">Person</label>
                    <select2
                      class="select2 form-control responsive"
                      style="width: 100%"
                    >
                      <optgroup label="Välj resurs">
                        <option disabled></option>
                        <option
                          v-for="(user, index) in usersList"
                          :value="user.id"
                          :key="index"
                        >
                          {{ user.firstname }} {{ user.lastname }} ({{
                            user.email
                          }})
                        </option>
                      </optgroup>
                    </select2>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel panel-default panel-fill" v-if="!loading">
        <div class="panel-heading">
          <h3 class="panel-title">
            {{ trans("center.examinationCenterSettings") }}
          </h3>
        </div>
        <div class="panel-body col-md-12">
          <div class="col-md-12 col-sm-3">
            <label>
              {{ trans("center.examinationCenter") }}:
              <DerToggle v-model="centerTypeConsultation" />
            </label>
          </div>

          <div
            class="card my-3"
            v-if="
              (center.connected_centers && center.connected_centers.length) >
                0 || center.type === 'consultation'
            "
          >
            <div class="card-header">
              {{ trans("users.connectedCentersLabel") }}
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ trans("center.name") }}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(connectedCenter,
                      index) in center.connected_centers"
                      :key="index"
                    >
                      <td class="centerCell">
                        {{ connectedCenter.name }}
                      </td>

                      <td>
                        <div class="text-right">
                          <button
                            class="btn btn-danger"
                            @click="removeConnectedCenter(connectedCenter.id)"
                          >
                            {{ trans("general.formDeleteLabel") }}
                          </button>
                        </div>
                      </td>
                    </tr>

                    <!-- form to add new connected center -->
                    <tr
                      v-if="
                        isAddingNewConnectedCenter &&
                          center.type === 'consultation'
                      "
                      class="editing-centers"
                    >
                      <td :class="{ 'has-error': errors.message }">
                        <select2 name="centers" v-model="selectedCenterId">
                          <option value=""></option>
                          <option
                            v-for="(center, index) in regularCenters"
                            :key="index"
                            :value="center.id"
                            >{{ center.name }}</option
                          >
                        </select2>
                        <form-error
                          v-if="errors.message"
                          :errors="errors.message"
                        ></form-error>
                      </td>

                      <td>
                        <div
                          class="text-right"
                          v-if="isAddingNewConnectedCenter"
                        >
                          <button
                            class="btn btn-grey m-1"
                            @click="cancelAddingNewSelectedCenter"
                          >
                            {{ trans("general.modalCancelButton") }}
                          </button>
                          <button
                            class="btn btn-primary saveSelectedCentre"
                            @click="addCenterToExaminationCenter"
                          >
                            {{ trans("center.connectToExaminationCenter") }}
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <button
                  class="btn btn-primary m-1 float-right addCenterButton"
                  @click="addingCenterToExaminationCenter"
                  v-if="
                    !isAddingNewConnectedCenter &&
                      center.type === 'consultation'
                  "
                >
                  {{ trans("admin.addNewCenter") }}
                </button>
              </div>
            </div>
          </div>

          <div
            class="card my-3"
            v-if="
              center.consultation_centers &&
                center.consultation_centers.length > 0
            "
          >
            <div class="card-header">
              {{ trans("center.connectedExaminationCentersLabel") }}
            </div>
            <div class="card-body">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th>{{ trans("center.name") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(connectedConsultationCenter,
                      index) in center.consultation_centers"
                      :key="index"
                    >
                      <td>{{ connectedConsultationCenter.name }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import DerToggle from "../../../design-system/DerToggle.vue";

const { state: patientLookupTypes } = useAsyncState(
  axios
    .get("/api/internal/admin/patient-lookup-types")
    .then(response => response.data),
  []
);
</script>
<script>
import PlatformAttributeEditor from "../../PlatformAttributes/PlatformAttributesEditor.vue";
import AdminCenterContactInfo from "./AdminCenterContactInfo.vue";
import AdminCenterProducts from "./AdminCenterProducts.vue";
import AdminCenterSettings from "./AdminCenterSettings.vue";
import HeaderAdmin from "./../Header.vue";
import { URLS } from "../../../config";
import {
  getProductNameByType,
  mapAvailableProductSettings,
} from "../../../helpers/product";
import AdminCenterConsentStudies from "./AdminCenterConsentStudies/AdminCenterConsentStudies.vue";

export default {
  created: function() {
    this.loadData();
  },
  data() {
    return {
      loading: true,
      centerTypeConsultation: false,
      selectedCenterId: null,
      isAddingNewConnectedCenter: false,
      regularCenters: [],
      center: {
        id: null,
        postcode: null,
        country: null,
        province: null,
        patient_telephone_number: null,
        is_lab_pharmacy: null,
        platform_attributes: {},
        platform_attribute_values: {},
        region_id: null,
        connected_centers: [],
        consultation_centers: [],
        ucr_id: null,
        patient_lookup_type: null,
      },
      platform: "",
      products: {},
      features: {
        ucr: {
          active: false,
          config: {
            center_id: null,
          },
        },
        regionskaneDicom: {
          active: false,
          config: {},
        },
        educations: {
          active: false,
          config: {},
        },
        webdoc: {
          active: false,
          config: {
            api_key: "",
            document_type: "1",
            endpoint: "",
          },
        },
        image_quality_assurance: {
          active: false,
          config: {},
        },
        image_polarisation_check: {
          active: false,
          config: {},
        },
        ai_prioritization: {
          active: false,
          config: {},
        },
        ai_diagnosis: {
          active: false,
          config: {},
        },
        mobile_two_factor_authentication: {
          active: false,
          config: {},
        },
        hide_patient_fields: {
          active: false,
          config: {},
        },
        wound_measurement: {
          active: false,
          config: {},
        },
        external_resources_setting: {
          active: false,
          config: {},
        },
        default_reserve_number_prefix: {
          active: false,
          config: {
            reserve_number: "",
          },
        },
      },
      users: {
        primaryCenters: {},
        resource: {},
      },
      usersList: [],
      errors: [],
      regions: [],
      selectedRegionId: null,
      regionsLoading: true,
    };
  },
  methods: {
    cancelAddingNewSelectedCenter: function() {
      this.isAddingNewConnectedCenter = false;
      this.selectedCenterId = null;
      this.errors = [];
    },
    addingCenterToExaminationCenter: function() {
      this.isAddingNewConnectedCenter = true;
    },
    addCenterToExaminationCenter: function() {
      this.errors = [];

      axios
        .post(
          "/api/internal/admin/centers/couple-to-consultation-center/" +
            this.selectedCenterId +
            "/" +
            this.center.id
        )
        .then(response => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.cancelAddingNewSelectedCenter();
          this.loadData();
        })
        .catch(error => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    removeConnectedCenter: function(centerId) {
      this.$dialog
        .confirm(
          this.trans(
            "center.removeConnectedCenterFromExaminationCenterConfirmation"
          ),
          {
            okText: this.trans("general.modalConfirmButton"),
            cancelText: this.trans("general.modalCancelButton"),
          }
        )
        .then(() => {
          axios
            .delete(
              "/api/internal/admin/centers/decouple-from-consultation-center/" +
                centerId +
                "/" +
                this.center.id
            )
            .then(response => {
              this.loadData();
            })
            .catch(error => {
              this.userErrorMessage(error);
            });
        })
        .catch(() => {});
    },
    loadData: async function() {
      this.loading = true;
      try {
        const centerResponse = await axios.get(
          "/api/internal/admin/centers/" + this.$route.params.id
        );
        let data = centerResponse.data;
        this.center = data.center;
        this.platform = data.platform;
        this.center.platform_attributes = data.platform_attributes;
        this.center.platform_attribute_values = data.platform_attribute_values;
        this.users = data.users;
        this.usersList = data.usersList;
        this.center.ucr_id = data.ucr_id;

        const productsResponse = await axios.get(URLS.PRODUCTS);
        this.products = mapAvailableProductSettings(productsResponse.data);

        if (this.$route.params.id === "new") {
          data.features.push({
            type: 14,
          });
        }
        this.setFeaturesData(data.features);
        this.setProductData(data.features);

        if (this.center.type === "regular") {
          this.centerTypeConsultation = false;
        } else if (this.center.type === "consultation") {
          this.centerTypeConsultation = true;
        }

        this.loading = false;
        this.getRegularCenters();
      } catch (error) {
        this.loading = false;
        this.userErrorMessage(error);
      }

      this.loadRegions();
    },
    setProductData: function(features) {
      const ids = Object.keys(this.products).map(
        product => this.products[product].id
      );
      features.forEach(feature => {
        if (ids.includes(feature.type)) {
          this.products[getProductNameByType(feature.type)].active = true;
        }
      });
    },
    setFeaturesData: function(features) {
      for (let key in features) {
        let featureType = features[key].type;
        if (featureType == 4) {
          this.features.ucr.active = true;
          if (features[key].config) {
            this.features.ucr.config = features[key].config;
          }
        } else if (featureType == 7) {
          this.features.educations.active = true;
        } else if (featureType == 10) {
          this.features.webdoc.active = true;
          if (features[key].config) {
            this.features.webdoc.config = features[key].config;
          }
        } else if (featureType == 13) {
          this.features.regionskaneDicom.active = true;
        } else if (featureType == 14) {
          this.features.image_quality_assurance.active = true;
        } else if (featureType == 18) {
          this.features.image_polarisation_check.active = true;
        } else if (featureType == 15) {
          this.features.ai_prioritization.active = true;
        } else if (featureType == 16) {
          this.features.ai_diagnosis.active = true;
        } else if (featureType == 17) {
          this.features.mobile_two_factor_authentication.active = true;
        } else if (featureType == 20) {
          this.features.hide_patient_fields.active = true;
        } else if (featureType == 21) {
          this.features.wound_measurement.active = true;
        } else if (featureType == 22) {
          this.features.external_resources_setting.active = true;
        } else if (featureType == 30) {
          this.features.default_reserve_number_prefix.active = true;
          if (features[key].config) {
            this.features.default_reserve_number_prefix.config =
              features[key].config;
          }
        }
      }
    },
    saveData: function() {
      let saveUri =
        "/api/internal/admin/centers" +
        (this.$route.params.id !== "new" ? "/" + this.$route.params.id : "");

      this.errors = [];

      let postData = this.center;

      postData.features = { ...this.features, ...this.products };

      this.centerTypeConsultation === true
        ? (postData.type = "consultation")
        : (postData.type = "regular");

      if (this.selectedRegionId) {
        postData.region_id = this.selectedRegionId;
      }

      axios
        .post(saveUri, postData)
        .then(response => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });

          if (response.data.isNew) {
            this.$router.push({ name: "Admin" });
            // Clean form
            this.center = {
              id: null,
              postcode: null,
              country: null,
              province: null,
              patient_telephone_number: null,
              is_lab_pharmacy: null,
              platform_attributes: {},
              platform_attribute_values: {},
            };
          }
        })
        .catch(error => {
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    openUser: function(id) {
      this.$router.push({ name: "AdminUsersEdit", params: { id: id } });
    },
    addResourceUser: function() {},
    loadRegions: function() {
      this.regionsLoading = true;

      axios
        .get("/api/internal/admin/all-regions")
        .then(data => {
          this.regions = data.data;
          this.regionsLoading = false;
        })
        .catch(error => {
          this.regionsLoading = false;
          console.log("loadRegions error: ", error);
        });
    },
    getRegularCenters: function() {
      axios
        .get("/api/internal/admin/all-centers-of-type/regular")
        .then(data => {
          let collection = data.data;

          if (this.center.connected_centers?.length) {
            this.regularCenters = this.filterListFromAlreadySelectedItems(
              collection,
              this.center.connected_centers
            );
          } else {
            this.regularCenters = data.data;
          }
        })
        .catch(error => {
          console.log("Get regular centers error: ", error);
        });
    },
    // to do: maybe make this a reusable funtion in utils
    filterListFromAlreadySelectedItems: function(listToFilter, listOfItems) {
      const listOfItemId = listOfItems.map(item => {
        return item.id;
      });
      const filteredList = listToFilter.filter(
        element => !listOfItemId.includes(element.id)
      );

      return filteredList;
    },
    reactivateCenter: function() {
      axios
        .post("/api/internal/admin/centers/restore/" + this.center.id)
        .then(response => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
          this.loadData();
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    deactivateCenter: function() {
      this.$dialog
        .confirm(this.trans("center.deactivateCenterConfirmationMessage"), {
          okText: this.trans("general.modalConfirmButton"),
          cancelText: this.trans("general.modalCancelButton"),
        })
        .then(() => {
          axios
            .delete("/api/internal/admin/centers/" + this.center.id)
            .then(response => {
              this.$notify({
                text: this.trans("general.notificationSaved"),
                type: "success",
                duration: 1000,
              });
              this.loadData();
            })
            .catch(error => {
              this.loading = false;
              this.userErrorMessage(error);
            });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    userAdminRoles: function(user) {
      let userAdminRoleList = user.roles.map(role => {
        if (
          role.name === "center_admin" ||
          role.name === "region_admin" ||
          role.name === "case_admin"
        ) {
          return role.name;
        }
      });

      return userAdminRoleList;
    },
  },
  computed: {
    isActive: function() {
      if (this.center.deleted_at === null || !this.center.id) {
        return true;
      }
    },
    regionName: function() {
      let regionName;
      if (this.center.region_id) {
        this.regions.find(region => {
          if (region.id == this.center.region_id) {
            regionName = region.name;
          }
        });
        return regionName;
      }
    },
  },
  components: {
    PlatformAttributeEditor,
    AdminCenterContactInfo,
    AdminCenterProducts,
    AdminCenterSettings,
    AdminCenterConsentStudies,
    HeaderAdmin,
  },
};
</script>

<style lang="scss" scoped>
.gray-background {
  background-color: rgba(214, 214, 214, 0.603);
}
</style>
