<template>
  <div class="tab-pane active" id="system">
    <clip-loader v-if="loading"></clip-loader>
    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Action</th>
            <th>Effected id</th>
            <th>Model type effected</th>
            <th>Causer id</th>
            <th>Causer name</th>
            <th>Extra properties</th>
            <th>Created at</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in system.logs" :key="index">
            <td>{{ log.description }}</td>
            <td>{{ log.subject_id }}</td>
            <td>{{ log.subject_type }}</td>
            <td>{{ log.causer_id }}</td>
            <td>{{ log.causer_name }}</td>
            <td>{{ log.properties }}</td>
            <td>{{ log.created_at }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <paginate
        :pageCount="system.paginate.last_page"
        :click-handler="getSystemResults"
        :prev-text="'‹'"
        :next-text="'›'"
        :containerClass="'pagination'"
        ref="paginate"
        v-model="system.paginate"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'page-item active'"
        :prev-class="'page-item'"
        :prev-link-class="'page-link'"
        :next-class="'page-item'"
        :next-link-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  created: function() {
    this.getSystemResults();
  },
  data() {
    return {
      loading: false,
      system: {
        paginate: {
          current_page: 0,
          total: 0,
          last_page: 0,
        },
        logs: [],
      },
    };
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  methods: {
    getSystemResults(page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/logs/system?page=" + page)
        .then(data => {
          var collection = data.data;
          this.system.paginate = collection.paginate;
          this.system.logs = collection.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
  },
};
</script>
