<template>
  <div class="tw-grid tw-grid-rows-1 tw-h-full content-scroll">
    <StudyConsentView
      class="content-scroll"
      v-if="studyConsent !== undefined"
      :data-study-consent="studyConsent"
      :data-give-consent-url="giveConsentUrl"
      @downloadConsent="onDownloadConsent"
      @toStudyConsents="onToStudyConsents"
    />
    <ErrorAlert v-if="!!error" :data-error="error" />
  </div>
</template>
<script setup lang="ts">
import StudyConsentView from "./StudyConsentView.vue";
import { useAsyncState } from "@vueuse/core";
import { useRoute, useRouter } from "vue-router/composables";
import _noop from "lodash/fp/noop";
import { patientHttpService } from "./store/patient-http-service";
import {
  studyConsentDownload,
  studyConsentGiveUrl,
} from "./store/study-consent";
import ErrorAlert from "../../design-system/ErrorAlert.vue";
import { locationOf, ROUTES } from "../routing/routes";

const route = useRoute();
const router = useRouter();
const studyConsentId = route.params["studyConsentId"];
const { state: studyConsent, error } = useAsyncState(
  patientHttpService
    .get(`/v1/study-consents/${studyConsentId}`)
    .then(response => response.data.data),
  undefined
);
const onDownloadConsent = studyConsentDownload;
const giveConsentUrl = studyConsentGiveUrl(
  { id: studyConsentId },
  { callbackUrl: route.fullPath }
);
const onToStudyConsents = () => {
  router.push(locationOf(ROUTES["patient.studyConsents"], {})).catch(_noop);
};
</script>
