<template>
  <div class="time-pickers-container">
    <v-select
      name="timeSelection"
      :options="timePeriods"
      :reduce="timePeriod => timePeriod.id"
      v-model="selectedTimePeriod"
      label="text"
      @input="updateTimePeriod($event)"
    ></v-select>

    <div v-if="customTimePeriod">
      <div class="row mt-2">
        <div class="col-md-6">
          <div>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</div>
          <custom-datepicker
            @onDateChange="setDate('dateFrom', $event)"
            :date="dateFrom"
            :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
          ></custom-datepicker>
        </div>

        <div class="col-md-6">
          <div>{{ trans("evaluation.tableColumnCreatedAtTo") }}</div>
          <custom-datepicker
            @onDateChange="setDate('dateTo', $event)"
            :date="dateTo"
            :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
          ></custom-datepicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    loading: Boolean,
  },
  created: function() {
    this.updateTimePeriod();
  },
  data() {
    return {
      customTimePeriod: false,
      timePeriods: [
        { id: "all", text: this.trans("statistics.all") },
        { id: "week", text: this.trans("statistics.oneWeek") },
        { id: "month", text: this.trans("statistics.oneMonth") },
        { id: "year", text: this.trans("statistics.oneYear") },
        { id: "custom", text: this.trans("statistics.custom") },
      ],
      selectedTimePeriod: { id: "all", text: this.trans("statistics.all") },
      dateFrom: "",
      dateTo: moment().format("YYYY-MM-DD"),
    };
  },
  methods: {
    updateTimePeriod: function(selectedTimePeriod) {
      this.customTimePeriod = false;

      switch (selectedTimePeriod) {
        case "all":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = "";
          break;
        case "week":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(7, "d")
            .format("YYYY-MM-DD");
          break;
        case "month":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(30, "d")
            .format("YYYY-MM-DD");
          break;
        case "year":
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = moment()
            .subtract(12, "M")
            .format("YYYY-MM-DD");
          break;
        case "custom":
          this.customTimePeriod = true;
          break;
        default:
          this.selectedTimePeriod = this.trans("statistics.all");
          this.dateTo = moment().format("YYYY-MM-DD");
          this.dateFrom = "";
      }

      this.$emit("updateDate", this.dateFrom, this.dateTo);
    },
    setDate: function(target, date) {
      if (target === "dateFrom") {
        this.dateFrom = date;
      } else {
        this.dateTo = date;
      }

      this.$emit("updateDate", this.dateFrom, this.dateTo);
    },
    clearDate: function() {
      this.customTimePeriod = false;
      this.selectedTimePeriod = null;
      this.updateTimePeriod("all");
    },
  },
};
</script>
