<template>
  <div
    class="main-wrapper"
    :class="{
      'sidebar-left-open': sidebarIsOpen,
      'sidebar-right-open': chatIsOpen,
    }"
  >
    <div class="xl:tw-h-screen xl:tw-overflow-hidden">
      <slot name="sidebar-left"></slot>
    </div>
    <div class="main-wrapper__content">
      <portal-target name="chat-btn-portal"></portal-target>
      <slot name="main-content"></slot>
    </div>
    <div class="main-wrapper__side-right">
      <slot name="sidebar-right"></slot>
    </div>
    <portal-target class="lightbox-container" name="lightbox-container" />
    <portal-target
      class="print-dialog-container"
      name="print-dialog-container"
    />
  </div>
</template>

<script>
export default {
  props: ["sidebarIsOpen", "chatIsOpen"],
  methods: {
    handleSidebarToggle() {
      this.$emit("sidebar-toggle");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../sass/abstracts/_variables.scss";

.main-wrapper {
  @media (min-width: $screen-md-min) {
    display: grid;
    grid-template-columns: auto minmax(0, 1fr) auto;
    position: relative;

    height: 100vh;
    overflow: hidden;
  }

  &.sidebar-right-open {
    .main-wrapper__side-right {
      @media (max-width: $screen-md-max) {
        transform: translateX(0);
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
        width: 90vw;
      }

      @media (min-width: $screen-sm-min) {
        width: 50vw;
      }

      @media (min-width: $screen-md-min) {
        width: 25vw;
      }
    }

    .main-wrapper__side-left {
      @media (min-width: $screen-md-min) {
        width: 0px;
      }
    }
  }
}

.main-wrapper__content {
  position: relative;
  padding: 10px 20px;
  padding-top: 2em;
  overflow: auto;
}

.main-wrapper__side-right {
  @media (max-width: $screen-md-max) {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 2;

    transform: translateX(100%);
  }

  position: relative;
  transition: all 0.3s ease;
  overflow-y: auto;

  @media (min-width: $screen-md-min) {
    width: 0px;
  }
}

#sidebarCollapse {
  position: absolute;
  top: 1em;
  right: 0;
  white-space: nowrap;
  transform: translateX(100%);

  @media (min-width: $screen-md-min) {
    display: none;
  }
}

.lightbox-container {
  &:empty {
    display: none;
  }

  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  grid-column: 1 / 3;

  z-index: 10;

  @media (min-width: $screen-md-min) {
    position: absolute;
  }
}

.print-dialog-container {
  &:empty {
    display: none;
  }

  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  z-index: 10;

  background-color: rgba(0, 0, 0, 0.5);
}
</style>
