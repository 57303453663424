<template>
  <div>
    <chat-container
      :edit-save-uri="
        '/api/internal/' + this.$route.params.id + '/patient-channels'
      "
      :key="$route.fullPath"
      :chatNotifications="chatNotifications"
      @updateTopNavigation="updateTopNavigation"
    ></chat-container>

    <header-patient />

    <router-view
      class="tw-pt-8"
      :data-patient="dataPatient"
      @updatePatientChatNotifications="updatePatientChatNotifications"
    />
  </div>
</template>

<script>
import HeaderPatient from "./Header.vue";
import ChatContainer from "../Examinations/AddOns/Chat/ChatContainer.vue";
import PatientOverview from "./PatientOverview.vue";
import PatientImageCompare from "./PatientImageCompare.vue";
import RelatedExaminations from "./RelatedExaminations.vue";

export default {
  created() {
    localStorage.setItem("last_visited_patient_id", this.$route.params.id);
  },
  props: {
    dataPatient: {
      type: Object,
    },
  },
  data: function() {
    return {
      chatNotifications: null,
    };
  },
  methods: {
    updatePatientChatNotifications: function(notifications) {
      this.chatNotifications = notifications;
    },
    updateTopNavigation: function() {
      this.$emit("updateTopNavigation");
    },
  },
  components: {
    HeaderPatient,
    ChatContainer,
    PatientOverview,
    PatientImageCompare,
    RelatedExaminations,
  },
};
</script>
