<template>
  <div id="activeCenters" class="tab-content">
    <div class="table-responsive">
      <div id="custom-search-input" class="mb-3">
        <form class="input-group col-md-12" @submit.prevent="search">
          <input
            type="text"
            class="form-control input-lg"
            id="search-field"
            :placeholder="trans('forms.searchForCenterPlaceholder')"
            v-model="searchFields.q"
          />
          <span class="input-group-btn">
            <button
              class="btn"
              type="button"
              @click.stop="search"
              v-if="!isSearching"
            >
              <i class="far fa-search"></i>
            </button>
            <button
              class="btn"
              type="button"
              @click.stop="clearSearch"
              v-if="isSearching"
            >
              <i class="fal fa-times"></i>
            </button>
          </span>
        </form>
      </div>

      <clip-loader v-show="loading"></clip-loader>

      <table class="table table-hover interactive-table">
        <thead class="sortable-table-header">
          <tr>
            <th>{{ trans("center.name") }}</th>
            <th>{{ trans("forms.hsaId") }}</th>
            <th>{{ trans("center.primaryUsers") }}</th>
            <th>{{ trans("examination.createdAtLabel") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(center, index) in centers"
            :key="index"
            @click="openCenter(center.id)"
            :class="{
              last: isLastVisited(center.id),
            }"
          >
            <td>{{ center.name }}</td>
            <td>{{ center.hsa_id }}</td>
            <td>{{ center.total_users }}</td>
            <td>
              {{ center.created_at_formatted }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid float-right">
      <paginate
        :pageCount="paginate.last_page"
        :click-handler="getCenters"
        :prev-text="'‹'"
        :next-text="'›'"
        :containerClass="'pagination'"
        ref="paginate"
        v-model="paginate"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'page-item active'"
        :prev-class="'page-item'"
        :prev-link-class="'page-link'"
        :next-class="'page-item'"
        :next-link-class="'page-link'"
      >
      </paginate>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    centerType: String,
  },

  created: function() {
    this.setQueryFields();
    this.getCenters();
  },
  data() {
    return {
      loading: true,
      centers: [],
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      isSearching: false,
      searchFields: {
        page: 1,
        q: null,
        lastVisitedId: null,
      },
    };
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  methods: {
    getCenters: function(page) {
      this.loading = true;

      this.searchFields.page = page || this.searchFields.page;

      localStorage.setItem(
        `${this.centerType}_centers`,
        JSON.stringify(this.searchFields)
      );

      let queryCenterType =
        this.centerType === "active" ? "centers" : "deleted-centers";

      axios
        .get(
          `/api/internal/admin/${queryCenterType}?${queryStringBuilder(
            this.searchFields
          )}`
        )
        .then(data => {
          const collection = data.data;
          this.paginate = collection.paginate;
          this.centers = collection.data;
          this.loading = false;

          this.setQueryFields();
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    setQueryFields: function() {
      const queriesFromLocalStorage = localStorage.getItem(
        `${this.centerType}_centers`
      );

      if (queriesFromLocalStorage) {
        this.searchFields = JSON.parse(queriesFromLocalStorage);
      }

      if (this.searchFields.q) {
        this.isSearching = true;
      }
    },
    openCenter: function(id) {
      this.$router.push({ name: "AdminCentersEdit", params: { id: id } });
      this.searchFields.lastVisitedId = id;
      localStorage.setItem(
        `${this.centerType}_centers`,
        JSON.stringify(this.searchFields)
      );
    },
    clearSearch: function() {
      this.searchFields = {
        page: 1,
        q: null,
      };
      this.isSearching = false;
      this.getCenters(1);
    },
    search: function() {
      this.loading = true;
      this.isSearching = true;
      this.getCenters(1);
    },
    isLastVisited: function(centerId) {
      return (
        JSON.parse(localStorage.getItem(`${this.centerType}_centers`))
          .lastVisitedId == centerId
      );
    },
  },
};
</script>
