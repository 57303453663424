<template>
  <div>
    <div v-if="!!title">
      <strong>{{ title }}:</strong>
      <i class="far fa-asterisk" v-if="required"></i>
      <br />
    </div>
    <textarea
      class="form-control my-2"
      :value="value"
      :disabled="disabled"
      @input="onInput($event.target.value)"
    />
  </div>
</template>

<script>
import _isString from 'lodash/fp/isString';

export default {
  emits: ["field-unmount", "isValid"],
  props: {
    title: String,
    disabled: Boolean,
    required: Boolean,
    extraOptions: {
      key: String,
      onValueChange: Function,
    },
  },
  data() {
    return {
      value: "",
    };
  },
  computed: {
    isValid() {
      return !this.required || (_isString(this.value) && this.value.trim().length > 0);
    },
  },
  watch: {
    isValid: {
      handler: function(newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    }
  },
  methods: {
    onInput: function(event) {
      this.value = event;
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event,
      });
    },
  },
  beforeDestroy: function() {
    this.$emit("field-unmount");
  },
};
</script>
