<template>
  <div class="admin-activate row justify-content-center">
    <div>
      <clip-loader v-if="loading"></clip-loader>
    </div>
    <div class="col-6" v-if="!loading">
      <div class="row justify-content-center">
        <img
          src="/images/dermicus-logo.svg"
          height="50"
          class="align-self-center"
        />
      </div>

      <div>
        <table class="table table-hover interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th>{{ trans("forms.registrationIntro") }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(field, index) in tableData" :key="index">
              <td>{{ trans("forms." + index) }}</td>
              <td>{{ field }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row justify-content-center" v-if="errors.tentativeUserError">
        <p
          :class="{ 'has-error': errors.tentativeUserError }"
          class="text-danger font-weight-bold"
        >
          {{ errors.tentativeUserError.message }}
        </p>
      </div>

      <div class="row justify-content-center">
        <button class="btn btn-grey mx-1" @click="$router.back()">
          {{ trans("general.back") }}
        </button>
        <button
          :disabled="!userCanBeActivated ? true : false"
          class="btn btn-primary mx-1"
          @click="activateUser()"
        >
          Activate
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { URLS } from "../../../../config";
import { getRegistrationFormDefinition } from "../../../Registration/registrationFormDefinitions";

export default {
  data: function() {
    return {
      loading: false,
      user: {},
      errors: { tentativeUserError: {} },
      definition: undefined,
      tableData: undefined,
      userCanBeActivated: false,
    };
  },
  mounted: function() {
    this.loadData();
  },
  methods: {
    loadData: async function() {
      this.loading = true;
      await this.getTentativeUserData();
      this.initializeFormData();
    },
    initializeFormData: function() {
      const definition = getRegistrationFormDefinition();
      this.definition = definition.formData;

      const tableData = {};
      definition.formData.forEach(field => {
        if (
          Object.keys(this.user).includes(field.name) &&
          this.user[field.name] !== null
        ) {
          tableData[field.title] = this.user[field.name];
        }
      });
      this.tableData = tableData;
    },
    getTentativeUserData: async function() {
      try {
        const response = await axios.get(
          `${URLS.ADMIN_TENTATIVE_USERS}/${this.$route.params.id}`
        );
        this.user = response.data;
        this.isReadyForActivation(this.user.status);
        this.loading = false;
      } catch (error) {
        const status = error.response.status;
        if (status === 422) {
          this.errors.tentativeUserError = {
            message: error.response.data.tentativeUserId[0],
          };
          this.loading = false;
          this.$notify({
            text: error.response.data.tentativeUserId[0],
            type: "warn",
            duration: 1000,
          });
        }
      }
    },
    activateUser: async function() {
      try {
        const response = await axios.post(
          `${URLS.ADMIN_TENTATIVE_USERS_ACTIVATE}/${this.user.id}`
        );
        this.$router.push({
          name: "AdminUsersEdit",
          params: { id: response.data.id },
        });
      } catch (error) {
        this.errors.tentativeUserError = {
          message: error.response.data,
        };
        this.userErrorMessage(error);
      }
    },
    isReadyForActivation: function(userStatus) {
      if (userStatus === "user_updated") {
        this.userCanBeActivated = true;
      } else {
        this.userCanBeActivated = false;
      }
    },
  },
};
</script>
