<template>
  <div class="examination-documentation-container col-md-12 p-0">
    <!-- new documentation answer type selection -->
    <div class="mx-2">
      <div
        class="form-group col-md-12 mb-3 mt-4"
        v-if="addComment && !loading"
        :class="{ 'has-error': errors.type }"
      >
        <v-select
          id="documentation-type-dropdown"
          :options="availableDocumentationTypes"
          v-model="selectedDocumentationType"
          label="title"
          :placeholder="trans('forms.generalSelectPlaceholder')"
        ></v-select>
        <form-error v-if="errors.type" :errors="errors.type"></form-error>

        <small v-if="selectedDocumentationType" class="form-text">{{
          selectedDocumentationType.note
        }}</small>
      </div>
    </div>

    <!-- all documentaion answers -->
    <div v-if="loading" class="overlay">
      <clip-loader></clip-loader>
    </div>

    <div class="chat-body" v-if="!loading">
      <div
        class="chat-rows"
        v-if="!isListHidden && !(editComment || addComment)"
      >
        <documentation-item
          v-for="(comment, index) in allComments"
          :key="index"
          :displayUserContact="false"
          :comment="comment"
          :previousComments="previousComments[comment.id]"
          @on-get-history="getCommentHistory"
          @on-edit="onEditComment"
          :userCanEdit="comment.isOwner"
          :label="comment.typeLabel"
        />
      </div>

      <comment-editor
        v-if="editComment || addComment"
        @on-submit="onSubmitComment"
        @on-cancel="onCancelEdit"
        :isSaving="loading"
        :comment="currentEditComment ? currentEditComment.comment : undefined"
        :selectedDocumentationType="
          currentEditComment
            ? {
                typeLabel: currentEditComment.typeLabel,
                type: currentEditComment.type,
              }
            : selectedDocumentationType
        "
        :errors="errors"
        :editType="editType"
      />
    </div>

    <div
      v-if="!loading && allComments && !(editComment || addComment)"
      class="text-center my-5"
    >
      <span v-if="allComments.length === 0">{{
        trans("examination.noDocumentationAnwersInfo")
      }}</span>
      <button
        v-if="isListHidden && allComments.length > 0"
        type="button"
        class="btn btn-primary"
        :disabled="loading"
        @click="isListHidden = false"
      >
        {{ trans("examination.diagnosReplyShowConsultAnswers") }}
      </button>
    </div>
  </div>
</template>

<script>
import { URLS } from "../../../../config";
import {
  DOCUMENTATION_ANSWERS,
  STORE_CONSTANTS,
} from "../../../../helpers/definitions";

import CommentEditor from "./components/CommentEditor.vue";
import DocumentationItem from "./components/DocumentationItem.vue";
import _find from "lodash/fp/find";
import { productsConfigGet } from "../../../../store/core/products";

export default {
  components: { DocumentationItem, CommentEditor },
  props: {
    examinationId: Number,
    enabledTypes: Array,
    addNewDocumentation: Boolean,
    /** @type {ProductField} */
    productField: Object,
  },
  mounted: function() {
    this.mapDocumentationTypes();
    this.getComments();
    this.setDefaultDocumentationType();
  },
  watch: {
    addNewDocumentation: function() {
      if (this.addNewDocumentation) {
        this.addComment = true;
        this.editType = "addNewDocumentation";
      } else {
        this.addComment = false;
      }
    },
  },
  data() {
    return {
      loading: false,
      totalComments: 0,
      isListHidden: false,
      paginate: {},
      selectedDocumentationType: null,
      editComment: false,
      currentEditComment: undefined,
      addComment: false,
      previousComments: {},
      allComments: null,
      availableDocumentationTypes: [],
      errors: {},
      editType: null,
    };
  },
  methods: {
    setDefaultDocumentationType: async function() {
      // This component isn't always used based on the product definition, while
      // it should to make sure it follows the configured behaviour. While work
      // is done to fix this, we have exposed a temporary endpoint to get some
      // configuration like the default documentation type when this isn't used
      // though the product definition, and have a "quickfix" for now.
      this.selectedDocumentationType = _find(
        {
          name:
            (this.productField ?? (await productsConfigGet()).documentation)
              .initial_selected ?? "notes",
        },
        this.availableDocumentationTypes
      );
    },
    onAddButton: function() {
      this.addComment = true;
      this.editType = "addNewDocumentation";
    },
    mapDocumentationTypes: function() {
      let availableDocumentationTypes = [];
      this.enabledTypes.forEach(type => {
        if (type.edit) {
          availableDocumentationTypes.push(
            DOCUMENTATION_ANSWERS.find(t => {
              return t.name === type.name;
            })
          );
        }
      });

      this.availableDocumentationTypes = availableDocumentationTypes;
    },
    getComments: async function() {
      this.loading = true;
      const url = `${URLS.BASE}/${this.examinationId}/comments`;
      try {
        const response = await axios.get(url);
        this.allComments = response.data.comments.map(comment => {
          return comment.current;
        });
        this.paginate = response.data.paginate;
        this.isListHidden = response.data.hideList;
        this.totalComments = response.data.totalComments;
        this.$emit("setTotalComments", this.totalComments);
        this.loading = false;
      } catch (e) {
        this.loading = false;
        console.log("Get comments error: ", e);
      }
      this.loading = false;
    },
    getCommentHistory: async function(comment) {
      try {
        const url = `${URLS.BASE}/${this.examinationId}/comments/comment-history/${comment.id}`;
        const response = await axios.get(url);
        this.previousComments = {
          ...this.previousComments,
          [comment.id]: response.data.comments,
        };
      } catch (e) {
        console.log("Get comment history error: ", e);
      }
    },
    onSubmitComment: function(comment) {
      this.errors = {};
      this.loading = true;

      const postFix = this.currentEditComment
        ? `/${this.currentEditComment.id}`
        : "";

      const url = `${URLS.BASE}/${this.examinationId}/comments${postFix}`;
      const documentationType = this.currentEditComment
        ? this.currentEditComment.type
        : this.selectedDocumentationType
        ? this.selectedDocumentationType.type
        : undefined;

      axios
        .post(url, {
          type: documentationType,
          text: comment,
        })
        .then(() => {
          this.getComments();
          this.loading = false;
          this.editComment = false;
          this.addComment = false;
          this.currentEditComment = undefined;
          this.onCancelEdit();

          // to do: find a way to update a single patient case
          this.$route.path.includes("patient")
            ? this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES)
            : this.$store.dispatch(STORE_CONSTANTS.RELOAD_EXAMINATION);
        })
        .catch(error => {
          this.loading = false;
          this.errors = error.response.data;
          this.userErrorMessage(error);
        });
    },
    onCancelEdit: function() {
      this.editComment = false;
      this.currentEditComment = undefined;
      this.addComment = false;
      this.selectedDocumentationType = null;
      this.editType = null;
      this.errors = {};

      this.$emit("dismissAddNewDocumentation", false);
      this.setDefaultDocumentationType();
    },
    onEditComment: function(comment) {
      this.currentEditComment = comment;
      this.editComment = true;
      this.editType = "editDocumentation";
    },
  },
};
</script>

<style lang="scss">
.examination-documentation-container {
  max-height: 450px;
  overflow-y: auto;
  min-height: 100px;

  .form-group.has-error
    #documentation-type-dropdown.v-select.vs--single.vs--searchable
    .vs__dropdown-toggle {
    border-color: #d57171 !important;
  }
}
</style>
