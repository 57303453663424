<script>
import { Bar } from "vue-chartjs";
import "./chart.js.fix.scss";

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
    options: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    this.renderChart(this.chartdata, this.options);
  },
  watch: {
    chartdata: {
      deep: true,
      handler: function() {
        this.renderChart(this.chartdata, this.options);
      },
    },
  },
};
</script>
