import { ErrorFeedback } from "../lib/error-feedback";
import { StudyResultStatus } from "../../../../app/Dermicus/Studies/StudyResultStatus";
import { StudyExaminationStatus } from "../../../../app/Dermicus/Studies/StudyExaminationStatus";

export type DataVariant =
  | "primary"
  | "secondary"
  | "success"
  | "danger"
  | "warning"
  | "info"
  | "light"
  | "dark";

type VariantMap<T extends string> = Record<T, DataVariant>;

function fromVariant<T extends string>(map: VariantMap<T>) {
  return (variant: T) => map[variant];
}

export const fromVariantErrorFeedback = fromVariant<ErrorFeedback["variant"]>({
  error: "danger",
  warning: "warning",
});

export const fromStudyExaminationStatus = fromVariant<StudyExaminationStatus>({
  selected: "light",
  ready: "primary",
  invited: "warning",
  "research-answered": "success",
});

export const fromStudyResultStatus = fromVariant<StudyResultStatus>({
  new: "primary",
  "research-answered": "success",
});
