<template>
  <div
    v-if="addingPeopleToChannel"
    class="mb-3 mx-3 d-flex justify-content-apart align-items-center edit-channel"
  >
    <div class="col-9">
      <v-select
        multiple
        name="participants"
        :options="participants"
        v-model="newParticipants"
        label="name"
        :reduce="participant => participant.id"
        :placeholder="trans('chat.chooseParticipants')"
      ></v-select>
    </div>

    <div>
      <button class="btn btn-grey action-btn" @click="addNewParticipants">
        <i class="far fa-check"></i>
      </button>
      <button
        class="btn btn-grey cancel-btn"
        @click="toggleAddingPeopleToChannel(false)"
      >
        <i class="fal fa-times"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    addingPeopleToChannel: Boolean,
    participants: Array,
  },

  data: function() {
    return {
      newParticipants: null,
    };
  },
  methods: {
    addNewParticipants: function() {
      this.$emit("addNewParticipants", this.newParticipants);
      this.newParticipants = [];
    },
    toggleAddingPeopleToChannel: function(bool) {
      this.$emit("toggleAddingPeopleToChannel", bool);
    },
  },
};
</script>
