<template>
  <div class="tab-content">
    <SearchInput
      placeHolder="Search patient id"
      @submit="handleSearch"
      @clearInput="handleClearInput"
    />
    <p v-if="patients?.length === 0">{{ lang.get("center.noResults") }}</p>
    <Table v-if="patients?.length" :headings="tableHeadings">
      <template v-for="(patient, index) in patients">
        <tr :key="index">
          <td>{{ patient.ssn }}</td>
          <td>{{ patient.center.name }}</td>
          <td>{{ patient.products }}</td>
          <td>
            <button
              v-if="patient.externalResources.length"
              type="button"
              data-toggle="collapse"
              @click="toggleUserOptions"
              :class="[expandUserInfo ? 'btn-primary' : 'btn-secondary', 'btn']"
              aria-expanded="false"
              :data-target="`#toggle-${patient.id}`"
            >
              <span
                >{{
                  expandUserInfo
                    ? lang.get("center.showLess")
                    : lang.get("center.showMore")
                }}
                <i
                  class="far fa-angle-down"
                  :class="[
                    expandUserInfo ? 'fa-angle-up' : 'fa-angle-down',
                    'far',
                  ]"
                ></i
              ></span>
            </button>
          </td>
        </tr>
        <tr v-if="patient.externalResources.length">
          <td colspan="6">
            <div class="collapse multi-collapse" :id="`toggle-${patient.id}`">
              <Table :headings="subTableHeadings">
                <tr v-for="(resource, i) in patient.externalResources" :key="i">
                  <td>{{ resource.firstname }}</td>
                  <td>{{ resource.lastname }}</td>
                  <td>{{ resource.primaryCenter }}</td>
                  <td>
                    <button
                      @click="handleRevoke(patient, resource)"
                      class="btn btn-danger"
                    >
                      {{ lang.get("center.revokeAccess") }}
                    </button>
                  </td>
                </tr>
              </Table>
            </div>
          </td>
        </tr>
      </template>
    </Table>
  </div>
</template>

<script setup lang="ts">
import Vue, { ref } from "vue";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import SearchInput from "../../design-system/SearchInput.vue";
import { lang } from "../../i18n";
import Table from "../Layout/Table.vue";

const props = defineProps<{ apiPrefix: string }>();

const patients = ref(null);
const expandUserInfo = ref(false);

const tableHeadings = [
  { title: lang.get("evaluation.tableColumnPersonalNo"), sortable: false },
  { title: lang.get("evaluation.tableColumnCenter"), sortable: false },
  {
    title: lang.get("patient.tableHeaderPatientProducts"),
    sortable: false,
  },
  {
    title: "",
    sortable: false,
  },
];

const subTableHeadings = [
  {
    title: lang.get("center.externalResourceTableHeadFirstname"),
    sortable: false,
  },
  {
    title: lang.get("center.externalResourceTableHeadLastname"),
    sortable: false,
  },
  { title: lang.get("evaluation.tableColumnCenter"), sortable: false },
  { title: "", sortable: false },
];

function toggleUserOptions() {
  expandUserInfo.value = !expandUserInfo.value;
}

function handleClearInput() {
  patients.value = null;
}

function handleSearch(value: string) {
  practitionerHttpService
    .get(
      `/api/internal/${props.apiPrefix}/patients/with-external-resources/${value}`
    )
    .then(response => {
      patients.value = response.data;
    });
}

function handleRevoke(
  patient: { ssn: string; id: string },
  user: { firstname: string; lastname: string; id: string }
) {
  // @ts-ignore-next-line
  Vue.dialog
    .confirm(
      lang.get("center.confirmRevokeAccess", {
        firstName: user.firstname,
        lastName: user.lastname,
        patientId: patient.ssn,
      }),
      {
        okText: lang.get("general.modalConfirmButton"),
        cancelText: lang.get("general.modalCancelButton"),
      }
    )
    .then(() => {
      practitionerHttpService
        .delete(
          `/api/internal/${props.apiPrefix}/patients/revoke-access/${patient.id}/${user.id}`
        )
        .then(() => {
          handleSearch(patient.ssn);
        });
    });
}
</script>
