import Bugsnag from "@bugsnag/js";

import "./bootstrap";
import { resetLocalStorage, translator } from "./helpers/misc";
import router from "./routes";
import { store } from "./store";
import "../sass/app.scss";
import "jquery-cropbox";
import "./global";
import "query-string";
import Notifications from "./components/Utils/notifications";

Vue.prototype.trans = translator;

// polling data every minute (in milliseconds)
const TOKEN_EXPIRATION_POLLING_INTERVAL = 1 * 60 * 1000;
const ALLOWED_ERRORS_IN_SEQUENCE = 3;

window.axios.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status === 401) {
      // clearLocalStorage
      resetLocalStorage();
      store.state.isSplashScreen = false;

      // clearUser
      store.commit("setAuthenticated", false);
      store.commit("setUser", {});

      // removeModal
      $(".logout-alert").hide();
      document.querySelector("body").classList.remove("dg-open");

      return router.push({ name: "Auth" });
    }
    return Promise.reject(error);
  }
);

const app = new Vue({
  el: "#app",
  template: "<router-view/>",
  store,
  router,
  mounted: function() {
    this.$store.state.isSplashScreen = false;
  },
  beforeDestroy: function() {
    clearInterval(this.polling);
  },
  created: function() {
    this.pollData();
  },
  data: function() {
    return {
      polling: null,
    };
  },
  watch: {
    "$store.state.user": function(user) {
      Bugsnag.isStarted() && Bugsnag.setUser((user && user.id) || null);
    },
  },
  methods: {
    resetUser: function() {
      this.$store.commit("setAuthenticated", false);
      this.$store.commit("setUser", {});
    },
    logoutUser: function() {
      resetLocalStorage();
      this.$router.push({ name: "Auth" });
      this.resetUser();
      $(".pswp").remove();
    },
    removeModal: function() {
      $(".logout-alert").hide();
      document.querySelector("body").classList.remove("dg-open");
    },
    pollData: function() {
      let errorsInSequence = 0;
      this.polling = setInterval(() => {
        if (this.isAuthenticated) {
          axios
            .get("/api/internal/auth/token-expiration")
            .then(response => {
              if (errorsInSequence > 0) {
                Notifications.success(
                  this.trans(
                    "errorMessages.connectivityIssuesResolved.message"
                  ),
                  this.trans("errorMessages.connectivityIssuesResolved.heading")
                );
              }
              errorsInSequence = 0;
              let messageTime = moment().format("HH:mm:ss");
              let message = {
                title: this.trans("auth.sessionEndingMessageHeader"),
                body:
                  `[${messageTime}] - ` +
                  this.trans("auth.sessionEndingMessage"),
              };

              // when 5 minutes of active session is left, show info dialog
              if (response.data?.expires_in_minutes === 5) {
                this.$dialog
                  .alert(message, {
                    okText: this.trans("auth.continueUsingSystem"),
                    customClass: "logout-alert",
                  })
                  .then(() => {
                    // on accept dialog prolong the session
                    axios.get("/api/internal/auth/information");
                  });
              }
            })
            .catch(error => {
              if (
                error?.response?.status === 401 ||
                (error?.response?.status !== 401 &&
                  errorsInSequence > ALLOWED_ERRORS_IN_SEQUENCE)
              ) {
                this.removeModal();
                this.logoutUser();
              } else {
                errorsInSequence++;
                Notifications.error(
                  this.trans("errorMessages.connectivityIssues.message"),
                  this.trans("errorMessages.connectivityIssues.heading")
                );
              }
            });
        } else {
          // no polling if user if not authenticated
          return;
        }
      }, TOKEN_EXPIRATION_POLLING_INTERVAL);
    },
  },
  computed: {
    isAuthenticated: function() {
      return this.$store.state.isAuthenticated;
    },
  },
});
