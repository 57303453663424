<template>
  <div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
        <custom-datepicker
          @onDateChange="changeDates('dateFrom', $event)"
          :date="searchFields.dateFrom"
          :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
        ></custom-datepicker>
      </div>
      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
        <custom-datepicker
          @onDateChange="changeDates('dateTo', $event)"
          :date="searchFields.dateTo"
          :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
        ></custom-datepicker>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnCenter") }}</label>
        <v-select
          :options="searchOptions.centers"
          :value="center"
          label="text"
          :reduce="country => country.id"
          @input="searchFields.center = $event"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUserTypes") }}</label>
        <v-select
          :options="searchOptions.userTypes"
          :value="searchFields.userType"
          label="text"
          :reduce="userType => userType.value"
          @input="updateUserType($event)"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnUsers") }}</label>
        <v-select
          :options="computedUsersList"
          :value="user"
          label="text"
          :reduce="user => user.id"
          @input="searchFields.user = $event"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("evaluation.tableColumnStatus") }}</label>
        <v-select
          name="status"
          :options="searchOptions.statuses"
          v-model="searchFields.status"
          label="text"
          :reduce="status => status.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["searchFields", "searchOptions"],
  data: function() {
    return {
      selectedUserType: null,
    };
  },
  methods: {
    changeDates: function(target, date) {
      if (target == "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateTo = date;
      }
    },
    updateUserType: function(userType) {
      this.searchFields.userType = userType;
      this.searchFields.user = "";
      this.selectedUserType = userType;
    },
  },
  computed: {
    center: function() {
      let center = this.searchOptions.centers.some(
        center => center.id === this.searchFields.center
      );
      if (center) {
        return this.searchFields.center;
      }
      return;
    },
    user: function() {
      let user = this.searchOptions.users.some(
        user => user.id === this.searchFields.user
      );
      if (user) {
        return this.searchFields.user;
      }
      return;
    },
    computedUsersList: function() {
      if (this.selectedUserType) {
        return this.searchOptions.users.filter(user => {
          return user.types.includes(this.selectedUserType);
        });
      }
      return this.searchOptions.users;
    },
  },
};
</script>
