import Vue from "vue";
import { lang } from "../../i18n";

const saveSuccess = () => {
  Vue.notify({
    text: lang.get("general.notificationSaved"),
    type: "success",
    duration: 2000,
  });
};

const saveSuccessCustomText = customText => {
  Vue.notify({
    text: lang.get(`${customText}`),
    type: "success",
    duration: 2000,
  });
};

const success = (successMessage, successHeader) => {
  const successMessageText = successMessage ?? "";
  const headerText = successHeader ?? "";

  Vue.notify({
    title: headerText,
    text: successMessageText,
    duration: 10000,
    type: "success",
    ignoreDuplicates: true,
  });
};

const warn = (errorMessage, errorHeader) => {
  const errorMessageText = errorMessage
    ? errorMessage
    : "errorMessages.HTTPStatus.422";

  const headerText = errorHeader ? errorHeader : "";

  Vue.notify({
    title: headerText,
    text: lang.get(errorMessageText) ?? errorMessageText,
    duration: 10000,
    type: "warn",
    ignoreDuplicates: true,
  });
};

const error = (errorMessage, errorHeader) => {
  const errorMessageText = errorMessage
    ? errorMessage
    : "errorMessages.HTTPStatus.XXX";

  const headerText = errorHeader ? errorHeader : "Error";

  Vue.notify({
    title: headerText,
    text: lang.get(errorMessageText) ?? errorMessageText,
    duration: 10000,
    type: "error",
    ignoreDuplicates: true,
  });
};

const errorCustomText = customText => {
  Vue.notify({
    text: lang.get(`${customText}`),
    type: "error",
    duration: 10000,
  });
};

export default {
  saveSuccess,
  saveSuccessCustomText,
  success,
  warn,
  error,
  errorCustomText,
};
