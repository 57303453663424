<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p v-if="this.data && this.data.length === 0">-</p>
    <p
      v-else
      v-for="(value, key) in data"
      :key="key"
      class="text-muted consentList"
    >
      <i v-if="value.value === true" class="fal fa-check-circle"></i>
      <i
        v-else-if="value.value === false || value.value === null"
        class="fal fa-circle"
      ></i>
      {{ key }}<br />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    data: [Object, Array],
  },
};
</script>
