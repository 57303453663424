<template>
  <div class="d-flex flex-wrap py-0" v-if="imagesWithAiFilters.length !== 0">
    <div
      class="mt-3 col-md-6"
      v-for="(image, index) in imagesWithAiFilters"
      :key="index"
    >
      <label v-if="image">
        {{
          image.imgNumber == 1
            ? trans("examination.imageDermatoscopFirst") +
              " (Filter: " +
              image.filter +
              ")"
            : trans("examination.imageDermatoscopSecond") +
              " (Filter: " +
              image.filter +
              ")"
        }}
      </label>
      <img
        v-if="image"
        :src="image.uri"
        v-pswp="image.uri"
        class="img-fluid zoom"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: ["imagesWithAiFilters", "product"],
};
</script>
