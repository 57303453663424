<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ lang.get("view.userSettings.studyAdmin.title") }}
      </h3>
    </div>
    <div class="panel-body">
      <Alert v-if="!isStudyAdmin" data-variant="info">
        {{ lang.get("view.userSettings.studyAdmin.disabledMessage") }}
      </Alert>
      <AsyncState v-else :data-state="studiesState">
        <AsyncState :data-state="userStudiesState">
          <table class="table table-sm mb-0">
            <tbody>
              <tr v-for="study in studies" :key="study.id">
                <td>{{ study.name }}</td>
                <td class="text-right">
                  <Button
                    v-if="isAdminOf(study)"
                    @click="onDisconnectStudyAdmin(study, dataUser)"
                  >
                    {{ lang.get("action.study.adminRemove.label") }}
                  </Button>
                  <Button v-else @click="onConnectStudyAdmin(study, dataUser)">
                    {{ lang.get("action.study.adminAdd.label") }}
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
        </AsyncState>
      </AsyncState>
    </div>
  </div>
</template>
<script setup lang="ts">
import Alert from "../../design-system/Alert.vue";
import { computed, toRefs, watchEffect } from "vue";
import _some from "lodash/fp/some";
import { useAsyncState } from "@vueuse/core";
import {
  studiesGet,
  studyAdminAdd,
  studyAdminRemove,
  userStudiesAsAdminGet,
} from "../../store/core/studies";
import AsyncState from "../../design-system/AsyncState.vue";
import Button from "../../components/Layout/Button.vue";
import { Study } from "../../../../../app/Dermicus/Studies/Study";
import { userErrorMessage } from "../../mixins/UserErrorMessage.vue";
import { lang } from "../../i18n";

const props = defineProps<{
  dataUser: { id: number; roles: Array<{ name: string }> };
}>();

const { dataUser } = toRefs(props);

const isStudyAdmin = computed(() =>
  _some({ name: "study_admin" }, dataUser.value.roles)
);

const studiesState = useAsyncState(studiesGet, undefined);
const studies = computed(() => studiesState.state.value);

const userStudiesState = useAsyncState(userStudiesAsAdminGet, undefined, {
  immediate: false,
});
watchEffect(() => userStudiesState.execute(0, dataUser.value.id));
const userStudies = computed(() => userStudiesState.state.value);

function isAdminOf(study: Study) {
  return _some({ id: study.id }, userStudies.value);
}

function onConnectStudyAdmin(study: Study, user: { id: number }) {
  studyAdminAdd({
    studyId: study.id,
    userId: user.id,
  })
    .then(() => userStudiesState.execute(0, dataUser.value.id))
    .catch(userErrorMessage);
}

function onDisconnectStudyAdmin(study: Study, user: { id: number }) {
  studyAdminRemove({
    studyId: study.id,
    userId: user.id,
  })
    .then(() => userStudiesState.execute(0, dataUser.value.id))
    .catch(userErrorMessage);
}
</script>
