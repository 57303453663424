<template>
  <div>
    <label class="tw-flex tw-gap-2 tw-items-start">
      <input
        class="tw-mt-1"
        type="checkbox"
        :value="value"
        @change="toggleCheckbox"
        :disabled="disabled"
        :required="required"
      />
      <span>{{ title }}</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    disabled: Boolean,
    required: Boolean,
    formValue: [Object, String],

    extraOptions: {
      key: String,
      onValueChange: Function,
    },
  },
  emits: ["field-unmount", "isValid"],
  mounted: function() {
    this.value = this.formValue;
  },
  beforeDestroy: function() {
    this.$emit("field-unmount");
  },
  data() {
    return {
      value: undefined,
    };
  },
  computed: {
    isValid() {
      return !this.required || this.value === true;
    },
  },
  watch: {
    isValid: {
      handler: function(newValue) {
        this.$emit("isValid", newValue);
      },
      immediate: true,
    },
  },
  methods: {
    toggleCheckbox: function(event) {
      this.value = event.target.checked;
      this.extraOptions.onValueChange({
        key: this.extraOptions.key,
        value: event.target.checked,
      });
    },
  },
};
</script>
