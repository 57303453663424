<template>
  <field-panel :title="title">
    <div class="panel-body">
      <div v-if="loading" class="overlay">
        <ClipLoader></ClipLoader>
      </div>
      <div v-if="examinationData.length > 0" class="tw-space-y-6">
        <field
          v-for="(field, index) in examinationData"
          :key="index"
          :examinationId="examination.id"
          :title="field.title"
          :data="field.data"
          :type="field.type"
          :disabled="field.disabled"
          :required="field.required"
          @isValid="formDataValid[field.extraOptions.key] = $event"
          :extraOptions="{
            ...field.extraOptions,
            requiredFormFieldsValid: requiredFormFieldsValid,
          }"
        />
      </div>

      <div class="text-left">
        <span
          ><i class="far fa-asterisk"></i>
          {{ trans("center.requiredField") }}</span
        >
      </div>
    </div>
  </field-panel>
</template>

<script>
import FieldPanel from "./components/FieldPanel.vue";
import { FIELD_CONSTANTS } from "../fieldMap";
import { getFieldType } from "../../../../helpers/product";
import { STORE_CONSTANTS } from "../../../../helpers/definitions";
import notifications from "../../../Utils/notifications";
import ClipLoader from "../../../Utils/ClipLoader.vue";
import _noop from "lodash/fp/noop";

export default {
  props: {
    fields: Array,
    examination: Object,
    product: Object,
    viewOption: String,
  },
  mounted: function() {
    this.disableFields = this.examination.study_workflow_completed;
    this.mapData();
    this.addFieldsToValidation();
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      title: "examination.diagnosiskHeadline",
      loading: false,
      formData: {},
      formDataValid: {},
      disableFields: false,
    };
  },
  watch: {
    examination() {
      this.mapData();
      this.addFieldsToValidation();
    },
  },
  computed: {
    requiredFormFieldsValid() {
      return Object.values(this.formDataValid).every(value => value);
    },
  },
  /**
   * Temporary solution to be able to push out the actual API request and enable
   * writing unit tests and stories, while not breaking the current generic
   * product implementation in FE.
   * @todo Take this in mind when writing generic products v2.
   */
  inject: {
    saveStudyData: { from: "SAVE_STUDY_DATA_ACTION", default: _noop },
  },
  methods: {
    mapData: function() {
      const { fields, examination } = this;
      const examinationData = [];
      fields.forEach(field => {
        if (field.visible) {
          const valueOptions = JSON.parse(
            JSON.stringify(JSON.parse(field.value_options))
          );
          let extraOptions = undefined;
          if (field.extra_options) {
            extraOptions = JSON.parse(
              JSON.stringify(JSON.parse(field.extra_options))
            );
          }
          examinationData.push({
            /**
             * This was the mapping from `name` to `title` from the product
             * definition to this "view-model" used for the fields. Just to have
             * some clear code :D. I'm adding a check that when we use the new
             * guidelines on keys for this data in the actual `title` property,
             * we'll use that instead, so that we can start using the actual
             * title as label in the form, and not mess up the other usages of
             * `name` elsewhere. See the docs on the type for more information.
             *
             * @see {import("../../../../../../../../app/Dermicus/Products/Field.ts").Field}
             */
            title: field.title?.startsWith("data.") ? field.title : field.name,
            data: valueOptions
              ? valueOptions.map(option => ({
                  /**
                   * Again another mapping to confuse developers, `title` going to
                   * `label`, and translations being done with a prefix you can't
                   * know when building product definitions.... That said, we have
                   * added the ability to translate DB data in other way and thus
                   * need to keep that in mind here. Same as the `title` above.
                   *
                   * (!) Also, why do we translate this here, but other things
                   * lower, AND we also try to translate this again further down
                   * the line....
                   *
                   * @todo fix by upgrading how data translations are done.
                   */
                  label: option.title?.startsWith("data.")
                    ? this.trans(option.title)
                    : this.trans(`examination.${option.title}`),
                  value: option.data,
                }))
              : [],
            type: getFieldType(field),
            disabled: field.disabled || this.disableFields,
            required: !!field.required,
            extraOptions: {
              key: field.name,
              valueOptions: valueOptions,
              examination: examination,
              onValueChange: this.onValueChange,
              onClick: this.onSubmit,
              fieldOptions: extraOptions,
            },
          });
        }
      });
      this.examinationData = examinationData;
    },
    addFieldsToValidation: function() {
      const formDataValid = { ...this.formDataValid };
      for (const field of this.fields) {
        formDataValid[field.name] =
          formDataValid[field.name] ?? !field.required;
      }
      this.formDataValid = formDataValid;
    },
    onValueChange: function(input) {
      this.formData = {
        ...this.formData,
        [input.key]: input.value,
      };
    },
    onSubmit: function() {
      this.loading = true;
      return this.saveStudyData({
        product: this.product,
        examination: this.examination,
        results: this.formData,
      })
        .then(async () => {
          await this.$store?.dispatch(STORE_CONSTANTS.LOAD_STUDY_WORKFLOW, {
            userId: this.$store.state.user.id,
            studyExaminationId: this.$route.params.id,
          });

          notifications.saveSuccess();

          if (this.examination.study_workflow_completed) {
            this.$router.push({
              name: "research",
              query: { studyId: this.product.meta.study_id },
            });
          }
        })
        .catch(error => {
          console.log("save results error: ", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  components: {
    FieldPanel,
    ClipLoader,
  },
};
</script>
