<template>
  <div>
    <div class="row mt-1">
      <div class="col-md-4 mb-2">
        <label>{{ trans("examination.diagnosiskPlanedHeadline") }}</label>
        <v-select
          name="plannedDiagnosisId"
          :options="plannedDiagnoses"
          v-model="searchFields.plannedDiagnosisId"
          label="text"
          :reduce="plannedDiagnosis => plannedDiagnosis.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{
          trans("examination.diagnosisCodeDermatoskopiskLabel")
        }}</label>
        <v-select
          name="dermatoscopicDiagnosisId"
          :options="dermatoscopicDiagnoses"
          v-model="searchFields.dermatoscopicDiagnosisId"
          label="text"
          :reduce="diagnosis => diagnosis.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{
          trans("examination.diagnosiscodeHistopatologiskLabel")
        }}</label>
        <v-select
          name="histopathologicalDiagnosisId"
          :options="histopathologicalDiagnoses"
          v-model="searchFields.histopathologicalDiagnosisId"
          label="text"
          :reduce="histoDiagnosis => histoDiagnosis.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>

      <div class="col-md-4 mb-2">
        <label>{{ trans("examination.tagsLabel") }}</label>
        <v-select
          name="tags"
          :options="tags"
          v-model="searchFields.tags"
          label="text"
          :reduce="tag => tag.id"
          :placeholder="trans('examination.externalResourceSelectPlaceholder')"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";

export default {
  props: ["searchFields", "searchOptions"],
  mounted: function() {
    this.loadData();
  },
  data() {
    return {
      dermatoscopicDiagnoses: [],
      plannedDiagnoses: [],
      histopathologicalDiagnoses: [],
      tags: [],
    };
  },
  methods: {
    loadData: function() {
      axios
        .get("/api/internal/search/filter-lists/11")
        .then(data => {
          let collection = data.data;

          this.dermatoscopicDiagnoses = collection.dermatoscopicDiagnoses;
          this.plannedDiagnoses = collection.plannedDiagnoses;
          this.histopathologicalDiagnoses =
            collection.histopathologicalDiagnoses;
          this.tags = collection.tags;
          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
  },
  components: {
    Datepicker,
  },
};
</script>
