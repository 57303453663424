<template>
  <tr
    :class="[
      expandedPatientExamination ? 'expanded-row' : '',
      'patient-examination-row',
    ]"
  >
    <td>
      <div
        class="d-flex flex-wrap align-items-center"
        v-if="$can('prioritise_cases')"
      >
        <bookmark-icon
          class="consultations-list-icon"
          v-if="examinationCollection.main_examination.isBookmarked"
        /><flag-icon
          class="consultations-list-icon"
          v-if="examinationCollection.main_examination.isFlagged"
        /><priority-icon
          class="consultations-list-icon"
          v-if="examinationCollection.main_examination.isPrioritized"
        />
      </div>
    </td>

    <!-- TODO: decide on under_score or camelCase. It would be cleaner and easier to use under_score on all value -->
    <td style="word-break: break-all;">
      {{ examinationCollection.main_examination.caseNo }}
    </td>
    <td>{{ location }}</td>
    <td v-if="isExpanded">
      {{ examinationCollection.main_examination.createdAt }}
    </td>
    <td v-if="isExpanded">
      {{ examinationCollection.main_examination.createdUser }}
    </td>
    <td>
      <case-status
        :hideLabel="true"
        :examination="examinationCollection.main_examination"
      />
    </td>
    <td class="text-right">
      <button
        type="button"
        data-toggle="collapse"
        @click="toggleExamination"
        :class="[
          expandedPatientExamination ? 'btn-primary' : 'btn-secondary',
          'btn',
        ]"
        aria-expanded="false"
        :data-target="[
          '#patientExamination_' + examinationCollection.main_examination.id,
        ]"
      >
        <span
          ><span class="hidden-xs">{{
            expandedPatientExamination
              ? trans("center.showLess")
              : trans("center.showMore")
          }}</span>
          <i
            class="far fa-angle-down"
            :class="[
              expandedPatientExamination ? 'fa-angle-up' : 'fa-angle-down',
              'far',
            ]"
          ></i
        ></span>
      </button>
    </td>
  </tr>
</template>

<script>
import CaseStatus from "../../../Examinations/Examination/ExaminationComponents/components/CaseStatus.vue";
import BookmarkIcon from "../../../Utils/ActionIcons/BookmarkIcon.vue";
import FlagIcon from "../../../Utils/ActionIcons/FlagIcon.vue";
import PriorityIcon from "../../../Utils/ActionIcons/PriorityIcon.vue";

import { URLS } from "../../../../config";

export default {
  props: {
    examinationCollection: {
      main_examination: Object,
    },
    isExpanded: Boolean,
  },
  mounted: function() {
    this.mapLocation();
  },
  data() {
    return {
      location: undefined,
      expandedPatientExamination: false,
    };
  },
  methods: {
    toggleExamination: function() {
      this.expandedPatientExamination = !this.expandedPatientExamination;

      if (
        this.expandedPatientExamination &&
        this.examinationCollection?.main_examination?.id
      ) {
        this.deleteDocumentationNotifications();
      }
    },
    deleteDocumentationNotifications: function() {
      axios
        .delete(
          `${URLS.PATIENT_DELETE_DOCUMENTATION_NOTIFICATION}/${this.examinationCollection.main_examination.id}`
        )
        .catch(error => {
          console.log(
            "error updating patient documenation notification: ",
            error
          );
        });
    },
    mapLocation: function() {
      const { main_examination } = this.examinationCollection;
      if ("case_data" in main_examination) {
        const { case_data } = main_examination;

        let locationField;
        if ("lesion_location" in case_data)
          locationField = case_data.lesion_location;
        else if ("ulcer_location" in case_data)
          locationField = case_data.ulcer_location;
        else return;

        let result = "";
        if (
          typeof locationField === "string" ||
          locationField instanceof String
        ) {
          result = this.trans(`examination.${locationField}`);
        } else if (locationField) {
          Object.keys(locationField).forEach(location => {
            const translated = this.trans(`examination.${location}`);
            if (locationField[location])
              result += result === "" ? translated : `/${translated}`;
          });
        }

        this.location = result;
      }
    },
  },
  components: {
    CaseStatus,
    BookmarkIcon,
    FlagIcon,
    PriorityIcon,
  },
};
</script>

<style lang="scss" scoped>
.expanded-row {
  background-color: #f5f5f5;
  border: 2px solid #f5f5f5;

  &:hover {
    background-color: #f5f5f5;
  }
}

.patient-examination-row {
  td:first-of-type {
    border-left: 2px solid #f5f5f5;
  }

  td:last-of-type {
    border-right: 2px solid #f5f5f5;
  }

  td {
    border-top: 2px solid #f5f5f5;
    padding: 0.5rem;
  }

  .consultations-list-icon {
    padding: 0px 2px;
  }
}

.far.fa-angle-down,
.far.fa-angle-up {
  font-size: large;
  vertical-align: middle;
}

.btn {
  min-width: 120px;
}

@media (max-width: 700px) {
  .btn {
    min-width: auto;
  }
}
</style>
