<template>
  <div
    class="modal fade"
    :id="`editExaminationModal-${examination.caseNo}`"
    tabindex="-1"
    role="dialog"
    aria-labelledby="editExamination"
    aria-hidden="true"
  >
    <div class="modal-dialog edit-examination-modal-dialog" role="document">
      <div class="edit-examination-container modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ examination.caseNo }}</h5>
          <button
            type="button"
            :disabled="loading"
            class="close"
            aria-label="Close"
            @click="onCancelModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <clip-loader v-if="loading" />
        <div v-else class="modal-body edit-examination-body">
          <div class="input-item" v-for="field in fields" :key="field.id">
            <field
              :examinationId="examination.id"
              :title="field.title"
              :data="field.data"
              :type="field.type"
              :formValue="field.formValue"
              :extraOptions="field.extraOptions"
              :valueOptions="field.valueOptions"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            :disabled="loading"
            class="btn btn-secondary"
            @click="onCancelModal"
          >
            {{ trans("general.modalCancelButton") }}
          </button>
          <button
            :disabled="!enableSave"
            type="button"
            class="btn btn-primary"
            @click="onSaveValues"
          >
            {{ trans("general.formSaveLabel") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFormValue } from "../../../../../helpers/product";
import ClipLoader from "../../../../Utils/ClipLoader.vue";
import { editableFieldMap } from "../../fieldMap";

export default {
  components: { ClipLoader },
  props: {
    examination: Object,
    onSave: Function,
    onCancel: Function,
    productDefinition: Object,
    visible: Boolean,
    loading: Boolean,
    editType: String,
  },
  data() {
    return {
      fields: [],
      values: {},
      enableSave: false,
    };
  },
  mounted: function() {
    this.mapData();
    $(`#editExaminationModal-${this.examination.caseNo}`).modal("show");

    $(`#editExaminationModal-${this.examination.caseNo}`).on(
      "hidden.bs.modal",
      () => this.onCancelModal()
    );
  },
  watch: {
    visible: function() {
      this.showModal();
    },
  },
  methods: {
    onCancelModal: function() {
      if (Object.keys(this.values).length > 0) {
        this.fields = [];
        this.values = {};
      }

      this.$emit("on-cancel");
      $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
    },
    onSaveValues: function() {
      this.enableSave = false;
      this.$emit("on-save", this.values);
    },
    showModal: function() {
      if (this.visible) {
        this.mapData();
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("show");
      } else {
        $(`#editExaminationModal-${this.examination.caseNo}`).modal("hide");
      }
    },
    mapData: function() {
      const { productDefinition, examination } = this;
      const editableFields = [];
      const caseDataGroup = productDefinition.field_groups.find(
        group => group.name === this.editType
      );
      if (caseDataGroup && "frontend_fields" in caseDataGroup) {
        const fields = caseDataGroup.frontend_fields;
        fields.forEach(field => {
          const hasValue = field.name in examination[this.editType];
          if (field.visible) {
            const valueOptions = JSON.parse(
              JSON.stringify(JSON.parse(field.value_options))
            );
            editableFields.push({
              title: field.name,
              data: this.getData(valueOptions),
              type: editableFieldMap[field.type],
              disabled: field.disabled,
              formValue: hasValue
                ? getFormValue(
                    examination[this.editType][field.name],
                    this.trans
                  )
                : "",
              extraOptions: {
                onValueChange: this.onValueChange,
                key: field.name,
              },
              valueOptions,
            });
          }
        });
        this.fields = editableFields;
      }
    },
    getData: function(valueOptions) {
      if (valueOptions !== null) {
        const mapped = valueOptions.reduce((memo, current) => {
          if (current.range) {
            let i = current.range.start;
            while (i <= current.range.end) {
              const val = Math.round(i * 10) / 10;
              const value = current.postfix ? `${val}${current.postfix}` : val;
              const label = `${value}`;
              memo.push({ value, label });
              i += current.range.step;
            }
          } else {
            memo.push({
              value: current.data,
              label: this.trans(`examination.${current.title}`),
            });
          }
          return memo;
        }, []);
        return mapped;
      }
      return "";
    },
    onValueChange: function(input) {
      this.enableSave = true;
      const isBatch = Array.isArray(input.value);
      this.values[input.key] = isBatch
        ? JSON.stringify(input.value.map(val => val.value))
        : input.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-examination-container {
  background-color: white;
}
.edit-examination-modal-dialog {
  max-width: 80%;
}
.edit-examination-body {
  display: grid;
  grid-template-columns: 50% 50%;
}
.input-item {
  margin: 0 1rem 1rem 1rem;
}
@media screen and (max-width: 700px) {
  .edit-examination-body {
    display: grid;
    grid-template-columns: 100%;
  }
}
</style>
