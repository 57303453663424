<template>
  <div>
    <field-panel
      :title="title"
      :infoPopupText="trans('infoText.diagnosisAndTreatment')"
    >
      <div class="panel-body">
        <clip-loader v-show="loading"></clip-loader>
        <div v-if="examinationData.length > 0" class="row align-items-end">
          <column v-for="(field, index) in examinationData" :key="index">
            <field
              :examinationId="examination.id"
              :title="field.title"
              :data="field.data"
              :type="field.type"
              :disabled="field.disabled"
              :formValue="field.formValue"
              :extraOptions="field.extraOptions"
            />
          </column>
        </div>
      </div>
    </field-panel>

    <field-panel
      class="mt-3"
      :title="documentationTitle"
      :infoPopupText="getInfoPopupText()"
      :newDocumentationButton="
        !addNewDocumentation
          ? {
              onAddNewDocumentation: this.onAddNewDocumentation,
            }
          : null
      "
      :totalComments="totalComments"
    >
      <examination-documentation
        v-if="examinationData.length"
        :examinationId="
          examination.main_examination_id
            ? examination.main_examination_id
            : examination.id
        "
        :enabledTypes="enabledTypes"
        :addNewDocumentation="addNewDocumentation"
        @dismissAddNewDocumentation="dismissAddNewDocumentation"
        @setTotalComments="setTotalComments"
      ></examination-documentation>
    </field-panel>
  </div>
</template>

<script>
import Column from "./components/Column.vue";
import SubColumn from "./components/SubColumn.vue";
import FieldPanel from "./components/FieldPanel.vue";
import ExaminationDocumentation from "../../../Examinations/Examination/ExaminationComponents/ExaminationDocumentation.vue";
import Notifications from "../../../Utils/notifications";

import { FIELD_CONSTANTS } from "../fieldMap";
import {
  getEnabledDocumentationTypes,
  getFieldType,
} from "../../../../helpers/product";
import { STORE_CONSTANTS } from "../../../../helpers/definitions";
import { URLS } from "../../../../config";
import { infoPopupText } from "../../../../helpers/misc";

export default {
  mounted: function() {
    this.mapData();
    const product = this.$store.state.products[this.examination.type.id];
    this.enabledTypes = getEnabledDocumentationTypes(product);
  },
  props: {
    fields: Array,
    examination: Object,
    fieldGroupName: String,
  },
  data() {
    return {
      examinationData: [],
      FIELD_CONSTANTS: FIELD_CONSTANTS,
      title: "examination.diagnosisAndTreatmentHeadline",
      documentationTitle: "examination.consultBoxLabel",
      enabledTypes: [],
      loading: false,
      addNewDocumentation: false,
      totalComments: null,
    };
  },
  watch: {
    examination() {
      this.mapData();
    },
  },
  methods: {
    mapData: function() {
      const { fields, examination } = this;
      const examinationData = [];
      fields.forEach(field => {
        const valueOptions = JSON.parse(
          JSON.stringify(JSON.parse(field.value_options))
        );
        if (field.visible) {
          examinationData.push({
            title: field.name,
            data: valueOptions
              ? valueOptions.map(option => ({
                  label: this.trans(`examination.${option.title}`),
                  value: option.data,
                }))
              : "",
            type: getFieldType(field),
            disabled: field.disabled,
            formValue: this.getFormValue(
              examination?.diagnosis_form?.[field.name]
            ),
            extraOptions: {
              onValueChange: this.onValueChange,
              key: field.name,
              postUrl: `${URLS.TUMOUR_WOUND_DIAGNOS_ACTION}/${examination.id}`,
            },
          });
        }
      });
      this.examinationData = examinationData;
    },
    getFormValue: function(formValue) {
      if (!formValue) return;
      const hasMultipleValues =
        typeof formValue === "object" && formValue.constructor === Object;
      if (hasMultipleValues && formValue) {
        const result = [];
        Object.keys(formValue).forEach(val => {
          if (formValue[val]) {
            result.push({
              label: this.trans(`examination.${val}`),
              value: val,
            });
          }
        });
        return result;
      }
      return {
        label: this.trans(`examination.${formValue}`),
        value: formValue,
      };
    },
    onValueChange: async function(input) {
      const isBatch = Array.isArray(input.value);
      const success = await this.postValues({
        resources: isBatch
          ? JSON.stringify(input.value.map(val => val.value))
          : input.value,
        key: input.key,
      });
      if (success) Notifications.saveSuccess(this);
      this.$store.dispatch(STORE_CONSTANTS.LOAD_PATIENT_CASES);
    },
    postValues: async function(formData) {
      const postUrl = `${URLS.TUMOUR_WOUND_DIAGNOS_ACTION}/${this.examination.id}`;
      this.loading = true;
      try {
        const result = await axios.post(postUrl, formData);
        this.loading = false;
        return true;
      } catch (e) {
        this.loading = false;
        this.userErrorMessage(e);
      }
    },
    onAddNewDocumentation: function() {
      this.addNewDocumentation = true;
    },
    dismissAddNewDocumentation: function(bool) {
      this.addNewDocumentation = bool;
    },
    setTotalComments: function(num) {
      this.totalComments = num;
    },
    getInfoPopupText: function() {
      return this.enabledTypes.length > 0
        ? infoPopupText(this.enabledTypes, this.fieldGroupName)
        : "";
    },
  },
  components: {
    Column,
    SubColumn,
    FieldPanel,
    ExaminationDocumentation,
  },
};
</script>
