<template>
  <div class="tab-pane" id="take_care_files">
    <div id="custom-search-input">
      <form class="input-group col-md-12" @submit.prevent="search">
        <input
          type="text"
          class="form-control input-lg"
          id="takeCareSearchInput"
          v-model="searchFields.case_no"
          :placeholder="trans('forms.searchForCaseNumber')"
          :title="trans('general.search')"
        />
        <span class="input-group-btn">
          <button
            class="btn"
            type="button"
            @click.stop="clearSearch"
            v-if="isSearching"
          >
            <i class="fal fa-times"></i>
          </button>
          <button class="btn" type="button" @click.stop="search">
            <i class="far fa-search"></i>
          </button>
        </span>
      </form>
    </div>
    <div class="mb-3 advanced-search">
      <div class="row mb-3">
        <div class="col-md-3">
          <label for="center">{{
            trans("evaluation.tableColumnCenter")
          }}</label>
          <select2
            :data-placeholder="trans('forms.chooseCenterPlaceholder')"
            id="center"
            name="center"
            v-model="searchFields.center_id"
          >
            <option disabled></option>
            <option
              v-for="(center, index) in centers"
              :value="center.id"
              :key="index"
              >{{ center.name }}
            </option>
          </select2>
        </div>

        <div class="col-md-3">
          <label for="requisition_id">Requisition id</label>
          <input
            type="text"
            class="form-control input-lg"
            id="requisition_id"
            name="requisition_id"
            v-model="searchFields.requisition_id"
          />
        </div>

        <div class="col-md-3">
          <label>{{ trans("evaluation.tableColumnCreatedAtFrom") }}</label>
          <custom-datepicker
            @onDateChange="changeDates('dateFrom', $event)"
            :date="searchFields.dateFrom"
            :placeholder="trans('evaluation.tableColumnCreatedAtFrom')"
          ></custom-datepicker>
        </div>
        <div class="col-md-3">
          <label>{{ trans("evaluation.tableColumnCreatedAtTo") }}</label>
          <custom-datepicker
            @onDateChange="changeDates('dateUntil', $event)"
            :date="searchFields.dateUntil"
            :placeholder="trans('evaluation.tableColumnCreatedAtTo')"
          ></custom-datepicker>
        </div>
      </div>
    </div>

    <clip-loader v-if="loading"></clip-loader>

    <div class="table-responsive">
      <table class="table table-hover">
        <thead class="sortable-table-header">
          <tr>
            <th>Center</th>
            <th>Case created by</th>
            <th>Type</th>
            <th>Examination</th>
            <th>Requisition id</th>
            <th>Comment by</th>
            <th>Received</th>
            <th>Handled at</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(log, index) in take_care_files.logs" :key="index">
            <td>{{ log.center }}</td>
            <td>{{ log.examination_user }}</td>
            <td>{{ log.type }}</td>
            <td>{{ log.examination }}</td>
            <td>{{ log.requisition_id }}</td>
            <td>{{ log.comment_user }}</td>
            <td>{{ log.is_received }}</td>
            <td>{{ log.file_handled_at }}</td>
            <td>
              <a
                data-toggle="modal"
                data-target="#takeCareDetail"
                class="btn"
                @click="getTakeCareLogDetail(log.id)"
                >Details</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row-fluid pull-right">
      <paginate
        :pageCount="paginate.last_page"
        :click-handler="getTakeCareLogs"
        :prevText="'‹'"
        :nextText="'›'"
        :containerClass="'pagination'"
        ref="paginate"
        v-model="paginate"
        :prev-text="'‹'"
        :next-text="'›'"
        :page-class="'page-item'"
        :page-link-class="'page-link'"
        :active-class="'page-item active'"
        :prev-class="'page-item'"
        :prev-link-class="'page-link'"
        :next-class="'page-item'"
        :next-link-class="'page-link'"
      >
      </paginate>
    </div>

    <div
      class="modal fade"
      id="takeCareDetail"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <table class="table">
              <tr>
                <th>Id</th>
                <td>{{ takeCareLogDetail.id }}</td>
              </tr>

              <tr>
                <th>Center</th>
                <td>{{ takeCareLogDetail.center }}</td>
              </tr>

              <tr>
                <th>Type</th>
                <td>{{ takeCareLogDetail.type }}</td>
              </tr>

              <tr>
                <th>Direction</th>
                <td>{{ takeCareLogDetail.direction }}</td>
              </tr>

              <tr>
                <th>Examination no</th>
                <td>{{ takeCareLogDetail.examination }}</td>
              </tr>

              <tr>
                <th>Requisition id</th>
                <td>{{ takeCareLogDetail.requisition_id }}</td>
              </tr>

              <tr>
                <th>Examination created by</th>
                <td>{{ takeCareLogDetail.examination_user }}</td>
              </tr>

              <tr>
                <th>Created at</th>
                <td>{{ takeCareLogDetail.referral_created_at }}</td>
              </tr>

              <tr>
                <th>Consultation answered by</th>
                <td>{{ takeCareLogDetail.comment_user }}</td>
              </tr>

              <tr>
                <th>File name</th>
                <td>{{ takeCareLogDetail.filename }}</td>
              </tr>

              <tr>
                <th>File handled at</th>
                <td>{{ takeCareLogDetail.file_handled_at }}</td>
              </tr>

              <tr>
                <th>Message id</th>
                <td>{{ takeCareLogDetail.message_id }}</td>
              </tr>

              <tr>
                <th>Photographer</th>
                <td>{{ takeCareLogDetail.photographed_user }}</td>
              </tr>

              <tr>
                <th>Photographed at</th>
                <td>{{ takeCareLogDetail.photographed_at }}</td>
              </tr>

              <tr>
                <th>
                  XML file
                </th>
                <td></td>
              </tr>
            </table>

            <pre lang="xml">
                  {{ takeCareLogDetail.xml }}
                </pre
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created: function() {
    this.getCenters();
    this.getTakeCareLogs();
  },
  data() {
    return {
      loading: true,
      paginate: {
        current_page: 0,
        total: 0,
        last_page: 0,
      },
      take_care_files: {
        logs: [],
      },
      isSearching: false,
      centers: [],
      searchFields: {
        center_id: "",
        case_no: null,
        requisition_id: null,
        dateFrom: "",
        dateUntil: "",
      },
      takeCareLogDetail: [],
    };
  },
  watch: {
    paginate: function(page) {
      this.$refs.paginate.selected = this.paginate.current_page - 1;
    },
  },
  methods: {
    getTakeCareLogs(page) {
      this.loading = true;

      if (typeof page === "undefined") {
        page = 1;
      }

      axios
        .get("/api/internal/admin/logs/take-care?page=" + page)
        .then(data => {
          var collection = data.data;
          this.paginate = collection.paginate;
          this.take_care_files.logs = collection.data;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    search: function() {
      this.loading = true;
      this.isSearching = true;

      axios
        .get(
          "/api/internal/admin/logs/take-care?" +
            queryStringBuilder(this.searchFields)
        )
        .then(data => {
          var collection = data.data;
          this.take_care_files.logs = collection.data;
          this.paginate = collection.paginate;
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    clearSearch: function() {
      Object.assign(
        this.$data.searchFields,
        this.$options.data.call(this).searchFields
      );
      this.isSearching = false;
      this.getTakeCareLogs(1);
    },
    getTakeCareLogDetail: function(id) {
      this.loading = true;

      const xmlbeautify = require("xml-beautifier");

      axios
        .get("/api/internal/admin/logs/take-care-detail/" + id)
        .then(data => {
          const collection = data.data.data;
          this.takeCareLogDetail = collection;

          try {
            let formattedXml = xmlbeautify(collection.xml);
            this.takeCareLogDetail.xml = formattedXml;
          } catch (error) {
            console.error(error);
          }

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    changeDates: function(target, date) {
      if (target === "dateFrom") {
        this.searchFields.dateFrom = date;
      } else {
        this.searchFields.dateUntil = date;
      }
    },
    getCenters: function() {
      axios
        .get("/api/internal/admin/all-centers")
        .then(data => {
          this.centers = data.data;
        })
        .catch(error => {
          console.log("Get centers error: ", error);
        });
    },
  },
  filters: {
    pretty: function(value) {
      return JSON.stringify(JSON.parse(value), null, 2);
    },
  },
};
</script>
