<template>
  <div>
    <ul class="nav nav-tabs nav tabs-bordered" role="tablist">
      <li :class="{ active: selectedMenuItem == 1 }">
        <router-link
          :to="`/${routePath}/settings`"
          data-toggle="tab"
          role="tab"
        >
          <span>{{ trans("center.groupMenuLabelSettings") }}</span>
        </router-link>
      </li>
      <li :class="{ active: selectedMenuItem == 2 }">
        <router-link :to="`/${routePath}/users`" data-toggle="tab" role="tab">
          <span>{{ trans("center.centerUsers") }}</span>
        </router-link>
      </li>
      <li :class="{ active: selectedMenuItem == 3 }">
        <router-link :to="`/${routePath}/groups`" data-toggle="tab" role="tab">
          <span>{{ trans("center.groupMenuLabelGroups") }}</span>
        </router-link>
      </li>
      <li
        :class="{ active: selectedMenuItem == 4 }"
        v-if="!this.$route.path.includes('region')"
      >
        <router-link to="/center/patients" data-toggle="tab" role="tab">
          <span>{{ trans("menu.patients") }}</span>
        </router-link>
      </li>
      <li
        :class="{ active: selectedMenuItem == 5 }"
        v-if="!this.$route.path.includes('region')"
      >
        <router-link to="/center/export" data-toggle="tab" role="tab">
          <span>{{ trans("center.centerExport") }}</span>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["selectedMenuItem"],
  computed: {
    routePath: function() {
      if (this.$route.path.includes("region")) {
        return `region/centers/${this.$route.params.id}`;
      }
      return "center";
    },
  },
};
</script>
