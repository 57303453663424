<template>
  <div class="col-lg-12">
    <center-header :selected-menu-item="2"></center-header>

    <div class="tab-content">
      <ul class="nav nav-tabs">
        <li
          @click="selectTab(1)"
          :class="{ active: selectedTab == 1 }"
          class="nav-item"
        >
          <a
            href="#centerUsers"
            data-toggle="tab"
            aria-expanded="false"
            class="nav-link"
            :class="{ active: selectedTab == 1 }"
          >
            <span>{{ trans("center.primaryUsers") }}</span>
          </a>
        </li>
        <li
          @click="selectTab(2)"
          :class="{ active: selectedTab == 2 }"
          class="nav-item"
        >
          <a
            href="#connectedUsers"
            data-toggle="tab"
            aria-expanded="false"
            class="nav-link"
            :class="{ active: selectedTab == 2 }"
          >
            <span>{{ trans("center.groupTableConnectedUsers") }}</span>
          </a>
        </li>
        <li
          @click="selectTab(3)"
          :class="{ active: selectedTab == 3 }"
          class="nav-item"
        >
          <a
            href="#inactiveUsers"
            data-toggle="tab"
            aria-expanded="false"
            class="nav-link"
            :class="{ active: selectedTab == 3 }"
          >
            <span>{{ trans("users.inactiveUsersLabel") }}</span>
          </a>
        </li>
      </ul>

      <div class="tab-content table-responsive">
        <clip-loader v-if="loading"></clip-loader>
        <table class="table table-hover">
          <thead class="sortable-table-header">
            <tr>
              <th scope="col">{{ trans("users.personalInfoFullName") }}</th>
              <th scope="col">{{ trans("users.emailLabel") }}</th>
              <th scope="col">{{ trans("users.contactNoLabel") }}</th>
              <th scope="col">{{ trans("users.userRoleLabel") }}</th>
              <th scope="col">{{ trans("users.jobTitleLabel") }}</th>
              <th scope="col">&nbsp;</th>
            </tr>
          </thead>

          <tbody class="tab-pane" v-show="selectedTab === 1">
            <template
              v-for="(user, index) in activeUsers"
              v-show="activeUsers.length > 0"
            >
              <user :user="user" :key="index"></user>

              <tr :key="`${index}-${user.id}`">
                <td colspan="6" class="hiddenRow">
                  <user-options
                    :userData="user"
                    @userSettingsChanges="updateUsers"
                    :dataUrl="dataUrl"
                    :centerData="centerData"
                    :viewAsRegionAdmin="viewAsRegionAdmin"
                  ></user-options>
                </td>
              </tr>
            </template>

            <td colspan="6">
              <div
                v-if="
                  selectedTab === 1 && noUsers && !addingNewUser && !loading
                "
              >
                <h6 class="text-center">
                  {{ trans("center.noUsersInfoMessage") }}
                </h6>
              </div>
              <new-user
                v-if="centerData"
                class="p-0"
                :centerData="centerData"
                @newUserAdded="updateUsers"
                :dataUrl="dataUrl"
                @addingUser="addingUser"
                :viewAsRegionAdmin="this.viewAsRegionAdmin"
              ></new-user>
            </td>
          </tbody>

          <tbody
            class="tab-pane"
            v-show="selectedTab === 2 && activeConnectedUsers.length > 0"
          >
            <template v-for="(connectedUser, index) in activeConnectedUsers">
              <tr :key="index">
                <td>
                  {{ connectedUser.full_name }}
                </td>
                <td>{{ connectedUser.email }}</td>
                <td>{{ connectedUser.phone_no }}</td>
                <td>
                  {{ trans("roles." + dropdownRole(connectedUser)) }}
                </td>
                <td>{{ connectedUser.title }}</td>
                <td></td>
              </tr>
            </template>
          </tbody>

          <tbody class="tab-pane" v-show="selectedTab === 3">
            <template
              v-for="(user, index) in inactiveUsers"
              v-show="inactiveUsers.length > 0"
            >
              <user :user="user" :key="index"></user>

              <tr :key="`${index}-${user.id}`">
                <td colspan="6" class="hiddenRow">
                  <user-options
                    :userData="user"
                    @userSettingsChanges="updateUsers"
                    :dataUrl="dataUrl"
                    :centerData="centerData"
                    :viewAsRegionAdmin="viewAsRegionAdmin"
                  ></user-options>
                </td>
              </tr>
            </template>

            <td colspan="6">
              <div
                v-if="
                  selectedTab === 3 && inactiveUsers.length === 0 && !loading
                "
              >
                <h6 class="text-center">
                  {{ trans("center.noInactiveUsersInfoMessage") }}
                </h6>
              </div>
            </td>
          </tbody>
        </table>

        <div v-if="selectedTab === 2 && noConnectedUsers && !loading">
          <h6 class="text-center">
            {{ trans("center.noConnectedUsersInfoMessage") }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CenterHeader from "../CenterHeader.vue";
import UserOptions from "./UserOptions.vue";
import User from "./User.vue";
import NewUser from "./NewUser.vue";
import DropdownRole from "../../../mixins/DropdownRole.vue";
import URLS from "../../../config/urls";
import { centerSettingsGet } from "../../../store/core/center-settings";

export default {
  mixins: [DropdownRole],
  props: {
    dataCenterId: Number,
    viewAsRegionAdmin: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function() {
    this.assignUrl();
  },
  watch: {
    dataCenterId: {
      immediate: true,
      handler() {
        this.loadUsers();
      },
    },
  },
  data() {
    return {
      loading: true,
      selectedTab: 1,
      users: [],
      connectedUsers: [
        {
          id: "",
          email: "",
          firstname: "",
          lastname: "",
          phone_no: "",
          roles: [],
          title: "",
        },
      ],
      addingNewUser: false,
      dataUrl: null,
      centerData: null,
    };
  },
  methods: {
    assignUrl: function() {
      if (this.viewAsRegionAdmin) {
        this.dataUrl = URLS.REGION_ADMIN_URL;
      } else {
        this.dataUrl = URLS.CENTER_ADMIN_URL;
      }
    },
    loadUsers: function() {
      this.loading = true;

      centerSettingsGet(this.dataCenterId)
        .then(data => {
          this.centerData = data;
          this.users = data.users.primaryCenters;
          this.connectedUsers = data.users.resource;

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    updateUsers: function() {
      this.loadUsers();
    },
    selectTab(tabNumber) {
      this.selectedTab = tabNumber;
    },
    addingUser: function(val) {
      this.addingNewUser = val;
    },
  },
  computed: {
    noConnectedUsers: function() {
      if (this.connectedUsers.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    noUsers: function() {
      if (this.users.length === 0) {
        return true;
      } else {
        return false;
      }
    },
    inactiveUsers: function() {
      return this.users.filter(user => user.blocked);
    },
    activeUsers: function() {
      return this.users.filter(user => !user.blocked);
    },
    activeConnectedUsers: function() {
      return this.connectedUsers.filter(user => !user.blocked);
    },
  },
  components: {
    CenterHeader,
    UserOptions,
    User,
    NewUser,
  },
};
</script>

<style lang="scss" scoped>
.table .thead-light th {
  background-color: #f5f5f5 !important;
  border: 3px solid #f5f5f5;
}

.hiddenRow {
  padding: 0 !important;
  background: #f5f5f5;
  position: relative;
  top: -5px;
  border-top: none !important;

  &:hover {
    color: #676a6c !important;
  }
}

.table > thead > tr > th {
  padding: 1rem !important;
}

table {
  border-collapse: separate;
  border-spacing: 0 0.3rem;
}

tr td {
  border-bottom: 1px solid #ddd !important;
}

tr td:first-of-type {
  border-left: 1px solid #ddd;
}

tr td:last-of-type {
  border-right: 1px solid #ddd;
}

table > tbody > td:last-child {
  border: none;
}

.connected-users-remove-btn {
  display: block;
  margin: 0 auto;
  min-width: 175px;
}
</style>
