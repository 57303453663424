import { Patient } from "./../../../../../app/Dermicus/Patients/Patient";
import { UseQueryOptions } from "@tanstack/vue-query";
import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";

export function patientGet(patientId: string): UseQueryOptions<Patient> {
  return {
    queryKey: ["patient", patientId],
    queryFn: () =>
      practitionerHttpService
        .get(`/api/internal/patients/${patientId}`)
        .then(response => response.data),
  };
}
