<template>
  <div>
    <clip-loader v-show="loading"></clip-loader>
    <div v-if="!loading">
      <div v-if="patient && examination && productDefinition">
        <patient-product-overview
          :examination="examination"
          :product="productDefinition"
        />
      </div>
      <div v-if="enabledFeatures.patientWoundList">
        <patient-examination-list
          :examinationCollections="examinationCollections"
          :productDefinition="productDefinition"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PatientProductOverview from "./PatientComponents/PatientProductOverview.vue";
import PatientExaminationList from "./PatientComponents/PatientExaminationList.vue";

import { PRODUCTS, PRODUCT_PERMISSIONS } from "../../../helpers/definitions";
import {
  fetchProduct,
  getLastUpdatedExamination,
  productHasPermission,
} from "../../../helpers/product";
import { mapState } from "vuex";

export default {
  props: {
    patient: Object,
    product: Object,
  },
  data() {
    return {
      loading: true,
      isEditing: false,
      examination: null,
      examinationCollections: [],
      PRODUCTS: PRODUCTS,
      enabledFeatures: {
        patientGeneralInfo: true,
        medicalHistory: false,
        patientWoundList: false,
      },
      productHasPermission: productHasPermission,
      productDefinition: undefined,
    };
  },
  destroyed: function() {
    this.$store.commit("resetCurrentPatient");
  },
  computed: mapState(["currentPatient"]),
  watch: {
    currentPatient(newPatient, oldPatient) {
      if (newPatient.patientId) {
        this.examinationCollections = newPatient.patientCases;
        this.loadPatientData(newPatient.patientCases);
      }
    },
  },
  methods: {
    loadProduct: async function() {
      const product = await fetchProduct(
        this.product.type,
        this.patient.centerId,
        this.$store
      );
      if (
        productHasPermission(
          PRODUCT_PERMISSIONS.HAS_PATIENT_WOUND_LIST,
          product
        )
      ) {
        this.enabledFeatures = {
          ...this.enabledFeatures,
          patientWoundList: true,
        };
      }
      this.productDefinition = product;
    },
    loadData: async function() {
      this.loading = true;
      const { commit, dispatch, state } = this.$store;
      commit("setCurrentPatientId", this.$route.params.id);
      await this.loadProduct();
      await dispatch("loadPatientCases");
      this.examinationCollections = state.currentPatient.patientCases;
      this.loadPatientData(state.currentPatient.patientCases);
      this.loading = false;
    },
    loadPatientData: async function(examinations) {
      this.examination = getLastUpdatedExamination(examinations);
    },
  },
  components: {
    PatientProductOverview,
    PatientExaminationList,
  },
};
</script>
