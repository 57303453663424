<template>
  <div class="col-lg-12">
    <breadcrumbs class="mb-0"></breadcrumbs>

    <ul class="nav nav-tabs nav tabs-bordered" role="tablist">
      <li :class="{ active: currentPage.includes('region/settings') }">
        <router-link to="/region/settings" data-toggle="tab" role="tab">
          <span>{{ trans("center.groupMenuLabelSettings") }}</span>
        </router-link>
      </li>
      <li :class="{ active: currentPage.includes('centers') }">
        <router-link to="/region/centers" data-toggle="tab" role="tab">
          <span>{{ trans("admin.centers") }}</span>
        </router-link>
      </li>
      <li :class="{ active: currentPage.includes('patients') }">
        <router-link to="/region/patients" data-toggle="tab" role="tab">
          <span>{{ trans("admin.patients") }}</span>
        </router-link>
      </li>
      <li :class="{ active: currentPage.includes('export') }">
        <router-link to="/region/export" data-toggle="tab" role="tab">
          <span>{{ trans("center.centerExport") }}</span>
        </router-link>
      </li>
    </ul>

    <router-view></router-view>
  </div>
</template>

<script>
export default {
  computed: {
    currentPage: function() {
      return this.$route.path;
    },
  },
};
</script>
