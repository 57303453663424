<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{
          product === "moleUk"
            ? trans("examination.consultationHeadlineUk")
            : trans("examination.consultationHeadline")
        }}
        <info-popup
          v-if="product === 'mole'"
          :text-content="trans('tooltip.moleConsultationBox')"
        ></info-popup>
        <info-popup
          v-if="product === 'moleUk'"
          :text-content="trans('tooltip.moleUkConsultationBox')"
        ></info-popup>
      </h3>
    </div>
    <div class="panel-body">
      <div class="mb-3 row">
        <div class="mb-3 col-md-6" v-if="product === 'mole'">
          <strong>{{ trans("examination.lesionLocation") }}</strong>
          <br />
          <p class="text-muted lesionLocalizationBodyPart">
            {{ examination.case.lesionLocalizationBodyPart }}
            <span v-if="examination.case.lesionLocalizationLaterality"
              >({{ examination.case.lesionLocalizationLaterality }})</span
            >
          </p>
        </div>

        <div class="mb-3 col-md-6" v-if="product === 'mole'">
          <strong>{{ trans("examination.reasonForVisit") }}</strong>
          <br />
          <p class="text-muted reasonForVisit">
            {{ examination.case.reasonForVisit }}
          </p>
        </div>

        <div class="mb-3 col-md-6" v-if="product === 'mole'">
          <strong>{{ trans("examination.totalBodyScan") }}</strong>
          <br />
          <p class="textTotalBodyScan text-muted">
            {{ examination.case.totalBodyScan }}
          </p>
        </div>

        <div class="mb-3 col-md-6">
          <strong>{{ trans("examination.lesionExistence") }}</strong>
          <br />
          <p class="text-muted lesionExistence">
            <span>{{ examination.case.lesionExistence }}</span>
          </p>
        </div>
        <div class="mb-3 col-md-6">
          <strong>{{ trans("examination.lesionChange") }}</strong>
          <br />
          <p class="text-muted lesionChange">
            {{ examination.case.lesionChange }}
          </p>
        </div>
        <div class="mb-3 col-md-6" v-if="product === 'moleUk'">
          <strong>{{ trans("examination.lesionChangedSince") }}</strong>
          <br />
          <p class="text-muted lesionChangedSince">
            {{ examination.case.lesionChangeSince }}
          </p>
        </div>
        <div class="mb-3 col-md-6" v-if="product === 'mole'">
          <strong>{{
            trans("examination.lesionChangeNewlyDiscovered")
          }}</strong>
          <br />
          <p class="text-muted lesionChangeNewlyDiscovered">
            {{ examination.case.lesionChangeNewlyDiscovered }}
          </p>
        </div>

        <div class="mb-3 col-md-6 theCharacterOfTheChange">
          <strong>{{ trans("examination.theCharacterOfTheChange") }}</strong>
          <br />
          <span v-if="examination.case.lesionChangeCharacteristicsNoChange"
            >{{ trans("examination.lesionChangeCharacteristicsNoChange") }}<br
          /></span>
          <span v-if="examination.case.lesionChangeCharacteristicsSize"
            >{{ trans("examination.lesionChangeCharacteristicsSize") }}<br
          /></span>
          <span v-if="examination.case.lesionChangeCharacteristicsElevated"
            >{{ trans("examination.lesionChangeCharacteristicsElevated") }}<br
          /></span>
          <span v-if="examination.case.lesionChangeCharacteristicsForm"
            >{{ trans("examination.lesionChangeCharacteristicsForm") }}<br
          /></span>
          <span v-if="examination.case.lesionChangeCharacteristicsColor"
            >{{ trans("examination.lesionChangeCharacteristicsColor") }}<br
          /></span>
        </div>

        <div class="mb-3 col-md-6 symptomsFromTheLesion">
          <strong>{{ trans("examination.symptomsFromTheLesion") }}</strong>
          <br />
          <span
            v-if="examination.case.lesionSymptomsNoSymptom"
            class="textNoSymptom"
            >{{ trans("examination.lesionSymptomsNoSymptom") }}<br
          /></span>
          <span v-if="examination.case.lesionSymptomsItch"
            >{{ trans("examination.lesionSymptomsItch") }}<br
          /></span>
          <span v-if="examination.case.lesionSymptomsBleed"
            >{{ trans("examination.lesionSymptomsBleed") }}<br
          /></span>
          <span v-if="examination.case.lesionSymptomsSecretion"
            >{{ trans("examination.lesionSymptomsSecretion") }}<br
          /></span>
        </div>

        <div class="mb-3 col-md-6 palpation" v-if="product === 'moleUk'">
          <strong>{{ trans("examination.palpation") }}</strong>
          <br />
          <span v-if="examination.case.palpationUnknown"
            >{{ trans("examination.unknown") }}<br
          /></span>
          <span v-if="examination.case.palpationSmoothSurface"
            >{{ trans("examination.smoothSurface") }}<br
          /></span>
          <span v-if="examination.case.palpationUnevenSurface"
            >{{ trans("examination.unevenSurface") }}<br
          /></span>
          <span v-if="examination.case.palpationRoughSurface"
            >{{ trans("examination.roughSurface") }}<br
          /></span>
          <span v-if="examination.case.palpationSoftConsistency"
            >{{ trans("examination.softConsistency") }}<br
          /></span>
          <span v-if="examination.case.palpationNormalConsistency"
            >{{ trans("examination.normalConsistency") }}<br
          /></span>
        </div>

        <div
          class="mb-3 col-md-6 previousHistorySkinCancer"
          v-if="product === 'moleUk'"
        >
          <strong>{{ trans("examination.previousHistorySkinCancer") }}</strong>
          <br />
          <p class="text-muted">
            <span v-if="examination.case.previousHistorySkinCancerNo"
              >{{ trans("examination.no") }}<br
            /></span>
            <span v-if="examination.case.previousHistorySkinCancerMelanoma"
              >{{ trans("examination.melanoma") }}<br
            /></span>
            <span v-if="examination.case.previousHistorySkinCancerScc"
              >{{ trans("examination.scc") }}<br
            /></span>
            <span v-if="examination.case.previousHistorySkinCancerBcc"
              >{{ trans("examination.bcc") }}<br
            /></span>
            <span v-if="examination.case.previousHistorySkinCancerUnknown"
              >{{ trans("examination.unknown") }}<br
            /></span>
          </p>
        </div>

        <div
          class="mb-3 col-md-6 fhGeneticSkinCancer"
          v-if="product === 'moleUk'"
        >
          <strong>{{ trans("examination.fhGeneticSkinCancer") }}</strong>
          <br />
          <p class="text-muted">
            <span v-if="examination.case.fhGeneticSkinCancerNo"
              >{{ trans("examination.no") }}<br
            /></span>
            <span v-if="examination.case.fhGeneticSkinCancerMelanoma"
              >{{ trans("examination.melanoma") }}<br
            /></span>
            <span v-if="examination.case.fhGeneticSkinCancerScc"
              >{{ trans("examination.scc") }}<br
            /></span>
            <span v-if="examination.case.fhGeneticSkinCancerBcc"
              >{{ trans("examination.bcc") }}<br
            /></span>
            <span v-if="examination.case.fhGeneticSkinCancerUnknown"
              >{{ trans("examination.unknown") }}<br
            /></span>
          </p>
        </div>

        <div class="mb-3 col-md-6 riskFactors" v-if="product === 'moleUk'">
          <strong>{{ trans("examination.riskFactors") }}</strong>
          <br />
          <p class="text-muted">
            <span v-if="examination.case.riskFactorsNone"
              >{{ trans("examination.riskFactorsNone") }}<br
            /></span>
            <span v-if="examination.case.riskFactorsImmunosupression"
              >{{ trans("examination.immunosuppression") }}
              <span v-if="examination.case.riskRactorsImmunosupressionComment"
                >({{
                  examination.case.riskRactorsImmunosupressionComment
                }})</span
              >
              <br
            /></span>
            <span v-if="examination.case.riskFactorsExcessiveSunExposure"
              >{{ trans("examination.excessiveSunExposure") }}<br
            /></span>
            <span v-if="examination.case.riskFactorsLargeNumberNaevi"
              >{{ trans("examination.largeNumberNaevi") }}<br
            /></span>
            <span v-if="examination.case.riskFactorsUnknown"
              >{{ trans("examination.unknown") }}<br
            /></span>
          </p>
        </div>

        <div class="mb-3 col-md-6">
          <strong>{{ trans("examination.issue") }}</strong>
          <br />
          <p class="text-muted lesionDiagnosis">
            {{ examination.case.lesionDiagnosis }}
          </p>
        </div>

        <div class="mb-3 col-md-6">
          <strong>{{ trans("examination.otherComment") }}</strong>
          <br />
          <p class="text-muted otherComment">
            {{ examination.case.otherComment }}
          </p>
        </div>

        <div
          class="mb-3 col-md-12"
          v-if="$store.state.dermicusPlatform === 'skane'"
        >
          <strong>{{
            trans("examination.personalInformationHandling")
          }}</strong>
          <br />
          <p class="text-muted consentList">
            <i
              v-if="examination.case.consentPersonalData"
              class="fal fa-check-circle consentPersonalData"
            ></i>
            <i
              v-else-if="!examination.case.consentPersonalData"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentPersonalData") }}<br />
            <i
              v-if="examination.case.consentResearch"
              class="fal fa-check-circle consentResearch"
            ></i>
            <i
              v-else-if="!examination.case.consentResearch"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentResearch") }}<br />
          </p>
        </div>

        <div class="mb-3 col-md-12">
          <strong>{{ trans("examination.approval") }}</strong>
          <br />
          <p class="text-muted consentList">
            <i
              v-if="examination.case.consentPhoto"
              class="fal fa-check-circle consentPhoto"
            ></i>
            <i
              v-else-if="!examination.case.consentPhoto"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentPhoto") }}<br />
            <i
              v-if="examination.case.consentJournal"
              class="fal fa-check-circle consentJournal"
            ></i>
            <i
              v-else-if="!examination.case.consentJournal"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentJournal") }}<br />
            <i
              v-if="examination.case.consentEducation"
              class="fal fa-check-circle consentEducation"
            ></i>
            <i
              v-else-if="!examination.case.consentEducation"
              class="fal fa-circle"
            ></i>
            {{ trans("examination.consentEducation") }}
          </p>
        </div>

        <div
          class="row col-md-12 mb-3"
          v-if="
            examination.case.createdInTakeCare &&
              $can('view_take_care_anamnesis')
          "
        >
          <div class="col"><hr /></div>
        </div>
        <div
          class="mb-3 col-md-12 row"
          v-if="
            examination.case.createdInTakeCare &&
              $can('view_take_care_anamnesis')
          "
        >
          <strong class="mb-3 col-md-12">{{
            trans("examination.takeCareHeading")
          }}</strong>
          <div class="mb-3 col-md-6">
            <strong>{{ trans("examination.takeCareIssue") }}</strong>
            <br />
            <p class="text-muted text-break">
              {{ examination.case.takeCareIssue }}
            </p>
          </div>
          <div class="mb-3 col-md-6">
            <strong>{{ trans("examination.takeCareAnamnesis") }}</strong>
            <br />
            <p class="text-muted text-break">
              {{ examination.case.takeCareAnamnesis }}
            </p>
          </div>
          <div class="mb-3 col-md-6">
            <strong>{{ trans("examination.takeCareComment") }}</strong>
            <br />
            <p class="text-muted otherComment text-break">
              {{ examination.case.takeCareComment }}
            </p>
          </div>
        </div>
      </div>

      <div class="col-md-12 col-xs-12 row" v-if="product === 'moleUk'">
        <div @click="handleImageClick(examination.images.signedConsentForm)">
          <encoded-image
            :imageId="examination.images.signedConsentForm.id"
            :caseId="examination.id"
            width="200"
            :uri="examination.images.signedConsentForm.uri"
            @on-receive-encoded-image="onEncodedImageLoaded"
            styleClass="lightbox-thumbnail"
          />
        </div>
        <portal to="lightbox-container">
          <Lightbox
            v-if="activeImage"
            :images="lightboxImages"
            v-model="activeImage"
            @closeEvent="handleClose"
          />
        </portal>
      </div>
    </div>
  </div>
</template>

<script>
import EncodedImage from "./EncodedImage.vue";
import InfoPopup from "../../Utils/InfoPopup.vue";
import Lightbox from "../../Layout/Lightbox.vue";

export default {
  components: { EncodedImage, InfoPopup, Lightbox },
  props: {
    examination: {
      case: {},
      images: {},
      id: Number,
    },
    product: String,
  },
  data() {
    return {
      activeImage: false,
      lightboxImages: [],
    };
  },
  methods: {
    handleImageClick(image) {
      this.activeImage = this.activeImage
        ? false
        : this.lightboxImages.find(img => img.id === image.id);
    },
    handleClose() {
      this.activeImage = false;
    },
    onEncodedImageLoaded: function(event) {
      this.lightboxImages = [{ id: event.imageId, url: event.uri }];
    },
  },
};
</script>
