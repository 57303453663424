<template>
  <div
    class="lightbox-overlay"
    @keydown="handleKeyDown"
    tabindex="-1"
    ref="overlayRef"
  >
    <!-- 
      panZoom :selector should be set to its first child.
      If this is not set, it will look for an svg element, and translate that.
      This breaks the lightbox when rendering the bounding-box-image component.
    -->
    <panZoom
      :selector="`.${containerClass}`"
      :options="panZoomOptions"
      @init="handleInit"
    >
      <div :class="containerClass" data-testid="panzoom" ref="containerRef">
        <div
          ref="imgRef"
          :style="{ transform: `rotate(${rotation}deg)` }"
          class="lightbox-image"
        >
          <component
            :is="activeImage.dataMetadata ? 'bounding-box-image' : 'img'"
            :src="activeImage.url"
            alt="Lightbox Image"
            :data-metadata="activeImage.dataMetadata"
          />
        </div>
      </div>
    </panZoom>
    <button
      title="Previous"
      class="lightbox-button-previous"
      @click="handlePrevious"
      v-if="hasNavigation"
    >
      <ChevronLeftIcon />
    </button>
    <button
      title="Next"
      class="lightbox-button-next"
      @click="handleNext"
      v-if="hasNavigation"
    >
      <ChevronRightIcon />
    </button>
    <div class="lightbox-toolbar">
      <div class="lightbox-toolbar-section">
        <button
          title="Rotate ccw"
          class="lightbox-button-sm"
          @click="handleRotateCounterClockwise"
        >
          <RotateLeftIcon />
        </button>
        <button
          title="Rotate cw"
          class="lightbox-button-sm"
          @click="handleRotateClockwise"
        >
          <RotateRightIcon />
        </button>
      </div>
      <div class="lightbox-toolbar-section">
        <button title="Zoom in" class="lightbox-button" @click="handleZoomIn">
          <ZoomInIcon />
        </button>
        <button title="Zoom out" class="lightbox-button" @click="handleZoomOut">
          <ZoomOutIcon />
        </button>
      </div>
      <div class="lightbox-toolbar-section">
        <button title="Reset" class="lightbox-button" @click="resetPosition">
          <FitIcon />
        </button>
        <button title="Close" class="lightbox-button-lg" @click="handleClose">
          <CloseIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import panZoom from "vue-panzoom";
import Vue from "vue";
import RotateLeftIcon from "./Icons/RotateLeftIcon.vue";
import RotateRightIcon from "./Icons/RotateRightIcon.vue";
import ZoomInIcon from "./Icons/ZoomInIcon.vue";
import ZoomOutIcon from "./Icons/ZoomOutIcon.vue";
import FitIcon from "./Icons/FitIcon.vue";
import CloseIcon from "./Icons/CloseIcon.vue";
import ChevronLeftIcon from "./Icons/ChevronLeftIcon.vue";
import ChevronRightIcon from "./Icons/ChevronRightIcon.vue";
import BoundingBoxImage from "./BoundingBoxImage.vue";
import { hasBoundingBox } from "../../../../../app/Dermicus/Studies/StudyResult";

Vue.use(panZoom);

export default {
  components: {
    RotateLeftIcon,
    RotateRightIcon,
    ZoomInIcon,
    ZoomOutIcon,
    FitIcon,
    CloseIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    BoundingBoxImage,
  },
  props: ["activeImage", "images"],
  model: {
    prop: "activeImage",
    event: "updateActiveImage",
  },
  data() {
    return {
      rotation: 0,
      panZoomInstance: null,
      panZoomOptions: {
        transformOrigin: null,
        zoomSpeed: 0.2,
        boundsPadding: 0.2,
        minZoom: 0.2,
        maxZoom: 3,
        filterKey: () => {
          return true;
        },
        beforeMouseDown: this.handleMouseDown,
        beforeWheel: this.handleMouseWheel,
        onTouch: this.handleOnTouch,
      },
      resizeObserver: null,
      containerClass: "lightbox-image-container",
    };
  },
  mounted() {
    /**
     * @see {@link https://v2.portal-vue.linusb.org/guide/caveats.html#refs}
     */
    this.$nextTick().then(
      this.$nextTick(() => {
        this.$refs.overlayRef.focus();
      })
    );

    /**
     * When the container where the lightbox is mounted resizes, the image position should be reset.
     * This handles both the case where the chat container is opened, and when the window is resized.
     */
    this.resizeObserver = new ResizeObserver(() => {
      if (this.$refs.imgRef) {
        this.resetPosition();
      }
    });

    this.resizeObserver.observe(this.$refs.overlayRef);
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  methods: {
    hasBoundingBox,
    resetPosition() {
      this.rotation = 0;
      this.$nextTick(() => {
        const containerBox = this.$refs.overlayRef.getBoundingClientRect();
        const imgBox = this.$refs.imgRef.getBoundingClientRect();

        const dh = containerBox.height / imgBox.height;
        const dw = containerBox.width / imgBox.width;

        const cw = containerBox.width / 2;
        const ch = containerBox.height / 2;

        const scale = Math.min(dw, dh);

        const x = cw - imgBox.width / 2;
        const y = ch - imgBox.height / 2;

        if (!isNaN(x) && !isNaN(y)) {
          this.panZoomInstance.moveTo(x, y);
        }

        if (!isNaN(cw) && !isNaN(ch) && !isNaN(scale)) {
          this.panZoomInstance.zoomTo(cw, ch, scale);
        }
      });
    },
    handleInit(instance) {
      this.panZoomInstance = instance;
    },
    handleNext() {
      if (this.index + 1 >= this.images.length) {
        this.$emit("updateActiveImage", this.images[0]);
      } else {
        this.$emit("updateActiveImage", this.images[this.index + 1]);
      }
    },
    handlePrevious() {
      if (this.index - 1 < 0) {
        this.$emit("updateActiveImage", this.images[this.images.length - 1]);
      } else {
        this.$emit("updateActiveImage", this.images[this.index - 1]);
      }
    },
    handleOnTouch(e) {
      // Prevent page scrolling when handling touch events
      e.preventDefault();

      // Handle zoom and click also on touch events
      this.handleMouseWheel(e);
      this.handleMouseDown(e);
    },
    handleMouseWheel(e) {
      // Only allow zooming on the image itself
      return !this.$refs.containerRef.contains(e.target);
    },
    handleMouseDown(e) {
      // Close lightbox when clicking on the overlay
      if (e.target.classList.contains("vue-pan-zoom-scene")) {
        this.handleClose();
      }

      // Only allow panning on the image itself
      return !this.$refs.containerRef.contains(e.target);
    },
    handleClose() {
      this.$emit("closeEvent");
    },
    handleRotateClockwise() {
      this.rotation = this.rotation + 90;
    },
    handleRotateCounterClockwise() {
      this.rotation = this.rotation - 90;
    },
    handleZoomIn() {
      const t = this.panZoomInstance.getTransform();
      const imgBox = this.$refs.imgRef.getBoundingClientRect();

      this.panZoomInstance.smoothZoom(
        t.x + imgBox.width / 2,
        t.y + imgBox.height / 2,
        1.2
      );
    },
    handleZoomOut() {
      const t = this.panZoomInstance.getTransform();
      const imgBox = this.$refs.imgRef.getBoundingClientRect();

      this.panZoomInstance.smoothZoom(
        t.x + imgBox.width / 2,
        t.y + imgBox.height / 2,
        0.8
      );
    },
    handleKeyDown(e) {
      e.stopPropagation();

      // right arrow
      if (this.hasNavigation && e.keyCode === 39) {
        this.handleNext();
      }

      // left arrow
      if (this.hasNavigation && e.keyCode === 37) {
        this.handlePrevious();
      }

      // escape
      if (e.keyCode === 27) {
        this.handleClose();
      }
    },
  },
  computed: {
    index() {
      return this.images.indexOf(this.activeImage);
    },
    hasNavigation() {
      return this.images.length > 1;
    },
  },
  watch: {
    activeImage() {
      if (this.$refs.overlayRef && this.$refs.imgRef) {
        this.resetPosition();
      }
    },
  },
};
</script>

<style>
.vue-pan-zoom-item,
.vue-pan-zoom-scene {
  height: 100%;
}

.lightbox-thumbnail {
  cursor: zoom-in;
}
</style>

<style lang="scss" scoped>
@use "/resources/assets/sass/abstracts/variables";

.lightbox-overlay {
  background-color: variables.$overlayBackground;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.lightbox-image {
  img {
    width: 100%;
    cursor: grab;
  }
}

.lightbox-button {
  font-size: 1.8em;
  color: variables.$dermicusWhite;
  border: 0;
  background: unset;
  padding: 0;
  height: 0.8em;
  width: 0.8em;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    filter: drop-shadow(variables.$text-shadow);
    width: 100%;
  }
}

.lightbox-button-sm {
  @extend .lightbox-button;
  font-size: 1.5em;
}

.lightbox-button-lg {
  @extend .lightbox-button;
  font-size: 2.2em;
}

%arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 2em;
  width: 2em;
}

.lightbox-button-previous {
  @extend .lightbox-button;
  @extend %arrow;
  left: 0.5em;
}

.lightbox-button-next {
  @extend .lightbox-button;
  @extend %arrow;
  right: 0.5em;
}

.lightbox-toolbar {
  position: absolute;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  background-color: variables.$overlayBackground;
  padding: 1em 0;
  border-radius: 8px;
}

.lightbox-toolbar-section {
  display: flex;
  gap: 0.5em;
  padding: 0 1em;
  align-items: center;

  &:not(:last-of-type) {
    border-right: 1px solid white;
  }
}
</style>
