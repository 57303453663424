<template>
  <div class="form-group">
    <div
      :style="{
        display: 'grid',
        gridTemplateColumns: '1fr auto auto',
        alignItems: 'center',
      }"
    >
      <label :for="excludedCentersInputId">
        {{ trans("center.excludeExaminationCenterHeader") }}
      </label>
      <button type="button" class="btn btn-link" @click="selectAll">
        {{ trans("statistics.allCenters") }}
      </button>
      <button
        type="button"
        class="close"
        aria-label="Close"
        @click="deselectAll"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <!-- vue-select, doesn't have the option to set the "name" of the input,
    thus we need to use a hidden one so that the value can be retrieved from
    the form on submission. -->
    <input
      type="hidden"
      name="centers[]"
      v-for="centerId in selectedCenterIds"
      :key="centerId"
      :value="centerId"
    />
    <v-select
      :input-id="excludedCentersInputId"
      label="text"
      multiple
      name="centerPicker"
      :options="dataAvailableCenters"
      v-model="selectedCenterIds"
      :reduce="center => center.id"
    />
  </div>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";
import { ref, toRefs } from "vue";

const excludedCentersInputId = uuidV4();
const selectedCenterIds = ref(null);

const props = defineProps({
  dataAvailableCenters: {
    type: /** @type {Array<{ id: number, text: string }>} */ Array,
    required: true,
  },
});
const { dataAvailableCenters } = toRefs(props);

const selectAll = () => {
  selectedCenterIds.value = dataAvailableCenters.value.map(center => center.id);
};
const deselectAll = () => {
  selectedCenterIds.value = null;
};
</script>
