<template>
  <div id="mole" class="col-lg-12">
    <center-header :selected-menu-item="3"></center-header>

    <div class="tab-content">
      <div class="text-right mb-2">
        <router-link
          :to="{
            name: `New${assignUrlandCenterId.adminType}CenterGroup`,
          }"
          class="btn btn-primary"
        >
          {{ trans("center.addNewGroupBtn") }}
        </router-link>
      </div>

      <div class="table-responsive">
        <table class="table table-hover interactive-table">
          <thead class="sortable-table-header">
            <tr>
              <th>{{ trans("center.groupTableGroupName") }}</th>
              <th>{{ trans("center.groupTableConnectedUsers") }}</th>
            </tr>
          </thead>
          <tbody v-if="!loading">
            <tr
              @click="openGroup(group)"
              v-for="(group, index) in groups"
              :key="index"
            >
              <td>{{ group.name }}</td>
              <td>{{ group.totalUsers }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <clip-loader v-if="loading"></clip-loader>

      <div class="row-fluid float-right">
        <paginate
          :pageCount="paginate.last_page"
          :click-handler="getGroups"
          :prev-text="'‹'"
          :next-text="'›'"
          :containerClass="'pagination'"
          ref="paginate"
          v-model="paginate.current_page"
          :page-class="'page-item'"
          :page-link-class="'page-link'"
          :active-class="'page-item active'"
          :prev-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-class="'page-item'"
          :next-link-class="'page-link'"
        >
        </paginate>
      </div>
    </div>
  </div>
</template>

<script>
import CenterHeader from "../CenterHeader.vue";
import URLS from "../../../config/urls";

export default {
  created: function() {
    this.getGroups();
  },
  data() {
    return {
      loading: false,
      groups: [],
      paginate: {
        current_page: 0,
        last_page: 0,
      },
    };
  },
  methods: {
    getGroups(page) {
      this.loading = true;

      axios
        .get(
          `${this.assignUrlandCenterId.url}/groups/${this.assignUrlandCenterId.centerId}`,
          { params: { page } }
        )
        .then(data => {
          let collection = data.data;
          this.paginate = collection.paginate;
          this.groups = collection.data;
          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
    openGroup: function(group) {
      this.$router.push({
        name: `Edit${this.assignUrlandCenterId.adminType}CenterGroup`,
        params: {
          groupId: group.id,
          id: this.assignUrlandCenterId.centerId,
        },
      });
    },
  },
  computed: {
    assignUrlandCenterId: function() {
      if (
        this.$can("manage_region") &&
        this.$store.state.user.primaryCenter.region_id &&
        (!this.$can("manage_center") || this.$route.path.includes("region"))
      ) {
        return {
          centerId: this.$route.params.id,
          url: URLS.REGION_ADMIN_URL,
          adminType: "Region",
        };
      } else {
        return {
          centerId: this.$store.state.user.primaryCenter.id,
          url: URLS.CENTER_ADMIN_URL,
          adminType: "",
        };
      }
    },
  },
  components: {
    CenterHeader,
  },
};
</script>
