<template>
  <div class="consultation-container">
    <div id="mole" class="col-lg-12" v-if="examination">
      <examinations-header
        product="wound"
        @mountImageCompare="mountImageCompare"
        :totalExaminations="totalExaminations"
        @initializePrint="printCase"
      ></examinations-header>

      <div class="tab-content">
        <div class="tab-pane active" id="home">
          <div class="row equal-height row-eq-height">
            <div class="col-md-6">
              <div class="col-md-12 px-0">
                <general-info
                  :examination="examination"
                  @update:examination="examination = $event"
                  :user="user"
                  :actions="actions"
                  @closeCase="closeCase"
                  @printCase="printCase"
                  @updateCaseActions="updateCaseActions"
                  product="wound"
                ></general-info>
              </div>
            </div>

            <div class="col-md-6 margin-mobile">
              <div class="col-md-12 px-0">
                <consultation-images
                  :caseId="examination.id"
                  :images="examination.images"
                  product="wound"
                ></consultation-images>
              </div>
            </div>
          </div>
          <div class="row">
            <wound-consultation-info
              :examination="examination"
            ></wound-consultation-info>
          </div>
        </div>

        <div class="tab-pane" id="image-compare" v-if="examination">
          <image-compare
            ref="imageCompare"
            :examination-id="examination.id"
            :type="2"
          ></image-compare>
        </div>
      </div>
    </div>
    <VueJsDialogMixinWrapper
      v-if="!!showPrintDialogWithOptions"
      :data-component="showPrintDialogComponent"
      :data-options="showPrintDialogWithOptions"
      @close="closePrintCase"
    />
  </div>
</template>

<script>
import ImageCompare from "../AddOns/ImageCompare.vue";
import DiagnoseReply from "../AddOns/DiagnoseReply.vue";
import CustomPrintViewDialog from "../AddOns/PrintCaseView.vue";
import Notifications from "../../Utils/notifications";

import ExaminationsHeader from "../AddOns/ExaminationsHeader.vue";
import GeneralInfo from "../AddOns/GeneralInfo.vue";
import ConsultationImages from "../AddOns/ConsultationImages.vue";
import WoundConsultationInfo from "./WoundConsultationInfo.vue";
import VueJsDialogMixinWrapper from "../../../mixins/VueJsDialogMixinWrapper.vue";

export default {
  mounted: function() {
    this.loadData();
  },
  data() {
    return {
      loading: true,
      examination: null,
      comments: {},
      user: {},
      backButtonUri: "/",
      actions: {
        isPrioritized: false,
        isFlagged: false,
        isBookmarked: false,
      },
      images: {},
      totalExaminations: null,
      showPrintDialogComponent: CustomPrintViewDialog,
      showPrintDialogWithOptions: null,
    };
  },
  methods: {
    loadData: function() {
      this.loading = true;

      this.user = this.$store.state.user;

      axios
        .get("/api/internal/wounds/" + this.$route.params.id)
        .then(data => {
          let collection = data.data;
          this.examination = collection.examination;

          this.comments = collection.comments;

          this.actions.isPrioritized = collection.examination.isPrioritized;
          this.actions.isFlagged = collection.examination.isFlagged;
          this.actions.isBookmarked = collection.examination.isBookmarked;

          this.getTotalExaminations();

          this.loading = false;
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });

      localStorage.setItem(
        "last_visited_examination_id",
        this.$route.params.id
      );
    },
    getTotalExaminations: function() {
      axios
        .get(
          "/api/internal/image-comparer/count-examinations/" +
            this.$route.params.id +
            "?type=2"
        )
        .then(data => {
          let collection = data.data;
          this.totalExaminations = collection.total_examinations;
        })
        .catch(error => {
          console.log("Get total examinations error: ", error);
        });
    },
    closeCase: function() {
      axios
        .get("/api/internal/wounds/" + this.$route.params.id + "/close")
        .then(data => {
          this.examination.statusName = data.data.examination.statusName;
          this.examination.caseCloseAt = data.data.examination.caseCloseAt;
          Notifications.saveSuccess();
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    printCase: function() {
      this.showPrintDialogWithOptions = {
        data: {
          uri: "/api/internal/wounds/" + this.$route.params.id + "/print",
          caseNo: this.examination.caseNo,
          type: "wound",
        },
      };
    },
    closePrintCase() {
      this.showPrintDialogWithOptions = null;
    },
    updateCaseActions: function(actionName) {
      this.actions[actionName] = this.actions[actionName] ? false : true;

      axios
        .put(
          "/api/internal/wounds/" + this.$route.params.id + "/actions",
          this.actions
        )
        .then(() => {
          this.$notify({
            text: this.trans("general.notificationSaved"),
            type: "success",
            duration: 1000,
          });
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    mountImageCompare: function() {
      this.$refs.imageCompare.getImages();
    },
  },
  components: {
    VueJsDialogMixinWrapper,
    ImageCompare,
    DiagnoseReply,
    CustomPrintViewDialog,
    ExaminationsHeader,
    GeneralInfo,
    ConsultationImages,
    WoundConsultationInfo,
  },
};
</script>
