<template>
  <div class="login-window">
    <div class="row">
      <div class="col-sm-12">
        <div class="bg-text">
          <div class="my-3">
            <div class="text-center logo">
              <span
                ><img src="/images/dermicus-logo.svg" alt="" height="50"
              /></span>
            </div>

            <div class="text-center mt-4 mb-5">
              <h6 class="mb-3">
                {{ trans("general.browserNotSupportedHeader") }}
              </h6>
              <p>
                {{ trans("general.browserNotSupportedText") }}
              </p>
            </div>
          </div>

          <div
            class="information"
            v-if="appSupportFooter !== undefined"
            v-dompurify-html="appSupportFooter.data"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { useAsyncState } from "@vueuse/core";
import { appSupportFooterGet } from "../../app/practitioner-portal/store/app-support-footer";

const { state: appSupportFooter } = useAsyncState(
  appSupportFooterGet(),
  undefined
);
</script>
<style lang="scss" scoped>
.login-window {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 50%;
  padding: 20px;
}

// Small devices (landscape phones, 576px and up)
@media (max-width: 768px) {
  .login-window {
    width: 90%;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .login-window {
    width: 40%;
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .login-window {
    width: 35%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .login-window {
    width: 30%;
  }
}

.information {
  font-size: 10px;
}
</style>
