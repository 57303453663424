<template>
  <div>
    <div class="bg-image"></div>

    <div class="login-window">
      <div class="row">
        <div class="col-sm-12">
          <div class="bg-text">
            <div class="mt-40">
              <div class="text-center logo">
                <img src="/images/dermicus-logo.svg" alt="" />
              </div>

              <slot />
            </div>

            <div
              class="information"
              v-if="appSupportFooter !== undefined"
              v-dompurify-html="appSupportFooter.data"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAsyncState } from "@vueuse/core";
import { appSupportFooterGet } from "../../app/practitioner-portal/store/app-support-footer";

const { state: appSupportFooter } = useAsyncState(
  appSupportFooterGet(),
  undefined
);
</script>
