<template>
  <div class="tab-content">
    <div class="text-right" v-if="!isAddingStudyParticipant">
      <button class="btn btn-primary" @click="toggleAddingStudyParticipant">
        {{ trans("aiFeatures.addParticipantToResearchToggleBtn") }}
      </button>
    </div>

    <add-resource-panel
      v-if="isAddingStudyParticipant"
      resourceType="participants"
      :loading="loading"
      :availableResources="potentialStudyParticipants"
      :addResourcePanelTitle="
        trans('aiFeatures.addParticipantToResearchPanelHeader')
      "
      :addResourceButtonText="trans('aiFeatures.addParticipantToResearchBtn')"
      @addResource="addParticipantToStudy"
      @cancelAddingResource="onCancel"
    ></add-resource-panel>

    <div class="study-participants-list-container">
      <div
        class="overlay"
        v-if="
          !loading &&
            studyParticipantsList &&
            studyParticipantsList.length === 0
        "
      >
        <div class="overlay-content">
          {{ trans("aiFeatures.noStudyParticipantsInfoText") }}
        </div>
      </div>

      <div class="mt-3 study-participants-list">
        <div class="overlay" v-if="loading">
          <clip-loader></clip-loader>
        </div>
        <div class="table-responsive">
          <table class="table table-hover table-actions-bar">
            <thead class="sortable-table-header">
              <tr>
                <th>{{ trans("forms.fullName") }}</th>
                <th>{{ trans("forms.email") }}</th>
                <th>{{ trans("forms.phone") }}</th>
                <th>{{ trans("evaluation.tableColumnCenter") }}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(participant, index) in studyParticipantsList"
                :key="index"
              >
                <td>{{ participant.full_name }}</td>
                <td>{{ participant.email }}</td>
                <td>{{ participant.phone_no }}</td>
                <td>{{ participant.primary_center.name }}</td>
                <td class="text-right" v-if="!participant.is_invited">
                  <button
                    class="btn btn-danger"
                    @click="removeParticipant(participant.id)"
                  >
                    {{ trans("general.formDeleteLabel") }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AddResourcePanel from "../Utils/AddResourcePanel.vue";
import notifications from "../../Utils/notifications";

import { URLS } from "../../../config";

export default {
  created() {
    this.getStudyParticipants();
  },
  data() {
    return {
      loading: false,
      potentialStudyParticipants: [],
      studyParticipantsList: null,
      isAddingStudyParticipant: false,
    };
  },
  computed: {
    researchId() {
      return this.$route.params.studyId;
    },
  },
  methods: {
    getStudyParticipants: function() {
      this.loading = true;

      axios
        .get(`${URLS.STUDY_ADMIN_USERS}/participants/${this.researchId}`)
        .then(data => {
          this.studyParticipantsList = data.data;

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    getPotentianStudyParticipants: function() {
      axios
        .get(`${URLS.STUDY_ADMIN_USERS}/candidates/${this.researchId}`)
        .then(response => {
          this.potentialStudyParticipants = response.data;
        })
        .catch(error => {
          this.userErrorMessage(error);
        });
    },
    toggleAddingStudyParticipant: function() {
      // dont save state since it updates after resource has been added ?
      this.getPotentianStudyParticipants();
      this.isAddingStudyParticipant = !this.isAddingStudyParticipant;
    },
    addParticipantToStudy: function(newParticipantId) {
      this.loading = true;
      axios
        .post(
          `${URLS.STUDY_ADMIN_USERS}/participant/${this.researchId}/${newParticipantId}`
        )
        .then(() => {
          this.isAddingStudyParticipant = false;
          this.getStudyParticipants();
          this.loading = false;
          notifications.saveSuccess();
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    removeParticipant: function(participantId) {
      this.$dialog
        .confirm(
          this.trans(
            "aiFeatures.removeParticipantFromStudyConfirmationMessage"
          ),
          {
            okText: this.trans("general.modalConfirmButton"),
            cancelText: this.trans("general.modalCancelButton"),
          }
        )
        .then(() => {
          (this.loading = true),
            axios
              .delete(
                `${URLS.STUDY_ADMIN_USERS}/participant/${this.researchId}/${participantId}`
              )
              .then(() => {
                this.loading = false;
                this.getStudyParticipants();
                notifications.saveSuccess();
              })
              .catch(error => {
                this.loading = false;
                this.userErrorMessage(error);
              });
        })
        .catch(() => {
          console.log("on cancel");
        });
    },
    onCancel: function() {
      this.isAddingStudyParticipant = false;
    },
  },
  components: {
    AddResourcePanel,
  },
};
</script>

<style lang="scss" scoped>
.study-participants-list-container {
  position: relative;

  .study-participants-list {
    min-height: 300px;
  }
}

.overlay-content {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  margin: 20px 0px;
  text-align: center;
  font-weight: bold;
}
</style>
