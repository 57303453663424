<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">{{ trans("users.tabMenuLabelProducts") }}</h3>
    </div>
    <div class="panel-body">
      <div
        v-if="Object.keys(products).length > 0"
        class="row col-md-12 align-items-end"
      >
        <div
          v-for="(product, key) in products"
          :key="key"
          class="col-md-2 col-sm-3"
        >
          <label>
            {{ trans(`general.${key}`) }}:
            <DerToggle v-model="product.active" />
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggle from "../../../design-system/DerToggle.vue";

export default {
  props: {
    products: Object,
    organisation: String,
  },
  components: {
    DerToggle,
  },
};
</script>
