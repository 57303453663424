<template>
  <error-page
    :headerText="trans('errorMessages.404ErrorPageHeader')"
    :bodyText="trans('errorMessages.404ErrorPageContent')"
    :buttonText="trans('errorMessages.404ErrorPageButtonText')"
  ></error-page>
</template>

<script>
import ErrorPage from "./ErrorPage.vue";

export default {
  components: {
    ErrorPage,
  },
};
</script>
