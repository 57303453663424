<template>
  <nav class="examination-header">
    <ul class="nav nav-tabs tabs-bordered">
      <li class="nav-item" @click="onBackClick">
        <router-link :to="{ name: 'Evaluation' }" class="nav-link">
          <span class="visible-xs">
            <i class="fal fa-arrow-left"></i>
          </span>
          <span class="hidden-xs">{{ trans("general.back") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a
          href="#home"
          data-toggle="tab"
          class="examinationLink nav-link active"
          aria-expanded="false"
        >
          <span>
            {{ trans("examination.tabLabelConsultation") }}
          </span>
        </a>
      </li>
      <li
        class="nav-item"
        v-if="
          productHasPermission(
            PRODUCT_PERMISSIONS.COMPARE_IMAGES,
            productDefinition
          )
        "
      >
        <a
          href="#image-compare"
          data-toggle="tab"
          aria-expanded="false"
          class="imageCompare nav-link"
          @click="mountImageCompare"
        >
          <span>
            {{ trans("examination.tabLabelImageCompare") }}
            <span v-if="totalExaminations" class="consultationCount"
              >({{ totalExaminations }})</span
            >
          </span>
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          v-if="
            productHasPermission(
              PRODUCT_PERMISSIONS.PRINT_CASE,
              productDefinition
            )
          "
          @click="initializePrint"
        >
          <span
            >{{ trans("general.actionPrintText") }} /
            <i class="fas fa-print"></i>
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
import { PRODUCT_PERMISSIONS } from "../../../../../helpers/definitions";
import { productHasPermission } from "../../../../../helpers/product";

export default {
  props: {
    consensusNotifications: [String, Number],
    totalExaminations: Number,
    productDefinition: Object,
  },
  data: function() {
    return {
      PRODUCT_PERMISSIONS: PRODUCT_PERMISSIONS,
      productHasPermission: productHasPermission,
    };
  },
  methods: {
    mountImageCompare: function() {
      this.$emit("mountImageCompare");
    },
    initializePrint: function() {
      this.$emit("initializePrint");
    },
    onBackClick: function() {
      this.cancelRequests();
    },
    cancelRequests: function() {
      this.$store.state.cancelTokens.forEach(token => {
        token.cancel();
      });
      this.$store.commit("resetCancelTokens");
    },
  },
};
</script>
