import _includes from "lodash/fp/includes";
import _placeholder from "lodash/fp/placeholder";
import _dropWhile from "lodash/fp/dropWhile";
import _takeWhile from "lodash/fp/takeWhile";
import _negate from "lodash/fp/negate";
import _eq from "lodash/fp/eq";

export const StudyExaminationStatusValues = [
  "selected",
  "ready",
  "invited",
  "research-answered",
] as const;

export type StudyExaminationStatus = typeof StudyExaminationStatusValues[number];

export const canInviteForStatus = _includes(
  _placeholder,
  _dropWhile(_negate(_eq("ready")), StudyExaminationStatusValues)
);

export const canRemoveForStatus = _includes(
  _placeholder,
  _takeWhile(_negate(_eq("ready")), StudyExaminationStatusValues)
);
