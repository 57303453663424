import App from "../../App.vue";
import AdminPatients from "../../components/Admin/AdminPatients.vue";
import Analytics from "../../components/Admin/Analytics.vue";
import ActiveCenters from "../../components/Admin/Centers/CentersList/ActiveCenters.vue";
import Centers from "../../components/Admin/Centers/CentersList/Centers.vue";
import InactiveCenters from "../../components/Admin/Centers/CentersList/InactiveCenters.vue";
import AdminCentersEdit from "../../components/Admin/Centers/Edit.vue";
import AdminEducationsEdit from "../../components/Admin/Educations/Edit.vue";
import Educations from "../../components/Admin/Educations/Educations.vue";
import New from "../../components/Admin/Educations/New.vue";
import AdminExport from "../../components/Admin/Export.vue";
import ApiInLog from "../../components/Admin/Logs/ApiInLog.vue";
import ApiOutLog from "../../components/Admin/Logs/ApiOutLog.vue";
import EmailLog from "../../components/Admin/Logs/EmailLog.vue";
import Log from "../../components/Admin/Logs/Log.vue";
import MinDoktorRetransfers from "../../components/Admin/Logs/MinDoktorRetransfers.vue";
import SystemLog from "../../components/Admin/Logs/SystemLog.vue";
import TakeCareLogs from "../../components/Admin/Logs/TakeCareLogs.vue";
import EditRegion from "../../components/Admin/Regions/EditRegion.vue";
import Regions from "../../components/Admin/Regions/Regions.vue";
import AdminUsersUserEdit from "../../components/Admin/Users/User/Edit.vue";
import NewUserInvite from "../../components/Admin/Users/User/NewUserInvite.vue";
import TentativeUserAdminActivate from "../../components/Admin/Users/User/TentativeUserAdminActivate.vue";
import ActiveUsersList from "../../components/Admin/Users/UsersList/ActiveUsersList.vue";
import InactiveUsersList from "../../components/Admin/Users/UsersList/InactiveUsersList.vue";
import PreliminaryUsersList from "../../components/Admin/Users/UsersList/PreliminaryUsersList.vue";
import UsersHeader from "../../components/Admin/Users/UsersList/UsersHeader.vue";
import Login from "../../components/Auth/Login.vue";
import Reset from "../../components/Auth/Reset.vue";
import Siths from "../../components/Auth/Siths.vue";
import SithsVerify from "../../components/Auth/SithsVerify.vue";
import Sms from "../../components/Auth/Sms.vue";
import Verify from "../../components/Auth/Verify.vue";
import CenterHeader from "../../components/Center/CenterHeader.vue";
import Export from "../../components/Center/Exports/Export.vue";
import Edit from "../../components/Center/Groups/Edit.vue";
import Groups from "../../components/Center/Groups/Groups.vue";
import CenterPatients from "../../components/Center/Patients/CenterPatients.vue";
import Mole from "../../components/Educations/EducationCase/Mole.vue";
import EducationsList from "../../components/Educations/EducationsList.vue";
import Errors404 from "../../components/errors/404.vue";
import BrowserNotSupported from "../../components/errors/BrowserNotSupported.vue";
import List from "../../components/Evaluations/List.vue";
import ExaminationContainer from "../../components/Examinations/Examination/ExaminationContainer.vue";
import MoleUk from "../../components/Examinations/MoleUk.vue";
import Wound from "../../components/Examinations/Wound/Wound.vue";
import PatientRoute from "../../components/Patients/PatientRoute.vue";
import Patients from "../../components/Patients/Patients.vue";
import RegionCenters from "../../components/Region/RegionCenters.vue";
import RegionExport from "../../components/Region/RegionExport.vue";
import RegionHeader from "../../components/Region/RegionHeader.vue";
import NoRegion from "../../components/Region/NoRegion.vue";
import RegionNewCenter from "../../components/Region/RegionNewCenter.vue";
import RegionSettings from "../../components/Region/RegionSettings.vue";
import RegionPatients from "../../components/Region/RegionPatients.vue";
import RegistrationForm from "../../components/Registration/RegistrationForm.vue";
import Statistics from "../../components/Statistics/Statistics.vue";
import Contact from "../../components/Support/Contact.vue";
import RegionSupportPage from "../../components/Support/RegionsSupport/RegionSupportPage.vue";
import User from "../../components/User/User.vue";
import { research } from "../research";
import { center } from "../center";
import { region } from "../region";
import { researchAdmin } from "../research-admin";
import PatientImageCompare from "../../components/Patients/PatientImageCompare.vue";
import PatientOverview from "../../components/Patients/PatientOverview.vue";
import RelatedExaminations from "../../components/Patients/RelatedExaminations.vue";

/**
 * Practitioner portal module.
 *
 * @type {AppModule}
 */
export const practitionerPortal = {
  routes: [
    {
      path: "/",
      component: App,
      children: [
        {
          path: "/auth",
          component: Login,
          name: "Auth",
          meta: {
            disallowAuthed: true,
          },
        },
        {
          path: "/auth/sms",
          component: Sms,
          name: "SmsLogin",
        },
        {
          path: "/auth/password/reset",
          component: Reset,
          name: "ResetLogin",
        },
        {
          path: "/auth/password/reset/:token",
          component: Reset,
          name: "ResetLoginStep2",
        },
        {
          path: "/auth/verification/verify/:id/:hash",
          component: Verify,
          name: "Verify",
        },
        {
          path: "/auth/siths",
          component: Siths,
          name: "Siths",
        },
        {
          path: "/auth/siths/verify",
          component: SithsVerify,
          name: "SithsVerify",
        },
        {
          path: "/consultations",
          component: List,
          name: "Evaluation",
          meta: {
            requiresAuth: true,
          },
          props: true,
        },
        {
          path: "/",
          redirect: "/consultations",
        },
        {
          path: "/moles/:id/edit",
          redirect: "/consultations/moles/:id/edit",
        },
        {
          path: "/consultations/moles/:id/edit",
          component: ExaminationContainer,
          name: "Mole",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/moles-uk/:id/edit",
          redirect: "/consultations/moles-uks/:id/edit",
        },
        {
          path: "/consultations/moles-uk/:id/edit",
          component: MoleUk,
          name: "MoleUk",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/wounds/:id/edit",
          redirect: "/consultations/wounds/:id/edit",
        },
        {
          path: "/consultations/wounds/:id/edit",
          component: ExaminationContainer,
          name: "Wound",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/wound-legacy/:id/edit",
          redirect: "/consultations/wound-legacy/:id/edit",
        },
        {
          path: "/consultations/wound-legacy/:id/edit",
          component: Wound,
          name: "WoundLegacy",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/mole/:id/edit",
          redirect: "/consultations/mole/:id/edit",
        },
        {
          path: "/consultations/mole/:id/edit",
          component: ExaminationContainer,
          name: "Mole",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/mole-uk/:id/edit",
          redirect: "/consultations/mole-uk/:id/edit",
        },
        {
          path: "/consultations/mole-uk/:id/edit",
          component: ExaminationContainer,
          name: "MoleUk",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/wound/:id/edit",
          redirect: "/consultations/wound/:id/edit",
        },
        {
          path: "/consultations/wound/:id/edit",
          component: ExaminationContainer,
          name: "Wound",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/tumour-wound/:id/edit",
          redirect: "/consultations/tumour-wound/:id/edit",
        },
        {
          path: "/consultations/tumour-wound/:id/edit",
          component: ExaminationContainer,
          name: "TumourWound",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/region-skane-mole/:id/edit",
          redirect: "/consultations/region-skane-mole/:id/edit",
        },
        {
          path: "/consultations/region-skane-mole/:id/edit",
          component: ExaminationContainer,
          name: "RegionSkaneMole",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/wound-uk/:id/edit",
          redirect: "/consultations/wound-uk/:id/edit",
        },
        {
          path: "/consultations/wound-uk/:id/edit",
          component: ExaminationContainer,
          name: "WoundUK",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/consultations/wound-lite/:id/edit",
          component: ExaminationContainer,
          name: "WoundLite",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/photo/:id/edit",
          redirect: "/consultations/photo/:id/edit",
        },
        {
          path: "/consultations/photo/:id/edit",
          component: ExaminationContainer,
          name: "Photo",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/photos/:id/edit",
          redirect: "/consultations/photos/:id/edit",
        },
        {
          path: "/consultations/photos/:id/edit",
          component: ExaminationContainer,
          name: "Photo",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/photo-uk/:id/edit",
          redirect: "/consultations/photo-uk/:id/edit",
        },
        {
          path: "/consultations/photo-uk/:id/edit",
          component: ExaminationContainer,
          name: "Photo UK",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/consultations/photo-uk/:id/edit",
          component: ExaminationContainer,
          name: "Photo UK",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/patients",
          component: Patients,
          name: "Patients",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/patients/:id/consensus",
          redirect: "/patients/:id",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/patients/:id/edit",
          redirect: "/patients/:id",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/patients/:id",
          component: PatientRoute,
          name: "Patient",
          meta: {
            requiresAuth: true,
          },
          children: [
            {
              path: "",
              component: PatientOverview,
              name: "PatientOverview",
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "images",
              component: PatientImageCompare,
              name: "PatientImages",
              meta: {
                requiresAuth: true,
              },
            },
            {
              path: "related-examinations",
              component: RelatedExaminations,
              name: "PatientRelatedExaminations",
              meta: {
                requiresAuth: true,
              },
            },
          ],
        },
        {
          path: "/support",
          component: RegionSupportPage,
          name: "SupportDocumentationDefault",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/support/region-support",
          component: RegionSupportPage,
          name: "RegionSupportPage",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/support/contact",
          component: Contact,
          name: "SupportContact",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/educations",
          component: EducationsList,
          name: "Educations",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/educations/moles/:id/edit",
          component: Mole,
          name: "EducationMole",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/educations/mole/:id/edit",
          component: Mole,
          name: "EducationMole",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/statistics",
          component: Statistics,
          name: "Statistics",
          meta: {
            requiresAuth: true,
          },
        },
        ...researchAdmin.routes,
        ...research.routes,
        {
          path: "/auth/settings",
          component: User,
          name: "UserSettings",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/center",
          component: CenterHeader,
          name: "CenterHeader",
          redirect: "/center/settings",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        ...center.routes,
        {
          path: "/center/export",
          component: Export,
          name: "CenterExport",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups",
          component: Groups,
          name: "CenterGroups",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/patients",
          component: CenterPatients,
          name: "CenterPatients",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups/edit/:groupId",
          component: Edit,
          name: "EditCenterGroup",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/center/groups/edit/new",
          component: Edit,
          name: "NewCenterGroup",
          meta: {
            requiresAuth: true,
            centerAuth: true,
          },
        },
        {
          path: "/region/no-region",
          component: NoRegion,
          name: "NoRegion",
          meta: {
            requiresAuth: true,
            regionAuth: true,
          },
        },
        {
          path: "/region",
          component: RegionHeader,
          name: "RegionHeader",
          redirect: "/region/settings",
          meta: {
            requiresAuth: true,
            regionAuth: true,
            breadcrumb: "breadcrumbs.region",
          },
          children: [
            {
              path: "/region/settings",
              component: RegionSettings,
              name: "RegionSettings",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionSettings",
              },
            },
            {
              path: "/region/export",
              component: RegionExport,
              name: "RegionExport",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionExport",
              },
            },
            {
              path: "/region/patients",
              component: RegionPatients,
              name: "RegionPatients",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionPatients",
              },
            },
            {
              path: "/region/centers",
              component: RegionCenters,
              name: "RegionCenters",
              meta: {
                requiresAuth: true,
                regionAuth: true,
                breadcrumb: "breadcrumbs.regionCenters",
              },
              children: [
                {
                  path: "/region/centers/newCentre",
                  component: RegionNewCenter,
                  name: "RegionNewCenter",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionNewCenter",
                  },
                },
                {
                  path: "/region/centers/:id",
                  redirect: "/region/centers/:id/settings",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                  },
                },
                ...region.routes,
                {
                  path: "/region/centers/:id/groups",
                  component: Groups,
                  name: "RegionCenterGroups",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroups",
                  },
                },
                {
                  path: "/region/centers/:id/groups/edit/:groupId",
                  component: Edit,
                  name: "EditRegionCenterGroup",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroup",
                  },
                },
                {
                  path: "/region/centers/:id/groups/new",
                  component: Edit,
                  name: "NewRegionCenterGroup",
                  meta: {
                    requiresAuth: true,
                    regionAuth: true,
                    breadcrumb: "breadcrumbs.regionEditCenterGroup",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/region/centers/new",
          component: AdminCentersEdit,
          name: "RegionCentersNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin",
          component: Centers,
          name: "Admin",
          redirect: "/admin/centers/active-centers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/centers",
          component: Centers,
          name: "AdminCenters",
          redirect: "/admin/centers/active-centers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          children: [
            {
              path: "active-centers",
              component: ActiveCenters,
              name: "AdminCentersActiveCenters",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "inactive-centers",
              component: InactiveCenters,
              name: "AdminCentersInactiveCenters",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/centers/:id",
          component: AdminCentersEdit,
          name: "AdminCentersEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/centers/new",
          component: AdminCentersEdit,
          name: "AdminCentersEditNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/regions",
          component: Regions,
          name: "AdminRegions",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/regions/:id",
          component: EditRegion,
          name: "AdminRegionsEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/regions/new",
          component: EditRegion,
          name: "AdminRegionsEditNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/users",
          component: UsersHeader,
          name: "AdminUsers",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          redirect: "/admin/users/active-users",
          children: [
            {
              path: "active-users",
              component: ActiveUsersList,
              name: "AdminUsersActiveUsers",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "inactive-users",
              component: InactiveUsersList,
              name: "AdminUsersInactiveUsers",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "/admin/users/preliminary-users",
              component: PreliminaryUsersList,
              name: "AdminUsersPreliminary",
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/users/invite",
          component: NewUserInvite,
          name: "NewUserInvite",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/users/:id",
          component: AdminUsersUserEdit,
          name: "AdminUsersEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/preliminary/users/:id",
          component: TentativeUserAdminActivate,
          name: "AdminPreliminaryUsersActivate",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/users/new",
          component: AdminUsersUserEdit,
          name: "AdminUsersEditNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations",
          component: Educations,
          name: "AdminEducations",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations/new",
          component: New,
          name: "AdminEducationsNew",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/educations/:id",
          component: AdminEducationsEdit,
          name: "AdminEducationsEdit",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/logs",
          component: Log,
          name: "AdminLog",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
          redirect: "/admin/logs/system",
          children: [
            {
              path: "system",
              component: SystemLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "apiOut",
              component: ApiOutLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "apiIn",
              component: ApiInLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "email",
              component: EmailLog,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "minDoktorRetransfers",
              component: MinDoktorRetransfers,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
            {
              path: "takeCare",
              component: TakeCareLogs,
              meta: {
                requiresAuth: true,
                adminAuth: true,
              },
            },
          ],
        },
        {
          path: "/admin/patients",
          component: AdminPatients,
          name: "AdminPatients",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/analytics",
          component: Analytics,
          name: "AdminAnalytics",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/admin/exports",
          component: AdminExport,
          name: "AdminExport",
          meta: {
            requiresAuth: true,
            adminAuth: true,
          },
        },
        {
          path: "/unsupported-browser",
          component: BrowserNotSupported,
          name: "BrowserNotSupported",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "/register/:key",
          component: RegistrationForm,
          name: "RegistrationForm",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "*",
          component: Errors404,
          name: "NotFoundPage",
          requiresAuth: false,
        },
      ],
    },
  ],
};
