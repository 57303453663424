<template>
  <div class="panel panel-default panel-fill">
    <div class="panel-heading">
      <h3 class="panel-title">{{ trans("menu.settings") }}</h3>
    </div>
    <div class="panel-body col-md-12">
      <div class="row col-md-12 my-3 align-items-end">
        <div class="col-md-2 col-sm-3">
          <label>
            Region Skåne - DICOM:
            <DerToggle v-model="settings.regionskaneDicom.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3">
          <label>
            Educations:
            <DerToggle v-model="settings.educations.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.imageQualityAssurance") }}:
            <DerToggle v-model="settings.image_quality_assurance.active" />
          </label>
        </div>
        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.imagePolarisationCheck") }}:
            <DerToggle v-model="settings.image_polarisation_check.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.aiPrioritization") }}:
            <DerToggle v-model="settings.ai_prioritization.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.aiDiagnosis") }}:
            <DerToggle v-model="settings.ai_diagnosis.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.mobileTwoFactorAuth") }}:
            <DerToggle
              v-model="settings.mobile_two_factor_authentication.active"
            />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            Lab/pharmacy (hides fields from Mole form):
            <DerToggle v-model="center.is_lab_pharmacy" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            Require patient telephone number:
            <DerToggle v-model="center.patient_telephone_number" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.hidePatientFields") }}:
            <DerToggle v-model="settings.hide_patient_fields.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.woundMeasurementSetting") }}:
            <DerToggle v-model="settings.wound_measurement.active" />
          </label>
        </div>

        <div class="col-md-2 col-sm-3" v-if="center">
          <label>
            {{ trans("admin.externalResourceSetting") }}:
            <DerToggle v-model="settings.external_resources_setting.active" />
          </label>
        </div>
      </div>
    </div>

    <div class="card-deck" v-if="center">
      <div class="card">
        <div class="card-header">
          {{ trans("examination.ucr_feature_name") }}
        </div>
        <div class="card-body">
          <label class="card-text col-md-12">
            UCR
            <span v-if="!settings.ucr.active">
              ({{ trans("examination.ucr_sync_info") }})</span
            >:
            <DerToggle v-model="settings.ucr.active" disabled="disabled" />
          </label>
          <div v-if="settings.ucr.active">
            <strong
              >{{ trans("examination.ucr_center_synced_id") }}:
              {{ center.ucr_id }}</strong
            >
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">Webdoc</div>
        <div class="card-body">
          <label class="card-text col-md-12">
            Webdoc:
            <DerToggle v-model="settings.webdoc.active" />
          </label>
          <div class="card-text">
            <label class="col-md-12">
              Endpoint
              <input
                type="text"
                v-model="settings.webdoc.config.endpoint"
                class="form-control"
                :disabled="!settings.webdoc.active"
              />
            </label>
            <label class="col-md-12">
              API key
              <input
                type="text"
                v-model="settings.webdoc.config.api_key"
                class="form-control"
                :disabled="!settings.webdoc.active"
              />
            </label>
            <label class="col-md-12">
              Document type
              <input
                type="text"
                v-model="settings.webdoc.config.document_type"
                class="form-control"
                :disabled="!settings.webdoc.active"
              />
            </label>
          </div>
        </div>
      </div>

      <div class="card">
        <div class="card-header">Default reserve number prefix:</div>
        <div class="card-body">
          <label class="card-text col-md-12">
            Enable:
            <DerToggle
              v-model="settings.default_reserve_number_prefix.active"
            />
          </label>
          <div class="card-text">
            <label class="col-md-12">
              Default reserve number prefix
              <input
                type="text"
                v-model="
                  settings.default_reserve_number_prefix.config.reserve_number
                "
                class="form-control"
                :disabled="!settings.default_reserve_number_prefix.active"
              />
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DerToggle from "../../../design-system/DerToggle.vue";

export default {
  props: {
    organisation: String,
    settings: {
      ucr: {
        active: false,
        config: {
          center_id: null,
        },
      },
      regionskaneDicom: {
        active: false,
      },
      educations: {
        active: false,
      },
      defaultReserveNumberPrefix: {
        active: false,
        config: {
          reserve_number: "",
        },
      },
      webdoc: {
        active: false,
        config: {
          api_key: "",
          document_type: "1",
          endpoint: "",
        },
      },
    },
    center: {
      is_lab_pharmacy: Number,
      patient_telephone_number: Number,
    },
  },
  components: {
    DerToggle,
  },
};
</script>
