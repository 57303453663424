<template>
  <div class="custom-view-wrapper">
    <div class="dg-content-body">
      <clip-loader v-show="loading"></clip-loader>

      <div
        class="dg-content"
        v-if="!loading && Object.keys(productDefinition).length > 0"
      >
        <b>{{ trans("examination.tabLabelPrint") }}</b> <br /><br />

        <div>
          <button
            v-if="
              showPatientLevelReports &&
                productHasPermission(
                  PRODUCT_PERMISSIONS.PRINT_CASE_REPORT_WITHOUT_IMAGES,
                  productDefinition
                )
            "
            @click="
              printRemiss(
                'referral',
                'examination.printTypeReportWithoutImages'
              )
            "
            class="dg-btn dg-btn--ok mb-2 w-100"
          >
            <span>{{ trans("examination.printCaseReportWithoutImages") }}</span>
          </button>
          <button
            v-if="
              productHasPermission(
                PRODUCT_PERMISSIONS.PRINT_CASE_REFERRAL,
                productDefinition
              )
            "
            @click="printRemiss('referral', 'examination.printTypeRemiss')"
            class="dg-btn dg-btn--ok mb-2 w-100"
          >
            <span>{{ trans("examination.printCaseRemiss") }}</span>
          </button>
          <button
            v-if="
              productHasPermission(
                PRODUCT_PERMISSIONS.PRINT_CASE_IMAGES,
                productDefinition
              )
            "
            @click="printRemiss('images', 'examination.printTypeImages')"
            class="dg-btn dg-btn--ok mb-2 w-100"
          >
            <span>{{ trans("examination.printCaseImages") }}</span>
          </button>
          <button
            v-if="
              productHasPermission(
                PRODUCT_PERMISSIONS.PRINT_CASE_SUMMARY,
                productDefinition
              )
            "
            @click="printRemiss('summary', 'examination.printTypeSummary')"
            class="dg-btn dg-btn--ok mb-1 w-100"
          >
            <span>{{ trans("examination.printCaseSummary") }}</span>
          </button>
        </div>
      </div>
    </div>

    <div class="dg-content-footer">
      <button @click="handleDismiss()" class="dg-btn dg-btn--cancel w-100">
        <span>{{ trans("examination.printCaseCancel") }}</span>
      </button>
      <div class="dg-clear"></div>
    </div>
  </div>
</template>

<script>
import DialogMixin from "../../../../../mixins/vuejs-dialog-mixin.js"; // Include mixin
import { PRODUCT_PERMISSIONS } from "../../../../../helpers/definitions";
import { productHasPermission } from "../../../../../helpers/product";

export default {
  mixins: [DialogMixin],
  mounted: function() {
    this.type = this.options.data.type;
    this.productDefinition = this.options.data.productDefinition;
    this.showPatientLevelReports = this.options.data.showPatientLevelReports;
  },
  data() {
    return {
      loading: false,
      productHasPermission: productHasPermission,
      PRODUCT_PERMISSIONS: PRODUCT_PERMISSIONS,
      productDefinition: {},
      showPatientLevelReports: false,
    };
  },
  methods: {
    handleDismiss() {
      this.cancel(); // included in DialogMixin
    },
    printRemiss: function(pdfType, translationKey) {
      this.loading = true;

      axios
        .get(`${this.options.data.uri}?type=${pdfType}`, {
          responseType: "arraybuffer",
        })
        .then(response => {
          const blob = new Blob([response.data], {
            type: "application/pdf",
          });

          let fileName = `${this.options.data.caseNo}-${this.trans(
            translationKey
          )}`;

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileName);
            //return;
          }

          const data = window.URL.createObjectURL(blob);
          var link = document.createElement("a");
          link.href = data;
          link.download = fileName;
          link.click();

          this.loading = false;

          setTimeout(function() {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data), 100;
          });
        })
        .catch(error => {
          this.userErrorMessage(error);
          this.loading = false;
        });
    },
  },
};
</script>
