<template>
  <form v-on="$listeners">
    <CenterFormGroup :data-available-centers="dataAvailableCenters" />
    <div class="form-group">
      <label :for="yearInputId">{{ trans("forms.yearLabel") }}</label>
      <select2 :id="yearInputId" name="year" :options="years" />
    </div>
    <slot />
  </form>
</template>
<script setup>
import { v4 as uuidV4 } from "uuid";
import CenterFormGroup from "../generic/export/comp/CenterFormGroup.vue";

const yearInputId = uuidV4();
const years = Array.from(
  { length: new Date().getFullYear() - 2011 },
  (value, index) => 2012 + index
);

defineProps({
  dataAvailableCenters: {
    type: /** @type {Array<{ id: number, text: string }>} */ Array,
    required: true,
  },
  dataFormErrors: {
    type: /** @type {Record<'ssn'|'fromDate'|'untilDate', string|string[]>} */ Object,
  },
});
</script>
