<template>
  <div
    class="collapse multi-collapse col-lg-12"
    :id="'patientExamination_' + examinationCollection.main_examination.id"
  >
    <div v-if="examinations.length > 0" class="col-md-12">
      <div v-for="(examination, key) in examinations" :key="key">
        <patient-examination
          :examination="examination"
          :product="productDefinition"
          :viewOption="
            key === 0
              ? 'patient_examination'
              : 'patient_examination_previous_case'
          "
        />
      </div>
    </div>
  </div>
</template>

<script>
import PatientExamination from "./PatientExamination.vue";

export default {
  props: {
    examinationCollection: {
      main_examination: Object,
      examinations: Array,
    },
    productDefinition: Object,
  },
  components: {
    PatientExamination,
  },
  computed: {
    examinations: function() {
      /**
       * This depends on the api returning the examinations from most recent to oldest.
       * The main examination will always be the oldest.
       */
      return [
        ...this.examinationCollection.examinations,
        this.examinationCollection.main_examination,
      ];
    },
  },
};
</script>
