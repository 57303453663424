import { lang } from "../i18n";

export const extractNestedKeys = string => {
  return string.split(".");
};

/**
 * @deprecated use "lang.get()" directly instead of this wrapper.
 */
export const translator = lang.get.bind(lang);

export const camelToSnakeCase = str =>
  str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

export const transformExaminationToSnakeCase = examination => {
  const transformed = {};

  Object.keys(examination).forEach(key => {
    if (typeof examination[key] === "object" && examination[key] !== null) {
      transformed[key] = {};
      Object.keys(examination[key]).forEach(nestedKey => {
        transformed[key][camelToSnakeCase(nestedKey)] =
          examination[key][nestedKey];
      });
    } else {
      transformed[camelToSnakeCase(key)] = examination[key];
    }
  });

  return transformed;
};

export const infoPopupText = (infoFields, fieldGroupName) => {
  let infoText = fieldGroupName
    ? translator(`infoText.${fieldGroupName}`) + " <br />"
    : "";

  const fieldTranslator = field => {
    const key = field.name || field.title || field;
    const translatedText = translator(`infoText.${key}`);

    return translatedText ? translatedText + " <br />" : "";
  };

  if (Array.isArray(infoFields)) {
    infoFields.forEach(field => {
      infoText += `${fieldTranslator(field)}`;
    });
  } else {
    Object.keys(infoFields).forEach(field => {
      infoText += `${fieldTranslator(field)}`;
    });
  }

  return infoText;
};

/**
 * Convert dubiously (the dermicus way) formatted strings to floats.
 * All commas are replaced with dots then all but the last dot is removed, unless the separators used are more than
 * two and only of one kind then the whole number should be considered decimal-free. Further any letter optionally
 * followed by a number is removed IE cm2. Then all whitespace is removed. If the resulting strings starts with a dot and number
 * IE .5 a zero will be prepended for correct float parsing IE 0.5.
 */
export const sanitizeAndConvertFieldValueStringToFloat = value => {
  if (typeof value !== "string") {
    value = value.toString();
  }

  const containsTwoOrMoreOfOnlyTheSameSeparator =
    (value.match(",")?.length > 1 && value.match(".")?.length === 0) ||
    (value.match(".")?.length > 1 && value.match(",")?.length === 0);
  const regex = containsTwoOrMoreOfOnlyTheSameSeparator
    ? /(\.)|([a-zA-Z_-]+[0-9]?)|([\s>]|)/
    : /\.(?=.*\.)|([a-zA-Z_-]+[0-9]?)|([\s>]|)/;
  return parseFloat(
    value
      .replace(",", ".")
      .replace(regex, "")
      .replace(/^\./, "0.")
  );
};

export function resetLocalStorage() {
  /**
   * Keep reference to auth_method to be able to restore it, since we don't want to clear this.
   */
  const authMethod = localStorage.getItem("auth_method");

  localStorage.clear();

  localStorage.setItem("ref_uri", "/");
  localStorage.setItem("auth_method", authMethod);
}
