import { patientAuthToken } from "../auth/patient-auth-token";
import axios from "axios";
import { httpRequestAuthTokenInjectorProvider } from "../../../lib/http-request-auth-token-injector";
import { httpResponse401UnauthorizedHandlerProvider } from "../../../lib/http-response-401-unauthorized-handler";
import { appRouter } from "../../routing/app-router";
import _noop from "lodash/fp/noop";
import { locationOf, ROUTES } from "../../routing/routes";

export const patientHttpService = axios.create({
  baseURL: "/api/patient-portal",
});

patientHttpService.interceptors.request.use(
  httpRequestAuthTokenInjectorProvider({ authToken: patientAuthToken })
);
patientHttpService.interceptors.response.use(
  undefined,
  httpResponse401UnauthorizedHandlerProvider({
    logout: () =>
      appRouter
        .push(locationOf(ROUTES.patientLogout, { query: { force: "1" } }))
        .then(_noop),
  })
);
