<template>
  <div class="consultation-container">
    <ul class="nav nav-tabs tabs-bordered">
      <li class="nav-item">
        <router-link :to="locationBack" class="nav-link">
          <span class="visible-xs">
            <i class="fal fa-arrow-left"></i>
          </span>
          <span class="hidden-xs">{{ trans("general.back") }}</span>
        </router-link>
      </li>
      <li class="nav-item">
        <a
          href="#consultation"
          data-toggle="tab"
          class="nav-link active"
          aria-expanded="false"
        >
          <span>
            {{ trans("examination.tabLabelConsultation") }}
          </span>
        </a>
      </li>
    </ul>

    <div style="position: relative;" class="mt-3 row">
      <div class="overlay" v-if="loading">
        <clip-loader></clip-loader>
      </div>
      <research-case-view
        :product="workflow"
        :examination="examination"
        :data-permissions="JSON.parse($store?.state.user.permissions ?? '[]')"
      />
      <info-modal
        v-if="showInfoModal"
        :infoHeaderText="trans('aiFeatures.workflowIncompleteHeader')"
        :infoText="trans('aiFeatures.workflowIncompleteBody')"
        infoType="warning"
        @closeInfoModal="closeInfoModal"
      ></info-modal>
    </div>
  </div>
</template>

<script>
import { PRODUCTS, STORE_CONSTANTS } from "../../helpers/definitions";
import ResearchCaseView from "./ResearchCaseView.vue";
import InfoModal from "../../components/Utils/InfoModal.vue";
import { locationOf, ROUTES } from "../routing/routes";
import { URLS } from "../../config";

export default {
  provide: {
    SAVE_STUDY_DATA_ACTION({
      product: { meta },
      examination: { study_examination_id },
      results,
    }) {
      return axios.post(
        `${URLS.STUDY_RESULT}/${meta.study_id}/${meta.id}/${study_examination_id}`,
        { results }
      );
    },
  },
  created: function() {
    this.getStudyWorkFlow();
  },
  watch: {
    "$store.state.currentStudyWorkflow": function() {
      const { state } = this.$store;

      this.examination =
        state.currentStudyWorkflow.studyWorkflow.study_examination;

      this.workflow =
        state.currentStudyWorkflow.studyWorkflow.workflow_definition;

      this.workflowCompleted = this.examination.study_workflow_completed;
    },
  },
  data: function() {
    return {
      loading: true,
      education: null,
      examination: null,
      workflow: null,
      PRODUCTS: PRODUCTS,

      dermatoscopicDiagnoses: null,
      plannedDiagnoses: null,
      diagnosticsDifficultyLevels: null,
      aiImportanceInDiagnosticsLevels: null,
      futureUseOfAiInDiagnostics: null,

      dermatoscopicDiagnosisId: null,
      plannedDiagnosisId: null,
      diagnosticsDifficulty: null,

      workflow: null,
      aiResults: null,
      workflowCompleted: false,
      showInfoModal: false,
    };
  },
  computed: {
    locationBack() {
      return locationOf(ROUTES.research, {
        query: {
          studyId: this.$store?.state?.currentStudyWorkflow?.studyWorkflow?.workflow_definition?.meta?.study_id?.toString(),
        },
      });
    },
  },
  methods: {
    getStudyWorkFlow: async function() {
      this.loading = true;
      const { dispatch } = this.$store;
      await dispatch(STORE_CONSTANTS.LOAD_STUDY_WORKFLOW, {
        userId: this.$store.state.user.id,
        studyExaminationId: this.$route.params.id,
        refresh: true,
      });
      this.loading = false;
    },
    isWorkflowCompleted: function() {
      // users should not leave workflow 3 if only answered 1 round
      // examination.study_result_set is either null, 1 or 2
      //
      // (!) The `?.` in these checks are a temporary solution to avoid a bug
      // where this checks fails and thus doesn't let the user leave. It fails
      // when the user navigates to this page, and the workflow doesn't exist.
      // meaning that the store can't load it, but we still keep being on this
      // page. The current code is really not good and should be refactored to
      // use explicit reactive store code, handling errors in its first level,
      // and only continue with the actual component when we have the data for
      // it loaded, as then we are doing the functional correct things. As we
      // are still in the process of creating a better store, and just before
      // a release, we'll apply this quick fix, which will still result in an
      // empty page due to the research case not found, but will not block the
      // user further from navigating elsewhere.
      // So in short, the quick fix is to keep in mind that the data isn't
      // loaded or failed to load. The long-term fix should be to use the right
      // concepts and only activate code when it should....
      // See https://gnosco.atlassian.net/browse/DER-2762
      if (
        !this.workflowCompleted &&
        this.workflow?.meta.double_results &&
        this.examination?.study_result_set === 1
      ) {
        this.showInfoModal = true;
        return false;
      }
      return true;
    },
    closeInfoModal: function() {
      this.showInfoModal = false;
    },
  },
  beforeRouteLeave(to, from, next) {
    // check so users don't leave workflow 3 incomplete
    // to do: add listener on browser navigation, not only vue router
    if (this.isWorkflowCompleted()) {
      next();
    }
  },
  components: {
    ResearchCaseView,
    InfoModal,
  },
};
</script>

<style></style>
