<template>
  <div class="panel panel-default panel-fill panel-actions">
    <div class="panel-heading panel-heading-underline">
      <h3 class="panel-title">
        {{ trans(title) }}

        <span v-if="totalComments">({{ totalComments }})</span>

        <button
          v-if="newDocumentationButton"
          type="button"
          class="btn btn-custom float-right documentation-actions-button"
          @click="newDocumentationButton.onAddNewDocumentation"
        >
          {{ trans("examination.addNewDocumentationAnswerButton") }}
        </button>

        <action-handler
          class="left"
          v-if="actionsMenu"
          @on-action="actionsMenu.onAction"
          :examinationActions="actionsMenu.examinationActions"
          :product="actionsMenu.product"
          :loading="actionsMenu.loading"
          :errorUpdatingActions="actionsMenu.errorUpdatingActions"
          :caseClosedAt="actionsMenu.caseClosedAt"
          :viewOption="actionsMenu.viewOption"
        />

        <!-- need a custom actions handler to be able to show only specific / some product actions -->
        <custom-action-handler
          v-if="customActionsMenu"
          :actions="customActionsMenu.actions"
          @on-action="customActionsMenu.onAction"
          :loading="customActionsMenu.loading"
        ></custom-action-handler>

        <info-popup
          v-if="infoPopupText"
          :text-content="infoPopupText"
        ></info-popup>
        <span class="mx-2 field-timestamp" v-if="updatedDate">{{
          updatedDate
        }}</span>
      </h3>
    </div>
    <slot />
  </div>
</template>

<script>
import InfoPopup from "../../../../Utils/InfoPopup.vue";
import ActionHandler from "./ActionHandler.vue";
import CustomActionHandler from "./CustomActionHandler.vue";

export default {
  components: {
    InfoPopup,
    ActionHandler,
    CustomActionHandler,
  },
  props: {
    title: String,
    updatedDate: String,
    infoPopupText: String,
    actionsMenu: Object,
    customActionsMenu: Object,
    newDocumentationButton: Object,
    totalComments: Number,
  },
};
</script>

<style lang="scss" scoped>
.field-timestamp {
  font-weight: normal;
  font-size: 14px;
  float: right;
}

.documentation-actions-button {
  margin-left: 10px !important;
}
</style>
