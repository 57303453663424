import { NavigationGuard } from "vue-router";
import { locationOf, ROUTES } from "../routing/routes";
import _some from "lodash/fp/some";
import _head from "lodash/fp/head";
import { userStudiesAsAdminGet } from "../../store/core/users";
import { store } from "../../store";

/**
 * This hook will check the available studies, and redirect if needed, because:
 *
 * - Path param `studyId` was added for multiple study support.
 * - Access to a study could have been revoked, or study deleted.
 * - Navigated to the main/root of this module, no study selected.
 *
 * (!) Keep in mind that this hook runs in combination with direct redirects!
 * Redirects are calculated first, then the hooks will run. The research module
 * does have a redirect for deprecated URLs, the ones without `studyId` param.
 */
export const checkStudiesAndRedirect: NavigationGuard = async (
  to,
  from,
  next
) => {
  await userStudiesAsAdminGet(store.state.user.id)
    .then(studies => {
      // If the user ends up here, there should be studies to be admin of!
      // So if this happens, we'll abort the navigation, shouldn't happen!
      if (studies.length === 0) {
        // `next` can never be called twice, so we need to return here.
        return next(false);
      }

      const params = { studyId: _head(studies).id.toString() };

      if (to.name === ROUTES.researchAdmin.name) {
        next(locationOf(ROUTES["researchAdmin.studyCases"], { params }));
      } else if (!_some({ id: parseInt(to.params.studyId, 10) }, studies)) {
        // Should we redirect to first study or to "no access"?
        next(locationOf(ROUTES[to.name], { params }));
      } else {
        next();
      }
    })
    .catch(next);
};
