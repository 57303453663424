<template>
  <div class="row justify-content-center">
    <div class="col-4"><hr /></div>
    <div class="col-auto">
      <span class="hr-text">{{ text }}</span>
    </div>
    <div class="col-4"><hr /></div>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
  },
};
</script>

<style lang="scss" scoped>
.hr-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 2.5em;
  font-weight: bold;
  font-size: 12px;
  color: #676a6c;
}
</style>
