<template>
  <div>
    <div class="overlay" v-if="loading"><clip-loader></clip-loader></div>

    <div @keyup.enter="getResults(1)" style="outline: none;">
      <div class="filter-search-bar">
        <div class="row px-3">
          <v-select
            class="col-md-3 my-3"
            name="patientFilters"
            :options="patientSearchOptions.patientFilters"
            :reduce="filter => filter.value"
            v-model="searchFields.patientsFilter"
            label="text"
            @input="getResults(1)"
            :placeholder="trans('patient.patientsCaseFiltersPlaceholder')"
          ></v-select>

          <v-select
            class="col-md-3 my-3"
            multiple
            name="extraPatientFilters"
            :options="patientSearchOptions.extraPatientFilters"
            :value="selectedExtraPatientFiltersList"
            label="text"
            @input="selectExtraPatientFilters($event)"
            :placeholder="trans('patient.patientsMessageFiltersPlaceholder')"
          ></v-select>

          <form class="col my-3 input-group" @submit.prevent="getResults(1)">
            <input
              type="text"
              class="form-control"
              id="search-field"
              v-model="searchFields.query"
              :placeholder="trans('patient.patientsSearchFieldPlaceholder')"
            />

            <span class="input-group-btn pl-4">
              <button
                class="btn btn-danger mr-2"
                type="button"
                @click.stop="clearSearch"
                v-if="isSearching"
              >
                <span>{{ trans("general.clearSearch") }}</span>
                <i class="fal fa-times"></i>
              </button>
              <button
                class="btn btn-primary"
                type="button"
                @click.stop="getResults(1)"
              >
                <span>{{ trans("general.search") }}</span>
                <i class="far fa-search"></i>
              </button>
            </span>
          </form>
        </div>
      </div>

      <patients-advanced-search-fields
        v-if="isAdvancedSearchOpen"
        :searchOptions="patientSearchOptions"
        :searchFields="searchFields"
      ></patients-advanced-search-fields>

      <div class="clearfix"></div>
      <div class="filter-links">
        <span class="float-right pb-2">
          <a class="advanced-btn" @click="toggleAdvancedSearch">
            {{ trans("evaluation.advancedShow") }}
            <i
              class="far fa-angle-down"
              :class="[
                isAdvancedSearchOpen ? 'fa-angle-up' : 'fa-angle-down',
                'far',
              ]"
            ></i>
          </a>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import PatientsAdvancedSearchFields from "./PatientsAdvancedSearchFields.vue";

export default {
  components: {
    PatientsAdvancedSearchFields,
  },
  props: {
    loading: Boolean,
    patientSearchOptions: {
      patientFilters: Array,
      extraPatientFilters: Array,
      products: Array,
      centers: Array,
      userActivity: Array,
      users: Array,
      statuses: Array,
    },
    searchFields: Object,
  },
  data() {
    return {
      isSearching: false,
      isAdvancedSearchOpen: false,
      selectedExtraFilters: null,
      selectedExtraPatientFiltersList: null,
    };
  },
  methods: {
    getResults: function(page) {
      this.setQueryFields();
      this.$emit("getPatientSearchResults", page);
    },
    selectExtraPatientFilters: function(filtersList) {
      const oldSelectedExtraFilters = this.selectedExtraFilters;

      this.selectedExtraFilters = filtersList;

      const newSelectedExtraFilters = this.selectedExtraFilters;

      this.selectedExtraPatientFiltersList = filtersList;

      oldSelectedExtraFilters.forEach(el => {
        if (
          newSelectedExtraFilters.length !== 0 &&
          newSelectedExtraFilters.includes(el.value)
        ) {
          return;
        } else {
          // if element is not in the new list, assign ""
          this.searchFields[el.value] = "";
        }
      });

      // assign 1 to all elements in the new list
      newSelectedExtraFilters.forEach(element => {
        this.searchFields[element.value] = 1;
      });

      this.getResults(1);
    },
    clearSearch: function() {
      this.isSearching = false;
      this.isAdvancedSearchOpen = false;

      this.$emit("clearSearch");
    },
    toggleAdvancedSearch: function() {
      this.isAdvancedSearchOpen
        ? (this.isAdvancedSearchOpen = false)
        : (this.isAdvancedSearchOpen = true);
    },
    setQueryFields: function() {
      // advanced search and clear button should not be visible if one of the values triggering them gets cleared
      this.isAdvancedSearchOpen = false;
      this.isSearching = false;

      // assign extra filter values after page refresh
      let list = [];
      if (this.patientSearchOptions.extraPatientFilters.length !== 0) {
        for (let key in this.searchFields) {
          this.patientSearchOptions.extraPatientFilters.find(e => {
            if (e.value === key && this.searchFields[key] == 1) {
              list.push(e);
            }
          });
        }
        this.selectedExtraPatientFiltersList = list;
        this.selectedExtraFilters = list;
      }

      for (let key in this.searchFields) {
        let value = this.searchFields[key];
        // if advanced search filter is applied - advanced search is open and user can remove selection
        if (
          value &&
          value !== "" &&
          inArray(key, [
            "query",
            "products",
            "center",
            "userType",
            "user",
            "status",
          ])
        ) {
          this.isAdvancedSearchOpen = true;
          this.isSearching = true;
        }
      }
    },
    getProps: function(el) {
      this.setQueryFields();
    },
  },
  watch: {
    patientSearchOptions: [
      {
        handler: "getProps",
      },
    ],
  },
};
</script>

<style scoped>
.filter-search-bar,
.advanced-search {
  background-color: #f5f5f5;
}

.advanced-search {
  padding-top: 0px;
}
</style>
