<template>
  <div class="chat-conversation-header ">
    <div class="channel-row d-flex justify-content-between align-items-center">
      <channel-icon :channel="channel" />
      <div
        class="channel-row-text ml-3 mr-auto"
        v-if="channel"
        style="width: 100%;"
      >
        <div class="font-weight-normal">
          {{ channel.name }}
        </div>

        <channel-participants-list
          :channel="channel"
        ></channel-participants-list>
      </div>

      <div
        class="channel-row-settings d-inline-block"
        v-if="isEditable(channel)"
      >
        <div class="dropdown">
          <button
            type="button"
            class="btn icon-dropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="fas fa-ellipsis-v"></i>
          </button>

          <!-- channel options -->
          <div class="dropdown-menu">
            <a
              class="dropdown-item"
              href="#"
              @click="toggleChangeChannelName(true)"
              v-if="canEditChannelName(channel)"
              >{{ trans("chat.editChannelName") }}</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click="toggleAddingPeopleToChannel(true)"
              v-if="canAddPeopleToChannel(channel)"
              >{{ trans("chat.addPeopleToChannel") }}</a
            >
            <a
              v-if="canRemoveChannelUsers(channel)"
              class="dropdown-item"
              href="#"
              @click="toggleRemovingPeopleFromChannel(true)"
              >{{ trans("chat.removePoopleFromChannel") }}</a
            >
            <!-- center admins should have a direct link to edit predefined group (changes visible only on new patient cases) -->
            <router-link
              v-if="channel.type === 'predefined' && $can('manage_center')"
              class="dropdown-item"
              :to="`/center/groups/edit/${channel.group_id}`"
              >{{ trans("chat.editGroupInCenter") }}</router-link
            >
          </div>
        </div>
      </div>
    </div>

    <slot></slot>
  </div>
</template>

<script>
import ChannelIcon from "./ChannelIcon.vue";
import ChannelParticipantsList from "./ChannelParticipantsList.vue";

export default {
  components: {
    ChannelIcon,
    ChannelParticipantsList,
  },
  props: {
    channel: Object,
  },
  data() {
    return {
      loading: false,
      showEditChannelName: false,
      newChannelName: "",
    };
  },
  methods: {
    toggleChangeChannelName: function(bool) {
      this.$emit("toggleChangeChannelName", bool);
    },
    toggleAddingPeopleToChannel: function(bool) {
      this.$emit("toggleAddingPeopleToChannel", bool);
    },
    toggleRemovingPeopleFromChannel: function(bool) {
      this.$emit("toggleRemovingPeopleFromChannel", bool);
    },
    isEditable: function(channel) {
      if (
        channel.type === "manual" ||
        (this.$can("manage_center") && channel.type !== "public")
      ) {
        return true;
      } else {
        return false;
      }
    },
    canEditChannelName: function(channel) {
      return (
        channel.type === "manual" &&
        channel.participants &&
        channel.participants.length > 1
      );
    },
    canAddPeopleToChannel: function(channel) {
      return channel.type === "manual";
    },
    canRemoveChannelUsers: function(channel) {
      return (
        channel.type === "manual" &&
        channel.owner &&
        channel.participants &&
        channel.participants.length > 1
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-conversation-header {
  background-color: #f5f5f5;
  border-bottom: 2px solid #dee2e6;

  .channel-row-icon {
    background-color: #fff;
  }

  &:hover {
    cursor: initial;
  }

  .channel-row-settings {
    padding: 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  .channel-row {
    border-bottom: none;
  }

  .icon-dropdown {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #676a6c;
    cursor: pointer;
    &:hover {
      background-color: white;
    }
  }
}
</style>
