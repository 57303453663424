import { practitionerHttpService } from "../../app/practitioner-portal/store/practitioner-http-service";
import {
  RegionCentersSearchParams,
  RegionCentersSearchResult,
} from "../../../../../app/Http/Controllers/Api/Internal/RegionsController";
import { CenterSummary } from "../../../../../app/Dermicus/Centers/CenterSummary";

export function regionCentersGet(id: number) {
  return practitionerHttpService
    .get<CenterSummary[]>(`/api/internal/regions/${id}/centers`)
    .then(response => response.data);
}

export function regionCentersSearch(
  id: number,
  params: RegionCentersSearchParams
) {
  return {
    queryKey: ["regions", "id", id, "centers", "search", params],
    queryFn: () =>
      practitionerHttpService
        .request<RegionCentersSearchResult>({
          method: "POST",
          url: `/api/internal/regions/${id}/centers/search`,
          params,
        })
        .then(response => response.data),
  };
}
