<template>
  <login-form-container>
    <div class="account-content mx-3">
      <div v-if="!verifiedSuccessfully && hashIsValid">
        <div class="text-center my-3">
          <p class="text-muted">
            {{ trans("auth.verifyLabel") }}
          </p>
        </div>

        <div v-if="generalError != ''" class="alert alert-danger">
          {{ generalError }}
        </div>

        <form class="form-horizontal" @submit.prevent="submitVerifyData()">
          <div class="form-group" :class="{ 'has-error': username.hasErrors }">
            <div class="col-xs-12">
              <label for="username">{{ trans("users.usernameLabel") }}</label>
              <input
                id="username"
                type="username"
                class="form-control"
                name="username"
                v-model="username.value"
                required
                autofocus
              />
              <small class="form-text text-muted">{{
                trans("auth.recommendedUsernameLength")
              }}</small>

              <span class="help-block" v-if="username.hasErrors">
                <strong>{{ username.errorMessage }}</strong>
              </span>
            </div>
          </div>

          <div class="form-group" :class="{ 'has-error': password.hasErrors }">
            <div class="col-xs-12">
              <label for="password">{{
                trans("auth.resetPasswordInputLabelPassword")
              }}</label>
              <input
                id="password"
                type="password"
                class="form-control"
                name="password"
                required
                v-model="password.value"
              />
              <small class="form-text text-muted">{{
                trans("auth.recommendedPasswordLength")
              }}</small>

              <span class="help-block" v-if="password.hasErrors">
                <strong>{{ password.errorMessage }}</strong>
              </span>
            </div>
          </div>

          <div
            class="form-group mb-4"
            :class="{ 'has-error': passwordConfirm.hasErrors }"
          >
            <div class="col-xs-12">
              <label for="password-confirm">{{
                trans("auth.resetPasswordInputLabelPasswordConfirm")
              }}</label>
              <input
                id="password-confirm"
                type="password"
                class="form-control"
                name="password_confirmation"
                required
                v-model="passwordConfirm.value"
              />

              <span class="help-block" v-if="passwordConfirm.hasErrors">
                <strong>{{ passwordConfirm.errorMessage }}</strong>
              </span>
            </div>
          </div>

          <div class="form-group account-btn text-center">
            <div class="col-md-12">
              <clip-loader v-show="loading"></clip-loader>
              <button
                v-if="!loading"
                type="submit"
                class="btn btn-custom btn-block btn-lg"
              >
                {{ trans("auth.verifyAccountInputLabel") }}
              </button>
            </div>
            <div class="col-md-12 mt-2">
              <router-link to="/auth" class="btn btn-grey btn-block btn-lg">
                {{ trans("auth.forgotPasswordBackButton") }}</router-link
              >
            </div>
          </div>
        </form>

        <div class="clearfix"></div>
      </div>

      <div v-if="verifiedSuccessfully" class="text-center my-4">
        {{ trans("auth.verifyAccountSuccess") }}
      </div>

      <div
        v-if="generalError != '' && !hashIsValid"
        class="alert alert-danger text-center my-4"
      >
        {{ generalError }}
      </div>

      <div
        v-if="!hashIsValid && !loading"
        class="alert alert-warning text-center my-4"
      >
        {{ trans("auth.verifyHashInvalid") }}
      </div>
    </div>
  </login-form-container>
</template>

<script>
import LoginFormContainer from "./LoginFormContainer.vue";
import { lang } from "../../i18n";

export default {
  components: {
    LoginFormContainer,
  },
  mounted: function() {
    var instance = this;
    axios
      .get(
        "/api/internal/auth/verification/verify-hash/" +
          this.$route.params.id +
          "/" +
          this.$route.params.hash
      )
      .then(response => {
        this.loading = false;
        this.hashIsValid = response.data.valid;
        lang.setLocale(response.data.language);
      })
      .catch(function(error) {
        instance.generalError = this.trans("errorMessages.HTTPStatus.5XX");
        this.userErrorMessage(error);
      });
  },
  data() {
    return {
      loading: true,
      success: false,
      generalError: "",
      username: {
        value: "",
        hasErrors: false,
        errorMessage: "",
        successMessage: "",
      },
      password: {
        value: "",
        hasErrors: false,
        errorMessage: "",
      },
      passwordConfirm: {
        value: "",
        hasErrors: false,
        errorMessage: "",
      },
      verifiedSuccessfully: false,
      hashIsValid: false,
    };
  },
  methods: {
    submitVerifyData: function() {
      let instance = this;
      axios
        .post(
          "/api/internal/auth/verification/verify-account/" +
            this.$route.params.id +
            "/" +
            this.$route.params.hash,
          {
            username: this.username.value,
            password: this.password.value,
            password_confirmation: this.passwordConfirm.value,
            id: this.id,
            hash: this.hash,
            lang_code: localStorage.getItem("lang_code", "en"),
          }
        )
        .then(response => {
          this.loading = false;
          this.verifiedSuccessfully = true;
          setTimeout(
            function() {
              instance.$router.push({ name: "Auth" });
            },
            3000,
            instance
          );
        })
        .catch(function(error) {
          let data = error.response.data;

          if (error.response.status == 500) {
            instance.generalError = this.trans("errorMessages.HTTPStatus.5XX");
          }

          if (data) {
            if (data.error && data.error.general) {
              instance.generalError = data.error.general;
            }

            if (data.username) {
              instance.username.hasErrors = true;
              instance.username.errorMessage = data.username[0];
            } else {
              instance.username.hasErrors = false;
            }

            if (data.password) {
              instance.password.hasErrors = true;
              instance.passwordConfirm.hasErrors = true;
              instance.password.errorMessage = data.password[0];
            } else {
              instance.password.hasErrors = false;
              instance.passwordConfirm.hasErrors = false;
            }
          }

          instance.loading = false;
          instance.userErrorMessage(error);
        });
    },
  },
};
</script>
