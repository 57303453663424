<template>
  <div>
    <div class="back-button py-2 px-1 mb-2" @click="backToAllChannels">
      <i class="fal fa-arrow-left"></i>
      <span class="px-2">{{ trans("general.back") }}</span>
    </div>

    <div class="add-private-message-container">
      <div class="header pb-3 pt-1">{{ trans("chat.newChatChannel") }}</div>

      <div id="custom-search-input" class="mb-3" v-if="!loading">
        <input
          type="text"
          class="form-control input-lg"
          :placeholder="trans('chat.searchUserName')"
          :title="trans('general.search')"
          v-model="searchQuery"
        />
      </div>

      <div class="chat-subheader mt-4 mb-3">
        {{ trans("chat.usersListHeader") }}
      </div>

      <clip-loader v-if="loading"></clip-loader>

      <div v-if="selectedUsersNames.length === 0" class="my-3">
        {{ trans("chat.selectUsersToStartConversation") }}
      </div>

      <div class="users-container" v-if="!loading">
        <div
          v-if="!filteredUsersList || filteredUsersList.length === 0"
          class="text-center my-5"
        >
          {{ trans("chat.noUsers") }}
        </div>

        <div
          v-else-if="filteredUsersList && filteredUsersList.length > 0"
          v-for="(user, index) in filteredUsersList"
          :key="index"
          class="channel-row d-flex justify-content-between align-items-center"
          :class="{ selected: isSelected(user) }"
          @click="selectUser(user)"
        >
          <div class="channel-row-icon">
            <img v-if="user.avatar" :src="user.avatar" alt="user-img" />
            <span v-else class="text-center">{{ user.initials }}</span>
          </div>
          <div class="channel-row-text ml-3 mr-auto">
            <div class="font-weight-normal">{{ user.name }}</div>
            <div class="chat-subtext">
              {{ user.primary_center }}
            </div>
          </div>
          <div>
            <i
              v-if="isSelected(user)"
              class="fal fa-check-circle is-selected-icon"
            ></i>
            <i v-else class="fal fa-circle"></i>
          </div>
        </div>
      </div>

      <div class="pb-2 new-chat-buttons">
        <div
          v-if="selectedUsersNames.length !== 0"
          class="py-3 selected-users-list"
        >
          <label>{{ trans("chat.selectedUsersLabel") }}: </label>
          <span>{{ selectedUsersNames.join(", ") }} </span>
        </div>
        <button
          class="btn btn-primary btn-block"
          @click="createNewChannel"
          :disabled="selectedUsersIds.length === 0"
        >
          {{ trans("chat.createChannelButton") }}
        </button>
        <button class="btn btn-secondary btn-block" @click="backToAllChannels">
          {{ trans("chat.cancelButton") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ExternalResource from "./Utils/ExternalResource";

export default {
  props: {
    editSaveUri: String,
  },
  mounted: function() {
    this.getUsers();
  },
  data() {
    return {
      searchQuery: null,
      loading: false,
      allUsersList: [],
      selectedUsersIds: [],
      selectedUsersNames: [],
      filteredUsersList: null,
    };
  },
  watch: {
    searchQuery: function() {
      const users = this.allUsersList;

      this.filteredUsersList = users.filter(user => {
        return user.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
  methods: {
    selectUser: function(user) {
      if (this.selectedUsersIds.includes(user.id)) {
        this.selectedUsersIds = this.selectedUsersIds.filter(
          u => u !== user.id
        );
        this.selectedUsersNames = this.selectedUsersNames.filter(
          u => u !== user.name
        );
        return;
      } else {
        this.selectedUsersIds.push(user.id);
        this.selectedUsersNames.push(user.name);
      }
    },
    getUsers: function() {
      this.loading = true;

      axios
        .get(this.editSaveUri + "/user/candidates")
        .then(response => {
          const allUsers = response.data.users;

          // filter current user
          this.allUsersList = allUsers.filter(
            user => user.id !== this.$store.state.user.id
          );
          this.filteredUsersList = this.allUsersList;

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    backToAllChannels: function() {
      this.$emit("backToAllChannels");
    },
    createNewChannel: function() {
      if (
        ExternalResource.isExternalResouceId(
          this.allUsersList,
          this.selectedUsersIds
        ).isExternalResources
      ) {
        this.$dialog
          .confirm(
            {
              title: this.trans(
                "chat.startConversationWithExternalResourceHeader"
              ),
              body: `${
                ExternalResource.isExternalResouceId(
                  this.allUsersList,
                  this.selectedUsersIds
                ).externalResourcesList
              } ${this.trans(
                "chat.startConversationWithExternalResourceBody"
              )}`,
            },
            {
              okText: this.trans("general.modalConfirmButton"),
              cancelText: this.trans("general.modalCancelButton"),
            }
          )
          .then(() => {
            this.createChannel();
          })
          .catch(() => {});
      } else {
        this.createChannel();
      }
    },
    createChannel: function() {
      this.loading = true;
      axios
        .post(this.editSaveUri, {
          _token: Laravel.csrfToken,
          userIds: this.selectedUsersIds,
        })
        .then(response => {
          this.$emit("newChannelCreated", response.data.id);
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.userErrorMessage(error);
        });
    },
    isSelected: function(user) {
      return this.selectedUsersIds.includes(user.id);
    },
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.selected {
  background-color: #f5f5f5;
}

.fal.fa-circle {
  font-size: x-large;
  color: #676a6ca6;
}

.fal.fa-check-circle {
  font-size: x-large;
  color: variables.$dermicusBlue;
}

.selected-users-list {
  background-color: #fff;
}

.new-chat-buttons {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  background-color: #fff;
}
</style>
