<template>
  <div>
    <field-panel
      class="mt-3"
      v-if="!!studyConsents?.length || !!error"
      :title="'model.examination.studyConsents.label'"
      :infoPopupText="infoPopup()"
    >
      <div class="panel-body">
        <ErrorAlert v-if="!!error" :data-error="error" />
        <span v-if="studyConsents?.length === 0">
          {{ lang.get("model.examination.studyConsents.empty") }}
        </span>
        <div class="row">
          <div
            class="col-md-6 mb-3"
            v-for="studyConsent in studyConsents"
            :key="studyConsent.id"
          >
            <strong>{{ studyConsent.study_name }}</strong>
            <div>
              <Badge :color="badgeColorMap[studyConsent.status]">
                {{
                  lang.get(
                    `model.studyConsentStatus.${studyConsent.status}.display`
                  )
                }}
              </Badge>
            </div>
            <button
              v-if="studyConsent.status === 'active'"
              type="button"
              class="btn btn-secondary mt-3"
              @click="
                examinationStudyConsentDownload({
                  id: dataExaminationId,
                  studyConsentId: studyConsent.id,
                })
              "
            >
              {{ lang.get("patientPortal.action.download") }}
            </button>
          </div>
        </div>
      </div>
    </field-panel>
  </div>
</template>
<script setup lang="ts">
import { ref, toRefs } from "vue";
import { computedAsync } from "@vueuse/core";
import { examinationStudyConsents } from "../../../../app/practitioner-portal/store/examination-study-consents";
import { lang } from "../../../../i18n";
import { examinationStudyConsentDownload } from "../../../../app/practitioner-portal/store/examination-study-consent-download";
import ErrorAlert from "../../../../design-system/ErrorAlert.vue";
import Panel from "../../../../components/Layout/Panel.vue";
import Badge from "../../../../components/Layout/Badge.vue";
import FieldPanel from "./components/FieldPanel.vue";

const error = ref();
const props = defineProps<{ dataExaminationId: number }>();
const { dataExaminationId } = toRefs(props);

const studyConsents = computedAsync(
  () => examinationStudyConsents(dataExaminationId.value),
  undefined,
  { onError: e => (error.value = e) }
);

const infoPopup = function() {
  return (
    lang.get("infoText.study_consent_status_requested") +
    "<br />" +
    lang.get("infoText.study_consent_status_signed") +
    "<br />" +
    lang.get("infoText.study_consent_status_withdrawn")
  );
};

const badgeColorMap = {
  active: "success",
  proposed: "warning",
  rejected: "danger",
};
</script>
