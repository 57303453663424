<template>
  <div>
    <strong>{{ title }}</strong>
    <br />
    <p class="text-muted">
      <router-link
        :to="{
          name: 'PatientOverview',
          params: { id: extraOptions.examination.patient.id },
        }"
        :title="trans('examination.visitPatientOverview')"
        >{{ data }}
      </router-link>
      <i
        class="fal fa-copy p-2"
        :title="trans('general.copyToClipboard')"
        @click="copyPatientId(data)"
      ></i>
    </p>
  </div>
</template>

<script>
import { copyPatientId } from "../../../../../helpers/platform";

export default {
  props: {
    title: String,
    data: String | Number,
    extraOptions: {
      examination: Object,
    },
  },
  data: function() {
    return {
      copyPatientId: copyPatientId,
    };
  },
};
</script>

<style lang="scss" scoped>
@use '/resources/assets/sass/abstracts/variables';

.fal.fa-copy {
  cursor: pointer;

  &:hover {
    color: variables.$mellanBlue;
    transform: scale(1.08);
    font-weight: 400;
  }

  &:active {
    transform: scale(0.98);
  }
}
</style>
